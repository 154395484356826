import ISelectListValueModel from '../../types/selectListModel'
import IAttachment from './attachment'
import ICorporation, { IEmailSetting } from './corporation'
import { IEntity } from './entity'
import { ITag } from './ressource'
import ITeam from './team'

interface IUserAvatar extends IAttachment {
  thumb: IAttachment
}

export type UserRole = 'employee' | 'power_user' | 'admin'

export type DashboardType = 'audit_point_top_twenty'
| 'completion_rate_top_twenty'
| 'checklists_by_status'
| 'overdue_checklists'
| 'completion_rate_per_day'
| 'overdue_checklists_per_week'
| 'checklists_by_due'
| 'audit_points_by_tags'
| 'completion_rate_by_tags'

export interface IDashboardEntry {
  id: string
  position: number
  dashboard_type: DashboardType
  direction: 'asc' | 'desc'
  timespan: number
  start_date?: Date
  end_date?: Date
  chart_type: 'column' | 'line'
  tags: ITag[]
}

export default interface IUser extends IEntity {
  hash?: string
  invite_pending: boolean
  avatar: IUserAvatar
  email: string
  firstname: string
  lastname: string
  phone: string | null
  mobile: string | null
  newsletter: boolean
  corporation_id: number | null
  has_license: boolean
  license_message: string
  license_expiry: Date
  last_active: Date
  last_logged_in: Date
  last_logged_out: Date
  trial_start: Date | null
  trial_end: Date | null
  license_type: 'basic' | 'premium' | 'individual'
  teams: ITeam[]
  corporation: ICorporation | null
  full_name: string
  current_role: UserRole
  corporations: ICorporation[]
  username?: string
  team_ids?: number[]
  password?: string
  has_valid_license?: boolean
  email_setting?: IEmailSetting
  email_setting_attributes?: IEmailSetting
  language: string
}

export function IUserToReactSelectValues(users: IUser[]): Array<ISelectListValueModel<number>> {
  return users.map((user) => ({
    value: user.id,
    label: `${user.firstname} ${user.lastname} <${user.email}>`
  }))
}
