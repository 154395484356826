import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DeviceDetector from 'device-detector-js';
import { Component } from 'react';
import * as React from 'react';
import { isIPad13, isMobileOnly, isTablet, isBrowser } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ScrollMemory from 'react-router-scroll-memory';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import Refiner from 'refiner-js';
import { ConfirmProvider } from 'material-ui-confirm';
import { configureScope } from '@sentry/browser';
import { updateUser } from './actions/userActions';
import api from './api';
import IUser from './api/types/user';
import './App.sass';
import CordovaBadge from './components/CordovaBadge';
import { DataProvider } from './components/DataProvider';
import DebugHashRouter from './components/DebugHashRouter';
import DebugRouter from './components/DebugRouter';
import ErrorBoundary from './components/ErrorBoundary';
import BottomMenu from './components/menu/BottomMenu';
import SideMenu from './components/menu/SideMenu';
import TopMenu from './components/menu/TopMenu';
import DetectOldBrowserNotification from './components/notifications/DetectOldBrowserNotification';
import OfflineIndicator from './components/OfflineIndicator';
import Routes from './components/Routes';
import VersionChecker from './components/VersionChecker';
import EdgeMessage from './components/widgets/EdgeMessage';
import ChatProvider from './contexts/ChatContext';
import GraphQlProvider from './contexts/GraphQlProvider';
import OneSignalSetup from './OneSignalSetup';
import { IAlphaprocessState } from './store';
import ThemeConfig from './theme';
import ITranslationProps from './types/translationProps';
import { isCordova } from './util';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
interface IStateProps {
  isAuthenticated: boolean;
  jwt?: string;
  user?: IUser;
  currentLanguage: string;
}
interface IDispatchProps {
  updateUser: (user: IUser) => void;
}
type Props = IStateProps & IDispatchProps & ITranslationProps;
const localeMap = {
  en: enLocale,
  de: deLocale
};

// see: https://www.apollographql.com/docs/react/errors#%7B%22version%22%3A%223.10.3%22%2C%22message%22%3A12%2C%22args%22%3A%5B%22conversationUpdated%22%2C%22%7B%7D%22%5D%7D
if (process.env.NODE_ENV !== "production") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}
class App extends Component<Props> {
  public static defaultProps = {
    jwt: null,
    user: null
  };
  private readonly routerRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    api.addToken(props.jwt);
    this.routerRef = React.createRef();
  }

  // Yes, we have already two other libraries for user agent based browser detection
  // But both are returning weird version numbers (3 digit) for Safari on some devices
  // however DeviceDetector returns the correct version
  private isOldSafari = (): boolean => {
    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);

    // this issue affects iPads with older Safari but not iPhones
    if (isMobileOnly) {
      return false;
    }
    if (!device.client) {
      return false;
    }
    if (device.client.name === 'Safari' || device.client.name === 'Mobile Safari') {
      const browserMajorVersion = device.client.version && device.client.version.split('.').length ? device.client.version.split('.') : [];
      const osVersion = device.os.version && device.os.version.split('.').length ? device.os.version.split('.') : [];
      const checkVersion = browserMajorVersion.length ? browserMajorVersion : osVersion;
      const isIOS = device.os.name === 'iOS';
      return browserMajorVersion.length && browserMajorVersion.length ? parseInt(browserMajorVersion[0]) < 13 : isIOS && checkVersion.length && parseInt(checkVersion[0]) < 13;
    }
    return false;
  };

  // returns true if this is iOS 13 or lower
  private isiOSLte13 = (): boolean => {
    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);

    // if this is iPadOS 13 returns true
    // iPadOS 13 identifies itself as macOS so we can not use the regular check
    if (isIPad13) {
      return true;
    }
    if (!device.client) {
      return false;
    }
    if (device.client.name === 'Safari' || device.client.name === 'Mobile Safari') {
      const browserMajorVersion = device.client.version && device.client.version.split('.').length ? device.client.version.split('.') : [];
      const osVersion = device.os.version && device.os.version.split('.').length ? device.os.version.split('.') : [];
      const checkVersion = browserMajorVersion.length ? browserMajorVersion : osVersion;
      const isIOS = device.os.name === 'iOS';
      return browserMajorVersion.length && browserMajorVersion.length ? parseInt(browserMajorVersion[0]) <= 13 : isIOS && checkVersion.length && parseInt(checkVersion[0]) <= 13;
    }
    return false;
  };
  public async componentDidMount() {
    // for some reason impulse based scrolling breaks the modals on iOS Safari < 13
    // if this is an old Safari then add a css class to <html>
    // which disables impulse based scrolling when starting the app.
    if (this.isOldSafari()) {
      document.querySelector('html').classList.add('is-old-safari');
    }
    if (this.isiOSLte13()) {
      document.querySelector('html').classList.add('is-safari-lte13');
    }
    if (this.props.user && this.props.user.id) {
      api.users.self().then(response => {
        this.props.updateUser(response);
      });
    }
    if (process.env.NODE_ENV === 'production' && this.props.user) {
      if ('cordova' in window) {
        (window as any).intercom?.registerIdentifiedUser({
          created_at: this.props.user.created_at,
          email: this.props.user.email,
          has_license: this.props.user.has_license,
          name: `${this.props.user.firstname} ${this.props.user.lastname}`,
          user_hash: this.props.user.hash // HMAC using SHA-256
        });
      }
      if (!isMobileOnly) {
        Refiner('setProject', 'befeba00-e868-11ea-90e7-79463aa878bc');
        Refiner('identifyUser', {
          id: this.props.user.id,
          // Replace with your user ID
          email: this.props.user.email,
          // Replace with user Email
          name: this.props.user.full_name // Replace with user name
        });
      }
      // initialize Support Chat if we are already logged in when opening the app
      if (document.querySelector('#show-support')) {
        //this.initializeIntercom()
        this.initializeSupportChat();
      }
      if (this.props.user && this.props.user.id) {
        try {
          if (isCordova()) {
            (window as any).plugins.OneSignal.setExternalUserId(this.props.user.id.toString());
          }
        } catch (e) {
          console.error('error setting up onesignal', e);
        }
      }
    }
    this.routerRef.current?.history?.listen(() => {
      (window as any).Intercom('update');
    });
  }
  public async componentDidUpdate(prevProps: Props) {
    // initialize Support Chat after login
    if (process.env.NODE_ENV === 'production' && this.props.isAuthenticated && !prevProps.isAuthenticated) {
      //this.initializeIntercom()
      this.initializeSupportChat();
    }
    if (this.props.user && this.props.user.id) {
      try {
        configureScope(scope => {
          scope.setUser({
            id: this.props.user.id.toString(),
            email: this.props.user.email,
            username: this.props.user.username
          });
        });
        if (isCordova()) {
          (window as any).plugins.OneSignal.setExternalUserId(this.props.user.id.toString());
        }
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error('error setting up onesignal', e);
      }
    }
  }

  /*private initializeIntercom = () => {
    (window as any).Intercom('boot', {
      app_id: 'jw218iyj',
      created_at: this.props.user.created_at,
      email: this.props.user.email,
      has_license: this.props.user.has_license,
      name: `${this.props.user.firstname} ${this.props.user.lastname}`,
      custom_launcher_selector: '#show-support',
      language_override: this.props.currentLanguage,
      hide_default_launcher: true,
      user_hash: this.props.user.hash // HMAC using SHA-256
    })
  }*/

  private initializeSupportChat = () => {
    // Add Zammad chat
    const zammadScript = document.createElement("script");
    zammadScript.src = "https://support.alphaprocess.io/assets/chat/chat.min.js";
    zammadScript.async = true;
    document.body.appendChild(zammadScript);
    zammadScript.onload = () => {
      (window as any).$(() => {
        new (window as any).ZammadChat({
          title: '<strong>Support benötigt?</strong> Chatte mit uns...',
          background: '#D0F2FF',
          fontSize: '14px',
          chatId: 1
        });
      });
    };
  };
  public render() {
    const {
      t
    } = this.props;
    const userIsFullyAuthenticated = this.props.user;
    const html = document.getElementsByTagName('html')[0];
    if (this.props.isAuthenticated) {
      html.classList.remove('has-bgcolor');
    } else {
      html.classList.add('has-bgcolor');
    }
    const isProd = process.env.NODE_ENV === 'production' && process.env.REACT_APP_SYSTEM === 'prod';
    const beamerConfig = userIsFullyAuthenticated ? `
            var beamer_config = {
                  product_id : 'vJShNnTZ26672', //DO NOT CHANGE: This is your product code on Beamer
                  //user_firstname : "${userIsFullyAuthenticated.firstname}",
                  user_lastname : "${userIsFullyAuthenticated.lastname}",
                  user_email : "${userIsFullyAuthenticated.email}",
                  user_id : "${userIsFullyAuthenticated.id}"
            };` : '';
    const hotJar = isProd ? `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2691607,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    ` : null;
    const gtags = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-NXY12PJQQ0');
      `;
    const facebookPixel = `
    !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '587634202521002');
      fbq('track', 'PageView');
`;
    const isEmbedded = window.location.pathname.startsWith('/embedded');
    const CProvider = userIsFullyAuthenticated ? ChatProvider : React.Fragment;
    const content = <ErrorBoundary>
        <ThemeConfig>
          <ConfirmProvider defaultOptions={{
          title: '',
          cancellationButtonProps: {
            variant: 'contained'
          },
          confirmationButtonProps: {
            variant: 'contained'
          },
          cancellationText: t('common.cancel')
        }}>
            <GraphQlProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[userIsFullyAuthenticated?.language ?? 'de']}>
                <CProvider>
                  {userIsFullyAuthenticated && (isTablet || isBrowser) && !isEmbedded && <SideMenu />}
                  <div id="root-container">
                    {userIsFullyAuthenticated && <Helmet>
                      <script>
                        {beamerConfig}
                      </script>
                      <script type="text/javascript" src="https://app.getbeamer.com/js/beamer-embed.js" defer />
                      <script>
                        {hotJar}
                      </script>
                      <script async src="https://www.googletagmanager.com/gtag/js?id=G-NXY12PJQQ0" />
                      <script>
                        {gtags}
                      </script>
                      <script>
                        {facebookPixel}
                      </script>
                    </Helmet>}
                    {userIsFullyAuthenticated && !isEmbedded && <TopMenu />}
                    {userIsFullyAuthenticated && <DataProvider />}
                    {userIsFullyAuthenticated && isCordova() && <CordovaBadge />}
                    <DetectOldBrowserNotification message={t('outdatedBrowser')} className="outdated-browser-notification" />
                    <EdgeMessage />
                    <ToastContainer position="top-center" theme="colored" />
                    <ReactTooltip id="main-tooltip" html multiline />
                    <ScrollMemory />
                    <OfflineIndicator />
                    <VersionChecker />
                    {userIsFullyAuthenticated && <OneSignalSetup />}
                    <React.StrictMode>
                      <Routes />
                    </React.StrictMode>
                  </div>
                  {userIsFullyAuthenticated && !isEmbedded && <BottomMenu />}
                </CProvider>
              </LocalizationProvider>
            </GraphQlProvider>
          </ConfirmProvider>
        </ThemeConfig>
      </ErrorBoundary>;
    if (isCordova()) {
      return <DebugHashRouter>{content}</DebugHashRouter>;
    }
    return <DebugRouter ref={this.routerRef}>{content}</DebugRouter>;
  }
}
const mapStateToProps = ({
  core: state
}: IAlphaprocessState): IStateProps => ({
  isAuthenticated: !!(state.jwt && state.user),
  jwt: state.jwt,
  user: state.user,
  currentLanguage: state.currentLanguage
});
const mapDispatchToProps = (dispatch: (x: any) => void): IDispatchProps => ({
  updateUser: (user: IUser) => dispatch(updateUser(user))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));