import { useTranslation } from 'react-i18next';
import { ICloudAttachment } from '../../../../../api/types/attachment';
import AttachmentPicker from '../../../../../components/cloudUpload/containers/attachment-picker';
import useCorporationSettings from '../../../../../hooks/useCorporationSettings';
import CloudFileList from '../../../../cloudFiles/CloudFileList';
import MiniAccordion from '../../../../widgets/MiniAccordion';
interface Props {
  attachments: ICloudAttachment[];
  onAdd: (attachments: ICloudAttachment[], accessToken?: string | null) => void | null;
  onDelete: (attachment: ICloudAttachment) => void | null;
  isExpanded?: boolean;
  onExpansion?: (isExpanded: boolean) => void;
  // required because if enforce update when adding multiple files
  cloudAttachmentCount: number;
  title?: string | null;
}
function ResultCloudAttachments({
  attachments,
  isExpanded,
  onExpansion,
  onAdd,
  onDelete,
  title
}: Props) {
  const {
    t
  } = useTranslation();
  const {
    use_onedrive,
    use_google_drive,
    use_dropbox
  } = useCorporationSettings();
  if (!use_google_drive && !use_dropbox && !use_onedrive && attachments.length === 0) return null;
  return <MiniAccordion className="result-cloud-attachments" title={!title ? t('result.cloudAttachments') : title} count={attachments ? attachments.length : 0} isOpen={onExpansion ? isExpanded : undefined} initialOpen={typeof isExpanded !== 'undefined' ? isExpanded : true} setIsOpen={onExpansion ? () => onExpansion(!isExpanded) : undefined}>
      {!!onAdd && <AttachmentPicker onSelect={onAdd} />}
      <CloudFileList files={attachments} onDelete={onDelete} />
    </MiniAccordion>;
}
export default ResultCloudAttachments;