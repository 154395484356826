import { useSelector } from 'react-redux'

import IUser from '../api/types/user'
import { IAlphaprocessState } from '../store'

export function useIsLoggedIn(): boolean {
  return useSelector((state: IAlphaprocessState) => !!state.core.jwt && !!state.core.user)
}

export function useCurrentUser(): IUser {
  return useSelector((state: IAlphaprocessState) => state.core.user)
}

export function useIsAdmin(): boolean {
  return useSelector(
    (state: IAlphaprocessState) => state.core.user && state.core.user?.current_role === 'admin'
  )
}

export function useIsPowerUser(): boolean {
  const role = useSelector(
    (state: IAlphaprocessState) => state.core.user && state.core.user?.current_role
  )

  return role === 'power_user' || role === 'admin'
}

export function useHasCompany(): boolean {
  const user = useCurrentUser()
  return !!(user && user.corporation)
}
