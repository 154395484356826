import { TasksEntity } from '../graphql/queries/FetchBoard'
import { ITaskQuestion } from '../util/BoardUtil'
import { ApiBase } from './ApiBase'
import { ICloudAttachment } from './types/attachment'
import IProject from './types/project'
import IResource from './types/ressource'
import IResult, { IComment, IResultAttachment } from './types/result'
import IResultSet from './types/resultSet'
import ITeam from './types/team'
import IUser from './types/user'

export type TaskPriority = 'trivial' | 'minor' | 'medium' | 'critical' | 'blocker'

export interface ITask {
  id: string
  title: string
  description?: string
  creator?: string
  users?: IUser[]
  teams?: ITeam[]
  status?: string
  lane_id: number
  position: number
  color?: string
  board_id?: number
  team_ids: number[]
  user_ids: number[]
  due_date?: Date
  result_set_ids?: number[]
  result_sets?: IResultSet[]
  task_result_assignment?: ITaskQuestion | null
  checklist?: string
  priority: TaskPriority
  result_id?: string
  attachments?: IResultAttachment[]
  comments?: IComment[]
  ressources?: IResource[]
  cloud_attachments?: ICloudAttachment[]
  projects?: IProject[]
  project_ids: number[]
  ressource_ids: number[]
}

export interface IMoveTask {
  id: string
  position: number
  lane_id: number
}

export interface IMoveTaskResponse {
  updated_tasks: IMoveTask[]
  task_count: number
}

export default class TasksApi extends ApiBase {
  upload(task: TasksEntity, acceptedFiles: File[]) {
    const form = new FormData()
    form.append('filepond', acceptedFiles[0])

    return this.post<IResultAttachment>(`/boards/${task.boardId}/tasks/${task.id}/upload`, form)
  }

  public all(boardId: number) {
    return this.get<ITask[]>(`/boards/${boardId}/tasks`)
  }

  public async create(
    boardId: number,
    task: ITask,
    result: IResult = null,
    accessToken?: string | null
  ): Promise<ITask> {
    if (this.isOffline()) {
      return this.offlineDb.createTask(boardId, task, result.result_set_id, result.id)
    }

    let url = `/boards/${boardId}/tasks`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    const response = await this.post<ITask>(url, task)

    return response.data
  }

  public update(boardId: number, taskId: number | string, task: ITask) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      attachments, creator, users, ...values
    } = task // don't send data to the backend we are not processing
    return this.put<ITask>(`/boards/${boardId}/tasks/${taskId}`, values)
  }

  public move(boardId: number, taskId: number | string, moveTask: IMoveTask) {
    return this.put<IMoveTaskResponse>(`/boards/${boardId}/tasks/${taskId}/move`, moveTask)
  }

  public archiveTask(boardId: number, taskId: any) {
    return this.delete(`/boards/${boardId}/tasks/${taskId}`)
  }

  public deleteTask(boardId: number, taskId: string) {
    return this.delete(`/boards/${boardId}/tasks/${taskId}/delete`)
  }

  public async archive(boardId: number, page: number, term: string) {
    const response = await this.get<ITask[]>(`/boards/${boardId}/tasks/archive`, { params: { page, term } })
    return this.paginateResponse(response)
  }

  public async restore(board_id: number, id: string) {
    const response = await this.post<ITask>(`/boards/${board_id}/tasks/${id}/restore`)
    return response.data
  }
}
