import { Button } from 'bloomer';
import { useTranslation } from 'react-i18next';
interface IProps {
  value: string;
  onUpdate: (value: 'true' | 'false' | 'n/a') => void;
  disabled: boolean;
}
export default function NaButton({
  value,
  onUpdate,
  disabled
}: IProps) {
  const {
    t
  } = useTranslation();
  return <Button className="has-text-weight-bold answer-na" onClick={() => onUpdate('n/a')} isActive={value === 'n/a'} isColor={value === 'n/a' ? 'dark' : 'light'} disabled={disabled}>
      {t('N/A')}
    </Button>;
}