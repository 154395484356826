import * as React from 'react';
import SignaturePad from 'signature_pad';
interface IProps {
  onChange: (svg: string) => void;
  data?: any;
  padId: string;
}
class SignatureWidget extends React.Component<IProps> {
  private canvas: React.RefObject<HTMLCanvasElement>;
  private signaturePad: SignaturePad;
  constructor(props: IProps) {
    super(props);
    this.canvas = React.createRef<HTMLCanvasElement>();
  }
  private onUnmount: () => void;
  public componentDidMount() {
    const {
      onChange,
      data
    } = this.props;
    this.signaturePad = new SignaturePad(this.canvas.current);
    this.signaturePad.addEventListener('endStroke', () => {
      const svg = this.signaturePad.toDataURL('image/svg+xml');
      onChange(svg);
    });
    this.signaturePad.clear();
    if (data) {
      this.signaturePad.fromDataURL(data);
      const pointData = this.signaturePad.toData();
      this.signaturePad.fromData(pointData);
    }
    const resizeCanvas = () => {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.current.width = this.canvas.current.offsetWidth * ratio;
      this.canvas.current.height = this.canvas.current.offsetHeight * ratio;
      this.canvas.current.getContext('2d').scale(ratio, ratio);
      // this.signaturePad.clear() // otherwise isEmpty() might return incorrect value
    };
    this.onUnmount = () => {
      window.removeEventListener('resize', resizeCanvas);
    };

    // window.addEventListener('resize', resizeCanvas)
    resizeCanvas();
  }
  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data) {
        this.signaturePad.fromDataURL(this.props.data);
        const pointData = this.signaturePad.toData();
        this.signaturePad.fromData(pointData);
      }
    }
  }
  public componentWillUnmount() {
    if (this.onUnmount) {
      this.onUnmount();
    }
  }
  public render() {
    return <canvas id={this.props.padId} className="signature-pad" ref={this.canvas} />;
  }
}
export default SignatureWidget;