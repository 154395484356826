import querystring from 'query-string'
import { ApiBase } from './ApiBase'
import IContact from './types/contact'

class ContactsApi extends ApiBase {
  public find(id: number) {
    return this.get<IContact>(`/contacts/${id}`)
  }

  public async all(accessToken?: string | null) {
    if (this.isOffline()) {
      return this.offlineDb.contacts.toArray()
    }

    let url = '/contacts'

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    const response = await this.get<IContact[]>(url)

    return response.data
  }

  public async page(page: number, searchSubject: string | null, companies: string[] | null) {
    const params = {
      term: searchSubject,
      companies,
      page
    }

    const query = querystring.stringify(params, { arrayFormat: 'bracket' })

    const response = await this.get<IContact[]>(`/contacts?${query}`)

    return this.paginateResponse<IContact>(response)
  }

  public create(data: any) {
    return this.post<IContact>('/contacts', data)
  }

  public update(id: number, data: any) {
    return this.put<IContact>(`/contacts/${id}`, data)
  }

  public deleteContact(id: number) {
    return this.delete(`/contacts/${id}`)
  }
}

export default ContactsApi
