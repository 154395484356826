import { Box, Button, Title } from 'bloomer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { IFolder } from '../../../../../api/FolderApi';
import LoadSpinner from '../../../../loadSpinner/LoadSpinner';
import FolderDialogEntry from './FolderDialogEntry';
import './styles.sass';
interface Props {
  onClose: () => void;
  onMove: (folder: IFolder) => void;
  folderType?: 'boards' | 'checklists';
}
function MoveFolderDialog({
  onClose,
  onMove,
  folderType = 'checklists'
}: Props) {
  const {
    t
  } = useTranslation();
  const [root, setRoot] = useState<IFolder>(null);
  const [selected, setSelected] = useState<IFolder>(null);
  useEffect(() => {
    if (!root) {
      api.folders.root().then(result => setRoot(result));
    }
  });
  const handleMove = () => {
    onMove(selected);
    onClose();
  };
  return <Box className="move-folder-dialog">
      <Title isSize={6} className="has-text-centered">
        {t('folder.moveObject')}
      </Title>
      {!root && <LoadSpinner className="centered" />}
      <div className="folder-tree">
        {root && <FolderDialogEntry folderType={folderType} selectedFolder={selected} folder={root} level={0} onClick={setSelected} />}
      </div>
      <div className="buttons has-margin-top-8 is-right">
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        <Button isColor="link" disabled={!selected} onClick={handleMove}>{t('common.move')}</Button>
      </div>
    </Box>;
}
export default MoveFolderDialog;