import { Button, Control, Field, Icon, Input } from 'bloomer';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useIsPowerUser } from '../../../hooks/authHooks';
interface Props {
  startDate: Date | null;
  onChange: (startDate: Date | null) => void;
}
function StartDateControl({
  startDate,
  onChange
}: Props) {
  const {
    t
  } = useTranslation();
  const isPowerUser = useIsPowerUser();
  const parsedDate = typeof startDate === 'string' ? parseISO(startDate) : startDate;
  return <Field className="start-date-control" isGrouped>
      <Control className="text-control">
        <Input value={startDate ? format(parsedDate, 'yyyy-MM-dd HH:mm') : ''} disabled className="start-date-input" />
      </Control>
      <Control className="start-date-buttons">
        <Button isHidden={!!startDate} isFullWidth isColor="link" onClick={() => onChange(new Date())}>
          <Icon className="fa fa-clock" />
          {t('common.start')}
        </Button>
        {isPowerUser && <Button isHidden={!startDate} isFullWidth isColor="link" onClick={() => onChange(null)}>
            <Icon className="fa fa-undo" />
            {t('common.reset')}
          </Button>}
      </Control>
    </Field>;
}
export default StartDateControl;