import { Notification } from 'bloomer';
import { useTranslation } from 'react-i18next';
function ReportFailed() {
  const {
    t
  } = useTranslation();
  return <Notification isColor="danger" className="has-margin-top-10">
      <p>{t('resultSet.couldNotGenerateReport')}</p>
      <p>{t('errorPage.pleaseContactSupport')}</p>
    </Notification>;
}
export default ReportFailed;