import { useTranslation } from 'react-i18next';
import ReactSelect, { MenuPlacement } from 'react-select';
import { ITag } from '../../../api/types/ressource';
import { reactSelectMaxMenuHeight } from '../../../constants';
interface IProps {
  tags: ITag[];
  onChange: (value: number | number[]) => void;
  selected: number | number[];
  isMulti?: boolean;
  placeholder: string;
  name?: string;
  id?: string;
  setSelectedAsValue?: boolean;
  onMenuOpen?: any;
  menuPlacement?: MenuPlacement | null;
}
export default function TagSelect(props: IProps) {
  const {
    t
  } = useTranslation();
  const options = props.tags.map(tag => ({
    value: tag.id,
    label: tag.title
  }));
  const selected = Array.isArray(props.selected) ? options.filter(o => (props.selected as number[]).includes(o.value)) : options.find(o => o.value === props.selected);
  const handleChange = (values: any) => {
    if (Array.isArray(values)) {
      props.onChange(values.map(v => v.value));
    } else {
      props.onChange(values.value);
    }
  };
  return <ReactSelect options={options} isMulti={props.isMulti} onChange={handleChange} value={props.setSelectedAsValue ? selected : undefined} placeholder={props.placeholder} isSearchable maxMenuHeight={reactSelectMaxMenuHeight} className="dropdown-select-container" classNamePrefix="dropdown-select" name={props.name} id={props.id} onMenuOpen={props.onMenuOpen} noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
    menuPortal: base => ({
      ...base,
      zIndex: 9999
    })
  }} />;
}