import { IArea, IQuestion, IRule, IRuleSet } from '../api/types/checklist';
import { translateValue } from '../components/history/ResultHistoryEntry';
import { ruleActionTypeOptions, ruleLinkOperatorOptions, ruleOperatorOptions } from '../constants';
import i18n from '../i18n';
export default class RuleUtil {
  public static getRuleText(rule: IRule, ruleSet: IRuleSet, questions: IQuestion[]): string {
    const operator = ruleOperatorOptions.find(x => x.value === rule.operator).translationKey;
    let expected = translateValue('value', rule.expected, null);
    const question = questions.find(q => q.id === ruleSet.question_id);
    if (question && question.question_type === 'dropdown') {
      const item = question.question_dropdown_items.find(x => x.id === parseInt(rule.expected));
      if (item) {
        expected = `"${item.title}"`;
      }
    }
    const link_operator = rule.link_operator ? ruleLinkOperatorOptions.find(x => x.value === rule.link_operator).translationKey : null;
    let text = `${i18n.t(operator)} ${expected}`;
    if (link_operator) {
      text += ` ${i18n.t(link_operator)}`;
    }
    return text;
  }
  public static getRuleSetText(ruleSet: IRuleSet, areas: IArea[]): string {
    const questions = areas.map(area => area.questions).flat();
    const texts = ruleSet.rules.map(rule => RuleUtil.getRuleText(rule, ruleSet, questions));
    if (ruleSet.rule_action) {
      texts.push(i18n.t('common.then'));
      const actionType = ruleActionTypeOptions.find(x => x.value === ruleSet.rule_action.action_type);
      const actionString = actionType && actionType.translationKey ? i18n.t(actionType.translationKey) : null;
      if (actionString) {
        texts.push(actionString);
      }
      switch (ruleSet.rule_action.action_type) {
        case 'create_task':
          texts.push(`"${ruleSet.rule_action.task_data?.title || '-'}"`);
          break;
        case 'set_question_value':
          // find question title and show it
          areas.forEach(oneArea => {
            const question = oneArea.questions.filter(x => ruleSet.rule_action.target_question_ids[0] === x.id);
            if (question.length) {
              texts.push(`"${question[0].title}" = `);
            }
          });
          // Show a translated human readable value
          const valueText = translateValue('value', ruleSet.rule_action.value, null);
          texts.push(`"${valueText}"`);
          break;
        case 'show_area':
        case 'duplicate_area':
          // Find and show the area title
          const theAreas = areas.filter(x => ruleSet.rule_action.target_area_ids.includes(typeof x.id === 'number' && x.id));
          texts.push(theAreas.length ? theAreas.map(a => `"${a.title}"`).join(', ') : '-');
          break;
        case 'show_question':
          // Find and show the question title
          // iterate through all areas
          // if we find a question with that id, append it's name to the tooltipTexts
          areas.forEach(oneArea => {
            const showQuestions = oneArea.questions.filter(x => ruleSet.rule_action.target_question_ids.includes(x.id));
            if (showQuestions.length) {
              const questionsText = showQuestions.map(q => `"${q.title}"`);
              texts.push(questionsText.join(', '));
            }
          });
      }
    }
    return texts.join(' ');
  }
}