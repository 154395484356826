import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';
import { IAlphaprocessState } from '../../../store';
interface IProps {
  onChange: (value: number | number[]) => void;
  selected: number | number[];
  isMulti?: boolean;
  placeholder: string;
  name?: string;
  id?: string;
  onMenuOpen?: any;
  readonly?: boolean;
}
interface NewProps {
  label: string;
  selected: number | number[];
  onChange: (selected: number | number[]) => void;
  multiple?: boolean;
  disabled?: boolean;
}
export function NewTeamSelect({
  label,
  selected,
  onChange,
  multiple = false,
  disabled
}: NewProps) {
  const teams = useSelector((state: IAlphaprocessState) => state.data.teams);
  const value = Array.isArray(selected) ? selected : [selected];
  return <Autocomplete fullWidth value={teams.filter(x => value.includes(x.id))} options={teams} multiple={multiple} disabled={disabled} getOptionLabel={team => typeof team === 'string' ? team : team.name} renderInput={params => <TextField {...params} label={label} />} onChange={(event: any, newValue) => {
    if (typeof newValue === 'string') {
      onChange(parseInt(newValue));
    } else if (Array.isArray(newValue)) {
      const newSelected = newValue.map(x => typeof x === 'string' ? parseInt(x) : x.id);
      onChange(newSelected);
    } else if (newValue) {
      onChange(newValue.id);
    } else {
      onChange(null);
    }
  }} />;
}
export default function TeamSelect(props: IProps) {
  const {
    t
  } = useTranslation();
  const teams = useSelector((state: IAlphaprocessState) => state.data.teams);
  const options = teams ? teams.map(team => ({
    value: team.id,
    label: team.name
  })) : [];
  const selected = Array.isArray(props.selected) ? options.filter(o => (props.selected as number[]).includes(o.value)) : options.find(o => o.value === props.selected);
  const handleChange = (values: any) => {
    if (Array.isArray(values)) {
      props.onChange(values.map(v => v.value));
    } else {
      props.onChange(values.value);
    }
  };
  return <ReactSelect options={options} isMulti={props.isMulti} onChange={handleChange} value={selected} placeholder={props.placeholder} isSearchable className="dropdown-select-container" classNamePrefix="dropdown-select" name={props.name} id={props.id} onMenuOpen={props.onMenuOpen} noOptionsMessage={() => t('common.noOptions')} isDisabled={props.readonly} menuPortalTarget={document.body} menuPlacement="auto" styles={{
    menuPortal: base => ({
      ...base,
      zIndex: 9999
    })
  }} />;
}