import { TFunction } from 'i18next';
import { ICloudAttachment } from '../api/types/attachment';
import { alert } from './dialogs';
export const checkFileSelection = (file: ICloudAttachment, otherFiles: ICloudAttachment[], t: TFunction): boolean => {
  // Dropbox only returns an url if the file can be shared by link
  // if this it not enabled for the selected file then show an error message
  if (file.provider === 'dropbox' && !file.url) {
    alert(t('cloudAttachments.errors.dropboxLinkMissing', {
      filename: file.name
    }));
    return false;
  }
  // if this file was already added don't add it again
  return !otherFiles.some(x => x.provider === file.provider && x.url === file.url && !x._destroy);
};