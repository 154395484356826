import { ApiBase } from './ApiBase'

import IAttachment from './types/attachment'

export interface IImportError {
  sheet: string
  message: string
  row: number
  column: number
  value: string
  fatal: boolean
}

export interface IExcelImportResponse {
  checklist_title: string
  id: number
  checklist_id: number
  finished: boolean
  import_errors: IImportError[]
  error_file: IAttachment
}

export default class ExcelImportApi extends ApiBase {
  /**
   * Uploads an excel file to be imported
   *
   * @param file file to upload
   */
  public uploadFile(file: File) {
    const formData = new FormData()
    formData.append('import_file', file)
    return this.post<IExcelImportResponse>('/excel_imports', formData)
  }

  public async find(id: number) {
    const response = await this.get<IExcelImportResponse>(`/excel_imports/${id}`)
    return response.data
  }
}
