import { Notification } from 'bloomer';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { FilterLocation } from '../../actions/filterActions';
import { emptyFilterSettings, getFilterSettings } from '../../reducers/filterReducer';
import { IAlphaprocessState } from '../../store';
interface Props {
  filterLocation: FilterLocation;
}
const ActiveFilterMessage: React.FunctionComponent<Props> = ({
  filterLocation
}) => {
  const allFilters = useSelector((state: IAlphaprocessState) => state.filter);
  const filters = getFilterSettings(allFilters, filterLocation) || emptyFilterSettings;
  const filterActive = filters.startDate || filters.endDate || filters.selectedProjects.length || filters.selectedRessources.length || filters.selectedContacts.length || filters.selectedTeams.length || filters.selectedTesters.length || filters.selectedCompanies?.length || filters.selectedTags.length || filters.term && filters.term.length > 0;
  if (!filterActive) return null;
  return <Notification isColor="info">
      <Trans>filters.activeMessage</Trans>
    </Notification>;
};
export default ActiveFilterMessage;