import { gql } from '@apollo/client'

export interface CreateQuestionPayload {
  questionType: string;
  isTimespan: boolean;
  showTasksButton: boolean;
  max: number |null;
  min: number | null;
  unit: string;
  hasAuditPoints: boolean;
  isDescriptionCollapsed: boolean;
  hasTime: boolean;
  areaId: number;
  position: number;
  isRequired: boolean;
  enableAttachments: boolean;
  enableSpeechToText: boolean;
  isHorizontal: boolean;
  isMulti: boolean;
  defaultValue: string;
  excludeFromProgress: boolean;
  isTextfield: boolean;
  hasAttachmentsRequired: boolean;
  signeeFreeText: boolean;
  displayAsCheckbox: boolean;
  enableComments: boolean;
  title: string;
  hasDefaultToday: boolean;
  infotext: string;
  description: string;
  disableNa: boolean;
}

export interface Question {
  id: string;
}

export interface CreateQuestionMutation {
  errors: (string)[]
  question: Question;
}

export interface CreateQuestionResponse {
  createQuestion: CreateQuestionMutation;
}

const CREATE_QUESTION = gql`
  mutation createQuestion(
    $title: String!
    $description: String!
    $questionType: String!
    $position: Int!
    $areaId: ID!
    $min: Int
    $max: Int
    $hasAuditPoints: Boolean!
    $isMulti: Boolean!
    $isRequired: Boolean!
    $displayAsCheckbox: Boolean!
    $disableNa: Boolean!
    $isTextfield: Boolean
    $isHorizontal: Boolean!
    $hasTime: Boolean!
    $hasDefaultToday: Boolean!
    $unit: String
    $infotext: String
    $defaultValue: String
    $enableComments: Boolean!
    $enableAttachments: Boolean!
    $excludeFromProgress: Boolean
    $isDescriptionCollapsed: Boolean!
    $hasAttachmentsRequired: Boolean
    $signeeFreeText: Boolean
    $enableSpeechToText: Boolean
    $showTasksButton: Boolean
    $isTimespan: Boolean!
) {
  createQuestion(
    title: $title
    description: $description
    questionType: $questionType
    position: $position
    areaId: $areaId
    min: $min
    max: $max
    hasAuditPoints: $hasAuditPoints
    isRequired: $isRequired
    isMulti: $isMulti
    displayAsCheckbox: $displayAsCheckbox
    disableNa: $disableNa
    isTextfield: $isTextfield
    isHorizontal: $isHorizontal
    hasTime: $hasTime
    hasDefaultToday: $hasDefaultToday
    isTimespan: $isTimespan
    unit: $unit
    infotext: $infotext
    defaultValue: $defaultValue
    enableComments: $enableComments
    enableAttachments: $enableAttachments
    excludeFromProgress: $excludeFromProgress
    isDescriptionCollapsed: $isDescriptionCollapsed
    hasAttachmentsRequired: $hasAttachmentsRequired
    showTasksButton: $showTasksButton
    signeeFreeText: $signeeFreeText
    enableSpeechToText: $enableSpeechToText
  ) {
    errors
    question {
      id
    }
  }
}

`
export default CREATE_QUESTION
