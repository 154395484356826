import { Component } from 'react';
import { connect } from 'react-redux';
import api from '../../../api';
import { ICompany } from '../../../api/CompanyApi';
import { IChecklist } from '../../../api/types/checklist';
import IContact from '../../../api/types/contact';
import IProject from '../../../api/types/project';
import IResource from '../../../api/types/ressource';
import IResultSet from '../../../api/types/resultSet';
import IUser from '../../../api/types/user';
import { IAlphaprocessState } from '../../../store';
import LoadSpinner from '../../loadSpinner/LoadSpinner';
import EditResultSetModal from './EditResultSetModal';
interface IProps extends IStateProps {
  id: number;
  onClose: () => void;
  onSubmit: (resultSet: IResultSet) => void;
}
interface IStateProps {
  user: IUser;
}
interface IState {
  resultSet: IResultSet;
  isLoading: boolean;
  contacts: IContact[];
  resources: IResource[];
  users: IUser[];
  projects: IProject[];
  error: any;
  checklist?: IChecklist | null;
  companies: ICompany[];
}
class EditModalWrapper extends Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    resultSet: null,
    contacts: [],
    resources: [],
    users: [],
    projects: [],
    companies: [],
    error: null
  };
  public async componentDidMount() {
    try {
      const projects = await api.projects.select(this.props.user.corporation_id, 0);
      const resultSet = await api.resultSets.find(0, this.props.id);
      const checklist = await api.checklists.find(resultSet.checklist_id);
      const resources = await api.resources.select();
      const contacts = await api.contacts.all();
      const users = await api.users.forUser();
      const companies = await api.companies.select();
      if (!projects.some(x => x.id === resultSet.project_id)) {
        projects.push(({
          name: resultSet.project,
          id: resultSet.project_id
        } as any));
      }
      this.setState({
        projects,
        resultSet,
        resources,
        contacts,
        users,
        checklist,
        companies
      });
    } catch (error) {
      this.setState({
        error
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  }
  public render() {
    const {
      isLoading,
      resultSet,
      contacts,
      users,
      resources,
      companies
    } = this.state;
    if (isLoading) {
      return <LoadSpinner className="centered" />;
    }

    // ADF-983
    // if projects are assigned to the checklist template only these are selectable
    // if no projects are assigned then all projects can be selected
    const projects = this.state.checklist.project_ids && this.state.checklist.project_ids.length ? this.state.projects.filter(x => this.state.checklist.project_ids.some(y => y === x.id)) : this.state.projects;
    return <EditResultSetModal resultSet={resultSet} contacts={contacts} isActive onClose={this.props.onClose} onSubmit={this.props.onSubmit} projects={projects} resources={resources} users={users} companies={companies} />;
  }
}
function mapStateToProps(state: IAlphaprocessState): IStateProps {
  return {
    user: state.core.user
  };
}
export default connect(mapStateToProps)(EditModalWrapper);