import { Delete, Notification } from 'bloomer';
import { useState } from 'react';
import { isEdge } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
export default function EdgeMessage() {
  const [isDismissed, setDismissed] = useState(false);
  const {
    t
  } = useTranslation();
  if (isEdge) {
    return <Notification isColor="warning" isHidden={isDismissed}>
        {t('edgeWarning')}
        <Delete onClick={() => setDismissed(true)} />
      </Notification>;
  }
  return null;
}