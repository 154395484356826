// import './wdyr'

import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from "@sentry/react";
import 'filepond/dist/filepond.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import OneSignal from 'react-onesignal';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'url-search-params-polyfill';
import api from './api';
import App from './App';
import LoadSpinner from './components/loadSpinner/LoadSpinner';
import './i18n';
import './index.sass';
import { persistor, store } from './store';
import { isCordova } from './util';
LicenseInfo.setLicenseKey('6a515dfcef5f5bae4b86de8435c76cf6T1JERVI6NDMwNjgsRVhQSVJZPTE2ODMyNzA3OTAwMDAsS0VZVkVSU0lPTj0x');
function configureOneSignal() {
  if (isCordova()) {
    console.debug('setting up one signal');
    console.debug('one signal app id', process.env.REACT_APP_ONE_SIGNAL_APP_ID);
    const oneSignal = (window as any).plugins.OneSignal;
    if (!oneSignal) {
      console.error('one signal is not defined');
      return;
    }
    oneSignal.setLogLevel(6, 0); // VERBOSE, NONE

    const notificationOpenedCallback = jsonData => {
      console.log(`notificationOpenedCallback: ${JSON.stringify(jsonData)}`);
    };
    const iosSettings: any = {};
    iosSettings.kOSSettingsKeyAutoPrompt = true;
    iosSettings.kOSSettingsKeyInAppLaunchURL = false;
    if (typeof oneSignal.setAppId === 'function') {
      oneSignal.setAppId(process.env.REACT_APP_ONE_SIGNAL_APP_ID);
      oneSignal.setNotificationOpenedHandler(notificationOpenedCallback);
    } else {
      oneSignal.startInit(process.env.REACT_APP_ONE_SIGNAL_APP_ID).handleNotificationOpened(notificationOpenedCallback).iOSSettings(iosSettings).inFocusDisplaying(oneSignal.OSInFocusDisplayOption.Notification).endInit();
    }

    // The promptForPushNotificationsWithUserResponse
    // function will show the iOS push notification prompt.
    // We recommend removing the following code and
    // instead using an In-App Message to prompt for notification permission (See step 6)
    oneSignal.promptForPushNotificationsWithUserResponse(accepted => {
      console.log(`User accepted notifications: ${accepted}`);
    });
  } else if (process.env.NODE_ENV === 'development') {
    OneSignal.init({
      appId: '0e2c2191-5dc1-4cac-a6cb-e852b8fedf30',
      allowLocalhostAsSecureOrigin: true,
      autoRegister: true,
      autoResubscribe: true,
      notifyButton: {
        enable: false
      }
    });
  } else {
    OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
      safari_web_id: process.env.REACT_APP_ONE_SIGNAL_SAFARI_WEB_ID,
      autoRegister: true,
      autoResubscribe: true,
      notifyButton: {
        enable: false
      }
    });
  }
}
const startApp = async () => {
  if (process.env.NODE_ENV === 'production' && navigator && 'serviceWorker' in navigator && !isCordova()) {
    navigator.serviceWorker.register('/OneSignalSDKWorker.js');
  }
  if (process.env.NODE_ENV === 'production') {
    configureOneSignal();
  }
  if (isCordova()) {
    window.open = (window as any).cordova.InAppBrowser.open;
  }
  const jwt = localStorage.getItem('jwt');
  if (jwt) {
    api.addToken(jwt);
  }
  ReactDOM.render(<HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={<LoadSpinner className="centered" />} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </HelmetProvider>, document.getElementById('root'));
};
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `alphaprocess@${process.env.REACT_APP_COMMIT_SHA}`,
    ignoreErrors: ['Non-Error promise rejection captured', 'Network Error', 'Request aborted']
  });
}
const {
  cordova,
  StatusBar
} = (window as any);
if (cordova) {
  if (StatusBar) {
    StatusBar.styleDefault();
  }
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}