import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTags, updateTeamsAction, updateUsersAction } from '../actions/dataStoreActions';
import api from '../api';
export function DataProvider() {
  const dispatch = useDispatch();
  useEffect(() => {
    api.tags.all().then(response => {
      dispatch(updateTags(response.data));
    });
    api.users.all().then(response => {
      dispatch(updateUsersAction(response));
    });
    api.teams.all(0).then(response => {
      dispatch(updateTeamsAction(response));
    });
  }, [dispatch]);
  return <></>;
}