import { gql } from '@apollo/client'

export interface MarkConversationAsReadVariables {
  id: string
}

const markConversationAsReadMutation = gql`
  mutation markConversationAsRead($id: ID!) {
      markConversationAsRead(conversationId: $id) {
          conversation {
              id
              unreadCount
          }
      }
  }
`

export default markConversationAsReadMutation
