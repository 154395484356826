import { Dropdown, DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger, Icon } from 'bloomer';
import { useCallback } from 'react';
import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { IBoard } from '../../../api/boardsApi';
import IContact from '../../../api/types/contact';
import IResource from '../../../api/types/ressource';
import IResultSet from '../../../api/types/resultSet';
import ITeam from '../../../api/types/team';
import IUser from '../../../api/types/user';
import { useIsPowerUser } from '../../../hooks/authHooks';
import settingsIcon from '../../../images/icon_settings.png';
import { IAlphaprocessState } from '../../../store';
import ReportDownloader from './ReportDownloader';
import ResultOfflineSyncer from './ResultOfflineSyncer';
interface IProps {
  isActive: boolean;
  resultSet: IResultSet;
  resources: IResource[];
  contacts: IContact[];
  users: IUser[];
  teams: ITeam[];
  boards: IBoard[];
  onToggleHistory: (event: React.MouseEvent) => void;
  onEditTemplate: (event: React.MouseEvent) => void;
  onExcelExport: (event: React.MouseEvent) => void;
  onToggleEditModal: (event: React.MouseEvent) => void;
  onToggleDropDown: (open: boolean) => void;
  onCloseDropdown: () => void;
  onShare: (event: React.MouseEvent) => void;
  onMakeOfflineAvailable?: () => void;
  onFullscreen: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onOpenPermissionsModal?: () => void | null;
  excelExportUrl: string;
  disabled?: boolean | null;
}
export default function ResultSettingsMenu({
  resources,
  onToggleHistory,
  resultSet,
  contacts,
  users,
  teams,
  boards,
  onExcelExport,
  excelExportUrl,
  onToggleEditModal,
  isActive,
  onToggleDropDown,
  onEditTemplate,
  onMakeOfflineAvailable,
  onShare,
  onFullscreen,
  onCloseDropdown,
  onOpenPermissionsModal,
  disabled
}: IProps) {
  const {
    t
  } = useTranslation();
  const isPowerUser = useIsPowerUser();
  const isOffline = useSelector((state: IAlphaprocessState) => state.core.isOffline);
  const editTemplateButton = isPowerUser && !isMobileOnly ? <DropdownItem onClick={isOffline ? undefined : onEditTemplate} className={isOffline ? 'is-disabled' : ''}>
      <a href={`/templates/editor/${resultSet.checklist_id}`}>
        <Icon className="fas fa-list-alt" />
        {t('checklist.editTemplate')}
      </a>
    </DropdownItem> : null;
  const beforeCloseDropdown = useCallback(event => {
    const target = (event.target as HTMLElement);
    // ClickOutSide Handler is also called if I click the toggle icon for reasons
    // this is a workaround to prevent this
    if (!(target.classList.contains('gear-menu-toggle') || target.classList.contains('icon-settings'))) {
      onToggleDropDown(false);
    }
  }, [onToggleDropDown]);
  const cursorClass = !disabled ? 'has-pointer' : 'has-not-allowed';
  const dispatchOnOpenPermissionsModal = onOpenPermissionsModal ? event => {
    event.preventDefault();
    event.stopPropagation();
    onOpenPermissionsModal();
  } : null;
  const permissionsMenuItem = isPowerUser && dispatchOnOpenPermissionsModal ? <DropdownItem>
        <a href="#" onClick={dispatchOnOpenPermissionsModal}>
          <Icon className="fas fa-user-lock clickable-icon is-block" />
          {t('checklist.gearMenu.permissions')}
        </a>
      </DropdownItem> : null;
  return <Dropdown isPulled="right" isActive={isActive} className={cursorClass}>
      <DropdownTrigger>
        <span onClick={() => onToggleDropDown(!isActive)} className={`${cursorClass} gear-menu-toggle`}>
          <img src={settingsIcon} className={`icon-settings is-pulled-right ${cursorClass}`} alt={t('resultSet.settings')} />
        </span>
      </DropdownTrigger>
      <OutsideClickHandler onOutsideClick={beforeCloseDropdown}>
        <DropdownMenu className="area-dropdownmenu">
          <DropdownContent>
            {!isMobileOnly && <DropdownItem onClick={onFullscreen}>
                <a href="#">
                  <Icon className="fas fa-compress-arrows-alt" />
                  {t('checklist.fullScreen')}
                </a>
              </DropdownItem>}
            <DropdownItem onClick={!isOffline ? onToggleHistory : undefined} className={isOffline ? 'is-disabled' : ''}>
              <a href="#">
                <Icon className="fa fa-history" />
                {t('resultSet.showHistory')}
              </a>
            </DropdownItem>
            <DropdownItem onClick={isOffline ? undefined : onShare} className={isOffline ? 'is-disabled' : ''}>
              <a href="#">
                <Icon className="fa fa-share-alt" />
                {t('Share')}
              </a>
            </DropdownItem>
            <DropdownItem onClick={onToggleEditModal}>
              <a href="#">
                <Icon className="fas fa-pencil-alt" />
                {t('common.edit')}
              </a>
            </DropdownItem>
            {permissionsMenuItem}
            <DropdownItem className={isOffline ? 'is-disabled' : ''}>
              <ReportDownloader resultSet={resultSet} onClick={isOffline ? undefined : () => onToggleDropDown(false)} isButton={false}>
                <Icon className="fas fa-file-pdf" />
                {t('report.pdf')}
              </ReportDownloader>
            </DropdownItem>
            <DropdownItem onClick={isOffline ? undefined : onExcelExport} className={isOffline ? 'is-disabled' : ''}>
              <a href={isOffline ? '' : excelExportUrl}>
                <Icon className="fas fa-file-excel" />
                {t('ExcelExport')}
              </a>
            </DropdownItem>
            {editTemplateButton}
            <ResultOfflineSyncer resultSet={resultSet} contacts={contacts} resources={resources} teams={teams} boards={boards} users={users} onCloseDropdown={onCloseDropdown} onMakeOfflineAvailable={onMakeOfflineAvailable} />
          </DropdownContent>
        </DropdownMenu>
      </OutsideClickHandler>
    </Dropdown>;
}