import { withScope, captureException, showReportDialog } from '@sentry/browser';
import { Button, Message, MessageBody, Notification, Title } from 'bloomer';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import ITranslationProps from '../types/translationProps';
import { getWithExpiry, setWithExpiry } from '../util';
interface IState {
  hasError: boolean;
  error: Error;
  showDetails: boolean;
  eventId: string;
}
class ErrorBoundary extends React.Component<ITranslationProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      showDetails: false,
      eventId: null
    };
  }
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
    if (process.env.NODE_ENV === 'production') {
      withScope(() => {
        const eventId = captureException(error);
        this.setState({
          eventId
        });
      });
    }
  }
  public static getDerivedStateFromError(error) {
    return {
      error,
      hasError: true
    };
  }
  public render() {
    const {
      hasError,
      error,
      showDetails
    } = this.state;
    const {
      t
    } = this.props;
    if (hasError) {
      return <div className="error-boundary">
          <Notification isColor="danger">
            <Title>
              {t('errorPage.title')}
            </Title>
            <p>
              {t('errorPage.message')}
            </p>
            <Button className="show-error-details" hasTextColor="white" onClick={() => showReportDialog({
            eventId: this.state.eventId
          })} isColor="text">
              {t('errorPage.sendReport')}
            </Button>
            {showDetails && <Message>
                <MessageBody hasTextColor="white" className="error-details">
                  <Title>
                    {error.message}
                  </Title>
                  <p style={{
                whiteSpace: 'pre'
              }}>
                    {error.stack}
                  </p>
                </MessageBody>
              </Message>}
          </Notification>
        </div>;
    }
    return this.props.children;
  }
}
export default withTranslation()(ErrorBoundary);