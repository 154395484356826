import * as React from 'react';
import { isCordova } from '../util';
const CordovaView: React.FunctionComponent = ({
  children
}) => {
  if (!isCordova()) return null;
  return <>
      {children}
    </>;
};
export default CordovaView;