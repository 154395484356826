import axios, { AxiosInstance } from 'axios'

import {
  requestTimeout
} from '../constants'
import AreasApi from './areasApi'
import BoardsApi from './boardsApi'
import { BulkJobsApi } from './BulkJobsApi'
import ChartsApi from './chartsApi'
import ChartsTagsApi from './chartsTagsApi'
import ChecklistsApi from './checklistsApi'
import CloudAttachmentsApi from './cloudAttachmentsApi'
import CommentsApi from './commentsApi'
import CompanyApi from './CompanyApi'
import ContactsApi from './contactsApi'
import CorporationApi from './corporationApi'
import DashboardApi from './dashboardApi'
import EventsApi from './events.api'
import ExcelImportApi from './excelImportApi'
import { FolderApi } from './FolderApi'
import LocationApi from './locationApi'
import ProjectApi from './projectApi'
import QuestionsApi from './questionApi'
import ResourcesApi from './resourcesApi'
import ResultApi from './resultApi'
import ResultSetApi from './resultSetApi'
import ScheduleApi from './scheduleApi'
import TagsApi from './tagsApi'
import TasksApi from './tasksApi'
import TeamApi from './teamApi'
import TemplateStoreEntryApi from './templateStoreEntryApi'
import TodoListApi from './todoListApi'
import UserApi from './userApi'
import LanesApi from './lanesApi'

class Api {
  private client: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: requestTimeout
  })

  public questions = new QuestionsApi(this.client)

  public areas = new AreasApi(this.client)

  public resources = new ResourcesApi(this.client)

  public contacts = new ContactsApi(this.client)

  public checklists = new ChecklistsApi(this.client)

  public comments = new CommentsApi(this.client)

  public corporations = new CorporationApi(this.client)

  public projects = new ProjectApi(this.client)

  public resultSets = new ResultSetApi(this.client)

  public locations = new LocationApi(this.client)

  public results = new ResultApi(this.client)

  public cloudAttachments = new CloudAttachmentsApi(this.client)

  public users = new UserApi(this.client)

  public teams = new TeamApi(this.client)

  public schedules = new ScheduleApi(this.client)

  public tags = new TagsApi(this.client)

  public excelImport = new ExcelImportApi(this.client)

  public boards = new BoardsApi(this.client)

  public tasks = new TasksApi(this.client)

  public workflows = new LanesApi(this.client)

  public events = new EventsApi(this.client)

  public todoLists = new TodoListApi(this.client)

  public dashboards = new DashboardApi(this.client)

  public folders = new FolderApi(this.client)

  public companies = new CompanyApi(this.client)

  public charts = new ChartsApi(this.client)

  public chartsTags = new ChartsTagsApi(this.client)

  public bulk = new BulkJobsApi(this.client)

  public templateStoreEntries = new TemplateStoreEntryApi(this.client)

  public addToken(token: string) {
    if (token) {
      this.client.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  }

  public removeToken() {
    this.client.defaults.headers.common.Authorization = null
  }

  public login(data) {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, data)
  }

  public logout() {
    return this.client.delete('/logout')
  }

  public signup(data) {
    return this.client.post('/signup', data)
  }

  // Password reset with token
  public resetPassword(data) {
    return this.client.post('/reset_password', data)
  }

  // change password in the user profile modal (without token)
  public changePasswordWithoutToken(data) {
    return this.client.post('/change_password', data)
  }

  public changePassword(data) {
    return this.client.put('/reset_password', data)
  }

  public invite(emails) {
    return this.client.post('/invite', emails)
  }

  public setAvatar(userId, data) {
    return this.client.post(`/users/${userId}/avatar`, data)
  }

  // this is called when a user rejects to receive push notifications
  public removeNotification() {
    return this.client.delete('remove_notification')
  }

  public deleteAttachment(id: number) {
    return this.client.delete(`/attachments/${id}`)
  }

  public ping() {
    return this.client.get('/ping')
  }
}

const api = new Api()

export default api
