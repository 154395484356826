import { Icon, Tag } from 'bloomer';
import { parseISO } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IFolder } from '../../api/FolderApi';
import ChecklistProgressbar from '../../components/checklists/result/ChecklistProgressbar';
import { FolderMode, TableData } from '../../components/checklists/result/folders/Folder';
import TitleColumn from '../../components/checklists/result/folders/TitleColumn';
import { TableAssignees } from '../../components/checklists/result/TableAvatarDisplay';
import { TableDueDate } from '../../components/checklists/result/TableDueDate';
import { FolderChecklist, FolderTemplate } from '../../types/FolderChecklist';
import DateUtil from '../../util/DateUtil';
import { useIsPowerUser } from '../authHooks';
import useIsOffline from '../useIsOffline';
function getIconClass(x: FolderTemplate) {
  if (x.entity_type === 'folder') return 'fas fa-folder';
  if (x.is_audit) return 'fas fa-clipboard-check';
  return 'fas fa-tasks';
}
export default function useTableData(data: any[], offlineIds: string[], mode: FolderMode, onUpdateFolder: (id: string) => void, lastNewFolderId: string, checklistStatus: 'open' | 'closed' | 'due' | 'invited', tabIndex: number, folder: IFolder): TableData[] {
  const isOffline = useIsOffline();
  const {
    t
  } = useTranslation();
  const isAdmin = useIsPowerUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileOnly = useMediaQuery(theme.breakpoints.down('sm'));
  if (mode === FolderMode.checklists) {
    const checklistData = (data as FolderChecklist[]);
    return checklistData.map(x => ({
      id: x.id,
      project: <div className="is-ellipsed">
          {x.project}
        </div>,
      rawProject: x.project,
      created_at: x.entity_type === 'result_set' ? <div className="due-date-and-progress">
          {x.created_at && <Tag data-tip={DateUtil.format(x.created_at, DateUtil.LongFormat)} className="is-rounded is-light">
              {DateUtil.format(x.created_at, isMobile ? DateUtil.ShorterFormat : DateUtil.LongFormat)}
            </Tag>}
        </div> : null,
      duration: x.duration ? <Tag className="is-rounded">{x.duration}</Tag> : '',
      resource: x.resource && <Link to={`/resources/${x.resource_id}`}>
        <Tag key={x.resource} isColor="link" className="resource-tag" data-tip={!isMobile && x.title}>
          {x.resource}
        </Tag>
      </Link>,
      due_date: x.entity_type !== 'folder' ? <div className="due-date-and-progress">
          {x.due_date && <TableDueDate date={parseISO(x.due_date)} />}
        </div> : '',
      creator: <TableAssignees assignees={x.creator ? [x.creator] : []} />,
      tester: <TableAssignees assignees={x.assignees} />,
      title: x.entity_type === 'folder' ? <a className="is-flex folder-title" onClick={() => onUpdateFolder(x.id)}>
          <span className="folder-icon icon has-text-link">
            <i className="fas fa-folder" />
          </span>
          <span className={lastNewFolderId === x.id ? 'has-text-link ellipsis-wrapper' : 'ellipsis-wrapper'}>
            {x.title}
          </span>
          {(checklistStatus === 'closed' ? x.closed_checklist_count : x.open_checklist_count) > 0 && <Tag className="folder-tag is-small is-rounded" isColor="link">
        {checklistStatus === 'closed' ? x.closed_checklist_count : x.open_checklist_count}
      </Tag>}
        </a> : <TitleColumn data={x} checklistStatus={checklistStatus} tabIndex={tabIndex} folder={folder} offlineIds={offlineIds} />,
      progress: x.entity_type === 'result_set' ? <ChecklistProgressbar progress={x.progress} /> : null,
      type: x.entity_type,
      can_delete: true,
      status: x.status === 0 ? 'open' : 'closed',
      checklist_id: x.checklist_id,
      closed_at: x.entity_type === 'result_set' ? <div className="due-date-and-progress">
          {x.closed_at && <Tag data-tip={DateUtil.format(x.closed_at, DateUtil.LongFormat)} className="is-rounded is-light">
            {DateUtil.format(x.closed_at, isMobile ? DateUtil.ShortFormat : DateUtil.LongFormat)}
          </Tag>}
        </div> : null,
      employees_can_restart: x.employees_can_restart,
      isOffline: offlineIds.includes(x.id)
    }));
  }
  const templateData = (data as FolderTemplate[]);
  return templateData.map(x => {
    const iconClass = getIconClass(x);
    const iconToolTip = x.entity_type === 'template' && x.is_audit ? t('Audit') : '';
    const href = isMobileOnly || !isAdmin || isOffline ? `/resultset/${x.id}/new` : `/templates/editor/${x.id}`;
    return {
      can_delete: isAdmin,
      type: x.entity_type,
      created_at: x.created_at,
      title: x.entity_type === 'folder' ? <a className="is-flex" onClick={() => onUpdateFolder(x.id)}>
          <span className="folder-icon icon has-text-link">
            <i className="fas fa-folder" />
          </span>
          <span className={lastNewFolderId === x.id ? 'has-text-link ellipsis-wrapper' : 'ellipsis-wrapper'}>
            {x.title}
          </span>
          {x.template_count > 0 && <Tag className="folder-tag is-small is-rounded" isColor="link">
              {x.template_count}
            </Tag>}
        </a> : <Link className="has-hyphens is-inline-flex-mobile" to={href}>
          <span className="has-margin-right-0 has-font-size-1em">
            <Icon title={iconToolTip} className={iconClass} />
          </span>
          {x.title}
          {x.draft && <Tag className="folder-tag is-small is-rounded" isColor="link">
            <Trans>checklist.labels.draft</Trans>
          </Tag>}
          {offlineIds.includes(x.id) && <span className="offline-icon icon">
            <i className="fas fa-download" />
          </span>}
        </Link>,
      creator: <TableAssignees assignees={x.assignees} />,
      id: x.id,
      status: 'open',
      isOffline: offlineIds.includes(x.id)
    };
  });
}