import { toast } from 'react-toastify'
import * as yup from 'yup'
import yupLocaleDe from 'yup-locale-de'

import { AuthActions } from '../actions/authActions'
import { CompanyActions } from '../actions/companyActions'
import { MenuActions } from '../actions/menuActions'
import { OfflineActions } from '../actions/offlineActions'
import { TeamActions } from '../actions/teamActions'
import { UserActions } from '../actions/userActions'
import api from '../api'
import ICorporation from '../api/types/corporation'
import ITeam from '../api/types/team'
import IUser from '../api/types/user'
import i18n from '../i18n'
import { AlphaprocessDatabase } from '../offlineMode/offlineDb'
import DateUtil from '../util/DateUtil'

export interface ICoreState {
  currentLanguage: string
  company: ICorporation | null
  isMenuOpen: boolean
  jwt: string | null
  notificationToken: string | null
  team: ITeam | null
  user: IUser | null
  isOffline: boolean
  browserNotificationClosedDate: number | null
  startWithTemplateOpen: boolean
}

const initialState: ICoreState = {
  company: null,
  isMenuOpen: false,
  jwt: null,
  notificationToken: null,
  team: null,
  user: null,
  isOffline: false,
  currentLanguage: navigator.language.substr(0, 2),
  browserNotificationClosedDate: null,
  startWithTemplateOpen: true
}

export type CoreAction = AuthActions | CompanyActions |
MenuActions | TeamActions | UserActions | OfflineActions

export default function coreReducer(
  state: ICoreState = initialState,
  action: CoreAction
): ICoreState {
  switch (action.type) {
    case 'AUTH_LOGIN':
      api.addToken(action.jwt)
      localStorage.setItem('jwt', action.jwt)
      return {
        ...state,
        jwt: action.jwt,
        user: action.user
      }
    case 'AUTH_LOGOUT':
      const db = new AlphaprocessDatabase()
      db.tables.forEach(async (t) => await t.clear())
      localStorage.removeItem('jwt')
      api.removeNotification().then(() => {
        api.logout().finally(() => {
          api.removeToken()
        })
      })
      // remove wiki cookie
      document.cookie = 'accessToken=; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.alphaprocess.io;'
      try {
        if ('Intercom' in window) {
          (window as any).Intercom('shutdown')
        }
        if ('intercom' in window) {
          (window as any).intercom.logout()
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error shutting down intercom', error)
      }
      return initialState
    case 'TEAM_ADD':
      return {
        ...state,
        team: action.team
      }
    case 'COMPANY_ADD':
      return {
        ...state,
        company: action.company
      }
    case 'MENU_TOGGLE':
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen
      }

    case 'USER_UPDATE':
      return {
        ...state,
        user: action.user
      }
    case 'USER_SET_NOTIFICATION_TOKEN':
      return {
        ...state,
        notificationToken: action.token
      }
    case 'CORE_SET_OFFLINE_MODE':
      if (!state.isOffline && action.isOffline) {
        toast(i18n.t('offlineMode.enteringOfflineMode'), { type: 'default' })
      }
      return {
        ...state,
        isOffline: action.isOffline
      }
    case 'SET_LANGUAGE':
      DateUtil.setLocale(action.lang)
      yup.setLocale(yupLocaleDe)
      i18n.changeLanguage(action.lang)
      return {
        ...state,
        currentLanguage: action.lang
      }
    case 'SET_BROWSER_NOTIFICATION_CLOSE_DATE':
      return {
        ...state,
        browserNotificationClosedDate: action.closedDate
      }
    case 'TOGGLE_START_WITH_TEMPLATE':
      return {
        ...state,
        startWithTemplateOpen: !state.startWithTemplateOpen
      }
    default:
      return state
  }
}
