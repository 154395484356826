import querystring from 'query-string'
import { ApiBase } from './ApiBase'

interface IParams {
  title?: string
  project_id?: number[]
  resource_id?: number[]
}

export default class EventsApi extends ApiBase {
  public open(info, params: IParams) {
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    return this.get(`/events/open?start=${info.startStr}&end=${info.endStr}&${query}`)
  }

  public overdue(info, params: IParams) {
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    return this.get(`/events/overdue?start=${info.startStr}&end=${info.endStr}&${query}`)
  }

  public closed(info, params: IParams) {
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    return this.get(`/events/closed?start=${info.startStr}&end=${info.endStr}&${query}`)
  }

  public nextInspections(info, params: IParams) {
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    return this.get(`/events/next_inspections?start=${info.startStr}&end=${info.endStr}&${query}`)
  }

  public tasks(info, params: IParams) {
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    return this.get(`/events/tasks?start=${info.startStr}&end=${info.endStr}&${query}`)
  }

  public nonWorkingDays(info, params: IParams) {
    return this.get('/events/non_working_days', { start: info.startStr, end: info.endStr, ...params })
  }

  public todos(info, params: IParams) {
    return this.get('/events/todos', { start: info.startStr, end: info.endStr, ...params })
  }
}
