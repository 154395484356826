import { gql } from '@apollo/client'

export interface CreateRuleActionPayload {
  ruleSetId: string;
  emailReceiverUserIds: string;
  emailText: string;
  areaIds: (number)[]
  duplicateQuestionId: number | null
  duplicateMode: string
  emailSubject: string
  actionType: string
  emailReceiverTeamIds: string
  value: string
  targetAreaCount: number
  emailReceiverAddresses: string
  questionIds: number[]
}

export interface RuleAction {
  id: string;
}

export interface CreateRuleAction {
  errors: string[]
  ruleAction: RuleAction;
}

export interface CreateRuleActionResponse {
  createRuleAction: CreateRuleAction;
}

const CREATE_RULE_ACTION = gql`
mutation createRuleAction(
  $actionType: Action!
  $value: String!
  $ruleSetId: ID!
  $targetAreaCount: Int
  $emailText: String
  $emailReceiverUserIds: String
  $emailReceiverAddresses: String
  $emailReceiverTeamIds: String
  $emailSubject: String
  $questionIds: [Int!]!
  $areaIds: [Int!]!
  $duplicateQuestionId: Int
  $duplicateMode: String!
) {
  createRuleAction(
    actionType: $actionType
    value: $value
    ruleSetId: $ruleSetId
    targetAreaCount: $targetAreaCount
    emailText: $emailText
    emailReceiverAddresses: $emailReceiverAddresses
    emailReceiverUserIds: $emailReceiverUserIds
    emailReceiverTeamIds: $emailReceiverTeamIds
    emailSubject: $emailSubject
    questionIds: $questionIds
    areaIds: $areaIds
    duplicateQuestionId: $duplicateQuestionId
    duplicateMode: $duplicateMode
  ) {
    errors
    ruleAction {
      id
    }
  }
}
`

export default CREATE_RULE_ACTION
