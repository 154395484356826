import { Button } from 'bloomer';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { highlightTimeout } from '../../../../../constants';
import { useEventBus } from '../../../../../pages/checklists/ResultView/EventBus';
import NaButton from './NaButton';
interface Props {
  value: string;
  disableNa: boolean;
  disabled: boolean;
  resultId: string;
  onUpdate: (value: 'true' | 'false' | 'n/a') => void;
}
function QuestionYesNoInput({
  disableNa,
  onUpdate,
  value,
  resultId,
  disabled
}: Props) {
  const {
    t
  } = useTranslation();
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  useEventBus('result', resultId, () => {
    setIsHighlighted(true);
    setTimeout(() => setIsHighlighted(false), highlightTimeout);
  });
  const classes = clsx('buttons', disableNa && 'buttons-without-na', !disableNa && 'buttons-with-na', isHighlighted && 'is-highlighted');
  return <div className={classes}>
      <Button className="has-text-weight-bold answer-yes" onClick={() => onUpdate('true')} isActive={value === 'true'} isColor={value === 'true' ? 'success' : 'light'} disabled={disabled}>
        {t('Yes')}
      </Button>
      <Button className="has-text-weight-bold answer-no" onClick={() => onUpdate('false')} isActive={value === 'false'} isColor={value === 'false' ? 'danger' : 'light'} disabled={disabled}>
        {t('No')}
      </Button>
      {!disableNa && <NaButton value={value} onUpdate={onUpdate} disabled={disabled} />}
    </div>;
}
export default QuestionYesNoInput;