import { ApiBase } from './ApiBase'
import { ITag } from './types/ressource'

export default class TagsApi extends ApiBase {
  public all() {
    return this.get<ITag[]>(
      '/tags'
    )
  }

  public existing_tag(title: string) {
    return this.get<ITag>(
      '/existing_tag',
      {
        params: {
          title
        }
      }
    )
  }

  public create(data: ITag) {
    return this.post<ITag>(
      '/tags',
      data
    )
  }

  public update(data: ITag) {
    return this.put<ITag>(
      `/tags/${data.id}${
        data.id
      }`,
      data
    )
  }

  public deleteTag(data: ITag) {
    return this.delete(
      `/tags/${data.id}`
    )
  }
}
