/* eslint import/no-webpack-loader-syntax: off */
import { Dropdown, DropdownContent, DropdownItem, DropdownMenu, Icon } from 'bloomer';
import { useCallback, useState } from 'react';
import { MobileOnlyView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

// we use react-inlinesvg to be able to change the appearance of the vector icons by css rules
// react-inlinesvg uses fetch requests to obtain the files
// since in Cordova it uses the file:// protocol and Android denies this
// for security reasons, which causes that the icons are not shown in the Android Cordova app.
// we import the Icons as data uris as a workaround to fix this.
import clsx from 'clsx';
import { Badge } from '@mui/material';
import { ICorporationSetting } from '../../api/types/corporation';
import { fallbackCorporationSetting } from '../../constants';
import { useCurrentUser } from '../../hooks/authHooks';
import useChatContext from '../../hooks/useChatContext';
import useIsOffline from '../../hooks/useIsOffline';
import CheckImage from '../../images/check.png';
import { IAlphaprocessState } from '../../store';
import calendarIcon from '!!url-loader!../../images/menu-icons/calendar.svg';
import chatIcon from '!!url-loader!../../images/menu-icons/chat.svg';
import checksIcon from '!!url-loader!../../images/menu-icons/checks.svg';
import companiesIcon from '!!url-loader!../../images/menu-icons/companies.svg';
import contactsIcon from '!!url-loader!../../images/menu-icons/contacts.svg';
import peopleIcon from '!!url-loader!../../images/menu-icons/people.svg';
import resourcesIcon from '!!url-loader!../../images/menu-icons/resources.svg';
import tasksIcon from '!!url-loader!../../images/menu-icons/tasks.svg';
import todoIcon from '!!url-loader!../../images/menu-icons/todo.svg';
import supportIcon from '!!url-loader!../../images/menu-icons/support.svg';
import wikiIcon from '!!url-loader!../../images/menu-icons/wiki.svg';
import { isCordova } from '../../util';
import CordovaView from '../CordovaView';
import WebView from '../WebView';
export default function BottomMenu() {
  const {
    t
  } = useTranslation();
  const {
    pathname
  } = useLocation();
  const {
    push
  } = useHistory();
  const user = useCurrentUser();
  const isOffline = useIsOffline();
  const [isOpen, setIsOpen] = useState(false);
  const corporationSettings: ICorporationSetting = useSelector((state: IAlphaprocessState) => state.core.user?.corporation?.corporation_setting) || fallbackCorporationSetting;
  const onClickOutside = useCallback(event => {
    event.preventDefault();
    setIsOpen(false);
  }, []);
  const {
    totalUnreadMessages
  } = useChatContext();
  const closeMenu = useCallback(() => setIsOpen(false), []);
  const todosMatch = useRouteMatch('/todos');
  const matchChecklistOpen = useRouteMatch('/checklists/tab/:tabIndex/folders/:folderId/:checklistId/audit/:id/');
  const matchChecklistAreaOpen = useRouteMatch('/checklists/tab/:tabIndex/folders/:folderId/:checklistId/audit/:id/areas/:areaId/position/:areaPosition');
  if (todosMatch || matchChecklistOpen || matchChecklistAreaOpen) {
    return null;
  }

  //const { intercom } = window as any

  // ADF-898: dynamically calculate the position of the menu based on the count of menu items
  const itemHeight = 45;
  let dropdownContentCount = 11;
  dropdownContentCount -= Number(!user.has_valid_license);
  dropdownContentCount -= Number(corporationSettings && !corporationSettings.use_tasks);
  dropdownContentCount -= Number(corporationSettings && (!corporationSettings.use_contacts || !user.has_valid_license));
  dropdownContentCount -= Number(!corporationSettings.use_todo_lists);
  dropdownContentCount -= Number(corporationSettings && !corporationSettings.use_resources);
  dropdownContentCount += user.corporation.wiki_link ? 1 : 0;
  const menuTopPosition = dropdownContentCount * itemHeight * -1 - 100;

  // this is an invisible layer full screen sized div located under the open menu
  // it is used to catch click events outside of the menu
  // to prevent other click events from firing (e.g. click on a card at the "Templates" page)
  const clickOutsideElement = isOpen ? <div className="bottom-menu-click-outside" onClick={() => setIsOpen(false)} /> : null;
  return <MobileOnlyView className="bottom-menu">
      {clickOutsideElement}
      <div className="bottom-menu-items">
        <div className="bottom-menu-item">
          {/*<div id="show-support" className="has-pointer" onClick={() => intercom?.displayMessenger()}>
            <SVG src={chatIcon} />
           </div>*/}
          <div id="show-support" className="has-pointer" onClick={() => {
          const zammadChatButton = (document.querySelector('.zammad-chat .chat-toggle') as HTMLButtonElement);
          if (zammadChatButton) {
            zammadChatButton.click();
          }
        }}>
            <SVG src={chatIcon} />
          </div>
        </div>
        <div className="bottom-menu-app-logo">
          <Link to="/" onClick={closeMenu}>
            <div className="clickable-app-logo">
              <img src={CheckImage} alt={t('menu.home')} className="app-logo-icon" />
            </div>
          </Link>
        </div>
        <div className="bottom-menu-item">
          <OutsideClickHandler onOutsideClick={onClickOutside}>
            <div className="bottom-menu-open" onClick={() => setIsOpen(!isOpen)}>
              <Badge badgeContent={totalUnreadMessages} color="primary" sx={{
              '& > .MuiBadge-badge': {
                right: '28px'
              }
            }}>
                <Icon className="fas fa-ellipsis-h bottom-menu-icon" />
              </Badge>
            </div>

            <Dropdown isAlign="right" isActive={isOpen}>
              <DropdownMenu style={{
              top: menuTopPosition
            }}>
                <DropdownContent>
                  <DropdownItem isActive={pathname === '/profile'} onClick={closeMenu} onClickCapture={() => push('/profile')}>
                    <Link to="/profile">
                      <SVG src={peopleIcon} />
                      <span className="menu-entry-text">
                        {t('navigation.yourData')}
                      </span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem isActive={pathname === '/calendar'} onClick={closeMenu} isHidden={!user.has_valid_license} onClickCapture={() => push('/calendar')}>
                    <Link to="/calendar">
                      <SVG src={calendarIcon} />
                      <span className="menu-entry-text">
                        {t('menu.calendar')}
                      </span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem isActive={pathname === '/checklists'} onClick={closeMenu} onClickCapture={() => push('/checklists')}>
                    <Link to="/checklists">
                      <SVG src={checksIcon} />
                      <span className="menu-entry-text">
                        {t('menu.checklists')}
                      </span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem isActive={pathname === '/templates'} onClick={closeMenu} onClickCapture={() => push('/templates')}>
                    <Link to="/templates" className="menu-item-with-fa-icon">
                      <Icon className="far fa-list-alt" />
                      <span className="menu-entry-text">
                        {t('menu.templates')}
                      </span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem isHidden={corporationSettings && !corporationSettings.use_tasks} isActive={pathname === '/boards'} onClick={closeMenu} onClickCapture={() => push('/boards')}>
                    <Link to="/boards">
                      <SVG src={tasksIcon} />
                      <span className="menu-entry-text">
                        {t('menu.tasks')}
                      </span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem isHidden={!corporationSettings.use_todo_lists} isActive={pathname === '/todos'} onClick={closeMenu} onClickCapture={() => push('/todos')}>
                    <Link to="/todos">
                      <SVG src={todoIcon} />
                      <span className="menu-entry-text">
                        {t('menu.todo')}
                      </span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem isHidden={corporationSettings && (!corporationSettings.use_contacts || !user.has_valid_license)} isActive={pathname === '/contacts'} onClick={closeMenu} onClickCapture={() => push('/contacts')}>
                    <Link to="/contacts">

                      <SVG src={contactsIcon} />
                      <span className="menu-entry-text">
                        {t('menu.contacts')}
                      </span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem isHidden={corporationSettings && (!corporationSettings.use_companies || !user.has_valid_license)} isActive={pathname === '/companies'} onClick={closeMenu} onClickCapture={() => push('/companies')}>
                    <Link to="/companies">

                      <SVG src={companiesIcon} />
                      <span className="menu-entry-text">
                        {t('menu.companies')}
                      </span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem isHidden={corporationSettings && !corporationSettings.use_resources} isActive={pathname === '/resources'} onClick={closeMenu} onClickCapture={() => push('/resources')}>
                    <Link to="/resources">
                      <SVG src={resourcesIcon} />
                      <span className="menu-entry-text">
                        {corporationSettings.rename_resources ? t('menu.projects') : t('menu.resources')}
                      </span>
                    </Link>
                  </DropdownItem>
                  <Badge badgeContent={totalUnreadMessages} color="primary" sx={{
                  width: '100%',
                  '& > .MuiBadge-badge': {
                    right: '120px'
                  }
                }}>
                    <DropdownItem isActive={pathname.startsWith('/chat')} onClick={closeMenu} style={{
                    width: '100%'
                  }} onClickCapture={() => push('/chat')}>
                      <Link to="/chat">
                        <SVG src={supportIcon} />
                        <span className="menu-entry-text">
                          {t('menu.chat')}
                        </span>
                      </Link>
                    </DropdownItem>
                  </Badge>
                  {!!user.corporation.wiki_link && <DropdownItem isActive={pathname.startsWith('/wiki')} onClick={closeMenu} onClickCapture={() => isCordova() ? window.open(user.corporation.wiki_link, '_system') : push('/wiki')}>
                    <WebView>
                      <Link to="/wiki">
                        <SVG src={wikiIcon} />
                        <span className="menu-entry-text">
                          {t('menu.wiki')}
                        </span>
                      </Link>
                    </WebView>
                    <CordovaView>
                      <a>
                        <SVG src={wikiIcon} />
                        <span className="menu-entry-text">
                          {t('menu.wiki')}
                        </span>
                      </a>
                    </CordovaView>
                  </DropdownItem>}
                  <DropdownItem onClick={closeMenu} className="beamerTrigger">
                    <a href="#">
                      <span className={clsx('icon', isOffline && 'is-disabled')}>
                        <i className="fas fa-bell" />
                      </span>
                      <span className="menu-entry-text">
                        {t('menu.news')}
                      </span>
                    </a>
                  </DropdownItem>
                </DropdownContent>
              </DropdownMenu>
            </Dropdown>
          </OutsideClickHandler>
        </div>
      </div>
    </MobileOnlyView>;
}