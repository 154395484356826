import { gql, useQuery } from '@apollo/client';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import makeAnimated from 'react-select/animated';
import { Autocomplete, TextField } from '@mui/material';
import IProject from '../../../api/types/project';
import { reactSelectMaxMenuHeight } from '../../../constants';
import LoadSpinner from '../../loadSpinner/LoadSpinner';
interface IProps {
  projects: IProject[];
  onChange: (value: number | number[]) => void;
  selected: number | number[];
  isMulti?: boolean;
  placeholder: string;
  setAsValue?: boolean;
  readonly?: boolean;
  name?: string;
  id?: string;
}
const animatedComponents = makeAnimated();
const PROJECT_SELECT_QUERY = gql`
    query ProjectSelect {
        projects {
            id
            name
        }
    }
`;
interface NewProjectSelectProps {
  selected: string[];
  label: string;
  onChange: (selected: string[]) => void;
  disabled?: boolean;
}
interface ProjectSelectResponse {
  projects: Array<{
    id: string;
    name: string;
  }>;
}
export function NewProjectSelect({
  selected,
  label,
  onChange,
  disabled
}: NewProjectSelectProps) {
  const {
    loading,
    data
  } = useQuery<ProjectSelectResponse>(PROJECT_SELECT_QUERY);
  if (loading) {
    return <LoadSpinner />;
  }
  return <Autocomplete fullWidth value={data.projects.filter(x => selected.includes(x.id))} options={data.projects} multiple disabled={disabled} getOptionLabel={project => typeof project === 'string' ? project : project.name} renderInput={params => <TextField {...params} label={label} />} onChange={(event, newValue) => {
    onChange(newValue.map(x => typeof x === 'string' ? x : x.id));
  }} />;
}
export default function ProjectSelect(props: IProps) {
  const {
    t
  } = useTranslation();
  const options = props.projects.map(u => ({
    value: u.id,
    label: u.name,
    disabled: u.status === 'closed'
  }));
  const selected = Array.isArray(props.selected) ? options.filter(o => (props.selected as number[]).includes(o.value)) : options.find(o => o.value === props.selected);
  const handleChange = (values: any) => {
    if (!values) {
      props.onChange(null);
    } else if (Array.isArray(values)) {
      props.onChange(values.map(v => v.value));
    } else {
      props.onChange(values.value);
    }
  };
  return <ReactSelect options={options} id={props.id} name={props.name} isMulti={props.isMulti} onChange={handleChange} defaultValue={!props.setAsValue ? selected : undefined} value={props.setAsValue ? selected : undefined} placeholder={props.placeholder} isOptionDisabled={(option: any) => option.disabled} isSearchable isClearable components={animatedComponents} maxMenuHeight={reactSelectMaxMenuHeight} className="dropdown-select-container" classNamePrefix="dropdown-select" noOptionsMessage={() => t('common.noOptions')} isDisabled={props.readonly} menuPortalTarget={document.body} menuPlacement="auto" styles={{
    menuPortal: base => ({
      ...base,
      zIndex: 9999
    })
  }} />;
}