// tslint:disable:no-console
// import { Button, Column } from 'bloomer'
import { Component } from 'react';
// @ts-ignore
import GooglePicker from 'react-google-picker';
import Btn from '../button';
interface GDriveState {
  mimeTypes: string[];
  accessToken: string;
}
interface GDriveProps {
  onSelect: (data: string, accessToken: string) => void;
  onClick: () => void;
}
class GoogleDrive extends Component<GDriveProps, GDriveState> {
  constructor(props: GDriveProps) {
    super(props);
    this.state = {
      mimeTypes: ['image/png', 'image/jpeg', 'image/jpg'],
      accessToken: ''
    };
  }
  private onAuthentication = (accessToken: any) => {
    localStorage.setItem('gAuthToken', accessToken);
    this.setState({
      accessToken
    });
  };
  private onAuthFailure = (token: any) => {
    console.log('oauth token:', token);
  };
  private onChange = (data: any) => {
    if (data.action === 'picked') {
      const files = data.docs.map((doc: any) => ({
        id: doc.id,
        name: doc.name,
        size: doc.sizeBytes,
        fileType: doc.mimeType,
        url: `https://www.googleapis.com/drive/v3/files/${doc.id}?alt=media`,
        provider: 'google-drive'
      }));
      this.props.onSelect(files, this.state.accessToken);
    } else {
      console.log('Log :=> G_DRIVE', data);
    }
  };
  public render() {
    return <GooglePicker navHidden clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} developerKey={process.env.REACT_APP_GOOGLE_CLIENT_KEY} onChange={this.onChange} onAuthenticate={this.onAuthentication} onAuthFailed={this.onAuthFailure} multiselect authImmediate={false} mimeTypes={this.state.mimeTypes} viewId="DOCS">
        <Btn text="Google Drive" onClick={this.props.onClick} />
      </GooglePicker>;
  }
}
export default GoogleDrive;