import { compiledBlock as _compiledBlock } from "million/react";
const CloudFileList_1 = _compiledBlock(_props => <div>
      {_props.v0}
    </div>, {
  name: "CloudFileList_1",
  portals: ["v0"]
});
const CloudFileList = function CloudFileList({
  files,
  onDelete,
  twoColumnView
}: Props) {
  const providers = [...new Set(files.map(x => x.provider))].sort((a, b) => a.localeCompare(b));
  // list all cloud files grouped by the storage provider where they are located
  const providerFileList = providers.sort().map(x => {
    const providerFiles = files.filter(y => y.provider === x).sort((a, b) => a.name.localeCompare(b.name));
    const fileIcons = providerFiles.map(y => <CloudFile file={y} key={y.id} onDelete={onDelete} />);
    return <div key={x} className={clsx('cloud-file-list', twoColumnView && 'two-columns')}>
        <h3>
          {providerNames[x]}
        </h3>
        <Columns isMobile isMultiline isMarginless className="attachments-container cloud">
          {fileIcons}
        </Columns>
      </div>;
  });
  return /*@million jsx-skip*/<CloudFileList_1 v0={providerFileList} />;
};
import { block as _block } from "million/react";
import { Columns } from 'bloomer';
import clsx from 'clsx';
import { ICloudAttachment } from '../../api/types/attachment';
import CloudFile from './CloudFile';
interface Props {
  files: ICloudAttachment[];
  onDelete?: (file: ICloudAttachment) => void | null;
  twoColumnView?: boolean;
}

// user readable translations of cloud storage providers
export const providerNames = {
  'one-drive': 'One Drive',
  'google-drive': 'Google Drive',
  dropbox: 'Dropbox'
};
export default CloudFileList;