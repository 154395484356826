import { Delete, Notification } from 'bloomer';
import { addMonths } from 'date-fns';
import MobileDetect from 'mobile-detect';
import { Component } from 'react';
import { connect } from 'react-redux';
import { setBrowserNotificationCloseDate } from '../../actions/menuActions';
import { IAlphaprocessState } from '../../store';
import DateUtil from '../../util/DateUtil';
export interface IDetectOldBrowserNotificationProps extends StateProps, DispatchProps {
  message?: string;
  className?: string;
  blackList?: string[];
  whiteList?: string[];
  onClickClose?: () => void;
}
interface StateProps {
  closedDate: number;
}
interface DispatchProps {
  setClosedDate: (date: Date) => void;
}
class DetectOldBrowserNotification extends Component<IDetectOldBrowserNotificationProps> {
  public state = {
    openNotification: false
  };
  public componentDidMount() {
    let openNotification: boolean;
    const md = new MobileDetect(window.navigator.userAgent);
    const isOldAndroidVersion = md.os() === 'AndroidOS' && md.version('Android') < 5 && md.userAgent() !== 'Chrome';
    if (md.match('SamsungBrowser') || isOldAndroidVersion) {
      openNotification = true;
    } else {
      const {
        blackList = [],
        whiteList = []
      } = this.props;

      // Add samsung as item default blacklist first
      const arBlackList = ['Samsung', ...blackList].filter(item => whiteList.indexOf(item) === -1);
      openNotification = arBlackList.some(item => md.is(item));
    }
    this.setState({
      openNotification
    });
  }
  public onClickClose = () => {
    const {
      onClickClose
    } = this.props;
    this.setState({
      openNotification: false
    });
    this.props.setClosedDate(new Date());
    if (onClickClose) {
      onClickClose();
    }
  };
  public render() {
    const {
      message = 'Your browser is out of date please update your browser',
      className,
      closedDate
    } = this.props;
    const {
      openNotification
    } = this.state;
    if (!openNotification) {
      return null;
    }
    if (closedDate && DateUtil.isAfter(new Date(), addMonths(closedDate, 1))) {
      return null;
    }
    return <Notification className={`${className}`} isColor="danger">
        <Delete onClick={this.onClickClose} />
        {message}
      </Notification>;
  }
}
const mapStateToProps = (state: IAlphaprocessState): StateProps => ({
  closedDate: state.core.browserNotificationClosedDate
});
const mapDispatchToProps = (dispatch: (x: any) => void): DispatchProps => ({
  setClosedDate: date => dispatch(setBrowserNotificationCloseDate(date))
});
export default connect(mapStateToProps, mapDispatchToProps)(DetectOldBrowserNotification);