import { Icon } from 'bloomer';
import { useTranslation } from 'react-i18next';
import { IFolder } from '../../../../../api/FolderApi';
import { getFolderName } from '../../../../../util/getFolderName';
interface Props {
  level: number;
  folder: IFolder;
  onClick: (folder: IFolder) => void;
  selectedFolder: IFolder;
  folderType?: 'boards' | 'checklists';
}
function FolderDialogEntry({
  folder,
  onClick,
  level,
  selectedFolder,
  folderType = 'checklists'
}: Props) {
  const {
    t
  } = useTranslation();
  const classes = ['folder-dialog-entry'];
  classes.push(`level-${level}`);
  if (selectedFolder && selectedFolder.id === folder.id) {
    classes.push('is-selected');
  }
  return <>
      <div className={classes.join(' ')} onClick={() => onClick(folder)}>
        <Icon className="fas fa-folder" />
        {' '}
        {getFolderName(folder, t)}
      </div>
      {folder.children.filter(x => x.folder_type === folderType).map(f => <FolderDialogEntry selectedFolder={selectedFolder} key={f.id} level={level + 1} folder={f} onClick={onClick} />)}
    </>;
}
export default FolderDialogEntry;