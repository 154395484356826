import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../api';
import LoadSpinner from '../loadSpinner/LoadSpinner';
const LatestResultRedirect: React.FC = () => {
  const {
    scheduleId
  } = useParams<{
    scheduleId: string;
  }>();
  const history = useHistory();
  useEffect(() => {
    const fetchLatestResult = async () => {
      try {
        const response = await api.schedules.latestResultSet(Number(scheduleId));
        if (response.data && response.data.id) {
          const {
            checklist_id,
            id: resultSetId
          } = response.data;
          history.replace(`/checklists/${checklist_id}/audit/${resultSetId}`);
        } else {
          toast('No latest result set available for this schedule.', {
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error fetching latest result set:', error);
        toast('Für diese Wiedervorlage gibt es noch keinen offenen Prozess.', {
          type: 'error'
        });
      }
    };
    fetchLatestResult();
  }, [scheduleId, history]);
  return <LoadSpinner className="centered" />;
};
export default LatestResultRedirect;