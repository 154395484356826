import IResult from '../api/types/result'

/**
 * Provides utility functions for results.
 */
export default class ResultUtil {
  /**
   * Checks if a result is successfull
   *
   * checkbox: unless 'no' is selected -> true
   *
   * everything else: value is not null -> true
   */
  public static IsSuccessfull(result: IResult): boolean {
    if (!result.question.is_required) {
      return true
    }
    if (result.question.question_type === 'checkbox') {
      return !(result.value && result.value === 'no')
    }

    return !!result.value
  }

  // option_text is a column in rules table
  // options are seperated by a pipe char |
  // and are in format [id of dropdown item]=[The additional option text]
  // e.g. 123=war nicht auf lager|456=Tesla|789=Fritz Kola
  public static getOptionText = (id: number, option_text: string | null) => {
    const optionTexts = option_text
      ? option_text.split('|') : []
    let text = ''

    optionTexts.forEach((line: string) => {
      const splittedLine = line.split('=')
      if (splittedLine.length >= 2 && parseInt(splittedLine[0]) === id) {
        [, text] = splittedLine
      }
    })
    return text
  }
}
