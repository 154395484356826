import { Button, Dropdown, DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger, Icon } from 'bloomer';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import api from '../../../../api';
import { IFolder } from '../../../../api/FolderApi';
import useIsOffline from '../../../../hooks/useIsOffline';
import NameFolderDialog from './RenameFolderDialog';
interface Props {
  folder: IFolder;
  onCreateFolder: (updatedFolder: IFolder, newFolder: IFolder) => void;
}
export default function SplitButton({
  folder,
  onCreateFolder
}: Props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isOffline = useIsOffline();
  const toggleDropdownMenu = useCallback(() => {
    setIsDropdownOpen(current => !current);
  }, []);
  const handleCreateFolder = useCallback(async () => {
    const doCreate = async (name: string) => {
      const newFolder = await api.folders.create({
        name,
        parent_id: folder.id
      });
      const updatedFolder: IFolder = {
        ...folder,
        children: [...folder.children, newFolder].sort((a, b) => a.name.localeCompare(b.name))
      };
      onCreateFolder(updatedFolder, newFolder);
    };
    const customUI = ({
      onClose
    }) => <NameFolderDialog onClose={onClose} onEdit={name => doCreate(name)} mode="create" />;
    confirmAlert({
      customUI
    });
  }, [folder, onCreateFolder]);
  const onClickOutSide = useCallback((event: MouseEvent) => {
    // if the user clicks other datasets gear icon then close the menu
    const parentItem = document.querySelector('#split-button');
    const target = (event.target as HTMLElement);

    // ClickOutSide Handler is also called if I click the toggle icon for reasons
    // this is a workaround to prevent this
    if (!parentItem.contains(target) || !target.classList.contains('split-trigger')) {
      setIsDropdownOpen(false);
    }
  }, []);
  const newTemplateLink = folder.id ? `/templates/editor?folderId=${folder.id}` : '/templates/editor';
  return <div className={clsx('buttons', 'has-addons', 'is-pulled-right', 'has-margin-bottom-5', 'has-margin-right-10')} id="split-button">
      <Link className={clsx('button', 'is-link', isOffline && 'is-disabled')} to={newTemplateLink}>
        <Trans>checklist.newTemplate</Trans>
      </Link>
      <Dropdown isActive={isDropdownOpen} isAlign="right">
        <DropdownTrigger onClick={toggleDropdownMenu}>
          <Button disabled={isOffline} className="split-button-trigger split-trigger" isColor="link">
            <span className="icon is-small split-trigger">
              <i className="fas fa-angle-down split-trigger" aria-hidden="true" />
            </span>
          </Button>
        </DropdownTrigger>
        <OutsideClickHandler onOutsideClick={onClickOutSide}>
          <DropdownMenu>
            <DropdownContent>
              <DropdownItem className="" onClick={handleCreateFolder} disabled={isOffline}>
                <Icon className="fas fa-folder-plus" />
                <Trans>folder.createFolder</Trans>
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </OutsideClickHandler>
      </Dropdown>
    </div>;
}