import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AxiosError } from 'axios';
import { Column, Columns, Control, Field, Icon, Input } from 'bloomer';
import { createRef, Component, ChangeEvent, KeyboardEvent } from 'react';
import { withTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { MenuPlacement } from 'react-select';
import { FilterLocation, setProjects, setTerm } from '../../../actions/filterActions';
import api from '../../../api';
import { ICompany } from '../../../api/CompanyApi';
import IContact from '../../../api/types/contact';
import IResource, { ITag } from '../../../api/types/ressource';
import IUser from '../../../api/types/user';
import iconFiltersClosed from '../../../images/icon_filter.png';
import iconFiltersOpen from '../../../images/icon_filter_mobile.png';
import { emptyFilterSettings, getFilterSettings, IFilterState } from '../../../reducers/filterReducer';
import { IAlphaprocessState } from '../../../store';
import ISelectListValueModel from '../../../types/selectListModel';
import ITranslationProps from '../../../types/translationProps';
import Filters from './Filters';
interface IOwnProps {
  filterLocation: FilterLocation;
  onError?: (error: any) => void;
  onSearch?: (term: string) => void;
  onFilterDate?: (startDate: any, endDate: any) => void;
  defaultProject?: ISelectListValueModel<any>;
  onFilterTesters?: (testers: number[]) => void;
  onFilterProjects?: (projects: number[]) => void;
  onFilterTeams?: (teams: number[]) => void;
  onFilterRessources?: (resources: number[]) => void;
  hideFilter: boolean;
  searchPlaceholder: string;
  showTags: boolean;
  showTeams: boolean;
  showUsers: boolean;
  showProjects: boolean;
  showResources: boolean;
  showContacts: boolean;
  showDatepicker: boolean;
  showCompanies: boolean;
  menuPlacement?: MenuPlacement | null;
  disabled?: boolean;
  showStartDate: boolean;
  showCreator: boolean;
  showCloser: boolean;
  isClosedTab: boolean;
}
interface IStateProps {
  user: IUser;
  filters: IFilterState;
  tags: ITag[];
}
interface IDispatchProps {
  setProjects: (projects: Array<ISelectListValueModel<any>>, location: FilterLocation) => void;
  setTerm: (term: string, location: FilterLocation) => void;
}
type Props = IOwnProps & IStateProps & IDispatchProps & ITranslationProps & RouteComponentProps;
interface IState {
  allProjects: Array<ISelectListValueModel<any>>;
  showFilters: boolean;
  allContacts: IContact[];
  allResources: IResource[];
  allCompanies: ICompany[];
  error: AxiosError;
  isLoading: boolean;
  timer: NodeJS.Timer | null;
}
const FilterToggle = styled('div')({
  marginRight: '25px',
  cursor: 'pointer'
});
const SearchField = styled(Field)(({
  theme
}) => ({
  [theme.breakpoints.down('sm')]: {
    flexGrow: 1
  }
}));
class ActionTableSearch extends Component<Props, IState> {
  public static defaultProps = {
    // tslint:disable-next-line: no-empty
    onFilterProjects: () => {},
    // tslint:disable-next-line: no-empty
    onFilterTeams: () => {},
    // tslint:disable-next-line: no-empty
    onFilterTesters: () => {},
    // tslint:disable-next-line: no-empty
    onFilterDate: () => {},
    // tslint:disable-next-line: no-empty
    onFilterRessources: () => {},
    // tslint:disable-next-line: no-empty
    onSearch: () => {},
    defaultProject: null,
    onError: null
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      allProjects: [],
      // All projects for this user
      showFilters: !!props.defaultProject,
      allContacts: [],
      allResources: [],
      allCompanies: [],
      error: null,
      isLoading: true,
      timer: null
    };
  }
  private inputRef = createRef<HTMLInputElement>();
  public async componentDidMount() {
    const {
      user
    } = this.props;

    // If we have loaded the projects already on the page where we don't have to load it again
    // This is the case on "Projekte / Prüfungen" were we can just
    // pass the projects to this component

    this.setState({
      isLoading: true
    });
    try {
      const contacts = await api.contacts.all();
      const resources = await api.resources.select();
      const companies = await api.companies.select();
      const projects = await api.projects.select(user.corporation_id, 0);
      const projectOptions = projects.map(p => ({
        label: p.name,
        value: p.id
      }));
      this.setState({
        allContacts: contacts,
        allResources: resources,
        allCompanies: companies,
        allProjects: projectOptions
      });
    } catch (error) {
      this.setState({
        error: (error as AxiosError)
      });
    }
    if (this.props.defaultProject) {
      this.props.setProjects([this.props.defaultProject], this.props.filterLocation);
    }
    this.setState({
      isLoading: false
    }, this.triggerEvents);
  }

  /**
   * Triggers all events with the values loaded from the state
   */
  private triggerEvents = () => {
    const filters = getFilterSettings(this.props.filters, this.props.filterLocation);
    if (filters.startDate || filters.endDate) {
      const startDate = filters.startDate ? new Date(filters.startDate) : null;
      const endDate = filters.endDate ? new Date(filters.endDate) : null;
      this.props.onFilterDate(startDate, endDate);
    }
    if (filters.selectedTesters && filters.selectedTesters.length > 0) {
      const testerIds = filters.selectedTesters.map(tester => tester.value);
      this.props.onFilterTesters(testerIds);
    }
    if (filters.selectedTeams && filters.selectedTeams.length > 0) {
      const teamIds = filters.selectedTeams.map(team => team.value);
      this.props.onFilterTeams(teamIds);
    }
    if (filters.selectedProjects && filters.selectedProjects.length > 0) {
      const projectIds = filters.selectedProjects.map(project => project.value);
      this.props.onFilterProjects(projectIds);
    }
    if (filters.selectedRessources && filters.selectedRessources.length > 0) {
      const resourceIds = filters.selectedRessources.map(resource => resource.value);
      this.props.onFilterRessources(resourceIds);
    }
  };
  public onSearch = (searchSubject: string) => {
    this.props.setTerm(searchSubject, this.props.filterLocation);
    this.props.onSearch(searchSubject);
    this.setState({
      timer: null
    });
  };
  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.state.error && this.props.onError) {
      this.props.onError(this.state.error);
    }
    const prevFilters = getFilterSettings(prevProps.filters, prevProps.filterLocation) || emptyFilterSettings;
    const filters = getFilterSettings(this.props.filters, this.props.filterLocation) || emptyFilterSettings;
    if (prevFilters.term !== filters.term && this.inputRef.current) {
      this.inputRef.current.value = filters.term;
    }
  }
  private closeFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters
    });
  };
  private handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      timer
    } = this.state;
    if (timer) {
      clearTimeout(timer);
    }
    const {
      value
    } = e.target;
    const newTimer = setTimeout(() => {
      this.onSearch(value);
    }, 500);
    this.setState({
      timer: newTimer
    });
  };
  private handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const target = (e.target as HTMLInputElement);
      this.onSearch(target.value);
    }
  };
  public render() {
    const {
      hideFilter,
      disabled
    } = this.props;
    const filters = getFilterSettings(this.props.filters, this.props.filterLocation) || emptyFilterSettings;
    const filterActive = filters.startDate || filters.endDate || filters.selectedProjects.length || filters.selectedRessources.length || filters.selectedContacts.length || filters.selectedTeams.length || filters.selectedTesters.length || filters.selectedCompanies?.length || filters.createdEndDate || filters.createdStartDate || filters.selectedCreators?.length || filters.selectedClosers?.length || filters.selectedTags.length;
    if (this.state.isLoading) {
      return null;
    }
    return <div className="search-fields">
        <Box sx={{
        display: 'flex',
        gap: '10px'
      }}>
          <SearchField>
            <Control hasIcons>
              <Icon className="fa fa-search" />
              <Input className="search-term" type="text" defaultValue={filters.term} placeholder={this.props.searchPlaceholder} onChange={this.handleSearch} ref={this.inputRef} onKeyPress={this.handleKeyPress} disabled={disabled} />
            </Control>
          </SearchField>
          <FilterToggle className="filter-toggler" onClick={this.closeFilters}>
            <div className={`filter-wrapper ${filterActive ? 'is-active' : ''}`}>
              <div className="has-position-relative">
                <img src={filterActive ? iconFiltersOpen : iconFiltersClosed} alt="open filter" id="open-filter-image" />
                {!!filterActive && <div className="dot" />}
              </div>
            </div>
          </FilterToggle>
        </Box>
        <Columns isMobile isMarginless isGapless>
          <Column hasTextAlign="left" isSize={12}>
            <OutsideClickHandler onOutsideClick={e => {
            const wrapper = document.querySelector('.filter-wrapper');
            const target = (e.target as HTMLElement);
            if (target.classList.contains('dropdown-select__option') || target.classList.contains('test__option')) return;
            if (!wrapper.contains(target)) {
              this.setState({
                showFilters: false
              });
            }
          }}>
              <Filters projects={this.state.allProjects} isOpen={this.state.showFilters && !hideFilter} resources={this.state.allResources} contacts={this.state.allContacts} companies={this.state.allCompanies} tags={this.props.tags} onApply={this.closeFilters} showTags={this.props.showTags} showContacts={this.props.showContacts} showDatepicker={this.props.showDatepicker} showProjects={this.props.showProjects} showResources={this.props.showResources} showTeams={this.props.showTeams} showUsers={this.props.showUsers} showCompanies={this.props.showCompanies} menuPlacement={this.props.menuPlacement} filterLocation={this.props.filterLocation} showCloser={this.props.showCloser} showCreator={this.props.showCreator} showStartDate={this.props.showStartDate} isClosedTab={this.props.isClosedTab} />
            </OutsideClickHandler>
          </Column>
        </Columns>
      </div>;
  }
}
function mapProps(state: IAlphaprocessState): IStateProps {
  return {
    user: state.core.user,
    filters: state.filter,
    tags: state.data?.tags ?? []
  };
}
function mapDispatch(dispatch: (x: any) => void): IDispatchProps {
  return {
    setProjects: (projects: Array<ISelectListValueModel<any>>, location) => dispatch(setProjects(projects, location)),
    setTerm: (term: string, location) => dispatch(setTerm(term, location))
  };
}
export default withRouter(connect(mapProps, mapDispatch)(withTranslation()(ActionTableSearch)));