import { compiledBlock as _compiledBlock } from "million/react";
const PriorityIcon_1 = _compiledBlock(_props => <span className={_props.v0} data-tooltip={_props.v1}>
      <i className={_props.v2} />
    </span>, {
  name: "PriorityIcon_1"
});
const PriorityIcon = function PriorityIcon({
  priority
}: IProps) {
  const {
    t
  } = useTranslation();
  let icon = null;
  let color = 'grey';
  switch (priority) {
    case 'trivial':
      icon = 'fas fa-arrow-down';
      break;
    case 'minor':
      icon = 'fas fa-angle-double-down';
      color = 'success';
      break;
    case 'medium':
      icon = 'fas fa-arrow-up';
      color = 'danger';
      break;
    case 'critical':
      icon = 'fas fa-angle-double-up';
      color = 'danger';
      break;
    case 'blocker':
      icon = 'fas fa-ban';
      color = 'danger';
      break;
    default:
      icon = 'fas fa-question';
  }
  return /*@million jsx-skip*/<PriorityIcon_1 v0={`icon tooltip is-tooltip-right has-text-${color}`} v1={t(`task.priorities.${priority}`)} v2={icon} />;
};
import { block as _block } from "million/react";
import { useTranslation } from 'react-i18next';
import { TaskPriority } from '../../api/tasksApi';
interface IProps {
  priority: TaskPriority;
}
export default PriorityIcon;