import deepcopy from 'deepcopy'
import uniqid from 'uniqid'
import { IQuestion } from '../api/types/checklist'

export default class ChecklistUtil {
  public static cloneQuestion(question: any) {
    const clone: IQuestion = deepcopy(question)
    clone.isNew = true
    // @ts-ignore
    clone.id = uniqid()
    clone.question_dropdown_items = clone
      .question_dropdown_items.map((dropdownItem: any) => {
        const clonedDropdownItem = dropdownItem
        clonedDropdownItem.id = uniqid()
        clonedDropdownItem.isNew = true
        delete clonedDropdownItem.question_id
        return clonedDropdownItem
      })

    clone.rule_sets.forEach((rs) => {
      rs.id = uniqid()
      rs.isNew = true
      rs.rule_action.id = uniqid()
      rs.rule_action.isNew = true
      rs.rules.forEach((rule) => {
        rule.id = uniqid()
        rule.isNew = true
      })
    })

    return clone
  }
}
