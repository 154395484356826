import { Column, Columns, Icon, Image } from 'bloomer';
import clsx from 'clsx';
import { lazy, Suspense, FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import { IResultAttachment } from '../../../../../api/types/result';
import { useEventBus } from '../../../../../pages/checklists/ResultView/EventBus';
import AsyncErrorBoundary from '../../../../AsyncErrorBoundary';
import CordovaView from '../../../../CordovaView';
import LoadSpinner from '../../../../loadSpinner/LoadSpinner';
import WebView from '../../../../WebView';
import MiniAccordion from '../../../../widgets/MiniAccordion';
const LazyImageEditor = lazy(() => import( /* webpackChunkName: "imageEditor" */'./ImageEditorModal'));
function ImageEditorModal(props) {
  return <AsyncErrorBoundary>
      <Suspense fallback={<LoadSpinner className="centered" />}>
        <LazyImageEditor {...props} />
      </Suspense>
    </AsyncErrorBoundary>;
}
interface Props {
  questionId: number;
  attachments: IResultAttachment[];
  onDelete?: (id: number) => void;
  isExpanded: boolean;
  onExpansion: (isExpanded: boolean) => void;
  onUpdate: (attachment: IResultAttachment, data: string, onSuccess: () => void) => void;
  accordion?: boolean;
  readonly?: boolean;
  disableEdit?: boolean;
  className?: string;
}
const ResultAttachments: FunctionComponent<Props> = ({
  attachments,
  onDelete,
  isExpanded,
  onExpansion,
  questionId,
  onUpdate,
  accordion = true,
  readonly = false,
  className,
  disableEdit = false,
  children
}) => {
  const {
    t
  } = useTranslation();
  const [photoIndex, setPhotoIndex] = useState<number>(null);
  const [editAttachment, setEditAttachment] = useState<IResultAttachment>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHighlighted, setIsHighlited] = useState<boolean>(false);
  useEventBus('attachment', questionId, () => {
    setIsHighlited(true);
    setTimeout(() => setIsHighlited(false), 3000);
  });
  const delegateOnUpdate = useCallback((attachment: IResultAttachment, data: string) => {
    onUpdate(attachment, data, () => setEditAttachment(null));
  }, [onUpdate, setEditAttachment]);
  const images = attachments.filter(x => x.attachment && x.attachment.thumb && x.attachment.thumb.url).map(x => ({
    id: x.id,
    src: x.attachment.url
  }));
  if (editAttachment) {
    return <ImageEditorModal attachment={editAttachment} onClose={() => setEditAttachment(null)} onUpdate={delegateOnUpdate} mode={questionId === 0 ? 'template' : 'checklist'} />;
  }
  const thumbnails = attachments.map(x => x.attachment.thumb && x.attachment.thumb.url ? <Column className="image-column" key={x.id} isSize={{
    mobile: 'full',
    tablet: '1/2',
    desktop: '1/2'
  }}>
      {!readonly && !disableEdit && <span className="edit-icon" onClick={() => {
      setEditAttachment(x);
      setPhotoIndex(null);
    }} data-tip={t('common.edit')} data-for="main-tooltip">
        <Icon className="fas fa-pencil-alt has-pointer fa-lg" />
      </span>}
      {!readonly && onDelete && <span className="delete-icon" onClick={() => onDelete(x.id)} data-tip={t('Delete')} data-for="main-tooltip">
        <Icon className="fas fa-trash-alt has-pointer fa-lg" />
      </span>}
      <Image className="has-pointer checklist-image" src={x.attachment.thumb.url} onClick={() => {
      setPhotoIndex(images.findIndex(y => y.id === x.id));
      setIsOpen(true);
    }} crossOrigin="anonymous" />
      <div className="filename">
        {decodeURI(x.filename)}
      </div>
    </Column> : <Column className="image-column attachment-thumb" isPaddingless key={x.id} isSize={{
    mobile: '1/2',
    tablet: '1/3',
    desktop: '1/4'
  }}>
        {onDelete && <span className="delete-icon" onClick={() => onDelete(x.id)} data-tip={t('Delete')} data-for="main-tooltip">
          <Icon className="fas fa-trash-alt has-pointer fa-lg" />
        </span>}
        <CordovaView>
          <div className="icon-wrapper">
            <Icon className="far fa-file" onClick={() => {
          // @ts-ignore
          window.cordova.InAppBrowser.open(x.attachment.url, '_system');
        }} />
          </div>
          <div className="filename">
            {decodeURI(x.filename)}
          </div>
        </CordovaView>
        <WebView>
          <a href={x.attachment.url} target="_blank" rel="noopener noreferrer">
            <Icon className="far fa-file" />
            <div className="filename">
              {decodeURI(x.filename)}
            </div>
          </a>
        </WebView>
      </Column>);
  const lightboxAttachment = photoIndex !== null ? attachments.find(x => x.id === images[photoIndex].id) : null;
  const body = <>
      <Columns isMobile isMultiline className={clsx('attachments-container', className, isHighlighted && 'is-highlighted')}>
        {thumbnails}
      </Columns>
      {isOpen ? <Lightbox mainSrc={images[photoIndex].src} nextSrc={images.length > 1 ? images[(photoIndex + 1) % images.length].src : null} prevSrc={images.length > 1 ? images[(photoIndex + images.length - 1) % images.length].src : null} onCloseRequest={() => {
      setIsOpen(false);
    }} enableZoom onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)} toolbarButtons={[<span className="delete-photo-button lightbox-icons">
                  {lightboxAttachment !== null && <span className="edit-photo-icon" data-tip={t('common.edit')} data-for="main-tooltip" onClick={() => setEditAttachment(lightboxAttachment)}>
                    <Icon className="fas fa-pencil-alt has-pointer fa-lg" />
                  </span>}

                  <Icon key={1} className="fas fa-trash has-pointer has-text-white" onClick={() => onDelete(images[photoIndex].id)} />
                </span>]} onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)} /> : null}
    </>;
  if (!accordion) {
    return <div>{body}</div>;
  }
  return <MiniAccordion title={t('result.attachments')} count={attachments ? attachments.length : 0} isOpen={isExpanded} setIsOpen={() => onExpansion(!isExpanded)}>
      {body}
      {children}
    </MiniAccordion>;
};
export default ResultAttachments;