import {
  parseISO, formatDistanceToNow, format, isToday, isAfter,
  differenceInSeconds, addSeconds, isBefore,
  intlFormat, intervalToDuration, formatDuration
} from 'date-fns'
import { enGB, de } from 'date-fns/locale'

type DateParam = string | number | Date

export default class DateUtil {
  static formatLocal(date: Date) {
    return intlFormat(DateUtil.ensureDate(date), {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }, { locale: this.locale.code })
  }

  private static locale = enGB

  public static readonly LongFormat = 'yyyy-MM-dd HH:mm'

  public static readonly ShortFormat = 'yyyy-MM-dd'

  public static readonly ShorterFormat = 'yy-MM-dd'

  private static ensureDate(value:DateParam): Date {
    if (typeof value === 'string') return parseISO(value)
    if (typeof value === 'number') return new Date(value)
    return value
  }

  public static setLocale(lang: string) {
    switch (lang) {
      case 'de': this.locale = de
        break
      default: this.locale = enGB
    }
  }

  public static getLocale() {
    return this.locale
  }

  public static fromNow(date: DateParam) {
    return formatDistanceToNow(this.ensureDate(date), { addSuffix: true, locale: this.locale })
  }

  public static format(date: DateParam, formatString: string) {
    return format(this.ensureDate(date), formatString, { locale: this.locale })
  }

  public static isToday(date: DateParam) {
    return isToday(this.ensureDate(date))
  }

  public static isAfter(date: DateParam, dateToCompare: DateParam) {
    return isAfter(this.ensureDate(date), this.ensureDate(dateToCompare))
  }

  public static diffInSeconds(date1: DateParam, date2: DateParam) {
    return differenceInSeconds(this.ensureDate(date1), this.ensureDate(date2))
  }

  public static duration(seconds: number) {
    const helperDate = addSeconds(new Date(0), seconds)
    return format(helperDate, 'HH:mm:ss')
  }

  public static parse(value: string | Date) {
    return this.ensureDate(value)
  }

  static isBefore(due_date: Date | string, endDate: Date) {
    return isBefore(this.ensureDate(due_date), this.ensureDate(endDate))
  }

  public static formatTime(date: Date | string) {
    return format(this.ensureDate(date), 'HH:mm')
  }

  public static formatDuration(startDate: DateParam, endDate: DateParam) {
    const end = this.ensureDate(endDate)
    const start = this.ensureDate(startDate)

    const duration = intervalToDuration({
      start,
      end
    })

    let formattedString = formatDuration(duration, {
      format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
      locale: this.locale,
      delimiter: ','
    })

    if (!formattedString.length) {
      formattedString = formatDuration(duration, {
        format: ['seconds'],
        locale: this.locale,
        delimiter: ','
      })
    }

    return formattedString.split(',').map((x) => {
      const [number, unit] = x.split(' ')

      return `${number}${unit.substr(0, 1)}`
    }).join(' ')
  }
}
