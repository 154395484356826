/* eslint-disable no-console */
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { logout } from '../actions/authActions'
import { setOfflineMode } from '../actions/offlineActions'
import { AlphaprocessDatabase } from '../offlineMode/offlineDb'
import { store } from '../store'

export interface IPaginatedData<T> {
  data: T[]
  currentPage: number
  totalPages: number
  totalCount: number
}
export class ApiBase {
  private client: AxiosInstance

  protected offlineDb: AlphaprocessDatabase

  constructor(client: AxiosInstance) {
    this.client = client
    this.offlineDb = new AlphaprocessDatabase()
  }

  protected isOffline(): boolean {
    return store.getState().core.isOffline
  }

  private static isNetWorkError(error: AxiosError): boolean {
    const { message, response } = error as AxiosError
    return message === 'Network Error' && !response
  }

  private static checkError(error: AxiosError): void {
    const { message, response } = error as AxiosError

    console.log('AXIOS ERROR MESSAGE', message)
    console.log('AXIOS ERROR RESPONSE', response)

    if (this.isNetWorkError(error)) {
      store.dispatch(setOfflineMode(true))
    }

    const messages = ['Email or password wrong.', 'E-Mail oder Passwort ist fehlerhaft.']

    if (response && response.status === 401 && messages.includes((response.data as any)?.message)) {
      store.dispatch(logout())
    }
  }

  // eslint-disable-next-line consistent-return
  protected async get<T>(url: string, params?: any): Promise<AxiosResponse<T>> {
    try {
      return await this.client.get<T>(url, { params })
    } catch (error) {
      const ae = error as AxiosError
      ApiBase.checkError(ae)
      if (ae.code !== '401' && !ApiBase.isNetWorkError(ae)) {
        throw error
      }
    }
  }

  // eslint-disable-next-line consistent-return
  protected async post<T>(url: string, data?: any): Promise<AxiosResponse<T>> {
    try {
      return await this.client.post<T>(url, data)
    } catch (error) {
      const ae = error as AxiosError
      ApiBase.checkError(ae)
      if (ae.code !== '401' && !ApiBase.isNetWorkError(ae)) {
        throw error
      }
    }
  }

  // eslint-disable-next-line consistent-return
  protected async put<T>(url: string, data?: any): Promise<AxiosResponse<T>> {
    try {
      return await this.client.put<T>(url, data)
    } catch (error) {
      const ae = error as AxiosError
      ApiBase.checkError(ae)
      if (ae.code !== '401' && !ApiBase.isNetWorkError(ae)) {
        throw error
      }
    }
  }

  // eslint-disable-next-line consistent-return
  protected async delete<T>(url: string): Promise<AxiosResponse<T>> {
    try {
      return await this.client.delete<T>(url)
    } catch (error) {
      const ae = error as AxiosError
      ApiBase.checkError(ae)
      if (ae.code !== '401' && !ApiBase.isNetWorkError(ae)) {
        throw error
      }
    }
  }

  protected paginateResponse<T>(response: AxiosResponse<T[]>): IPaginatedData<T> {
    return {
      data: response.data,
      currentPage: parseInt(response.headers['current-page']),
      totalPages: parseInt(response.headers['total-count']) > 0
        ? parseInt(response.headers['total-pages']) : 0,
      totalCount: parseInt(response.headers['total-count'])
    }
  }
}
