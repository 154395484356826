import { IList, ITodo, TodoListShare } from '../components/todolist/components/interface'
import { ApiBase } from './ApiBase'

interface ItemParams {
  done: boolean
}

export interface MetaData {
  open_count: number
  today_count: number
  starred_count: number
}

export default class TodoListApi extends ApiBase {
  public async index(): Promise<IList[]> {
    const response = await this.get<IList[]>('/todo_lists')

    return response.data
  }

  public async createList(list: IList): Promise<IList> {
    const response = await this.post<IList>('/todo_lists', list)

    return response.data
  }

  public async updateList(list: IList): Promise<IList> {
    const response = await this.put<IList>(`/todo_lists/${list.id}`, list)

    return response.data
  }

  public deleteList(id: string) {
    return this.delete<IList>(`/todo_lists/${id}`)
  }

  public async itemsIndex(listId: string, id?: string | null, params?: ItemParams): Promise<ITodo[]> {
    const response = id ? await this.get<ITodo[]>(`/todo_lists/${listId}/todo_list_items/${id}`)
      : await this.get<ITodo[]>(`/todo_lists/${listId}/todo_list_items`, params)

    return response.data
  }

  public async createItem(todo: ITodo): Promise<ITodo> {
    const response = await this.post<ITodo>(`/todo_lists/${todo.todo_list_id}/todo_list_items`, todo)

    return response.data
  }

  public async updateItem(todo: ITodo): Promise<ITodo> {
    const response = await this.put<ITodo>(`/todo_lists/${todo.todo_list_id}/todo_list_items/${todo.id}`, todo)

    return response.data
  }

  public deleteItem(listId: string, id: string) {
    return this.delete<IList>(`/todo_lists/${listId}/todo_list_items/${id}`)
  }

  public async shareList(id: string, email: string) {
    const response = await this.post<TodoListShare>(`/todo_lists/${id}/share`, { email })
    return response.data
  }

  public async doneItems(currentListId: string, page: number) {
    const response = await this.get<ITodo[]>(`/todo_lists/${currentListId}/todo_list_items/done`, { page })
    return this.paginateResponse(response)
  }

  public async meta(): Promise<MetaData> {
    const response = await this.get<MetaData>('/todo_lists/meta')

    return response.data
  }
}
