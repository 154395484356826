export const publicRoutes = [
  {
    path: '/signup',
    loader: () => import(/* webpackChunkName: "auth" */ '../pages/auth/Signup'),
    exact: true,
    forceReload: false
  },
  {
    path: '/login',
    loader: () => import(/* webpackChunkName: "auth" */ '../pages/auth/Login'),
    exact: true,
    forceReload: false
  },
  {
    path: '/resetpassword',
    loader: () => import(/* webpackChunkName: "auth" */ '../pages/auth/ResetPassword'),
    exact: true,
    forceReload: false
  },
  {
    path: '/userdata',
    loader: () => import(/* webpackChunkName: "create_user" */'../pages/users/Create'),
    exact: true,
    forceReload: false
  },
  {
    path: '/checklists/:id/audit/:resultSetId/report',
    loader: () => import(/* webpackChunkName: "report" */'../pages/ReportPage'),
    exact: true,
    forceReload: false
  },
  {
    path: '/resultset/:id/new',
    loader: () => import(/* webpackChunkName: "new_result_set" */'../pages/resultSets/NewResultSet'),
    exact: true,
    forceReload: false
  },
  {
    path: '/embedded/template/:id',
    loader: () => import(/* webpackChunkName: "embedded" */'../pages/embedded/EmbeddedTemplatePage'),
    exact: true,
    forceReload: false
  },
  {
    path: '/embedded/process/:id',
    loader: () => import(/* webpackChunkName: "embedded" */'../pages/embedded/EmbeddedProcessPage'),
    exact: true,
    forceReload: false
  },
  {
    path: '/embedded/process/:id/areas/:areaId/position/:areaPosition',
    loader: () => import(/* webpackChunkName: "embedded" */'../pages/embedded/EmbeddedProcessPage'),
    exact: true,
    forceReload: false
  },
  {
    path: '/debug',
    exact: true,
    loader: () => import('../pages/Debug')
  }

]

export const restrictedRoutes = [
  {
    path: '/projects/new',
    loader: () => import(
      /* webpackChunkName: "projects" */ '../pages/projects/NewProject'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/projects/edit/:id',
    loader: () => import(
      /* webpackChunkName: "projects" */ '../pages/projects/EditProject'
    ),
    exact: true,
    forceReload: false
  }, {
    path: '/tenant/profile',
    loader: () => import(
    /* webpackChunkName: "tenant" */ '../pages/tenant/TenantProfilePage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/templates/editor/:id?',
    loader: () => import(
      /* webpackChunkName: "editor" */ '../pages/checklistTemplates/Editor'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/users/edit/:id',
    loader: () => import(
      /* webpackChunkName: "people" */ '../pages/users/EditUserPage/EditUserPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/people',
    loader: () => import(
      /* webpackChunkName: "people" */ '../pages/people/PeopleIndex'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/people/new',
    loader: () => import(
      /* webpackChunkName: "people" */ '../pages/people/NewUser'
    ),
    exact: true,
    forceReload: false
  }
]

export const privateRoutes = [
  {
    path: '/profile',
    loader: () => import(
      /* webpackChunkName: "profile" */ '../pages/users/UserProfilePage/UserProfilePage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/wiki',
    loader: () => import(
      /* webpackChunkName: "wiki" */ '../pages/wiki/Wiki'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/todos/new',
    loader: () => import(
      /* webpackChunkName: "todo" */ '../pages/todo/NewTodoList'
    ),
    exact: true,
    forceReload: false,
    requireCompany: false
  },
  {
    path: '/todos/:id?',
    loader: () => import(
      /* webpackChunkName: "todo" */ '../pages/todo/TodoListContainer'
    ),
    exact: true,
    forceReload: false,
    requireCompany: false
  },
  {
    path: '/contacts',
    loader: () => import(
      /* webpackChunkName: "contacts" */ '../pages/contacts/ContactsIndex'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/contacts/new',
    loader: () => import(
      /* webpackChunkName: "contacts" */ '../pages/contacts/NewContact'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/contacts/:id',
    loader: () => import(
      /* webpackChunkName: "contacts" */ '../pages/contacts/EditContact'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/resources/new',
    loader: () => import(
      /* webpackChunkName: "resources" */ '../pages/resources/NewResource'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/resources/:id/edit',
    loader: () => import(
      /* webpackChunkName: "resources" */ '../pages/resources/EditResource'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/resources/:id?',
    loader: () => import(
      /* webpackChunkName: "resources" */ '../pages/resources/ResourceIndex'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/templates',
    loader: () => import(
      /* webpackChunkName: "user_templates" */ '../pages/templates/UserTemplates'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/admin/',
    loader: () => import(
      /* webpackChunkName: "admin" */ '../pages/admin/AdminPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/admin/app_config',
    loader: () => import(
      /* webpackChunkName: "admin" */ '../pages/admin/AppConfigPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/admin/payment_plans',
    loader: () => import(
      /* webpackChunkName: "admin" */ '../pages/admin/PaymentPlanListPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/admin/payment_plans/new',
    loader: () => import(
      /* webpackChunkName: "admin" */ '../pages/admin/NewPaymentPlanPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/admin/payment_plans/:id',
    loader: () => import(
      /* webpackChunkName: "admin" */ '../pages/admin/EditPaymenPlanPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/admin/template_store',
    loader: () => import(
      /* webpackChunkName: "admin" */ '../pages/admin/template_store/list/TemplateStoreListPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/admin/template_store/new',
    loader: () => import(
      /* webpackChunkName: "admin" */ '../pages/admin/template_store/new/NewTemplateStoreEntryPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/admin/template_store/:id',
    loader: () => import(
      /* webpackChunkName: "admin" */ '../pages/admin/template_store/edit/TemplateStoreEntryEditPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/companies',
    loader: () => import(
      /* webpackChunkName: "companies" */ '../pages/companies/CompaniesList/CampaniesListPage'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/companies/new',
    loader: () => import(
      /* webpackChunkName: "companies" */ '../pages/companies/NewCompany/index'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/companies/:id',
    loader: () => import(
      /* webpackChunkName: "companies" */ '../pages/companies/EditCompany/index'
    ),
    exact: true,
    forceReload: false
  },
  {
    path: '/boards/:id',
    loader: () => import(
      /* webpackChunkName: "kanban" */ '../pages/boards/TaskBoardPage'
    ),
    exact: true,
    forceReload: false
  }
]
