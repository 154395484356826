import { lazy, Suspense } from 'react';
import { BrowserView, MobileOnlyView, TabletView, isTablet } from 'react-device-detect';
import AsyncErrorBoundary from '../components/AsyncErrorBoundary';
import LoadSpinner from '../components/loadSpinner/LoadSpinner';
import HomeMobile from './HomeMobile';
const LazyNewOverview = lazy(() => import( /* webpackChunkName: "newOverview" */'./NewOverview'));
export function NewOverview(props) {
  return <AsyncErrorBoundary>
      <Suspense fallback={<LoadSpinner className="centered" />}>
        <LazyNewOverview {...props} />
      </Suspense>
    </AsyncErrorBoundary>;
}
export default function Home() {
  return <>
      {!isTablet && <BrowserView>
        <NewOverview />
      </BrowserView>}
      <TabletView>
        <NewOverview />
      </TabletView>
      {!isTablet && <MobileOnlyView>
        <HomeMobile />
      </MobileOnlyView>}
    </>;
}