import querystring from 'query-string'
import { v4 } from 'uuid'

import { ICreateResultSetFollowerData } from '../components/checklists/result/CreateResultSetFollowerSettings'
import { ApiBase, IPaginatedData } from './ApiBase'
import { IChecklistSelectItem } from './checklistsApi'
import { IQuestion } from './types/checklist'
import IResult, { ISignature } from './types/result'
import IResultSet, { IResultSetAreaDuplicateCount, IResultSetOfflineSync, ResultSetStatus } from './types/resultSet'
import ITeam from './types/team'
import IUser from './types/user'

export interface IResultSetListDto {
  title: string
  due: Date
  checklist_id: number
  id: number
  percentage: number
  question_count?: number
  result_count?: number
  tasks_remaining?: number
  area_count?: number
  testers: IUser[]
  teams: ITeam[]
  created_at: Date
  status: ResultSetStatus
  project_id: number
  project: string
  can_delete: boolean
  show_report: boolean
  is_over_due: boolean
  closed_at: Date
  ressource_id: number
  is_audit: boolean
  audit_points: number
  location: string
  resource: string
  description?: string
}

export interface ICloneAreaResponse {
  area_duplicate_count: IResultSetAreaDuplicateCount
  results: IResult[]
}

interface IParams {
  page: number
  items?: number
  template_id?: number
  ressource_id?: number | number[]
  team_ids?: number[]
  tester_ids?: number[]
  contact_id?: number[]
  project_id?: number[]
  title?: string
  sort?: string
  direction?: string
  startDate?: string
  endDate?: string
  folder_id?: string
}

export default class ResultSetApi extends ApiBase {
  public all() {
    return this.get<IResultSet[]>('/checklists/0/result_sets')
  }

  public async select(): Promise<IChecklistSelectItem[]> {
    const response = await this.get<IChecklistSelectItem[]>('/checklists/0/result_sets/select')
    return response.data
  }

  private static getDefaultValue(question: IQuestion) {
    if (question.has_default_today) {
      return new Date().toISOString()
    }

    if (question.default_value && question.default_value.length > 0) {
      return question.default_value
    }

    return ''
  }

  public async create(checklistId: number, data: IResultSet | {}, skipResultCreation = false): Promise<IResultSet> {
    if (this.isOffline()) {
      const all = await this.offlineDb.resultSets.toArray()
      let newId = Math.min(...all.map((x) => x.id), 0) - 1
      // eslint-disable-next-line no-loop-func
      while (all.some((x) => x.id === newId)) {
        newId--
      }
      const rs = data as IResultSet
      const template = await this.offlineDb.checklists.get(checklistId)
      const questions = template.areas.flatMap((x) => x.questions).filter((x) => x.default_value || x.has_default_today)
      const resultSet = {
        ...data,
        id: newId,
        due_date: rs.due_date instanceof Date ? rs.due_date.toISOString() : rs.due_date,
        writable: true,
        checklist_id: checklistId,
        results: questions.map((x) => ({
          id: v4(),
          area_id: x.area_id,
          question_id: x.id,
          result_set_id: newId,
          value: ResultSetApi.getDefaultValue(x),
          created_at: new Date(),
          updated_at: new Date(),
          _new: true,
          _dirty: true,
          question: x,
          action: [],
          area_position: 0,
          audit_points: 0,
          versions: [],
          question_postion: 1
        })),
        progress: 0,
        result_set_area_duplicate_counts: [],
        status: 'open',
        testers: rs.testers || [],
        teams: rs.teams || [],
        signatures: [],
        created_at: new Date().toISOString(),
        corporation_id: template.corporation_id,
        folder_id: template.folder_id,
        _dirty: true
      }
      await this.offlineDb.resultSets.add(resultSet as any)
      return resultSet as any
    }
    const response = await this.post<IResultSet>(`/checklists/${checklistId}/result_sets`, {
      ...data,
      skip_create_results: skipResultCreation
    })
    return response.data
  }

  public async update(
    checklistId: number,
    id: number,
    data: IResultSet,
    accessToken?: string | null
  ) {
    if (this.isOffline()) {
      return await this.offlineDb.updateResultSet(id, data)
    }

    let url = `/checklists/${checklistId}/result_sets/${id}`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      testers, checklist, versions, creator, action, contact,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ressource, teams, project, result_set_followers, signatures, results, ...rest
    } = data

    const response = await this.put<IResultSet>(url, rest)

    return response.data
  }

  public reopen(checklistId: number, id: number, accessToken?: string) {
    let url = `/checklists/${checklistId}/result_sets/${id}/reopen`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }
    return this.post<IResultSet>(url)
  }

  public async find(checklistId: number, id: number) {
    if (this.isOffline() || id < 0) {
      if (typeof id === 'string') id = parseInt(id, 10)
      const resultSet = await this.offlineDb.resultSets.get(id)
      if (!resultSet) return null
      resultSet.checklist = await this.offlineDb.checklists.get(resultSet.checklist_id)
      return resultSet
    }

    const response = await this.get<IResultSet>(`/checklists/${checklistId}/result_sets/${id}`)

    return response.data
  }

  public async frontendReport(checklistId: number, id: number, accessToken?: string | null) {
    if (this.isOffline()) {
      return this.find(checklistId, id)
    }
    let resultSetUrl = `/checklists/${checklistId}/result_sets/${id}/frontend_report`

    if (accessToken) {
      resultSetUrl += `?access_token=${accessToken}`
    }

    const response = await this.get<IResultSet>(resultSetUrl)

    // let checklistUrl = `/checklists/${response.data.checklist_id}`
    //  if (accessToken) {
    //   checklistUrl += `?access_token=${accessToken}`
    // }

    // const templateResponse = await this.get(checklistUrl)

    // response.data.checklist = templateResponse.data
    return response.data
  }

  public cloneArea(checklistId: number, resultSetId: number, areaId: number | string) {
    return this.post<ICloneAreaResponse>(`/checklists/${checklistId}/result_sets/${resultSetId}/clone_area`, {
      area_id: areaId
    })
  }

  // delete a cloned area
  public deleteArea(
    checklistId: number,
    resultSetId: number,
    areaId: number | string,
    areaPosition: number
  ) {
    return this.post<IResult>(`/checklists/${checklistId}/result_sets/${resultSetId}/delete_area`, {
      area_id: areaId,
      area_position: areaPosition
    })
  }

  public deleteResultSet(checklistId: number, id: number) {
    if (this.isOffline()) {
      if (typeof id === 'string') id = parseInt(id, 10)
      return this.offlineDb.resultSets.delete(id)
    }

    return this.delete(`/checklists/${checklistId}/result_sets/${id}`)
  }

  /**
   * Fetches all closed result_sets from the backend.
   * The result is paginated.
   *
   * @param params search parameters
   */
  public async closed(params: IParams) {
    if (params.title === null) {
      delete params.title
    }
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    const response = await this.get<IResultSetListDto[]>(`checklists/1/result_sets/closed?${query}`)

    return this.paginateResponse(response)
  }

  public async inProgress(params: IParams) {
    if (params.title === null) {
      delete params.title
    }
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    const response = await this.get<IResultSetListDto[]>(
      `/checklists/1/result_sets/in_progress?${query}`
    )

    return this.paginateResponse(response)
  }

  public async due(params: IParams): Promise<IPaginatedData<IResultSetListDto>> {
    if (params.title === null) {
      delete params.title
    }
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })

    const response = await this.get<IResultSetListDto[]>(`/checklists/1/result_sets/due?${query}`)

    return this.paginateResponse(response)
  }

  public findByAccessToken(accessToken: string) {
    return this.get<IResultSet>(`/checklists/1/result_sets/by_token?access_token=${accessToken}`)
  }

  public share(id: number, data: ICreateResultSetFollowerData) {
    return this.post(`/checklists/1/result_sets/${id}/share`, data)
  }

  public async createSignature(
    checklistId: number,
    resultSetId: number,
    data: ISignature,
    accessToken?: string | null
  ) {
    if (this.isOffline()) {
      return await this.offlineDb.createSignature(resultSetId, data)
    }

    let url = `/checklists/${checklistId}/result_sets/${resultSetId}/signatures.json`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    const response = await this.post<ISignature>(url, data)

    return response.data
  }

  public async updateSignature(
    checklistId: number,
    resultSetId: number,
    id: number | string,
    data: ISignature,
    accessToken?: string | null
  ) {
    if (this.isOffline()) {
      return await this.offlineDb.updateSignature(resultSetId, id, data)
    }

    let url = `/checklists/${checklistId}/result_sets/${resultSetId}/signatures/${id}`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    const response = await this.put<ISignature>(url, data)
    return response.data
  }

  public async history(resultSetId: number) {
    const response = await this.get<any[]>(`/checklists/1/result_sets/${resultSetId}/history`)
    return response.data
  }

  public async addOfflineSync(checklistId: number, resultSetId: number) {
    const response = await this.post<IResultSetOfflineSync>(
      `/checklists/${checklistId}/result_sets/${resultSetId}/result_set_offline_syncs/`
    )
    return response.data
  }

  public removeOfflineSync(checklistId: number, resultSetId: number, id: number) {
    return this.delete(`/checklists/${checklistId}/result_sets/${resultSetId}/result_set_offline_syncs/${id}`)
  }

  public async clone(checklistId: number, id: number) {
    const response = await this.post<IResultSet>(`/checklists/${checklistId}/result_sets/${id}/clone`)
    return response.data
  }

  public async openByUser(userId: number, filters: IParams) {
    const response = await this.get<IResultSetListDto[]>(
      '/checklists/1/result_sets/open_by_user',
      {
        ...filters,
        user_id: userId
      }
    )

    return this.paginateResponse(response)
  }
}
