import { gql } from '@apollo/client'
import { ITag } from '../../api/types/ressource'

export interface FetchTemplateStoreEntryVariables {
  id: string
}

export interface FetchTemplateStoreEntryResponse {
  templateStoreEntry: TemplateStoreEntry;
}
export interface TemplateStoreEntry {
  id: string;
  name: string;
  description: string;
  templateStoreCategoryId: string;
  published: boolean;
  favored: boolean;
  image: string;
  templateFile: string[];
  tags: ITag[]
  position: number | null
  templateStoreCategory: {
    name: string
  }
}

const FetchTemplateStoreEntry = gql`
query FetchTemplateStoreEntry($id: ID!) {
  templateStoreEntry(id: $id) {
    id
    name
    description
      position
     templateStoreCategoryId
      published
      favored
      image
      templateFile
      templateStoreCategory {
          name
      }
      tags {
        id
          title
      }
    }
}`

export default FetchTemplateStoreEntry
