import { gql } from '@apollo/client'

export interface CreateRulePayload {
  operator: string;
  expected: string;
  position: number;
  linkOperator: string | null;
  ruleSetId: string;
}

export interface Rule {
  id: string;
}

export interface CreateRule {
  errors: string[]
  rule: Rule;
}

export interface CreateRuleResponse {
  createRule: CreateRule;
}

const CREATE_RULE = gql`
mutation createRule(
  $operator: RuleOperator!
  $expected: String!
  $position: Int!
  $linkOperator: LinkOperator
  $ruleSetId: ID!
) {
  createRule(
    operator: $operator
    expected: $expected
    position: $position
    linkOperator: $linkOperator
    ruleSetId: $ruleSetId
  ) {
    errors
    rule {
      id
    }
  }
}
`

export default CREATE_RULE
