import { Component, ChangeEvent } from 'react';
import { Button, Column, Columns, Content, Control, Delete, Field, Icon, Image, Input, Media, MediaContent, MediaLeft, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, Notification } from 'bloomer';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as yup from 'yup';
import IResultSet from '../../../api/types/resultSet';
import IUser from '../../../api/types/user';
import defaultAvatar from '../../../images/default_avatar.png';
import ITranslationProps from '../../../types/translationProps';
import InputToClipboard from '../../widgets/InputToClipboard';
export interface ICreateResultSetFollowerData {
  follower_ids: number[];
  follower_emails: string[];
}
interface IOwnProps {
  users: IUser[];
  onSubmit: (data: ICreateResultSetFollowerData) => void;
  onCancel: () => void;
  isLoading?: boolean;
  externalCanEdit?: boolean | null;
  resultSet: IResultSet;
}
type Props = IOwnProps & ITranslationProps;
interface IFormData {
  email: string;
}
interface IState {
  selectedUsers: number[];
  emails: string[];
  formData: IFormData;
  activeTab: string;
  validationMessage: string | null;
}
class CreateResultSetFollowerSettings extends Component<Props, IState> {
  public static defaultProps = {
    isLoading: false
  };
  public state = {
    selectedUsers: [],
    emails: [],
    formData: {
      email: ''
    },
    activeTab: 'company',
    validationMessage: null
  };
  private schema = yup.object({
    email: yup.string().email(this.props.t('EmailRequired')).required(this.props.t('EmailRequired'))
  });
  private selectUser = (id: number) => {
    const isSelected = this.state.selectedUsers.filter(i => i === id).length > 0;
    const selectedUsers = isSelected ? this.state.selectedUsers.filter(i => i !== id) : [...this.state.selectedUsers, id];
    this.setState({
      selectedUsers
    });
  };
  private addEmail = async () => {
    try {
      await this.schema.validate(this.state.formData);
      const emails = [...this.state.emails, this.state.formData.email];
      this.setState({
        emails: [...new Set(emails)],
        formData: {
          email: ''
        }
      });
    } catch (err) {
      this.setState({
        validationMessage: (err as Error).message
      });
    }
  };
  private removeEmail = (email: string) => {
    const emails = this.state.emails.filter(e => e !== email);
    this.setState({
      emails
    });
  };
  private switchTab = (name: string) => {
    this.setState({
      activeTab: name
    });
  };
  private getInfoNotificationText = () => {
    const {
      t
    } = this.props;
    let message = null;
    switch (this.state.activeTab) {
      case 'company':
        message = t('FollowerTeamInfo');
        break;
      case 'email':
        message = t('FollowerExternInfo');
        break;
      case 'link':
        message = `${t('FollowTeamModal.linkDescription')} ${t('FollowTeamModal.clickToCopy')}`;
        break;
    }
    return message;
  };
  private handleSave = () => {
    const {
      selectedUsers,
      emails,
      formData
    } = this.state;
    if (formData.email && emails.indexOf(formData.email) === -1) {
      try {
        if (this.schema.isValidSync(formData)) {
          emails.push(formData.email);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    const data = {
      follower_ids: selectedUsers,
      follower_emails: emails
    };
    this.props.onSubmit(data);
  };
  public render() {
    const {
      t,
      resultSet,
      externalCanEdit
    } = this.props;
    const userList = this.props.users.map(user => {
      const isSelected = this.state.selectedUsers.filter(i => i === user.id).length > 0;
      const userAvatar = user.avatar && user.avatar.thumb && user.avatar.thumb.url ? user.avatar.thumb.url : defaultAvatar;
      return <Media key={user.id} onClick={() => this.selectUser(user.id)} className={isSelected ? 'is-selected' : null}>
          <MediaLeft>
            <Image isSize="64x64" src={userAvatar} className="avatar-image" />
          </MediaLeft>
          <MediaContent>
            <Content>
              <h5 className="is-marginless">
                {user.firstname}
                {' '}
                {user.lastname}
              </h5>
              Team:
              {' '}
              {user.teams ? user.teams.map(team => team.name).join(' ') : ''}
            </Content>
          </MediaContent>
        </Media>;
    });
    const linkTab = <TabPanel>
        <InputToClipboard value={resultSet.share_url} />
      </TabPanel>;
    const emailList = this.state.emails.map(email => <a href={`#${email}`} className="list-item" key={email}>
        {email}
        <Delete isPulled="right" onClick={() => this.removeEmail(email)} />
      </a>);
    const editWarning = externalCanEdit && ['email', 'link'].includes(this.state.activeTab) ? <Notification isColor="warning">
          {t('resultSet.externalEditWarning')}
        </Notification> : null;
    const modalClasses = isMobile ? 'create-result-set-modal-card is-huge modal-full-screen modal-fx-fadeInScale not-on-top' : 'create-result-set-modal-card not-on-top';
    return <Modal isActive className={modalClasses}>
        <ModalBackground />
        <ModalCard className="modal-content">
          <ModalCardHeader className="has-text-weight-semibold">
            {t('ResultSet-Follow-Modal-Title')}
          </ModalCardHeader>

          <ModalCardBody>
            <Columns isCentered>
              <Column isSize="full">
                <Notification isColor="info">
                  {this.getInfoNotificationText()}
                </Notification>
                {editWarning}
                <Tabs>
                  <TabList>
                    <Tab onClick={() => this.switchTab('company')}>
                      {t('FollowTeamModal.myCompany')}
                    </Tab>
                    <Tab onClick={() => this.switchTab('email')}>
                      {t('FollowTeamModal.emailNotifications')}
                    </Tab>
                    <Tab onClick={() => this.switchTab('link')}>
                      {t('FollowTeamModal.link')}
                    </Tab>
                  </TabList>
                  <TabPanel>
                    {userList}
                  </TabPanel>
                  <TabPanel>
                    <div className="list is-hoverable">
                      {emailList}
                    </div>
                    <Field hasAddons isFullWidth>
                      <Control isExpanded>
                        <Input name="email" placeholder={t('EmailRequired')} onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({
                        formData: {
                          ...this.state.formData,
                          email: e.target.value
                        }
                      })} onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                          e.preventDefault();
                          this.addEmail();
                        }
                      }} />
                        {this.state.validationMessage && <span className="help is-danger">
                          {this.state.validationMessage}
                        </span>}
                      </Control>
                      <Control>
                        <Button onClick={this.addEmail} className="button"><Icon className="fas fa-plus" /></Button>
                      </Control>
                    </Field>
                  </TabPanel>
                  {linkTab}
                </Tabs>
              </Column>
            </Columns>
          </ModalCardBody>
          <ModalCardFooter>
            <Button onClick={this.props.onCancel}>{t('common.cancel')}</Button>
            <Button isLoading={this.props.isLoading} isColor="dark" onClick={this.handleSave}>
              {t('Share')}
            </Button>
          </ModalCardFooter>
        </ModalCard>
      </Modal>;
  }
}
export default withTranslation()(CreateResultSetFollowerSettings);