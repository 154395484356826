import { IArea, IChecklist, IQuestion } from '../api/types/checklist'
import IResult from '../api/types/result'
import IResultSet, { AreaName } from '../api/types/resultSet'
import { isFilled } from './ReportUtil'

export default class ResultSetUtil {
  private static applyActionsToAreas(areas: IArea[], results: IResult[]) {
    for (const result of results) {
      const actions = result.action.filter((x) => x.action === 'show_area'
        || x.action === 'hide_area' || x.action === 'duplicate_area')
      for (const action of actions) {
        if (action.target_area_ids) {
          const targetAreas = areas.filter((x) => action.target_area_ids.includes(x.id as number))
          for (const ta of targetAreas) {
            ta.hide = action.action === 'hide_area'
          }
          for (const ta of targetAreas) {
            ta.show_count = action.target_area_count ?? 1
          }
        }
      }
    }
    return areas
  }

  private static applyActionsToQuestions(areas: IArea[], results: IResult[]) {
    const questions = areas.flatMap((x) => x.questions)

    for (const result of results) {
      const actions = result.action.filter((x) => x.action === 'show_question' || x.action === 'hide_question')
      for (const action of actions) {
        if (action.target_question_ids) {
          const targetQuestions = questions.filter((x) => action.target_question_ids.includes(x.id))
          for (const tq of targetQuestions) {
            tq.hide = (action.action === 'hide_question')
          }
        }
      }
    }
    return areas
  }

  private static applyRuleActions(areas: IArea[], results: IResult[]) {
    let processedAreas = this.applyActionsToAreas(areas, results)
    processedAreas = this.applyActionsToQuestions(processedAreas, results)
    return processedAreas
  }

  private static visibleQuestionsAndAreas(checklist: IChecklist, resultSet: IResultSet) {
    if (!checklist) return { questions: [], areas: [] }

    const areas = this.applyRuleActions(checklist.areas, resultSet.results)

    let duplicated_areas = []

    for (const area of areas) {
      if (area.show_count > 1) {
        for (let i = 0; i < area.show_count; i++) {
          duplicated_areas.push({ ...area, position: i + 1 })
        }
      } else {
        duplicated_areas.push(area)
      }
    }

    duplicated_areas = duplicated_areas.filter((x) => !x.hide)

    const questions = areas.flatMap((x) => x.questions).filter((x) => !x.hide
      && x.question_type !== 'information' && !x.exclude_from_progress)
    return {
      areas: duplicated_areas,
      questions
    }
  }

  private static completedVisibleQuestions(resultSet: IResultSet, questions: IQuestion[]) {
    const filled: IResult[] = []

    questions.forEach((q) => {
      const result = resultSet.results.find((x) => x.question_id === q.id)
      if (result && isFilled(result, resultSet)) {
        filled.push(result)
      }
    })

    return filled
  }

  public static completionPercentage(resultSet: IResultSet, checklist: IChecklist) {
    if (!checklist) return 0
    const { questions } = this.visibleQuestionsAndAreas(checklist, resultSet)
    const results_count = this.completedVisibleQuestions(resultSet, questions).length
    const questionsCount = questions.length
    if (questionsCount > 0 && results_count > 0) {
      return Math.round((results_count / questionsCount) * 100.0)
    }
    return 0
  }

  public static getAreaName(area: IArea, position: number, areaNames: AreaName[]) {
    const areaName = areaNames.find((x) => x.area_id === area.id && x.area_position === position)

    if (areaName) {
      return areaName.name
    }

    return `${area.title}${position > 1 ? ` #(${position})` : ''}`
  }
}
