import { Button, Column, Columns, Content, Field, FieldBody, FieldLabel, Icon, Image, Input, Label, Modal, ModalBackground, ModalClose, ModalContent, Notification } from 'bloomer';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import * as React from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { FilePond, registerPlugin } from 'react-filepond';
import { withTranslation } from 'react-i18next';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import uniqid from 'uniqid';
import api from '../../../../api';
import { IBoard } from '../../../../api/boardsApi';
import { ICloudAttachment } from '../../../../api/types/attachment';
import { IArea, IQuestion } from '../../../../api/types/checklist';
import IContact from '../../../../api/types/contact';
import IResult, { IComment, IResultAttachment } from '../../../../api/types/result';
import IResultSet from '../../../../api/types/resultSet';
import IUser from '../../../../api/types/user';
import { allowedImageFileTypes, AUDIT_POINT_MAX } from '../../../../constants';
import { AlphaprocessDatabase } from '../../../../offlineMode/offlineDb';
import { IAlphaprocessState } from '../../../../store';
import ITranslationProps from '../../../../types/translationProps';
import { confirm } from '../../../../util';
import { checkFileSelection } from '../../../../util/CloudUploadUtil';
import ResultUtil from '../../../../util/ResultUtil';
import RuleUtil from '../../../../util/RuleUtil';
import CordovaView from '../../../CordovaView';
import LoadSpinner from '../../../loadSpinner/LoadSpinner';
import WebView from '../../../WebView';
import MiniAccordion from '../../../widgets/MiniAccordion';
import PrettyCheckbox from '../../../widgets/PrettyCheckbox';
import { NewContactSelect } from '../../../widgets/selects/ContactSelect';
import { NewUserSelect } from '../../../widgets/selects/UserSelect';
import ExternalEmailInput from '../ExternalEmailInput';
import QuestionSignature from '../QuestionSignature';
import ResultComments from '../ResultComments';
import ResultTasks from '../ResultTasks';
import ResultTimespanInput from '../ResultTimespanInput';
import StartDateControl from '../StartDateControl';
import ResultAttachments from './attachments';
import CordovaAttachmentButtons from './attachments/CordovaAttachmentsButton';
import CloudAttachments from './cloudAttachments';
import ApiButton from './inputs/ApiButton';
import QuestionCalculatedValue from './inputs/QuestionCalculatedValue';
import QuestionDateInput from './inputs/QuestionDateInput';
import QuestionDropdownInput from './inputs/QuestionDropdownInput';
import QuestionNumberInput from './inputs/QuestionNumberInput';
import QuestionRatingInput from './inputs/QuestionRatingInput';
import QuestionTextInput from './inputs/QuestionTextInput';
import QuestionYesNoInput from './inputs/QuestionYesNoInput';
import { SpeechToText } from './inputs/SpeechToText';
import SmallLoadSpinner from '../../../loadSpinner/SmallLoadSpinner';

// import addIcon from '../../../images/icon_add.png'

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileEncode, FilePondPluginImageTransform, FilePondPluginImageResize, FilePondPluginImageExifOrientation, FilePondPluginFileValidateSize);
interface IOwnProps {
  resultQuestionPosition: number;
  getAttachments: (questionId: number, areaPosition: number) => IResultAttachment[];
  attachmentCount: number;
  comments: IComment[];
  resultId?: string | null;
  value: string | null;
  onUpdate: (id: number, data: any, field?: string, areaPosition?: number, questionPosition?: number) => Promise<IResult>;
  onAddComment: (questionId: number, areaPosition: number, text: string) => void;
  onDeleteComment: (commentId: number | string) => void;
  onAttachmentUpdated: (questionId: number, attachmentId: number, updatedAttachment: IResultAttachment) => void;
  onAttachmentDeleted: (resultId: number, attachmentId: number) => void;
  item: IQuestion;
  disabled: boolean;
  resultSetId: number;
  showAuditPoints: boolean;
  contacts: IContact[];
  result: IResult;
  users: IUser[];
  hideCommentsAndAttachments?: boolean;
  allAreas?: IArea[];
  freeSigneeSelection: boolean;
  questionId?: number;
  showCommentsAndAttachments?: boolean;
  resultAreaPosition: number;
  boards: IBoard[];
  markNotFilledRequiredFields?: boolean;
  startDate?: Date;
  accessToken?: string | null;
  cloudAttachmentCount?: number;
  checklistId: number;
  resultSet: IResultSet;
  isLoading?: boolean;
}
interface IStateProps {
  token: string;
  isOffline: boolean;
}
interface IState {
  value: string;
  comments: IComment[];
  option_text: string | null;
  isUploading: boolean;
  showAttachmentModal: boolean;
  attachmentModalType: string;
  showTasksModal: boolean;
  attachmentsExpanded?: boolean;
  cloudAttachmentsExpanded?: boolean;
  attachments: IResultAttachment[];
  cloud_attachments: ICloudAttachment[];
  isTextInputUpdating: boolean;
}
type Props = IOwnProps & IStateProps & ITranslationProps;
class ResultQuestion extends React.PureComponent<Props, IState> {
  public state: IState = {
    comments: this.props.result && this.props.comments ? this.props.comments : [],
    isUploading: false,
    showAttachmentModal: false,
    attachmentModalType: 'photo',
    value: this.props.result ? this.props.value : null,
    option_text: this.props.result ? this.props.result.option_text : null,
    showTasksModal: false,
    attachmentsExpanded: this.props.attachmentCount > 0 || this.props.item.has_attachments_required,
    cloudAttachmentsExpanded: true,
    attachments: [],
    cloud_attachments: [],
    isTextInputUpdating: false
  };
  public componentDidMount(): void {
    const attachments = this.props.getAttachments(this.props.questionId, this.props.resultAreaPosition);
    const cloudAttachments = this.props.result.cloud_attachments;
    this.setState({
      attachments,
      cloud_attachments: cloudAttachments
    });
    ReactTooltip.rebuild();
  }
  public componentDidUpdate(prevProps: Props, prevState: IState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      });
    }
    if (prevProps.result.option_text !== this.props.result.option_text) {
      this.setState({
        option_text: this.props.result.option_text
      });
    }
    if (this.props.result && this.props.item.question_type === 'signature' && (this.props.result.signature !== prevProps.result?.signature || this.props.result.contact_id !== prevProps.result.contact_id || this.props.result.user_id !== prevProps.result.user_id)) {
      this.setState({
        cloud_attachments: this.props.result.cloud_attachments
      });
    }
    const attachments = this.props.getAttachments(this.props.questionId, this.props.resultAreaPosition);
    const oldAttachmentUrls = this.state.attachments.map(x => x.attachment.url);
    const newAttachmentUrls = attachments.map(x => x.attachment.url);
    if (this.props.attachmentCount !== prevProps.attachmentCount || attachments.length !== this.state.attachments.length || newAttachmentUrls.some(x => !oldAttachmentUrls.includes(x))) {
      this.setState({
        attachments
      });
    }
    if ((prevProps.result.cloud_attachments !== this.state.cloud_attachments || prevProps.cloudAttachmentCount !== this.props.cloudAttachmentCount) && this.props.result.cloud_attachments) {
      const cloud_attachments = this.props.result.cloud_attachments ? this.props.result.cloud_attachments : [];
      this.setState({
        cloud_attachments
      });
    }
    if (prevProps.comments !== this.props.comments) {
      const comments = this.props.comments ? this.props.comments : [];
      this.setState({
        comments
      });
    }

    // ADF-849: immediately open the file chooser of the OS on mobile
    // when the user opens the attachment upload modal
    if (this.state.showAttachmentModal !== prevState.showAttachmentModal && this.state.showAttachmentModal && isMobileOnly && this.pond) {
      this.pond.browse();
    }
    try {
      if (this.props.result?.question?.question_type === 'text' && this.props.result?.question?.is_textfield && this.props.result?.value === '' && prevProps.result?.value !== '') {
        // eslint-disable-next-line no-console
        console.log('textfield was emptied', this.props.result);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  private pond: any;
  private getSelectedDropdownItemIds = (): number[] => this.state.value ? this.state.value.toString().split(',').map(id => parseInt(id)) : [];
  private toggleTaskModal = () => {
    this.setState(prevState => ({
      showTasksModal: !prevState.showTasksModal
    }));
  };

  // option_text is a column in rules table
  // options are separated by a pipe char |
  // and are in format [id of dropdown item]=[The additional option text]
  // e.g. 123=war nicht auf lager|456=Tesla|789=Fritz Kola
  private getOptionText = (id: number) => ResultUtil.getOptionText(id, this.state.option_text);
  private setOptionText = (id: number, value: string) => {
    let resultOptionText = '';
    let found = false;
    const optionTexts = this.state.option_text ? this.state.option_text.split('|') : [];
    // iterate through all options
    // manipulate option with if if found
    optionTexts.forEach((line: string) => {
      const splittedLine = line.split('=');
      if (splittedLine.length >= 2) {
        if (parseInt(splittedLine[0]) === id) {
          resultOptionText += `${splittedLine[0]}=${value}`;
          found = true;
        } else {
          resultOptionText += `${splittedLine[0]}=${splittedLine[1]}`;
        }
        resultOptionText += '|';
      }
    });

    // if there is no option text entry yet for this id then add it
    if (!found) {
      resultOptionText += `${id}=${value}`;
    }
    const {
      resultQuestionPosition,
      onUpdate,
      item,
      resultAreaPosition
    } = this.props;
    onUpdate(item.id, resultOptionText, 'option_text', resultAreaPosition, resultQuestionPosition);
    this.setState({
      option_text: resultOptionText
    });
  };
  private onSetFromDate = (date: string) => {
    const {
      item,
      resultAreaPosition,
      resultQuestionPosition,
      onUpdate
    } = this.props;
    const value = this.state.value ? this.state.value : ';';
    const timestamp = Date.parse(date);

    // if the date is not a valid date and not empty
    const isValidDate = !Number.isNaN(timestamp);
    const dates = value.split(';');
    if (dates.length <= 1) {
      onUpdate(item.id, `${isValidDate ? date : ''};`, 'value', resultAreaPosition, resultQuestionPosition);
      return;
    }
    let newValue = `${isValidDate ? date : ''};${dates[1]}`;
    if (newValue === ';') {
      newValue = '';
    }
    onUpdate(item.id, newValue, 'value', resultAreaPosition, resultQuestionPosition);
  };
  private onSetToDate = (date: string) => {
    const {
      item,
      resultAreaPosition,
      resultQuestionPosition,
      onUpdate
    } = this.props;
    const value = this.state.value ? this.state.value : ';';
    const timestamp = Date.parse(date);

    // if the date is not a valid date and not empty
    const isValidDate = !Number.isNaN(timestamp);
    const dates = value.split(';');
    if (dates.length <= 1) {
      onUpdate(item.id, `;${isValidDate ? date : ''}`, 'value', resultAreaPosition, resultQuestionPosition);
      return;
    }
    let newValue = `${dates[0]};${isValidDate ? date : ''}`;
    if (newValue === ';') {
      newValue = '';
    }
    onUpdate(item.id, newValue, 'value', resultAreaPosition, resultQuestionPosition);
  };
  private onStartDateChanged = (date: Date | null) => {
    this.props.onUpdate(null, date, 'start_date');
  };
  private onExternalEmailChanged = (email: string | null) => {
    const {
      onUpdate
    } = this.props;
    onUpdate(this.props.item.id, email, 'value', this.props.resultAreaPosition, this.props.resultQuestionPosition);
    onUpdate(null, email, 'external_email');
  };
  private getDateFrom = () => {
    const value = this.state.value ? this.state.value : ';';
    const dates = value.split(';');
    return dates.length >= 1 && dates[0].length && !Number.isNaN(Date.parse(dates[0])) ? dates[0] : null;
  };
  private getDateTo = () => {
    const value = this.state.value ? this.state.value : ';';
    const dates = value.split(';');
    return dates.length >= 2 && dates[1].length && !Number.isNaN(Date.parse(dates[1])) ? dates[1] : null;
  };
  private onUpdateDropdownItem = (dropdownItemId: number) => {
    const question = this.props.item;
    const {
      resultQuestionPosition,
      resultAreaPosition
    } = this.props;
    // single select
    if (!question.is_multi) {
      const oldValues = this.getSelectedDropdownItemIds();
      // toggle it
      const doCheck = !oldValues.includes(dropdownItemId);
      const newValue = doCheck ? dropdownItemId : '';
      this.props.onUpdate(question.id, newValue, 'value', resultAreaPosition, resultQuestionPosition);
      this.setState({
        value: newValue.toString()
      });
    } else {
      // multi select
      const oldValues = this.getSelectedDropdownItemIds();
      // toggle it
      const isChecked = oldValues.includes(dropdownItemId);
      // check an item
      if (!isChecked) {
        const newValues = [...oldValues, dropdownItemId];

        // value field in database is a text field
        // so we join all selected item ids comma separated together
        // and post it to the backend
        const valueString = newValues.join(',');
        this.props.onUpdate(question.id, valueString, 'value', this.props.resultAreaPosition, resultQuestionPosition);
        this.setState({
          value: valueString
        });
      } else if (isChecked) {
        // handle uncheck item
        const newValues = oldValues.filter(x => x !== dropdownItemId);

        // value field in database is a text field
        // so we join all selected item ids comma separated together
        // and post it to the backend
        const valueString = newValues.join(',');
        this.props.onUpdate(question.id, valueString, 'value', resultAreaPosition, resultQuestionPosition);
        this.setState({
          value: valueString
        });
      }
    }
  };
  private setAttachmentsExpanded = (attachmentsExpanded: boolean) => {
    this.setState({
      attachmentsExpanded
    });
  };
  private setCloudAttachmentsExpanded = (cloudAttachmentsExpanded: boolean) => {
    this.setState({
      cloudAttachmentsExpanded
    });
  };
  private handleOfflineFileAdded = async (error, file) => {
    const offlineDb = new AlphaprocessDatabase();
    const {
      item,
      resultId,
      resultSetId,
      resultAreaPosition,
      resultQuestionPosition
    } = this.props;
    const result = await offlineDb.addAttachment(resultSetId, resultId, item, resultAreaPosition, file.filename, file.getFileEncodeDataURL());
    await this.props.onUpdate(item.id, {
      ...result
    }, 'full', resultAreaPosition, resultQuestionPosition);
    if (this.pond) {
      this.pond.removeFile(file);
    }
    this.setState({
      isUploading: false
    });
    this.setState({
      showAttachmentModal: false
    });
    this.setAttachmentsExpanded(true);
    this.forceUpdate();
  };
  private handleSttUploadFinished = async () => {
    const {
      onUpdate,
      item,
      resultAreaPosition,
      resultQuestionPosition
    } = this.props;
    await onUpdate(item.id, true, '_working', resultAreaPosition, resultQuestionPosition);
  };
  private onAddCloudAttachments = (files: ICloudAttachment[], accessToken?: string | null) => {
    const {
      item,
      resultAreaPosition,
      result,
      onUpdate,
      resultQuestionPosition
    } = this.props;
    const newAttachments: ICloudAttachment[] = [];
    const oldAttachments = result?.cloud_attachments?.length ? result.cloud_attachments : [];
    files.forEach(file => {
      // Dropbox only returns an url if the file can be shared by link
      // if this it not enabled for the selected file then show an error message
      if (!checkFileSelection(file, oldAttachments, this.props.t)) {
        return;
      }

      // add file to state
      const newFile: ICloudAttachment = {
        id: uniqid(),
        name: file.name,
        url: file.url,
        access_token: accessToken,
        provider: file.provider,
        _new: true
      };
      newAttachments.push(newFile);
    });
    const allFiles = [...oldAttachments, ...newAttachments];
    return onUpdate(item.id, allFiles, 'cloud_attachments', resultAreaPosition, resultQuestionPosition);
  };
  private onDeleteAttachment = (file: ICloudAttachment) => {
    const {
      item,
      result,
      onUpdate,
      resultAreaPosition,
      resultQuestionPosition
    } = this.props;
    const attachments = result?.cloud_attachments?.length ? result.cloud_attachments : [];
    const attachmentToDelete = attachments.find(x => x.id === file.id && x.provider === file.provider);
    const otherAttachments = attachments.filter(x => !(x.id === file.id && x.provider === file.provider));
    if (!attachmentToDelete) {
      return;
    }
    confirm(null, this.props.t('cloudAttachments.confirmDelete', {
      filename: file.name
    }), () => {
      attachmentToDelete._destroy = true;
      const allFiles = [...otherAttachments, attachmentToDelete];
      onUpdate(item.id, allFiles, 'cloud_attachments', resultAreaPosition, resultQuestionPosition);
    });
  };
  private buildCalculationToolTooltip = () => {
    const questions = this.props.allAreas.flatMap(x => x.questions);
    const {
      formula
    } = this.props.item;
    const idRegex = /(?<questionId>\$\d+)/g;
    let toolTip = formula;
    const matches = formula.matchAll(idRegex);
    if (matches) {
      for (const match of matches) {
        const result = questions.find(x => x.id.toString() === match.groups.questionId.replace('$', ''));
        if (result) {
          toolTip = toolTip.replace(`$${result.id}`, result.title);
        }
      }
    }
    return toolTip;
  };
  private setValue = value => {
    this.setState({
      value
    });
  };
  public render() {
    const {
      item,
      t,
      onUpdate,
      disabled,
      resultSetId,
      isOffline,
      resultQuestionPosition,
      isLoading
    } = this.props;
    let answer;
    const value = this.props.result ? this.props.value : null;
    if (item.question_type === 'checkbox' || item.question_type === 'real_checkbox') {
      answer = !item.display_as_checkbox && item.question_type !== 'real_checkbox' ? <QuestionYesNoInput resultId={this.props.result.id} value={this.state.value} disableNa={item.disable_na} disabled={disabled} onUpdate={newValue => onUpdate(this.props.questionId, newValue, 'value', this.props.resultAreaPosition, resultQuestionPosition)} /> : null;
    } else if (item.question_type === 'text') {
      answer = <QuestionTextInput resultId={this.props.result.id} textField={item.is_textfield} disabled={this.state.isTextInputUpdating ? false : disabled} onChange={this.setValue} onBlur={val => onUpdate(this.props.questionId, val, 'value', this.props.resultAreaPosition, resultQuestionPosition)} setTextInputUpdating={isTextInputUpdating => this.setState({
        isTextInputUpdating
      })} value={this.state.value} />;
    } else if (item.question_type === 'number') {
      answer = <QuestionNumberInput resultId={this.props.result.id} value={this.state.value} disabled={disabled} onChange={this.setValue} onBlur={val => onUpdate(this.props.questionId, val, 'value', this.props.resultAreaPosition, resultQuestionPosition)} unit={item.unit} />;
    } else if (item.question_type === 'rating') {
      answer = <QuestionRatingInput min={item.min} resultId={this.props.result.id} max={item.max} disabled={disabled} value={this.state.value} onUpdate={newValue => onUpdate(this.props.questionId, newValue, 'value', this.props.resultAreaPosition, resultQuestionPosition)} />;
    } else if (item.question_type === 'datetime') {
      if (item.is_timespan) {
        answer = <ResultTimespanInput item={item} onUpdateFrom={this.onSetFromDate} onUpdateTo={this.onSetToDate} valueFrom={this.getDateFrom()} disabled={disabled} valueTo={this.getDateTo()} />;
      } else {
        answer = <QuestionDateInput areaPosition={this.props.resultAreaPosition} resultId={this.props.result.id} item={item} onUpdate={onUpdate} disabled={disabled} value={value} questionPosition={this.props.resultQuestionPosition} />;
      }
    } else if (item.question_type === 'signature') {
      answer = <QuestionSignature freeSelection={this.props.freeSigneeSelection} result={this.props.result} contacts={this.props.contacts} questionId={this.props.item.id} onUpdate={this.props.onUpdate} users={this.props.users} resultAreaPosition={this.props.resultAreaPosition} signature={this.props.result ? this.props.result.signature : null} contactId={this.props.result ? this.props.result.contact_id : null} userId={this.props.result ? this.props.result.user_id : null} signeeFreeText={item.signee_free_text} disabled={disabled} />;
    } else if (item.question_type === 'dropdown') {
      // show an error if we haven't at least one item in dropdown
      if (!(item.question_dropdown_items && item.question_dropdown_items.length)) {
        answer = <Notification isColor="danger">
            Dropdown has no items
          </Notification>;
      } else {
        const values: number[] = this.getSelectedDropdownItemIds();
        answer = <QuestionDropdownInput resultId={this.props.result.id} isMulti={item.is_multi} values={values} items={item.question_dropdown_items} onOptionTextChanged={this.handleOptionTextChanged} getOptionText={this.getOptionText} disabled={disabled} isHorizontal={item.is_horizontal} onChange={this.onUpdateDropdownItem} />;
      }
    } else if (item.question_type === 'information') {
      answer = <Content dangerouslySetInnerHTML={{
        __html: item.infotext
      }} />;
    } else if (item.question_type === 'start_date') {
      answer = <StartDateControl startDate={this.props.startDate} onChange={this.onStartDateChanged} />;
    } else if (item.question_type === 'external_email') {
      answer =
      // tslint:disable-next-line:no-console

      <ExternalEmailInput value={value} onChange={this.onExternalEmailChanged} />;
    } else if (item.question_type === 'apicall') {
      answer = <ApiButton question={item} resultSet={this.props.resultSet} />;
    } else if (item.question_type === 'person') {
      answer = <NewUserSelect selected={value?.split(',').map(x => parseInt(x)) ?? []} label="" onChange={newVal => onUpdate(item.id, newVal.join(','), 'value', this.props.resultAreaPosition)} />;
    } else if (item.question_type === 'contact') {
      answer = <NewContactSelect selected={value?.split(',').map(x => parseInt(x)) ?? []} label="" onChange={newVal => onUpdate(item.id, newVal.join(','), 'value', this.props.resultAreaPosition)} />;
    } else if (item.question_type === 'calculated_value') {
      answer = <QuestionCalculatedValue value={this.props.value} />;
    } else {
      answer = <Notification isColor="danger">
          Unknown question type:
          {' '}
          {item.question_type}
        </Notification>;
    }
    const comments = this.props.showCommentsAndAttachments && item.enable_comments && item.question_type !== 'information' ? <div className="result-content-block">
        <MiniAccordion title={t('result.comment.comments')} initialOpen={this.state.comments && this.state.comments.length > 0} count={this.state.comments && this.state.comments.length ? this.state.comments.length : 0}>
          <ResultComments comments={this.state.comments} onAddComment={this.props.onAddComment} onDeleteComment={this.props.onDeleteComment} questionId={this.props.questionId} readonly={!this.props.token} areaPosition={this.props.resultAreaPosition} hasResult={!!this.props.resultId} />
        </MiniAccordion>
      </div> : null;
    const taskModal = this.state.showTasksModal ? <ResultTasks resultSetId={this.props.resultSetId} result={this.props.result} onClose={this.toggleTaskModal} boards={this.props.boards} onUpdate={onUpdate} accessToken={this.props.accessToken} /> : null;
    const attachments = this.state.attachments ? this.state.attachments.map(x => x.attachment.thumb && x.attachment.thumb.url ? <Column className="image-column" key={x.id} isSize={{
      mobile: '1/2',
      tablet: '1/3',
      desktop: '1/4'
    }}>
          <span className="delete-icon" onClick={() => this.handleRemoveAttachment(x.id)} data-tip={t('Delete')} data-for="main-tooltip">
            <Icon className="fas fa-trash-alt has-pointer fa-lg" />
          </span>
          <Image className="has-pointer checklist-image" src={x.attachment.thumb.url} crossOrigin="anonymous" />
          <div className="filename">
            {decodeURI(x.filename)}
          </div>
        </Column> : <Column className="image-column attachment-thumb" isPaddingless key={x.id} isSize={{
      mobile: '1/2',
      tablet: '1/3',
      desktop: '1/4'
    }}>
            <span className="delete-icon" onClick={() => this.handleRemoveAttachment(x.id)} data-tip={t('Delete')} data-for="main-tooltip">
              <Icon className="fas fa-trash-alt has-pointer fa-lg" />
            </span>
            <CordovaView>
              <Icon className="far fa-file" onClick={() => {
          // @ts-ignore
          window.cordova.InAppBrowser.open(x.attachment.url, '_system');
        }} />
              <div className="filename">
                {decodeURI(x.filename)}
              </div>
            </CordovaView>
            <WebView>
              <a href={x.attachment.url} target="_blank" rel="noopener noreferrer">
                <Icon className="far fa-file" />
                <div className="filename">
                  {decodeURI(x.filename)}
                </div>
              </a>
            </WebView>
          </Column>) : null;
    const auditPoints = this.props.showAuditPoints && item.has_audit_points && item.question_type !== 'information' ? <Field>
          <FieldLabel hasTextAlign="left">
            <Label>
              {t('resultSet.auditPoints')}
            </Label>
          </FieldLabel>
          <FieldBody>
            <Input disabled={disabled} max={AUDIT_POINT_MAX} maxLength={2} style={{
          width: '10%'
        }} type="text" name="audit_points" defaultValue={(this.props.result && this.props.result.audit_points ? this.props.result.audit_points : 0).toString()} onBlur={this.handleAuditPointsChanged} />
          </FieldBody>
        </Field> : null;
    const attachmentClassList = ['buttons', 'has-addons', 'has-margin-top-5', 'attachment-buttons'];
    if (this.props.hideCommentsAndAttachments || !this.props.item.enable_attachments) {
      attachmentClassList.push('is-hidden');
    }
    const shownBecauseOfRuleTooltip = this.props.item.shown_because_of_rule ? <span data-tip={t('rules.ShownBecauseRule')} data-for="main-tooltip" data-place="right">
          <Icon className="fas fa-info-circle" />
        </span> : null;
    let checkBoxValue = false;
    if (value === 'true') checkBoxValue = true;else if (value === 'n/a') checkBoxValue = null;
    const checkbox = item.question_type === 'checkbox' && item.display_as_checkbox || item.question_type === 'real_checkbox' ? <PrettyCheckbox value={checkBoxValue} onChange={newValue => {
      onUpdate(item.id, newValue, 'value', this.props.resultAreaPosition, resultQuestionPosition);
    }} text={resultQuestionPosition < 2 ? item.title : `${item.title} #${resultQuestionPosition}`} isRequired={item.is_required} disableNa={item.disable_na || false} shownBecauseOfRuleTooltip={shownBecauseOfRuleTooltip} /> : <Label className={item.is_required ? 'is-required is-inline-block' : 'is-inline-block'}>
          {resultQuestionPosition < 2 ? item.title : `${item.title} #${resultQuestionPosition}`}
          {shownBecauseOfRuleTooltip}
          {item.question_type === 'calculated_value' && <span data-tip={this.buildCalculationToolTooltip()} data-for="main-tooltip" data-place="right" className="icon">
              <Icon className="fas fa-calculator" />
            </span>}
        </Label>;
    const tooltipTexts = this.props.item.rule_sets ? this.props.item.rule_sets.map(ruleSet => RuleUtil.getRuleSetText(ruleSet, this.props.allAreas)) : null;
    const tooltip = tooltipTexts ? tooltipTexts.join(' ') : null;

    // it shows an icon for the questions that have rules
    const rulesTooltip = this.props.item.rule_sets && this.props.item.rule_sets.length ? <span data-tip={tooltip} data-for="main-tooltip" data-place="right" style={{
      display: 'flex',
      alignItems: 'center'
    }}>
        <Icon className="fas fa-code-branch" />
        {isLoading && <span style={{
        marginRight: '10px'
      }}>
            <SmallLoadSpinner />
          </span>}
      </span> : null;
    const tasksButton = this.props.item.show_tasks_button ? <div className="buttons has-addons has-margin-top-5 attachment-buttons">
        <Button isHidden={item.question_type === 'information'} className="has-margin-top-5 has-text-weight-bold attachment-button has-badge-rounded" onClick={this.toggleTaskModal} disabled={!this.props.token || disabled} data-badge={this.props.result.tasks && this.props.result.tasks.length > 0 ? this.props.result.tasks.length : null}>
          <Icon className="fas fa-tasks has-margin-right-5" isSize="small" />
          <span>{t('menu.tasks')}</span>
        </Button>
      </div> : null;
    let url = `/checklists/1/result_sets/${resultSetId}/attachment?question_id=${item.id}`;
    if (this.props.accessToken) {
      url += `&access_token=${this.props.accessToken}`;
    }
    const serverSettings = !isOffline ? {
      fetch: null,
      process: {
        headers: {
          Authorization: `Bearer ${this.props.token} `
        },
        onload: this.handleOnLoad,
        url,
        onerror: () => {
          this.setState({
            isUploading: false
          });
        },
        ondata: (data: FormData) => {
          data.append('area_position', (this.props.result.area_position || 1).toString());
          return data;
        }
      },
      revert: null,
      url: process.env.REACT_APP_API_BASE_URL
    } : null;
    const descriptionText = item.question_type !== 'information' && item.description && item.description.trim().length > 0 ? <div className="has-margin-top-8 has-margin-bottom-8">
          <div className="task-details-label">
            {t('resultSet.taskDetails')}
          </div>
          <MiniAccordion closedTitle={t('resultSet.moreInformation')} openTitle={t('resultSet.lessInformation')} initialOpen={!item.is_description_collapsed}>
            <div className="task-details-text">
              <Content dangerouslySetInnerHTML={{
            __html: item.description
          }} />
            </div>
          </MiniAccordion>
        </div> : null;
    const isRequiredAndNotFilled = this.props.item.is_required && !this.state.value;
    const hasAttachmentsRequiredAndNotFilled = this.props.item.has_attachments_required && !this.props.attachmentCount;

    // ADF-335: try to close the checklist, required field is not filled => Show red border
    const requiredClass = this.props.markNotFilledRequiredFields && (isRequiredAndNotFilled || hasAttachmentsRequiredAndNotFilled) ? 'has-red-border' : 'has-invisible-border';
    return <div className={`result-question  ${requiredClass}`}>
        <div className="has-hyphens question-title">
          <Columns isMobile isMarginless>
            <Column isSize={12} isPaddingless>
              {this.props.result._working && <LoadSpinner />}
              {this.props.item.question_type === 'information' && <Icon className="fa fa-info-circle" />}
              {checkbox}
              {rulesTooltip}
            </Column>
          </Columns>
        </div>
        {descriptionText}
        <div className="result-question-block has-margin-bottom-5">{answer}</div>
        
        {auditPoints}
        {!!(this.props.item.enable_attachments || attachments.length) && !this.props.hideCommentsAndAttachments && <div className="result-content-block">
            <ResultAttachments questionId={item.id} attachments={this.state.attachments} onUpdate={this.handleUpdateAttachment} onDelete={this.handleRemoveAttachment} isExpanded={this.state.attachmentsExpanded} onExpansion={this.setAttachmentsExpanded}>
              {' '}
              <div className={attachmentClassList.join(' ')}>
                <WebView>
                  <Button className="has-margin-top-5 has-text-weight-bold attachment-button" disabled={disabled} onClick={() => this.setState({
                showAttachmentModal: true,
                attachmentModalType: 'photo'
              })}>
                    <Icon className="fas fa-camera has-margin-right-5" isSize="small" />

                    <span className={item.has_attachments_required ? 'is-required' : null}>
                      {t('Photos')}
                    </span>
                  </Button>
                  <Button disabled={disabled} className="has-margin-top-5 has-text-weight-bold attachment-button" onClick={() => this.setState({
                showAttachmentModal: true,
                attachmentModalType: 'document'
              })}>
                    <Icon className="fas fa-paperclip has-margin-right-5" isSize="small" />
                    <span className={item.has_attachments_required ? 'is-required is-inline-block' : 'is-inline-block'}>
                      {t('Documents')}
                    </span>
                  </Button>
                </WebView>
                <CordovaView>
                  <CordovaAttachmentButtons resultId={this.props.resultId} areaPosition={this.props.resultAreaPosition} checklistId={1} questionId={this.props.questionId} resultSetId={this.props.resultSetId} accessToken={this.props.accessToken} onFileUploaded={this.onCordovaFileUpload} />
                </CordovaView>
              </div>
            </ResultAttachments>
          </div>}

        {
      // show the cloud attachments only if the current user is online.
      // Without a working internet connection this feature makes no sense
      !isOffline && !this.props.hideCommentsAndAttachments && this.props.item.enable_cloud_attachments && <CloudAttachments attachments={this.state.cloud_attachments} cloudAttachmentCount={this.props.cloudAttachmentCount} onAdd={this.onAddCloudAttachments} onDelete={this.onDeleteAttachment} isExpanded={this.state.cloudAttachmentsExpanded} onExpansion={this.setCloudAttachmentsExpanded} />}
        {item.question_type === 'text' && !item.is_textfield && item.enable_speech_to_text && <SpeechToText accessToken={this.props.accessToken} resultSetId={this.props.resultSetId} resultId={this.props.resultId} checklistId={this.props.checklistId} onUploadFinished={this.handleSttUploadFinished} />}
        {comments}
        {tasksButton}

        {this.state.showAttachmentModal && <Modal className="attachment-modal" isActive>
            <ModalBackground onClick={this.handleCloseAttachmentModal} />
            <ModalContent>
              <FilePond maxFileSize="20MB" allowImageExifOrientation acceptedFileTypes={this.state.attachmentModalType === 'document' ? undefined : allowedImageFileTypes} disabled={disabled} ref={(ref: any) => this.pond = ref} onaddfilestart={() => {
            this.setState({
              isUploading: true
            });
          }} onprocessfiles={() => {
            if (this.pond) {
              this.pond.removeFiles();
            }
            this.setState({
              isUploading: false,
              showAttachmentModal: false
            });
            this.setAttachmentsExpanded(true);
          }} onerror={() => this.setState({
            isUploading: false
          })} onaddfile={isOffline ? this.handleOfflineFileAdded : null} allowMultiple allowImageTransform imageTransformOutputQuality={80} server={serverSettings} maxParallelUploads={1} imageResizeMode="contain" imageResizeUpscale={false} imageResizeTargetWidth={2048} labelIdle={isMobile || 'cordova' in window ? t('FilePond-Idle-Mobile') : t('FilePond-Idle')} labelInvalidField={t('FilePond-InvalidField')} labelFileWaitingForSize={t('FilePond-FileWaitingForSize')} labelFileSizeNotAvailable={t('FilePond-FileSizeNotAvailable')} labelFileCountSingular={t('FilePond-FileCountSingular')} labelFileCountPlural={t('FilePond-FileCountPlural')} labelFileLoading={t('FilePond-FileLoading')} labelFileAdded={t('FilePond-FileAdded')} labelFileLoadError={t('FilePond-FileLoadError')} labelFileRemoved={t('FilePond-FileRemoved')} labelFileRemoveError={t('FilePond-FileRemoveError')} labelFileProcessing={t('FilePond-FileProcessing')} labelFileProcessingComplete={t('FilePond-FileProcessingComplete')} labelFileProcessingAborted={t('FilePond-FileProcessingAborted')} labelFileProcessingError={this.getErrorLabel} labelFileProcessingRevertError={t('FilePond-FileProcessingRevertError')} labelTapToCancel={t('FilePond-TapToCancel')} labelTapToRetry={t('FilePond-TapToRetry')} labelTapToUndo={t('FilePond-TapToUndo')} labelButtonRemoveItem={t('FilePond-ButtonRemoveItem')} labelButtonAbortItemLoad={t('FilePond-ButtonAbortItemLoad')} labelButtonRetryItemLoad={t('FilePond-ButtonRetryItemLoad')} labelButtonAbortItemProcessing={t('FilePond-ButtonAbortItemProcessing')} labelButtonUndoItemProcessing={t('FilePond-ButtonUndoItemProcessing')} labelButtonRetryItemProcessing={t('FilePond-ButtonRetryItemProcessing')} labelButtonProcessItem={t('FilePond-ButtonProcessItem')} />
            </ModalContent>
            <ModalClose onClick={() => {
          if (this.state.isUploading) {
            // If a file is selected ask before closing the modal
            confirm(null, t('AbortUpload'), () => {
              this.setState({
                showAttachmentModal: false
              });
            });
          } else {
            // ADF-231: If no file is selected yet just close the modal
            this.setState({
              showAttachmentModal: false
            });
          }
        }} />
          </Modal>}
        {taskModal}
      </div>;
  }
  private getErrorLabel = (errorResponse: any) => {
    const {
      t
    } = this.props;

    // if the backend returns status 400 this means, that the file type is rejected
    // unfortunately we can not access the response body at this point
    // so we can not show a specific error message
    // Most file types except some executable file formats are allowed
    return errorResponse.code === 400 ? t('FilePond-TypeNotAllowed') : t('FilePond-FileProcessingError');
  };
  private handleCloseAttachmentModal = () => {
    if (!this.state.isUploading) {
      this.setState({
        showAttachmentModal: false
      });
    }
  };
  private handleOptionTextChanged = (id: number, e: React.FocusEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    this.setOptionText(id, text);
  };
  private onCordovaFileUpload = (result: IResult) => {
    const {
      resultQuestionPosition,
      onUpdate,
      questionId,
      resultAreaPosition
    } = this.props;
    onUpdate(questionId, result, 'full', resultAreaPosition, resultQuestionPosition);
    this.setAttachmentsExpanded(true);
  };
  private handleOnLoad = (response: string) => {
    const result = JSON.parse(response);
    const {
      resultQuestionPosition,
      onUpdate,
      questionId,
      resultAreaPosition
    } = this.props;
    onUpdate(questionId, result, 'full', resultAreaPosition, resultQuestionPosition);
    return 'ok';
  };
  private handleAuditPointsChanged = (e: React.FocusEvent<HTMLInputElement>) => {
    const audit_points = !Number.isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0;
    const {
      item,
      onUpdate,
      resultAreaPosition,
      resultQuestionPosition
    } = this.props;
    onUpdate(item.id, Math.min(AUDIT_POINT_MAX, audit_points), 'audit_points', resultAreaPosition, resultQuestionPosition);
  };
  private handleUpdateAttachment = (attachment: IResultAttachment, data, callback) => {
    api.results.updateAttachment(this.props.resultSetId, this.props.resultId, attachment.id, data, this.props.accessToken).then(response => {
      callback();
      this.props.onAttachmentUpdated(this.props.item.id, attachment.id, response.data);
    });
  };
  private handleRemoveAttachment = (id: number) => {
    confirm(null, this.props.t('result.confirmDeleteAttachment'), () => {
      api.results.deleteAttachment(this.props.resultSetId, this.props.resultId, id, this.props.accessToken).then(() => {
        this.props.onAttachmentDeleted(this.props.item.id, id);
      });
    });
  };
}
const mapStateToProps = (state: IAlphaprocessState): IStateProps => ({
  token: state.core.jwt,
  isOffline: state.core.isOffline
});
export default connect(mapStateToProps)(withTranslation()(ResultQuestion));