import { ISortSetting } from '../reducers/sortReducer'

export interface ISetCreatedTemplateSortAction {
  type: 'SORT_SET_CREATED_TEMPLATE_SORT'
  settings: ISortSetting
}

export interface ISetClosedResultsSortAction {
  type: 'SORT_SET_CLOSED_RESULTS_SORT'
  settings: ISortSetting
}

export interface ISetActiveResultsSortAction {
  type: 'SORT_SET_ACTIVE_RESULTS_SORT'
  settings: ISortSetting
}

export interface ISetProjectsSortAction {
  type: 'SORT_SET_PROJECTS_SORT'
  settings: ISortSetting
}

export interface ISetNextInspectionsSortAction {
  type: 'SORT_SET_NEXT_INSPECTIONS_SORT'
  settings: ISortSetting
}

export interface ISetDueResultSetsAction {
  type: 'SORT_SET_DUE_RESULT_SETS_SORT'
  settings: ISortSetting
}

export type SortActions = ISetActiveResultsSortAction
| ISetClosedResultsSortAction
| ISetCreatedTemplateSortAction
| ISetNextInspectionsSortAction
| ISetProjectsSortAction
| ISetDueResultSetsAction

export function setCreatedTempaltesSort(settings: ISortSetting): ISetCreatedTemplateSortAction {
  return {
    settings,
    type: 'SORT_SET_CREATED_TEMPLATE_SORT'
  }
}

export function setClosedResultsSort(settings: ISortSetting): ISetClosedResultsSortAction {
  return {
    settings,
    type: 'SORT_SET_CLOSED_RESULTS_SORT'
  }
}

export function setActiveResultsSort(settings: ISortSetting): ISetActiveResultsSortAction {
  return {
    settings,
    type: 'SORT_SET_ACTIVE_RESULTS_SORT'
  }
}

export function setProjectsSort(settings: ISortSetting): ISetProjectsSortAction {
  return {
    settings,
    type: 'SORT_SET_PROJECTS_SORT'
  }
}

export function setNextInspectionsSort(settings: ISortSetting): ISetNextInspectionsSortAction {
  return {
    settings,
    type: 'SORT_SET_NEXT_INSPECTIONS_SORT'
  }
}

export function setDueResultSetsSort(settings: ISortSetting): ISetDueResultSetsAction {
  return {
    settings,
    type: 'SORT_SET_DUE_RESULT_SETS_SORT'
  }
}
