import { Dropdown, DropdownContent, DropdownMenu, DropdownTrigger } from 'bloomer';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import settingsIcon from '../../images/icon_settings.png';
interface Props {
  isOpen: boolean;
  onToggle: (open: boolean) => void;
  children: any;
  dataId: string | number | null;
  className?: string;
  isUp?: boolean;
}
export default function GearMenu({
  isOpen,
  onToggle,
  children,
  dataId,
  className,
  isUp = true
}: Props) {
  const {
    t
  } = useTranslation();
  const onClickOutSide = event => {
    // if the user clicks other datasets gear icon then close the menu
    const parentItem = document.querySelector(`#gear-dropdown-${dataId}`);
    const target = (event.target as HTMLElement);

    // ClickOutSide Handler is also called if I click the toggle icon for reasons
    // this is a workaround to prevent this
    if (!parentItem.contains(target) || !(target.classList.contains('gear-menu-toggle') || target.classList.contains('icon-settings'))) {
      onToggle(false);
    }
  };
  return <Dropdown isPulled="right" isActive={isOpen} className={clsx('has-pointer', 'gear-menu', isUp && 'is-up', className)} id={`gear-dropdown-${dataId}`}>
      <DropdownTrigger>
        <span onClick={() => onToggle(!isOpen)} className="has-pointer gear-menu-toggle">
          <img src={settingsIcon} className="icon-settings is-pulled-right has-pointer" alt={t('resultSet.settings')} />
        </span>
      </DropdownTrigger>
      <OutsideClickHandler onOutsideClick={onClickOutSide}>
        <DropdownMenu className="area-dropdownmenu">
          <DropdownContent>
            {children}
          </DropdownContent>
        </DropdownMenu>
      </OutsideClickHandler>
    </Dropdown>;
}