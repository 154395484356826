import { AxiosError } from 'axios';
import { Button, Column, Columns, Container, Control, Delete, Field, Icon, Notification, Panel, PanelBlock, PanelHeading } from 'bloomer';
import { Formik, Field as FormikField, ErrorMessage, Form, FormikHelpers } from 'formik';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import api from '../../api';
import Error from '../../components/ErrorView';
interface FormData {
  input: string;
  emails: string[];
}
const TeamInvite: FunctionComponent = () => {
  const {
    t
  } = useTranslation();
  const [error, setError] = useState<AxiosError | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const schema = useMemo(() => yup.object({
    input: yup.string().email(t('users.validation.emailValid')),
    emails: yup.array().of(yup.string().email(t('users.validation.emailValid')))
  }), [t]);
  const handleSubmit = useCallback((values: FormData, helpers: FormikHelpers<FormData>) => {
    // add email before submit (if we have one that isn't added yet)
    const emails = [...values.emails];
    if (values.input && schema.isValidSync(values)) {
      emails.push(values.input);
      helpers.setFieldValue('emails', emails);
    }
    if (emails.length === 0) {
      helpers.setSubmitting(false);
      return;
    }
    api.invite({
      emails
    }).then(() => {
      setSuccess(true);
      setError(null);
      helpers.setFieldValue('input', '');
    }).catch(e => {
      setSuccess(false);
      setError(e);
    }).finally(() => {
      helpers.setSubmitting(false);
    });
  }, [schema]);
  const message = success ? <Notification isColor="success">{t('team.invites.messages.success')}</Notification> : null;
  const errorView = error ? <Error error={error} /> : null;
  return <Container className="is-vertical-center">
      <Formik initialValues={{
      emails: [],
      input: ''
    }} validationSchema={schema} onSubmit={handleSubmit}>
        {({
        values,
        setFieldValue
      }) => <Form>
            <Columns isCentered>
              <Column isSize="1/2">
                <Panel className="signup-box">
                  <PanelHeading className="has-text-weight-semibold">
                    {t('team.invites.header')}
                  </PanelHeading>
                  <PanelBlock hasTextAlign="left" isDisplay="block">
                    <Columns>
                      <Column isSize="full">
                        {message}
                        {errorView}
                        <div className="list is-hoverable">
                          {values.emails.map((x: string) => <div className="list-item" key={x}>
                              {x}
                              <Delete isPulled="right" onClick={() => setFieldValue('emails', values.emails.filter(email => x !== email))} />
                            </div>)}
                        </div>
                        <Field hasAddons isFullWidth>
                          <Control isExpanded>
                            <FormikField className="input" name="input" placeholder={t('team.invites.placeholders.email')} onKeyDown={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                            if (values.input.length > 0 && schema.isValidSync({
                              input: values.input,
                              emails: []
                            })) {
                              setFieldValue('emails', [...values.emails, values.input]);
                              setFieldValue('input', '');
                            }
                          }
                        }} />
                            <ErrorMessage component="span" name="input" className="help is-danger" />
                          </Control>
                          <Control>
                            <Button type="button" className="has-no-border" onClick={() => {}}>
                              <Icon className="fas fa-plus" />
                            </Button>
                          </Control>
                        </Field>
                      </Column>
                    </Columns>
                    <Columns>
                      <Column isSize="full">
                        <div className="buttons has-buttons-right">
                          <Button isColor="dark" type="submit">{t('team.invites.buttonText')}</Button>
                        </div>
                      </Column>
                    </Columns>
                  </PanelBlock>
                </Panel>
              </Column>
            </Columns>
          </Form>}
      </Formik>
    </Container>;
};
export default TeamInvite;