import { useQuery } from '@apollo/client';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import api from '../../api';
import { IChecklist } from '../../api/types/checklist';
import FetchTemplateStoreEntry, { FetchTemplateStoreEntryVariables, FetchTemplateStoreEntryResponse, TemplateStoreEntry } from '../../graphql/queries/FetchTemplateStoreEntry';
import { useCurrentUser } from '../../hooks/authHooks';
import useTemplateImporter from '../../hooks/useTemplateImporter';
import LoadSpinner from '../loadSpinner/LoadSpinner';
import { Transition } from './TemplateStoreDialog';
interface Props {
  id: string;
  onClose: () => void;
}
export async function fetchTemplates(files: string[]) {
  const parsed: IChecklist[] = [];
  for (const file of files) {
    const response = await axios.get<IChecklist>(process.env.REACT_APP_API_BASE_URL + file);
    parsed.push(response.data);
  }
  return parsed;
}
const CoverImgStyle = styled('img')(() => ({
  top: 0,
  width: 'auto',
  maxHeight: '300px',
  objectFit: 'cover',
  position: 'absolute'
}));
export const CardMediaStyle = styled('div')(() => ({
  position: 'relative',
  paddingTop: '350px'
}));
const TemplateStoreDetailsDialog: FunctionComponent<Props> = ({
  id,
  onClose
}) => {
  const {
    loading,
    data
  } = useQuery<FetchTemplateStoreEntryResponse, FetchTemplateStoreEntryVariables>(FetchTemplateStoreEntry, {
    variables: {
      id
    }
  });
  const [templates, setTemplates] = useState<IChecklist[]>([]);
  const [importRunning, setImportRunning] = useState(false);
  const [importProgress, setImportProgress] = useState(0);
  const confirm = useConfirm();
  const currentUser = useCurrentUser();
  const history = useHistory();
  const importTemplate = useTemplateImporter(currentUser.corporation_id, () => {});
  const {
    t
  } = useTranslation();
  useEffect(() => {
    if (data && data.templateStoreEntry && data.templateStoreEntry.templateFile) {
      fetchTemplates(data.templateStoreEntry.templateFile).then(result => setTemplates(result));
    }
  }, [data]);
  const doImport = useCallback(async (entry: TemplateStoreEntry) => {
    try {
      setImportRunning(true);
      const root = await api.folders.root();
      if (templates.length > 1) {
        const folder = await api.folders.create({
          name: entry.name,
          parent_id: root.id
        });
        let progress = 0;
        for (const template of templates) {
          template.folder_id = folder.id;
          await importTemplate(template);
          setImportProgress(++progress);
        }
        history.push(`/checklists/tab/4/folders/${folder.id}`);
      } else {
        const [templ] = templates;
        templ.folder_id = root.id;
        const template = await importTemplate(templ);
        history.push(`/templates/editor/${template}`);
      }
    } catch (e) {
      toast((e as Error).message, {
        type: 'error'
      });
    } finally {
      setImportRunning(false);
    }
  }, [importTemplate, history, templates]);
  const onClickImport = useCallback(() => {
    confirm({
      title: '',
      description: t('templateStore.confirmImport', {
        name: data.templateStoreEntry.name
      })
    }).then(() => {
      doImport(data.templateStoreEntry);
    });
  }, [confirm, t, data.templateStoreEntry, doImport]);
  if (loading) return <LoadSpinner className="centered" />;
  const {
    name,
    description,
    tags,
    image,
    templateStoreCategory
  } = data.templateStoreEntry;
  const imageUrl = image ? `${process.env.REACT_APP_API_BASE_URL}${image}` : null;
  return <Dialog open maxWidth="md" TransitionComponent={Transition} fullWidth onClose={onClose}>
      <DialogTitle sx={{
      px: '16px !important',
      py: '8px !important'
    }}>
        {name}
      </DialogTitle>
      <DialogContent>
        {!importRunning && <>
            <CardMediaStyle>
              <CoverImgStyle alt={name} src={imageUrl} />
            </CardMediaStyle>
            <Typography gutterBottom variant="caption" sx={{
          color: 'text.disabled',
          display: 'block'
        }}>
              {templateStoreCategory.name}
            </Typography>
            <Typography variant="body1" dangerouslySetInnerHTML={{
          __html: description
        }} />
            <Typography variant="subtitle1" sx={{
          mt: 2
        }}>
              <Trans>templateStore.containedTemplates</Trans>
            </Typography>
            <List>
              {templates.map(template => <ListItem key={template.id}>
                  <ListItemIcon>
                    <CircleIcon sx={{
                height: '16px'
              }} />
                  </ListItemIcon>
                  <ListItemText>
                    {template.title}
                  </ListItemText>
                </ListItem>)}
            </List>
            <Stack direction="row" sx={{
          flexWrap: 'wrap',
          mt: 1
        }}>
              {tags.map(tag => <Chip sx={{
            mr: 1
          }} key={tag.id} label={tag.title} color="primary" size="small" />)}
            </Stack>
          </>}
        {importRunning && <Box>
            <LinearProgress variant="determinate" value={importProgress / templates.length * 100} />
          </Box>}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose} variant="contained" disabled={importRunning}>
          <Trans>common.close</Trans>
        </Button>
        <Button variant="contained" color="primary" disabled={importRunning} onClick={onClickImport}>
          <Trans>common.import</Trans>
        </Button>
      </DialogActions>
    </Dialog>;
};
export default TemplateStoreDetailsDialog;