import { styled } from '@mui/system';
import { Button, Column, Columns, DropdownItem, Icon, Progress, Tag } from 'bloomer';
import { createRef, Component, RefObject } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { isMobile, isTablet } from 'react-device-detect';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';
import { IBoard } from '../../../api/boardsApi';
import { IArea } from '../../../api/types/checklist';
import IContact from '../../../api/types/contact';
import IResult, { IResultAttachment, ISignature } from '../../../api/types/result';
import IResultSet from '../../../api/types/resultSet';
import IUser from '../../../api/types/user';
import { signaturePseudoQuestionIds } from '../../../constants';
import closeIcon from '../../../images/filters_close.png';
import { AlphaprocessDatabase } from '../../../offlineMode/offlineDb';
import RenameAreaDialog from '../../../pages/checklists/ResultView/components/RenameAreaDialog';
import { IAlphaprocessState } from '../../../store';
import ITranslationProps from '../../../types/translationProps';
import Slider from '../../slider/slider';
import '../../slider/slider.css';
import GearMenu from '../../widgets/GearMenu';
import { getProgressColor } from './ActiveResultsTable';
import ResultQuestion from './question';
import { QuestionCount } from '../../../pages/checklists/ResultView/types';
interface StateProps {
  isAdmin: boolean;
}
interface IProps extends StateProps {
  area: IArea;
  onUpdate: (questionId: any, value: any, field?: string, resultAreaPosition?: number, questionPostion?: number) => Promise<any>;
  onAddComment: (questionId: number | null, areaPosition: number, text: string) => void;
  onDeleteComment: (commentId: number | string) => void;
  filledQuestionCount: QuestionCount;
  disabled: boolean;
  resultSetId: number;
  checklistId: number;
  onAttachmentUpdated: (questionId: number, attachmentId: number, updatedAttachment: IResultAttachment) => void;
  onAttachmentDeleted: (questionId: any, attachmentId: any) => void;
  getAuditPoints: (questionId: number, areaPosition: number) => number;
  showAuditPoints: boolean;
  contacts: IContact[];
  users: IUser[];
  allUsers: IUser[];
  hideCommentAndAttachments?: boolean;
  allAreas?: IArea[];
  freeSigneeSelection: boolean;
  resultSet?: IResultSet | null;
  resultAreaPosition: number;
  boards: IBoard[];
  markNotFilledRequiredFields?: boolean;
  onClose: (e: any) => void;
  onPrevArea: () => void;
  onNextArea: () => void;
  onCloseChecklist: () => void;
  user: IUser;
  accessToken?: string | null;
  isExternalAccess: boolean;
  isResponsible: boolean;
  jumpToQuestionId?: number | null;
  title?: string;
  isLoading: boolean;
  onRenameArea: (areaId: number | string, areaPosition: number, name: string) => void;
  onOpenPermissionsModal?: (area: IArea) => void | null;
  hideButtons?: boolean;
}
interface IState {
  isSliderOpen: boolean;
  isOffline: boolean;
  isMenuOpen: boolean;
}
type Props = IProps & ITranslationProps & RouteComponentProps;
const Root = styled('div')(({
  theme
}) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: 100
  }
}));
class ResultArea extends Component<Props, IState> {
  private readonly references: Array<RefObject<HTMLDivElement>> = [];
  private db: AlphaprocessDatabase;
  constructor(props) {
    super(props);
    this.references = [];
    this.db = new AlphaprocessDatabase();
  }
  public async componentDidMount() {
    await this.checkIsOnline();
  }
  public componentDidUpdate(prevProps: Props) {
    const {
      jumpToQuestionId
    } = this.props;
    if (jumpToQuestionId) {
      const items = document.querySelectorAll(`.question-${jumpToQuestionId}`);
      const resultColumn = document.querySelector('#result-column');
      const resultHead = document.querySelector('.result-head');
      if (items.length) {
        // scroll to the the target question
        items[0].scrollIntoView(true);
        if (resultColumn && resultHead) {
          // height of the sticky header + padding
          resultColumn.scrollTop = resultColumn.scrollTop - resultHead.clientHeight - 15;
        }
        // offset height of the header
        window.scrollBy(0, -75);
      }
    }
  }
  public getAttachments = (questionId: number, areaPosition: number) => {
    if (this.props.resultSet && this.props.resultSet.results) {
      const result = this.props.resultSet.results.find(area => area.question_id === questionId && area.area_position === areaPosition);
      if (result && result.attachments) {
        return result.attachments.filter(a => !a._destroy);
      }
    }
    return [];
  };

  // ADF-798: Show an "Offline" tag if the checklist is offline available
  private checkIsOnline = async () => {
    try {
      const count = await this.db.resultSets.where('id').equals(this.props.resultSet.id).count();
      const isOffline = count > 0;
      this.setState({
        isOffline
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error checking for offline availability', error);
    }
  };
  public state = {
    isSliderOpen: false,
    isOffline: false,
    isMenuOpen: false
  };
  private openSlider = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isSliderOpen: true
    });
  };
  private closeSlider = () => {
    if (this.state.isSliderOpen) {
      this.setState({
        isSliderOpen: false
      });
      document.body.className = document.body.className.replace(/overflowHidden/g, '');
    }
  };
  private getOrCreateRef = (id: number) => {
    if (!this.references.hasOwnProperty(id)) {
      this.references[id] = createRef();
    }
    return this.references[id];
  };
  private getResult = (questionId: number, resultAreaPosition: number, questionPosition: number): IResult => this.props.resultSet.results.find(result => result.question_id === questionId && result.area_position === resultAreaPosition && result.question_position === questionPosition) || {
    audit_points: 0,
    comments: [],
    question_id: questionId,
    attachments: [],
    cloud_attachments: [],
    value: null,
    versions: [],
    tasks: [],
    result_set_id: this.props.resultSet.id,
    area_position: resultAreaPosition,
    question_position: questionPosition
  };
  private getSignaturePseudoResult = (questionId: number): IResult => {
    const signature: ISignature = questionId === signaturePseudoQuestionIds.tester ? this.props.resultSet.signatures.find((s: ISignature) => s.is_tester) : this.props.resultSet.signatures.find((s: ISignature) => !s.is_tester);
    if (signature) {
      return {
        audit_points: 0,
        question_id: questionId,
        signature: signature.signature,
        value: signature.signature && signature.signature.url ? 'signed' : null,
        versions: [],
        result_set_id: this.props.resultSet.id,
        user_id: signature.user_id,
        contact_id: signature.contact_id,
        signee: signature.display_user_name,
        area_position: 1,
        question_position: 1
      };
    }
    const fallback: IResult = {
      audit_points: 0,
      value: null,
      versions: [],
      result_set_id: this.props.resultSet.id,
      question_id: questionId,
      comments: [],
      area_position: 1,
      question_position: 1
    };
    if (!this.props.freeSigneeSelection && !this.props.isExternalAccess) {
      if (questionId === signaturePseudoQuestionIds.tester) {
        fallback.user_id = this.props.user.id;
      } else {
        fallback.contact_id = this.props.resultSet.contact_id;
      }
    }
    return fallback;
  };
  private toggleMenu = () => {
    this.setState(({
      isMenuOpen
    }) => ({
      isMenuOpen: !isMenuOpen
    }));
  };
  private closeDropdown = () => {
    this.setState({
      isMenuOpen: false
    });
  };
  private handleRenameArea = () => {
    const {
      area,
      resultAreaPosition,
      title
    } = this.props;
    const doRename = (name: string) => {
      this.props.onRenameArea(area.id, resultAreaPosition, name);
    };
    const customUI = ({
      onClose
    }) => <RenameAreaDialog currentName={title ?? area.title} onClose={onClose} onRename={doRename} />;
    confirmAlert({
      customUI
    });
    this.setState({
      isMenuOpen: false
    });
  };
  public render() {
    const {
      area,
      onUpdate,
      filledQuestionCount,
      disabled,
      resultSet,
      resultSetId,
      onAttachmentDeleted,
      onAttachmentUpdated,
      getAuditPoints,
      t,
      onAddComment,
      onDeleteComment,
      showAuditPoints,
      contacts,
      users,
      hideCommentAndAttachments,
      freeSigneeSelection
    } = this.props;
    const title = this.props.title ?? area.title;
    const visibleQuestions = area.questions.filter(x => !x.hide);
    const anchorIds = visibleQuestions.map((q, index) => ({
      id: `question_${index}`,
      priority: q.is_required || q.has_attachments_required
    }));
    const questions = [];
    visibleQuestions.forEach((question, index) => {
      const count = question.showCount ?? 1;
      for (let i = 1; i <= count; i++) {
        const result = question.id > 0 ? this.getResult(question.id, this.props.resultAreaPosition, i) : this.getSignaturePseudoResult(question.id);
        const attachmentCount = result?.attachments?.length ? result.attachments.length : 0;
        const cloudAttachmentCount = result?.cloud_attachments?.length ? result.cloud_attachments.length : 0;
        questions.push(<div id={anchorIds[index].id} key={`${question.id}-${this.props.resultAreaPosition}-${i}-${question.hide}`} ref={this.getOrCreateRef(index)} onClick={this.closeSlider} className={`question-${question.id.toString()}`}>
            <ResultQuestion accessToken={this.props.accessToken} allAreas={this.props.allAreas} attachmentCount={attachmentCount} boards={this.props.boards} checklistId={this.props.checklistId} cloudAttachmentCount={cloudAttachmentCount} comments={result ? result.comments : []} contacts={contacts} disabled={this.props.isLoading || disabled} freeSigneeSelection={freeSigneeSelection} getAttachments={this.getAttachments} hideCommentsAndAttachments={hideCommentAndAttachments} isLoading={this.props.isLoading} item={question} markNotFilledRequiredFields={this.props.markNotFilledRequiredFields} onAddComment={onAddComment} onAttachmentDeleted={onAttachmentDeleted} onAttachmentUpdated={onAttachmentUpdated} onDeleteComment={onDeleteComment} onUpdate={onUpdate} questionId={question.id} result={result} resultAreaPosition={this.props.resultAreaPosition} resultId={result ? result.id : null} resultQuestionPosition={i} resultSet={resultSet} resultSetId={resultSetId} showAuditPoints={showAuditPoints} showCommentsAndAttachments={!['signature'].includes(question.question_type)} startDate={resultSet.start_date} users={users} value={result ? result.value : null} />
            <div className="visual-separator has-margin-top-20 has-margin-bottom-20" />
          </div>);
      }
    });

    /*const questionCount = visibleQuestions
      .filter((q) => !q.hide && q.question_type !== 'information' && !q.exclude_from_progress).length*/

    const percentage = filledQuestionCount.total === 0 ? 100 : Math.round(filledQuestionCount.filled / filledQuestionCount.total * 100);
    const tasksOpen = Math.max(0, filledQuestionCount.total - filledQuestionCount.filled);
    const auditPoints = area.questions.reduce((a, b) => a + getAuditPoints(b.id, this.props.resultAreaPosition), 0);
    const auditPointTag = showAuditPoints ? <Tag className="tooltip is-tooltip-left audit-tag" isColor="link" data-tooltip={t('resultSet.auditPoints')}>
        {auditPoints}
      </Tag> : null;
    const realPercentage = !Number.isNaN(percentage) ? percentage : 0;

    // cap percentage value to 100
    const percentageValue = Math.min(realPercentage, 100);
    const slider = this.state.isSliderOpen ? <Slider anchorIds={anchorIds} innerRefs={this.references} containerId={!isMobile || isTablet ? 'result-column' : null} /> : <div className="arrow">
          <Icon className="fa fa-arrows-alt-v" isSize="medium" onClick={this.openSlider} onTouchEnd={this.openSlider} />
        </div>;

    // ADF-940: show "Close Checklist" if we are in the last area
    // else it shows a 'Continue' button
    const continueButton = this.props.onNextArea ? <Button className="back-button" onClick={this.props.onNextArea} disabled={this.props.isLoading}>
          {t('common.continue')}
        </Button> : <Button className="back-button" onClick={this.props.onCloseChecklist} disabled={this.props.isLoading}>
          {t('resultSet.close')}
        </Button>;
    return <Root className="result-area has-position-relative">

        <div className={clsx('result-head', this.props.hideButtons && 'result-head-direct')}>
          {!this.props.hideButtons && <Columns isFullWidth isMobile isMarginless isPaddingless>
              <Column isSize={{
            desktop: 7,
            mobile: 7
          }} isPaddingless>
                <span className="has-hyphens second-caption">
                  {title}
                </span>
              </Column>
              <Column isSize={{
            desktop: 5,
            mobile: 5
          }} isPaddingless hasTextAlign="right" className="has-no-padding-right" isHidden={this.props.isExternalAccess || !this.props.isResponsible}>
                {auditPointTag}
                <img className="is-pulled-right close-result is-hidden-mobile" src={closeIcon} alt="close" onClick={this.props.onClose} />
                <OutsideClickHandler onOutsideClick={this.closeDropdown}>
                  <GearMenu isOpen={this.state.isMenuOpen} onToggle={this.toggleMenu} dataId="area-gear-menu" className="is-pulled-right har-margin-right-8">
                    <DropdownItem className="area-icons clickable-icon is-block" onClick={this.handleRenameArea}>
                      <Icon className="fa fa-pencil-alt clickable-icon is-block" />
                      {t('common.rename')}
                    </DropdownItem>
                    {this.props.isAdmin && this.props.onOpenPermissionsModal && <DropdownItem href="#" className="area-icons clickable-icon is-block is-right" onClick={() => this.props.onOpenPermissionsModal(this.props.area)}>
                      <Icon className="fas fa-user-lock clickable-icon is-block" />
                      {t('checklist.gearMenu.permissions')}
                    </DropdownItem>}
                  </GearMenu>
                </OutsideClickHandler>
              </Column>
            </Columns>}

          <Columns isMobile className="progress-container has-margin-top-16 is-marginless-horizontal">
            <Column isSize={6} className="is-paddingless-vertical is-paddingless">
              <div className={`has-text-${getProgressColor(realPercentage)} has-text-weight-bold`}>
                {percentageValue}
                %
              </div>
            </Column>
            <Column isSize={6} hasTextAlign="right" className="is-paddingless">
              <div className="has-text-grey has-text-grey">
                {`${tasksOpen} / ${filledQuestionCount.total} ${tasksOpen === 1 ? t('resultSet.taskOpen') : t('resultSet.tasksOpen')}`}
              </div>
            </Column>
          </Columns>
          <div className="sticky-progressbar is-hidden-mobile">
            <Progress value={percentageValue} max={100} isColor={getProgressColor(percentageValue)} className="has-margin-bottom-24" />
          </div>
        </div>
        <div className="sticky-progressbar is-hidden-tablet is-hidden-desktop">
          <Progress value={filledQuestionCount.filled} max={filledQuestionCount.total} isColor={getProgressColor(percentageValue)} className="has-margin-bottom-24" />
        </div>
        <Columns isFullWidth isGapless>
          <Column isSize="full">
            {area.description && area.description.length > 0 && <ResultQuestion resultQuestionPosition={1} accessToken={this.props.accessToken} freeSigneeSelection={this.props.freeSigneeSelection} startDate={resultSet.start_date} users={users} contacts={contacts} checklistId={this.props.checklistId} resultSet={this.props.resultSet} item={{
            id: -30,
            area_id: area.id,
            description: '',
            question_type: 'information',
            title: area.title,
            default_value: '',
            exclude_from_progress: true,
            has_attachments_required: false,
            has_audit_points: false,
            is_required: false,
            position: -1,
            unit: '',
            display_as_checkbox: false,
            disable_na: false,
            infotext: area.description,
            enable_speech_to_text: true,
            enable_cloud_attachments: false,
            test_question: false,
            correct_answers: [],
            timeonly: false,
            formula: '',
            action: []
          }} onUpdate={onUpdate} onAddComment={onAddComment} boards={this.props.boards} onDeleteComment={onDeleteComment} questionId={-30} result={{
            id: '-30',
            area_position: 1,
            question_id: -30,
            result_set_id: resultSetId,
            audit_points: 0,
            versions: [],
            value: area.description,
            question_position: 1
          }} value={area.description} resultId="-30" comments={[]} disabled={this.props.isLoading || disabled} resultSetId={resultSetId} getAttachments={this.getAttachments} attachmentCount={0} cloudAttachmentCount={0} onAttachmentDeleted={onAttachmentDeleted} onAttachmentUpdated={onAttachmentUpdated} showAuditPoints={showAuditPoints} hideCommentsAndAttachments={hideCommentAndAttachments} allAreas={this.props.allAreas} showCommentsAndAttachments={false} resultAreaPosition={this.props.resultAreaPosition} markNotFilledRequiredFields={this.props.markNotFilledRequiredFields} />}
            {questions}
            {!this.props.hideButtons && <div className="buttons result-area-nav-buttons has-margin-bottom-15">
                <Button disabled={!this.props.onPrevArea || this.props.isLoading} className="back-button" onClick={this.props.onPrevArea || this.props.onClose}>
                  {t('common.back')}
                </Button>
                {continueButton}
                <Button disabled={this.props.isLoading} className="continue-button" onClick={this.props.onClose}>
                  {t('resultSet.backToOverview')}
                </Button>
              </div>}
          </Column>
        </Columns>
        {this.props.area.questions.length > 5 && slider}
      </Root>;
  }
}
const mapStateToProps = (state: IAlphaprocessState): StateProps => ({
  isAdmin: state.core.user?.current_role === 'power_user' || state.core.user?.current_role === 'admin'
});
export default withTranslation()(connect(mapStateToProps)(withRouter(ResultArea)));