import IUser from '../api/types/user'

export interface ILoginAction {
  type: 'AUTH_LOGIN'
  jwt: string
  user: IUser
}

export interface ILogoutAction {
  type: 'AUTH_LOGOUT'
}

export type AuthActions = ILoginAction | ILogoutAction

export function login(jwt: string, user: IUser): ILoginAction {
  return {
    jwt,
    type: 'AUTH_LOGIN',
    user
  }
}

export function logout(): ILogoutAction {
  return {
    type: 'AUTH_LOGOUT'
  }
}
