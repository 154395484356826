import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { IFolder } from '../../../../api/FolderApi';
import { getFolderName } from '../../../../util/getFolderName';
interface Props {
  folder: IFolder;
  onNavigation: (folder: IFolder) => void;
}
function FolderBreadCrumbs({
  folder,
  onNavigation
}: Props) {
  const {
    t
  } = useTranslation();
  const parents: IFolder[] = [];
  let {
    parent
  } = folder;
  while (parent) {
    parents.push(parent);
    parent = parent.parent;
  }
  return <Breadcrumbs sx={{
    ml: {
      xs: 3,
      md: 2
    }
  }}>
      {parents.reverse().map(p => <Typography color="text.primary" className="ellipsis-wrapper" key={p.id} onClick={() => onNavigation(p)}>
          {getFolderName(p, t)}
        </Typography>)}
      <Typography color="text.primary">{getFolderName(folder, t)}</Typography>
    </Breadcrumbs>;
}
export default FolderBreadCrumbs;