import { Button, Column, Columns, Field } from 'bloomer';
import clsx from 'clsx';
import { useState } from 'react';
import IResult from '../../../../../api/types/result';
import { useEventBus } from '../../../../../pages/checklists/ResultView/EventBus';
import { range } from '../../../../../util';
interface Props {
  min: number;
  max: number;
  value: string;
  resultId: string;
  onUpdate: (newValue: string) => Promise<IResult>;
  disabled: boolean;
}
function QuestionRatingInput({
  min,
  max,
  value,
  onUpdate,
  resultId,
  disabled
}: Props) {
  const theValue: number = value ? parseInt(value) : 0;
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  useEventBus('result', resultId, () => {
    setIsHighlighted(true);
    setTimeout(() => setIsHighlighted(false), 5000);
  });
  const buttons = range(min, max + 1, 1).map((i: number) => <Column key={i} style={{
    flexGrow: 0,
    marginBottom: '5px'
  }}>
      <Button className="has-text-weight-bold" style={{
      width: '50px'
    }} isColor={theValue === i ? 'link' : null} isSize="medium" onClick={() => onUpdate(i.toString())} disabled={disabled}>
        {i}
      </Button>
    </Column>);
  return <Field className={clsx('question-rating', isHighlighted && 'is-highlighted')}>
      <Columns isGapless isMobile isMultiline isPaddingless isMarginless isCentered>
        {buttons}
      </Columns>
    </Field>;
}
export default QuestionRatingInput;