import { IChecklist } from '../api/types/checklist'
import IResult from '../api/types/result'
import IResultSet from '../api/types/resultSet'

export function isFilled(result: IResult, resultSet: IResultSet): boolean {
  if (!result) {
    return false
  }
  const { question, value } = result
  if (!question) {
    return false
  }

  switch (question.question_type) {
    case 'start_date':
    case 'text':
    case 'checkbox':
    case 'real_checkbox':
    case 'number':
    case 'rating':
    case 'dropdown':
    case 'signature':
    case 'datetime':
    case 'external_email':
    case 'apicall':
    case 'person':
    case 'contact':
    case 'calculated_value':
      return value && String(value).trim().length > 0
    case 'information':
      return true // 'information' type is always considered filled
    default:
      return true
  }
}

export default class ReportUtil {
  // Calculate success rate in percent
  public static calculatePercentage = (displayCount: number, totalCount: number): number => {
    // cut of float numbers
    // we don't use round()
    if (displayCount === 0 || totalCount === 0) {
      return 0
    }

    return Math.round((displayCount / totalCount) * 100.0)
  }

  public static getHiddenQuestionIds = (checklist: IChecklist): number[] => {
    const hiddenQuestionIds = checklist.areas.reduce((a, b) => a.concat(
      b.questions.filter((x) => x.question_type === 'information'
                || x.hide || x.exclude_from_progress || x.question_type === 'apicall')
    ), []).map((x) => x.id)

    const questionsIdsInHiddenAreas = checklist.areas.filter(
      (x) => x.hide && x.questions.length
    ).map((area) => area.questions.map((question) => question.id)[0])

    return [...hiddenQuestionIds, ...questionsIdsInHiddenAreas]
  }

  public static getFilledQuestionCount = (checklist: IChecklist, resultSet: IResultSet): number => {
    const questionCount = ReportUtil.getQuestionCount(checklist, resultSet)
    const hiddenQuestionIds = ReportUtil.getHiddenQuestionIds(checklist)

    const count = resultSet.results
      ? resultSet.results.filter((r) => isFilled(r, resultSet)
            && !hiddenQuestionIds.includes(r.question_id)).length
      : 0
    return count < questionCount ? count : questionCount
  }

  public static getQuestionCount = (checklist: IChecklist, resultSet: IResultSet): number => {
    const hiddenQuestionIds = ReportUtil.getHiddenQuestionIds(checklist)

    const areas = [...checklist.areas]
    resultSet.result_set_area_duplicate_counts
      .filter((x) => x.area_duplicate_count)
      .forEach((duplicate) => {
        const area = areas.find((x) => x.id === duplicate.area_id)
        if (area) {
          for (let i = 0; i < duplicate.area_duplicate_count; i++) {
            areas.push({ ...area })
          }
        }
      })

    const questions = areas.filter((x) => !x.hide).flatMap((x) => x.questions)
      .filter((x) => !hiddenQuestionIds.includes(x.id))

    let count = 0

    for (const question of questions) {
      count += question.show_count ?? 1
    }

    return count
  }
}
