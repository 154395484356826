import { DropdownItem, Icon } from 'bloomer';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../../api';
import { IBoard } from '../../../api/boardsApi';
import { IChecklist } from '../../../api/types/checklist';
import IContact from '../../../api/types/contact';
import IResource from '../../../api/types/ressource';
import IResultSet from '../../../api/types/resultSet';
import ITeam from '../../../api/types/team';
import IUser from '../../../api/types/user';
import { useCurrentUser } from '../../../hooks/authHooks';
import { AlphaprocessDatabase } from '../../../offlineMode/offlineDb';
import { IAlphaprocessState } from '../../../store';
import { confirm } from '../../../util';
import DateUtil from '../../../util/DateUtil';
interface Props {
  resultSet: IResultSet;
  resources: IResource[];
  contacts: IContact[];
  teams: ITeam[];
  users: IUser[];
  boards: IBoard[];
  onCloseDropdown: (event?: React.MouseEvent) => void;
  onMakeOfflineAvailable?: () => void;
}
const ResultOfflineSyncer: FunctionComponent<Props> = ({
  resultSet,
  resources,
  contacts,
  users,
  teams,
  boards,
  onMakeOfflineAvailable,
  onCloseDropdown
}) => {
  const isOffline = useSelector((state: IAlphaprocessState) => state.core.isOffline);
  const user = useCurrentUser();
  const [isOfflineAvailable, setIsOfflineAvailable] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const {
    t
  } = useTranslation();
  const db = useMemo(() => new AlphaprocessDatabase(), []);
  const saveToIndexDb = useCallback(async (checklist: IChecklist, rs: IResultSet) => {
    try {
      await db.resources.clear();
      await db.boards.clear();
      await db.teams.clear();
      await db.contacts.clear();
      await db.users.clear();
      await db.resources.bulkPut(resources);
      await db.contacts.bulkPut(contacts);
      await db.teams.bulkPut(teams);
      await db.users.bulkPut(users);
      await db.boards.bulkPut(boards);
      if (await db.checklists.get(checklist.id)) {
        await db.checklists.update(checklist.id, checklist);
      } else {
        await db.checklists.add(checklist);
      }
      if (!rs.result_set_offline_syncs) {
        rs.result_set_offline_syncs = [];
      }
      if (isOfflineAvailable) {
        const {
          results
        } = await db.resultSets.get(rs.id);
        for (let i = 0; i < results.length; i++) {
          const onlineResult = rs.results.find(x => x.question_id === results[i].question_id && x.area_position === results[i].area_position);
          if (onlineResult && DateUtil.isAfter(onlineResult.updated_at, results[i].updated_at)) {
            results[i] = onlineResult;
          }
        }
        const newOfflineResultSet = {
          ...rs,
          results
        };
        await db.resultSets.update(rs.id, newOfflineResultSet);
      } else {
        await db.resultSets.put(rs);
        rs.result_set_offline_syncs.push(await api.resultSets.addOfflineSync(rs.checklist_id, rs.id));
      }
      setIsOfflineAvailable(true);
      toast(t('offlineMode.checklistNowAvailable'), {
        type: 'success'
      });
    } catch (error) {
      if ((error as Error).message !== 'Network Error') {
        toast(t('offlineMode.errors.savingChecklist'), {
          type: 'error'
        });
        // eslint-disable-next-line no-console
        console.error('error saving result set', error);
        throw error;
      }
    }
  }, [boards, contacts, db, isOfflineAvailable, resources, t, teams, users]);
  const doNothing = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
  }, []);
  const makeOffline = useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    const {
      checklist,
      ...rest
    } = resultSet;
    if (dirty) {
      confirm(null, t('offlineMode.confirm.makeOfflineAvailable'), async () => {
        await saveToIndexDb(checklist, rest);
        setDirty(false);
      });
      onCloseDropdown();
      return;
    }
    if (rest.result_set_offline_syncs && rest.result_set_offline_syncs.some(x => x.user_id !== user.id)) {
      const userNames = rest.result_set_offline_syncs.filter(s => s.user_id !== user.id).map(s => `${s.user.firstname} ${s.user.lastname}`).join(', ');
      confirm(null, t('offlineMode.confirmSyncedByOtherUser', {
        userNames
      }), async () => {
        await saveToIndexDb(checklist, rest);
      });
    } else {
      await saveToIndexDb(checklist, rest);
    }

    // ADF-798: callback to make the "Offline" tag to appear in checklist
    if (onMakeOfflineAvailable) {
      onMakeOfflineAvailable();
    }
    onCloseDropdown();
  }, [dirty, onCloseDropdown, onMakeOfflineAvailable, resultSet, saveToIndexDb, t, user.id]);
  useEffect(() => {
    try {
      db.resultSets.where('id').equals(resultSet.id).count().then(count => {
        if (count !== 0) {
          setIsOfflineAvailable(true);
          db.resultSets.get(resultSet.id).then(rs => {
            if (rs._dirty || rs.results.some(r => r._dirty) || rs.signatures.some(c => c._dirty)) {
              setDirty(true);
            }
          });
        } else {
          setIsOfflineAvailable(false);
          setDirty(false);
        }
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error('error checking for offline availability', error);
    }
  }, [db.resultSets, resultSet.id]);
  if (!isOffline) {
    return <DropdownItem onClick={makeOffline} className="has-pointer has-text-left ">
        <a href="#" className="offline-available">
          <Icon className="fas fa-download" />
          {t(isOfflineAvailable ? 'offlineMode.updateChecklist' : 'offlineMode.makeAvailable')}
        </a>
      </DropdownItem>;
  }
  return <DropdownItem className="is-inline-block has-text-success has-text-left is-disabled" data-tooltip={t('offlineMode.isAvailable')} onClick={doNothing}>
      <a href="#" className="has-not-allowed">
        <Icon className="fas fa-download" />
        {t('offlineMode.makeAvailable')}
      </a>
    </DropdownItem>;
};
export default ResultOfflineSyncer;