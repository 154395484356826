import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import makeAnimated from 'react-select/animated';
import { IBoard } from '../../../api/boardsApi';
import { reactSelectMaxMenuHeight } from '../../../constants';
interface IProps {
  boards: IBoard[];
  onChange: (value: number | number[]) => void;
  selected: number | number[];
  isMulti?: boolean;
  placeholder: string;
}
const animatedComponents = makeAnimated();
export default function BoardSelect(props: IProps) {
  const {
    t
  } = useTranslation();
  const options = props.boards.map(u => ({
    value: u.id,
    label: u.title
  }));
  const selected = Array.isArray(props.selected) ? options.filter(o => (props.selected as number[]).includes(o.value)) : options.find(o => o.value === props.selected);
  const handleChange = (values: any) => {
    if (Array.isArray(values)) {
      props.onChange(values.map(v => v.value));
    } else {
      props.onChange(values.value);
    }
  };
  return <ReactSelect options={options} isMulti={props.isMulti} onChange={handleChange} value={selected} placeholder={props.placeholder} isOptionDisabled={(option: any) => option.disabled} isSearchable components={animatedComponents} maxMenuHeight={reactSelectMaxMenuHeight} className="dropdown-select-container" classNamePrefix="dropdown-select" noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
    menuPortal: base => ({
      ...base,
      zIndex: 9999
    })
  }} />;
}