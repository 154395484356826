import { Notification } from 'bloomer';
import { useTranslation } from 'react-i18next';
function Unauthorized() {
  const {
    t
  } = useTranslation();
  return <Notification isColor="danger">
      {t('errorPages.unauthorized')}
    </Notification>;
}
export default Unauthorized;