import { gql } from '@apollo/client'

export interface SendMessageMutationVariables {
  conversationId: string
  body: string
  contentType: string
}

export interface SendMessageResponse {
  createChatMessage: {
    errors: string[]
    message: {
      id: string
      attachments: string[]
      body: string
      contentType: 'text'
      createdAt: string
      senderId: string
    }
  }
}

const SendMessageMutation = gql`
    mutation sendMessage($conversationId: ID!, $body: String!, $contentType: String!) {
        createChatMessage(conversationId: $conversationId, body: $body, contentType: $contentType) {
            errors
            message {
                id
                attachments
                body
                contentType
                createdAt
                senderId
            }
        }
    }
`

export default SendMessageMutation
