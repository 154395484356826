import { Hidden, Box, Stack, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { Image, Tag } from 'bloomer';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { IFolder } from '../../../../api/FolderApi';
import checksIcon from '../../../../images/menu-icons/checks.svg';
import { FolderChecklist } from '../../../../types/FolderChecklist';
import DateUtil from '../../../../util/DateUtil';
import { TableDueDate } from '../TableDueDate';
interface Props {
  data: FolderChecklist;
  checklistStatus: 'open' | 'closed' | 'due' | 'invited';
  tabIndex: number;
  folder: IFolder;
  offlineIds: string[];
}
const TitleWrapper = styled('div')(({
  theme
}) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '280px'
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    maxWidth: '441px'
  }
}));
export default function TitleColumn({
  data,
  folder,
  tabIndex,
  checklistStatus,
  offlineIds
}: Props) {
  const {
    t
  } = useTranslation();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const getLinkToChecklist = (x: FolderChecklist): string => {
    if (checklistStatus === 'closed') {
      return `/checklists/${x.checklist_id}/audit/${x.id}/report`;
    }
    return `/checklists/tab/${tabIndex}/folders/${folder.id}/${x.checklist_id}/audit/${x.id}`;
  };
  return <Box component={Link} to={getLinkToChecklist(data)} sx={{
    display: {
      xs: 'flex',
      md: 'block'
    },
    flexWrap: 'wrap'
  }}>
      <TitleWrapper>
        <Image className="has-margin-right-0" isSize="24x24" src={checksIcon} />
        {data.is_audit && <Tag className="audit-tag" data-tip={t('questions.auditPoints')} isColor="link">
          {data.audit_points}
        </Tag>}
        <Box component="span" sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
          {data.title}
        </Box>
        {offlineIds.includes(data.id) && <span className="offline-icon icon">
          <i className="fas fa-download" />
        </span>}
      </TitleWrapper>
      <Hidden implementation="js" lgUp>
        <Stack direction="row" sx={{
        ml: 1
      }}>
          {data.due_date && <TableDueDate date={DateUtil.parse(data.due_date)} />}
          <Tag className="is-rounded is-light">
            {data.created_at && DateUtil.format(data.created_at, isPhone ? DateUtil.ShortFormat : DateUtil.LongFormat)}
          </Tag>
        </Stack>
      </Hidden>
    </Box>;
}