import { Column, Columns, Container, Panel, PanelBlock, PanelHeading } from 'bloomer';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import useSWR from 'swr';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import api from '../../api';
import ITeam, { ITeamDto, ITeamMembership } from '../../api/types/team';
import LoadSpinner from '../../components/loadSpinner/LoadSpinner';
import TeamForm from '../../components/teams/TeamForm';
import { IAlphaprocessState } from '../../store';
interface IParams {
  id: string;
}
async function fetchTeam(id: string) {
  const result = await api.teams.find(parseInt(id));
  return result.data;
}
const EditTeam: FunctionComponent = () => {
  const {
    t
  } = useTranslation();
  const {
    id
  } = useParams<IParams>();
  const corporationId = useSelector((state: IAlphaprocessState) => state.core.user.corporation_id);
  const {
    isValidating,
    data
  } = useSWR(id, fetchTeam);
  const history = useHistory();
  const handleSubmit = async (values: ITeam, helper: FormikHelpers<ITeam>) => {
    const dto: ITeamDto = {
      name: values.name,
      code: values.code,
      corporation_id: values.corporation_id,
      team_memberships_attributes: values.team_memberships.map(m => ({
        id: m.id,
        user_id: m.user_id,
        _destroy: m._destroy,
        role: m.role
      }))
    };
    api.teams.update(corporationId, values.id, dto).then(() => {
      helper.setSubmitting(false);
      history.push('/people?currentTab=1');
    });
  };
  const schema = yup.object({
    name: yup.string().required(t('TeamNameRequired')),
    code: yup.string().nullable(),
    users: yup.array(),
    corporation_id: yup.number(),
    team_memberships: yup.array().test('test-memberships', (value: ITeamMembership[], context) => {
      const leaderCount = value.filter(x => x.role === 'leader').length;
      if (leaderCount > 1) {
        return context.createError({
          message: t('team.validator.onlyOneLeader')
        });
      }
      if (leaderCount === 0 && value.some(x => x.role === 'deputy')) {
        return context.createError({
          message: t('team.validator.noDeputyWithoutLeader')
        });
      }
      return true;
    }),
    id: yup.number(),
    created_at: yup.mixed(),
    discarded_at: yup.mixed().nullable(),
    updated_at: yup.mixed()
  });
  if (isValidating) return <LoadSpinner />;
  return <Container className="is-vertical-center">
      <Columns isCentered>
        <Column isSize="3/4">
          <Panel className="signup-box modal-box">
            <PanelHeading>
              {t('team.headers.editTeam')}
            </PanelHeading>
            <PanelBlock hasTextAlign="left">
              <Columns isCentered>
                <Column isSize={10}>
                  <Formik initialValues={data} validationSchema={schema} onSubmit={handleSubmit} enableReinitialize>
                    <TeamForm />
                  </Formik>
                </Column>
              </Columns>
            </PanelBlock>
          </Panel>
        </Column>
      </Columns>
    </Container>;
};
export default EditTeam;