import { Field, Label } from 'bloomer';
import * as React from 'react';
import { IDropdownItem } from '../../../../../api/types/checklist';
interface Props {
  item: IDropdownItem;
  selected: boolean;
  optionText: string;
  isMulti: boolean;
  disabled: boolean;
  onChange: (id: number) => void;
  onOptionTextChanged: (e: React.FocusEvent<HTMLInputElement>) => void;
  isHorizontal: boolean;
}
function QuestionDropdownOption({
  isMulti,
  item,
  onChange,
  disabled,
  optionText,
  selected,
  isHorizontal,
  onOptionTextChanged
}: Props) {
  const input = item.has_text ? <Field className="field-text-input">
      <input type="text" disabled={disabled} maxLength={256} className="input text-input" onBlur={onOptionTextChanged} defaultValue={optionText} />
    </Field> : null;
  const hasTextCssRadio = item.has_text ? 'has-additional-text' : '';
  return <li key={item.id} className={isHorizontal && !item.has_text ? 'dropdown-question-item is-horizontal' : `dropdown-question-item ${hasTextCssRadio}`}>
      <Field isHorizontal={isHorizontal && !item.has_text} className={!isHorizontal || item.has_text ? 'is-fullwidth' : ''}>
        <Field isPulled={item.has_text ? 'left' : undefined} className="input-container">
          <input type={isMulti ? 'radio' : 'checkbox'} checked={selected} onChange={() => onChange(item.id)} />
          <Label className="dropdown-checkbox-label has-text-weight-normal" onClick={() => onChange(item.id)}>
            {item.title}
          </Label>
        </Field>
        {input}
      </Field>
    </li>;
}
export default QuestionDropdownOption;