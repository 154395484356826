import querystring from 'query-string'

import { ApiBase, IPaginatedData } from './ApiBase'
import IProject from './types/project'

interface IParams {
  page: number
  title?: string
  team_ids?: number[]
  order: string
  direction: 'asc' | 'desc'
}

export default class ProjectApi extends ApiBase {
  public async all(params: IParams): Promise<IPaginatedData<IProject>> {
    if (this.isOffline()) {
      const projects = await this.offlineDb.projects.toArray()

      return {
        totalPages: 1,
        data: projects,
        currentPage: 1,
        totalCount: projects.length
      }
    }

    if (params.title === null) {
      delete params.title
    }
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    const response = await this.get<IProject[]>(`/corporations/0/teams/0/projects?${query}`)

    return this.paginateResponse<IProject>(response)
  }

  public async select(corporationId: number, teamId: number, accessToken?: string | null) {
    if (this.isOffline()) {
      return this.offlineDb.projects.toArray()
    }

    let url = `/corporations/${corporationId}/teams/${teamId}/projects/select`
    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    const response = await this.get<IProject[]>(url)
    return response.data
  }

  public async open(
    corporationId: number,
    teamId: number,
    accessToken?: string | null
  ): Promise<IProject[]> {
    if (this.isOffline()) {
      return this.offlineDb.projects.toArray()
    }
    let url = `/corporations/${corporationId}/teams/${teamId}/projects/open`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }
    const response = await this.get<IProject[]>(url)
    return response.data
  }

  public find(corporationId: number, teamId: number, id: number) {
    return this.get<IProject>(`/corporations/${corporationId}/teams/${teamId}/projects/${id}`)
  }

  public create(corporationId: number, data: IProject) {
    return this.post(
      `/corporations/${corporationId}/teams/${data.team_id}/projects`,
      data
    )
  }

  public update(corporationId: number, data: IProject) {
    return this.put<IProject>(
      `/corporations/${corporationId}/teams/${data.team_id}/projects/${data.id}`,
      data
    )
  }

  public deleteProject(corporationId: number, teamId: number, id: number) {
    return this.delete(`/corporations/${corporationId}/teams/${teamId}/projects/${id}`)
  }
}
