import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/browser';
function getTranslationKey(field: string): string {
  switch (field) {
    case 'due':
      return 'DueDate';
    case 'due_date':
      return 'resultSet.dueShort';
    case 'title':
      return 'Title';
    case 'signature_required':
      return 'Signature';
    case 'closed_at':
      return 'ClosedAt';
    case 'status':
      return 'Status';
    case 'comment':
      return 'result.comment.comment';
    case 'location':
      return 'Location';
    case 'tester_id':
      return 'Tester';
    case 'ressource_id':
      return 'resultSet.resource';
    case 'contact_id':
      return 'Contact';
    case 'is_audit':
      return 'Audit-Checklist';
    case 'description':
      return 'Description';
    case 'tags':
      return 'Tags';
    case 'audit_points':
      return 'resultSet.auditPoints';
    case 'startdate':
      return 'resultSet.startDate';
    case 'value':
      return 'resultSet.value';
    default:
      // send event to sentry
      captureException(new Error(`Unknown Field in History: ${field}`));
      return `Unknown Field: ${field}`;
  }
}
interface ITranslatedFieldProps {
  field: string;
}
function TranslatedField({
  field
}: ITranslatedFieldProps) {
  const {
    t
  } = useTranslation();
  const key = getTranslationKey(field);
  return <div className="history-field-title">
      {t(key)}
    </div>;
}
export default TranslatedField;