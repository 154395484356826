import { ApiBase } from './ApiBase'
import { Lane } from './boardsApi'

export default class LanesApi extends ApiBase {
  public create(boardId: number, lane: Lane) {
    return this.post<Lane>(`/boards/${boardId}/lanes/`, lane)
  }

  public update(boardId: number, id: number, lane: Lane) {
    const { title, position } = lane
    return this.put<Lane>(`/boards/${boardId}/lanes/${id}`, { title, position })
  }

  public deleteLane(boardId: number, id: number) {
    return this.delete(`/boards/${boardId}/lanes/${id}`)
  }

  public async archiveTasks(boardId: number, id: number) {
    return this.post(`/boards/${boardId}/lanes/${id}/archive_tasks`)
  }
}
