import axios from 'axios';
import { Button } from 'bloomer';
import { TFunction } from 'i18next';
import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IQuestion } from '../../../../../api/types/checklist';
import IResultSet from '../../../../../api/types/resultSet';
interface Props {
  question: IQuestion;
  resultSet: IResultSet;
}
const valueRegex = /\\$\\{(?<areaid>\\d+):(?<questionId>\\d+)}/;
function resolveValue(value: string, question: IQuestion) {
  switch (question.question_type) {
    case 'dropdown':
      if (question.is_multi) {
        const ids = value.split(',').map(x => parseInt(x));
        return question.question_dropdown_items?.filter(x => ids.includes(x.id)).map(x => x.title).join(',');
      }
      return question.question_dropdown_items?.find(x => x.id === parseInt(value))?.title ?? null;
    default:
      return value;
  }
}
function replaceValues(text: string, resultSet: IResultSet, question: IQuestion) {
  let newText = text;
  const matches = text.match(valueRegex);
  if (matches) {
    for (const match of matches) {
      const exec = valueRegex.exec(match);
      if (exec) {
        const result = resultSet.results.find(x => x.question_id.toString() === exec.groups.questionId);
        newText = text.replace(match, resolveValue(result.value, question));
      }
    }
  }
  return newText;
}
function buildHeaders(headers: Array<{
  name: string;
  value: string;
}>) {
  const headerObject: Record<string, string> = {};
  for (const header of headers) {
    headerObject[header.name] = header.value;
  }
  return headerObject;
}
export async function makeApiCall(question: IQuestion, resultSet: IResultSet, t: TFunction, setRunning: (value: (((prevState: boolean) => boolean) | boolean)) => void) {
  const {
    url,
    body,
    headers,
    method,
    username,
    password,
    use_http_auth
  } = question.api_call_config;
  try {
    const newBody = replaceValues(body, resultSet, question);
    const newUrl = replaceValues(url, resultSet, question);
    await axios({
      url: newUrl,
      headers: buildHeaders(headers),
      method,
      data: JSON.parse(newBody),
      auth: use_http_auth ? {
        username,
        password
      } : undefined
    });
    toast(t('questions.apiCalls.success'), {
      type: 'success'
    });
  } catch (e) {
    toast((e as Error).message, {
      type: 'error'
    });
  } finally {
    setRunning(false);
  }
}
const ApiButton: FunctionComponent<Props> = ({
  question,
  resultSet
}) => {
  const [running, setRunning] = useState(false);
  const {
    t
  } = useTranslation();
  const handleExecute = useCallback(async () => {
    setRunning(true);
    await makeApiCall(question, resultSet, t, setRunning);
  }, [question, resultSet, t]);
  return <div>
      <Button isColor="link" onClick={handleExecute} isLoading={running}>
        {question.title}
      </Button>
    </div>;
};
export default ApiButton;