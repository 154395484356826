import { Column } from 'bloomer';
import { FunctionComponent } from 'react';
interface BtnProps {
  text: string;
  onClick?: () => void;
}
const Btn: FunctionComponent<BtnProps> = ({
  text,
  onClick
}) => <Column hasTextAlign="left" isPaddingless onClick={onClick}>
    <p>{text}</p>
  </Column>;
export default Btn;