import { ApiBase } from './ApiBase'

export interface IChartTag {
  title: string
  id: number
}

export interface IChart {
  color: string
  corporation_id: number
  creator_id: number
  description: string
  file: string | null
  id: number
  title: string
  project_ids: number[]
  checklist_ids: number[]
  result_set_ids: number[]
  user_ids: number[]
  team_ids: number[]
  charts_tags: IChartTag[]
  charts_tags_ids: number[]
  data?: string
  thumbnail?: string
}

export default class ChartsApi extends ApiBase {
  public async all() {
    const response = await this.get<any[]>('/charts')
    return response.data
  }

  public async show(id: number) {
    return this.get<IChart>(`/charts/${id}`)
  }

  public async create(title: string) {
    return this.post('/charts', { title })
  }

  public async update(id: number, chart: IChart) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, ...rest } = chart
    return this.put<IChart>(`/charts/${id}`, rest)
  }

  public async updateFile(id: number, data: string, thumbnail: string) {
    return this.put(`/charts/${id}/update_file`, {
      id,
      data,
      thumbnail
    })
  }

  public async deleteChart(id: number) {
    return this.delete(`/charts/${id}`)
  }
}
