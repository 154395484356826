import { lazy, Suspense } from 'react';
import AsyncErrorBoundary from './AsyncErrorBoundary';
import LoadSpinner from './loadSpinner/LoadSpinner';
export default function CustomLoadable(opts) {
  const LazyComponent = lazy(opts.loader);
  return function (props) {
    return <AsyncErrorBoundary>
        <Suspense fallback={<LoadSpinner className="centered" />}>
          <LazyComponent {...props} />
        </Suspense>
      </AsyncErrorBoundary>;
  };
}