import { compiledBlock as _compiledBlock } from "million/react";
const JSX_signatureMissing_1 = _compiledBlock(_props => <div className="error-modal-item">
        <h4>{_props.v0}</h4>
        <ul>
          <li key="error-signature">
            <a href="#" onClick={_props.v1}>{_props.v2}</a>
          </li>
        </ul>
      </div>, {
  name: "JSX_signatureMissing_1",
  portals: ["v0", "v2"]
});
const NotFilledRequiredFieldsModal_1 = _compiledBlock(_props2 => _props2.v0, {
  name: "NotFilledRequiredFieldsModal_1",
  portals: ["v0"]
});
const NotFilledRequiredFieldsModal = function NotFilledRequiredFieldsModal({
  onClose,
  errors,
  areas,
  onJumpToQuestion,
  isSignedValid,
  currentUserCanAccessArea,
  users,
  teams
}: IProps) {
  const {
    t
  } = useTranslation();
  const areasWithErrors = [];

  // collect a list of all areas that contain not filled required questions
  areas.forEach(area => {
    if (errors.some(x => x.area_id === area.id) && !areasWithErrors.includes(area)) {
      areasWithErrors.push(area);
    }
  });

  // get the errors for each area.
  // make a list of links to questions
  const shownAreasWithErrors = areasWithErrors.map(area => {
    const errorsOfThisArea = errors.filter(x => x.area_id === area.id);
    const errorItems = errorsOfThisArea.map(error => {
      const onClickQuestion = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (currentUserCanAccessArea(area)) {
          onJumpToQuestion(error);
        }
      };
      const questionsLinkClass = !currentUserCanAccessArea(area) ? 'has-not-allowed not-allowed-link' : '';
      return <li key={`error-${error.id}`}>
          <a href="#" onClick={onClickQuestion} className={questionsLinkClass}>
            {error.title}
            {error.attachmentMissing ? ` (${t('checklist.attachmentRequired')})` : null}
          </a>
        </li>;
    });
    const permissionNotification = !currentUserCanAccessArea(area) ? <RequiredFieldAreaNoPermissionMessage area={area} users={users} teams={teams} /> : null;
    return <div key={`area-${area.id}`} className="error-modal-item">
        <h4>{area.title}</h4>
        {permissionNotification}
        <ul>
          {errorItems}
        </ul>
      </div>;
  });

  // special case: Old signature feature. This is not a real area.
  // if onJumpToQuestion is called with null as argument then it will jump to the signatures pseudo area.
  const onClickSignatureQuestion = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onJumpToQuestion(null);
  };

  // if the signatures are required and not filled then show it as error
  const signatureMissing = !isSignedValid ? /*@million jsx-skip*/<JSX_signatureMissing_1 v0={t('Signatures')} v1={onClickSignatureQuestion} v2={t('Signature')} /> : null;

  // This is a full screen modal on phones and a regular modal on desktop.
  return /*@million jsx-skip*/<NotFilledRequiredFieldsModal_1 v0={<Modal isActive className={isMobile ? 'is-huge modal-full-screen modal-fx-fadeInScale error-result-modal' : 'is-huge modal-fx-fadeInScale error-result-modal'}>
      <BrowserView>
        <ModalBackground onClick={onClose} />
      </BrowserView>

      <ModalCard className="modal-content" style={{
      margin: 0
    }}>
        <ModalCardHeader>
          <ModalCardTitle>{t('checklist.validationFailed')}</ModalCardTitle>
        </ModalCardHeader>
        <ModalCardBody>
          {t('resultSet.resultRequiredAlert')}
          <Content>
            {shownAreasWithErrors}
            {signatureMissing}
          </Content>
        </ModalCardBody>
        <ModalCardFooter>
          <Button onClick={onClose} className="is-link">{t('Close')}</Button>
        </ModalCardFooter>
      </ModalCard>
    </Modal>} />;
};
import { block as _block } from "million/react";
import { Button, Content, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, ModalCardTitle } from 'bloomer';
import * as React from 'react';
import { BrowserView, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { IArea, IQuestion } from '../../../api/types/checklist';
import ITeam from '../../../api/types/team';
import IUser from '../../../api/types/user';
import { INotFilledQuestion } from '../../../pages/checklists/ResultView/types';
import RequiredFieldAreaNoPermissionMessage from './RequiredFieldModalPermissionNotification';
interface IProps {
  onClose: () => void;
  onJumpToQuestion: (question?: IQuestion) => void;
  errors: INotFilledQuestion[];
  areas: IArea[];
  isSignedValid?: boolean;
  currentUserCanAccessArea: (area: IArea) => boolean;
  users: IUser[];
  teams: ITeam[];
}

// ADF-335: this component shows a modal dialog with a list of all not filled required fields when closing a checklist
// the user can jump to the fields by clicking a link

export default NotFilledRequiredFieldsModal;