import { ApiBase } from './ApiBase'
import ILocation from './types/location'

export default class LocationApi extends ApiBase {
  public create(corporationId: number, data: ILocation) {
    return this.post<ILocation>(`/corporations/${corporationId}/locations`, data)
  }

  public update(corporationId: number, id: number, data: ILocation) {
    return this.put<ILocation>(`/corporations/${corporationId}/locations/${id}`, data)
  }
}
