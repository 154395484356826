import { Box, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { setActiveResultsSort, setCreatedTempaltesSort } from '../../../../actions/sortActions';
import api from '../../../../api';
import { IFolder } from '../../../../api/FolderApi';
import { useIsPowerUser } from '../../../../hooks/authHooks';
import useFolderData from '../../../../hooks/folder/useFolderData';
import useFolderTableHeaders from '../../../../hooks/folder/useFolderTableHeaders';
import useTableData from '../../../../hooks/folder/useTableData';
import useCorporationSettings from '../../../../hooks/useCorporationSettings';
import { IAlphaprocessState } from '../../../../store';
import LoadSpinner from '../../../loadSpinner/LoadSpinner';
import DataTable, { DataTableData } from '../../../widgets/DataTable';
import FolderBreadCrumbs from './FolderBreadCrumbs';
import MoveFolderDialog from './MoveFolderDialog';
import NameFolderDialog from './RenameFolderDialog';
import SplitButton from './SplitButton';
import { confirm, alert } from '../../../../util';
import EditModalWrapper from '../EditModalWrapper';
import ErrorView from '../../../ErrorView';
export enum FolderMode {
  templates,
  checklists,
}
interface Props {
  folder: IFolder;
  mode: FolderMode;
  selectedId?: string | number;
  onSelect?: (x: any) => void;
  onUpdateFolder: (id: string) => void;
  checklistStatus: 'open' | 'closed' | 'due' | 'invited';
  onError: (e: AxiosError) => void;
  tabIndex: number;
}
export interface TableData extends DataTableData {
  type: 'folder' | 'result_set' | 'template';
  title: string | JSX.Element;
  due_date?: string | JSX.Element;
  created_at?: string | JSX.Element;
  tester?: string | JSX.Element;
  creator?: string | JSX.Element;
  can_delete: boolean;
  status: 'open' | 'closed' | 'reopened' | 'planned';
  checklist_id?: number;
  isOffline: boolean;
}
function getBulkIds(items: any[]) {
  const folderIds = items.filter(x => x.type === 'folder').map(x => x.id);
  const checklistIds = items.filter(x => x.type === 'result_set').map(x => x.id);
  const templateIds = items.filter(x => x.type === 'template').map(x => x.id);
  return {
    folderIds,
    checklistIds,
    templateIds
  };
}
const TableStyles = styled('div')(({
  theme
}) => ({
  '& tbody tr': {
    [theme.breakpoints.down('lg')]: {
      height: '70px',
      '& .checkbox': {
        marginTop: 'calc(50% + 5px)'
      },
      '& .gear-menu': {
        marginTop: 'calc(50% - 5px)'
      },
      '& .folder-title': {
        marginTop: '12px'
      }
    }
  }
}));
export default function Folder({
  folder,
  onUpdateFolder,
  mode,
  checklistStatus,
  onError,
  tabIndex,
  selectedId,
  onSelect
}: Props) {
  const {
    t
  } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isAdmin = useIsPowerUser();
  const theme = useTheme();
  const isMobileOnly = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    use_offline_mode
  } = useCorporationSettings();
  const [page, setPage] = useState(1);
  const [lastNewFolderId, setLastNewFolderId] = useState<string | null>(null);
  const isOffline = useSelector((state: IAlphaprocessState) => state.core.isOffline);
  const sort = useSelector((state: IAlphaprocessState) => state.sort);
  const [selectedEditResultSet, setSelectedEditResultSet] = useState<number | null>(null);
  const {
    data,
    isLoading,
    error,
    totalPages,
    handleResultSetUpdated,
    offlineIds,
    handleClone,
    refresh,
    onMakeOffline,
    updateOfflineChecklist,
    handleDeleteTemplate
  } = useFolderData(checklistStatus, folder, mode, page, tabIndex);
  const tableData = useTableData(data, offlineIds, mode, onUpdateFolder, lastNewFolderId, checklistStatus, tabIndex, folder);
  const headers = useFolderTableHeaders(!!selectedId, mode, checklistStatus);
  const checkValidEditItem = (item: any) => {
    if (item.type === 'folder') {
      return item.can_delete || isAdmin;
    }
    return (item.can_delete || isAdmin) && item.type === 'result_set' && item.status === 'open';
  };
  const sortSettings = useMemo(() => {
    if (mode === FolderMode.templates) {
      return sort.createdTemplatesSort;
    }
    if (mode === FolderMode.checklists) {
      return sort.activeResultsSort;
    }
    return {
      direction: 'asc',
      column: 'id'
    };
  }, [mode, sort.activeResultsSort, sort.createdTemplatesSort]);
  const handleEditModalClose = useCallback(() => {
    setSelectedEditResultSet(null);
  }, []);
  const handleRenameFolder = useCallback(async (id, name: string) => {
    const updatedFolder = await api.folders.updateFolder(id, {
      name
    });
    onUpdateFolder(updatedFolder.id);
  }, [onUpdateFolder]);
  const handleEditStart = useCallback(({
    id,
    type
  }: {
    id: number | string;
    type: 'folder' | 'result_set';
  }) => {
    if (type === 'result_set') {
      setSelectedEditResultSet((id as number));
    } else {
      const customUI = ({
        onClose
      }) => <NameFolderDialog onClose={onClose} onEdit={name => handleRenameFolder(id, name)} folder={folder.children.find(x => x.id === id)} mode="rename" />;
      confirmAlert({
        customUI
      });
    }
  }, [folder.children, handleRenameFolder]);
  const handleReopen = useCallback(async ({
    id
  }) => {
    confirm(null, t('resultSet.confirmReopen'), async () => {
      try {
        const reopened = await api.resultSets.reopen(0, id);
        const {
          folder_id,
          checklist_id
        } = reopened.data;
        history.push(`/checklists/tab/0/folders/${folder_id}/${checklist_id}/audit/${id}`);
      } catch (e) {
        onError((e as AxiosError));
      }
    });
  }, [history, onError, t]);
  const handleMove = useCallback(async (item, target) => {
    if (item.type === 'folder') {
      if (target.id === item.id) {
        toast(t('folder.cannotMoveToSelf'), {
          type: 'error'
        });
        return;
      }
      await api.folders.updateFolder(item.id, {
        parent_id: target.id
      });
      const updatedFolder = {
        ...folder,
        children: folder.children.filter(f => f.id !== item.id)
      };
      onUpdateFolder(updatedFolder.id);
    } else if (item.type === 'result_set') {
      await api.resultSets.update(item.checklist_id, item.id, ({
        folder_id: target.id
      } as any));
      await refresh();
    } else if (item.type === 'template') {
      await api.checklists.update(item.id, {
        folder_id: target.id
      });
      await refresh();
    }
  }, [folder, onUpdateFolder, refresh, t]);
  const handleSync = useCallback(({
    id
  }) => {
    confirm(null, t('offlineMode.confirmSync'), () => {
      history.push(`/checklists/offline/sync/${id}`);
    });
  }, [history, t]);
  const showMoveDialog = useCallback(item => {
    const customUI = ({
      onClose
    }) => <MoveFolderDialog onClose={onClose} onMove={target => handleMove(item, target)} />;
    confirmAlert({
      customUI
    });
  }, [handleMove]);
  const handleDeleteChecklist = useCallback(({
    id
  }) => {
    confirm(null, t('resultSet.confirmDelete'), async () => {
      await api.resultSets.deleteResultSet(0, id);
      await refresh();
    });
  }, [refresh, t]);
  const handleDeleteFolder = useCallback(({
    id
  }) => {
    confirm(null, t('folder.confirmDelete'), async () => {
      await api.folders.deleteFolder(id);
      const updatedFolder: IFolder = {
        ...folder,
        children: folder.children.filter(c => c.id !== id)
      };
      onUpdateFolder(updatedFolder.id);
    });
  }, [folder, onUpdateFolder, t]);
  const handleSort = useCallback((field: string, direction: 'desc' | 'asc') => {
    if (mode === FolderMode.checklists) {
      dispatch(setActiveResultsSort({
        column: field,
        direction
      }));
    } else {
      dispatch(setCreatedTempaltesSort({
        column: field,
        direction
      }));
    }
  }, [dispatch, mode]);
  const handlePagination = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);
  const handleBulkPdfExport = useCallback(async (items: any[]) => {
    const folderIds = items.filter(x => x.type === 'folder').map(x => x.id);
    const checklistIds = items.filter(x => x.type !== 'folder').map(x => x.id);
    try {
      await api.bulk.generateReports(folderIds, checklistIds);
      alert(t('bulk.pdf_done'));
    } catch (e) {
      onError((e as AxiosError));
    }
  }, [onError, t]);
  const handleCombinedPdfExport = useCallback(async (items: any[]) => {
    const folderIds = items.filter(x => x.type === 'folder').map(x => x.id);
    const checklistIds = items.filter(x => x.type !== 'folder').map(x => x.id);
    try {
      await api.bulk.generateCombinedReport(folderIds, checklistIds);
      alert(t('bulk.pdf_done'));
    } catch (e) {
      onError((e as AxiosError));
    }
  }, [onError, t]);
  const handleBulkExcelExport = useCallback(async (items: any[]) => {
    const folderIds = items.filter(x => x.type === 'folder').map(x => x.id);
    const checklistIds = items.filter(x => x.type !== 'folder').map(x => x.id);
    try {
      await api.bulk.generateExcel(folderIds, checklistIds);
      alert(t('bulk.excel_done'));
    } catch (e) {
      onError((e as AxiosError));
    }
  }, [onError, t]);
  const handleBulkDelete = useCallback(async (items: any[]) => {
    const {
      folderIds,
      checklistIds,
      templateIds
    } = getBulkIds(items);
    confirm(null, t('bulk.confirm_delete'), async () => {
      try {
        await api.bulk.bulkDelete(folderIds, checklistIds, templateIds);
        await refresh();
      } catch (e) {
        onError((e as AxiosError));
      }
    });
  }, [onError, refresh, t]);
  const handleBulkMove = useCallback(async (items: any[]) => {
    const {
      folderIds,
      checklistIds,
      templateIds
    } = getBulkIds(items);
    const customUI = ({
      onClose
    }) => <MoveFolderDialog onClose={onClose} onMove={async target => {
      await api.bulk.move(folderIds, checklistIds, templateIds, target.id);
      await refresh();
    }} />;
    confirmAlert({
      customUI
    });
  }, [refresh]);
  const handleCreateFolder = useCallback(async (updatedFolder: IFolder, newFolder: IFolder) => {
    const f: IFolder = {
      ...updatedFolder,
      children: [...updatedFolder.children, newFolder].sort((a, b) => a.name.localeCompare(b.name))
    };
    await refresh();
    onUpdateFolder(f.id);
    setLastNewFolderId(newFolder.id);
  }, [onUpdateFolder, refresh]);
  const editModal = selectedEditResultSet ? <EditModalWrapper id={selectedEditResultSet} onSubmit={handleResultSetUpdated} onClose={handleEditModalClose} /> : null;
  const handleCreateChecklist = useCallback(({
    id
  }: {
    id: number;
  }) => {
    history.push(`/resultset/${id}/new`);
  }, [history]);
  const multiSelectActions = useMemo(() => [{
    label: t('bulk.generate_pdf'),
    callback: handleBulkPdfExport,
    show: checklistStatus === 'closed'
  }, {
    label: t('bulk.generate_combined_pdf'),
    callback: handleCombinedPdfExport,
    show: checklistStatus === 'closed'
  }, {
    label: t('bulk.generate_excel'),
    callback: handleBulkExcelExport,
    show: checklistStatus === 'closed'
  }, {
    label: t('bulk.delete'),
    callback: handleBulkDelete,
    show: checklistStatus !== 'closed' && isAdmin
  }, {
    label: t('bulk.move'),
    callback: handleBulkMove
  }], [checklistStatus, handleBulkDelete, handleBulkExcelExport, handleBulkMove, handleBulkPdfExport, handleCombinedPdfExport, isAdmin, t]);
  const Wrapper = mode === FolderMode.checklists ? TableStyles : Box;
  return <div className={`folder ${isMobileOnly && 'folder-mobile'}`}>
      <div className={`folder-navigation ${isMobileOnly ? 'is-mobile' : 'is-not-mobile'}`}>
        <FolderBreadCrumbs folder={folder} onNavigation={f => onSelect({
        type: 'folder',
        item: f
      })} />
        <Hidden mdDown>
          <SplitButton folder={folder} onCreateFolder={handleCreateFolder} />
        </Hidden>
      </div>
      {error && <ErrorView error={error} />}
      {isLoading && <LoadSpinner className="centered" />}
      <Wrapper>
        <DataTable selectable data={tableData} isLoading={isLoading} headers={headers} sortColumn={sortSettings.column} sortDir={sortSettings.direction} actions={[{
        icon: 'fas fa-pencil-alt has-pointer',
        tooltip: t('common.edit'),
        onClick: handleEditStart,
        isValidForItem: (item: any) => checkValidEditItem(item) && item.type !== 'folder'
      }, {
        icon: 'fas fa-pencil-alt has-pointer',
        tooltip: t('common.rename'),
        onClick: handleEditStart,
        isValidForItem: (item: any) => checkValidEditItem(item) && item.type === 'folder'
      }, {
        icon: 'fas fa-lock-open has-pointer',
        onClick: handleReopen,
        tooltip: t('resultSet.reopen'),
        isValidForItem: (item: any) => (isAdmin || item.employees_can_restart) && item.type === 'result_set' && item.status === 'closed',
        isDisabledForItem: () => isOffline
      }, {
        icon: 'far fa-play-circle has-pointer',
        onClick: (handleCreateChecklist as any),
        tooltip: t('CreateChecklistFromTemplate'),
        isValidForItem: (item: any) => item.type === 'template'
      }, {
        icon: 'far fa-copy has-pointer',
        onClick: handleClone,
        tooltip: t('CloneChecklistTemplate'),
        isValidForItem: (item: any) => isAdmin && item.type === 'template',
        isDisabledForItem: () => isOffline
      }, {
        icon: 'far fa-copy has-pointer',
        onClick: handleClone,
        tooltip: t('resultSet.copy'),
        isValidForItem: (item: any) => isAdmin && item.type === 'result_set',
        isDisabledForItem: () => isOffline
      }, {
        icon: 'fas fa-arrow-right has-pointer',
        onClick: showMoveDialog,
        tooltip: t('folder.moveToFolder'),
        isValidForItem: () => isAdmin,
        isDisabledForItem: () => isOffline
      }, {
        icon: 'fas fa-download has-pointer',
        onClick: onMakeOffline,
        tooltip: t('offlineMode.makeAvailable'),
        isValidForItem: (item: any) => item.status !== 'closed' && item.type === 'result_set' && use_offline_mode && !item.isOffline,
        isDisabledForItem: () => isOffline,
        className: 'offline-mode-action'
      }, {
        icon: 'fas fa-download has-pointer',
        onClick: onMakeOffline,
        tooltip: t('offlineMode.makeAvailable'),
        isValidForItem: (item: any) => item.status !== 'closed' && item.type === 'template' && use_offline_mode,
        isDisabledForItem: () => isOffline,
        className: 'offline-mode-action'
      }, {
        icon: 'fas fa-download has-pointer',
        onClick: updateOfflineChecklist,
        tooltip: t('offlineMode.updateChecklist'),
        isValidForItem: (item: any) => item.status !== 'closed' && item.type === 'result_set' && use_offline_mode && item.isOffline,
        isDisabledForItem: () => isOffline,
        className: 'offline-mode-action'
      }, {
        icon: 'fas fa-upload',
        onClick: handleSync,
        tooltip: t('offlineMode.syncChecklist'),
        isValidForItem: (item: any) => item.type === 'resultSet' && item.status !== 'closed' && use_offline_mode,
        isDisabledForItem: () => isOffline
      }, {
        icon: 'fas fa-trash-alt has-pointer',
        tooltip: t('common.delete'),
        onClick: handleDeleteChecklist,
        isValidForItem: (item: any) => item.can_delete && isAdmin && item.type === 'result_set' && item.status !== 'closed',
        className: 'is-danger'
      }, {
        icon: 'fas fa-trash-alt has-pointer',
        tooltip: t('common.delete'),
        onClick: handleDeleteFolder,
        isValidForItem: (item: any) => item.can_delete && item.type === 'folder',
        className: 'is-danger'
      }, {
        icon: 'fas fa-trash-alt has-pointer',
        onClick: handleDeleteTemplate,
        tooltip: t('common.delete'),
        isValidForItem: (item: any) => isAdmin && item.type === 'template',
        className: 'is-danger',
        isDisabledForItem: () => isOffline
      }]} onSort={handleSort} currentPage={page} totalPages={totalPages} selectedId={selectedId?.toString()} onPagination={handlePagination} multiSelectActions={multiSelectActions} />
      </Wrapper>
      {editModal}
    </div>;
}