import * as React from 'react';
import { isCordova } from '../util';
const WebView: React.FunctionComponent = ({
  children
}) => {
  if (isCordova()) return null;
  return <>
      {children}
    </>;
};
export default WebView;