import IUser from '../api/types/user'

export interface IUpdateUserAction {
  type: 'USER_UPDATE'
  user: IUser
}

export const updateUser = (user: IUser): IUpdateUserAction => ({
  type: 'USER_UPDATE',
  user
})

export interface ISetNotificationTokenAction {
  type: 'USER_SET_NOTIFICATION_TOKEN'
  token: string
}

export const setNotificationToken = (token: string): ISetNotificationTokenAction => ({
  type: 'USER_SET_NOTIFICATION_TOKEN',
  token
})

export type UserActions = IUpdateUserAction | ISetNotificationTokenAction
