import { compiledBlock as _compiledBlock } from "million/react";
const ResultTasks_1 = _compiledBlock(_props => _props.v0, {
  name: "ResultTasks_1",
  portals: ["v0"]
});
const ResultTasks = function ResultTasks({
  result,
  onClose,
  boards,
  onUpdate,
  accessToken,
  resultSetId
}: IProps) {
  const {
    t
  } = useTranslation();
  const resultTasks = result.tasks || [];
  const boardIds = [...new Set(resultTasks.map(x => x.board_id))];
  const tasksGrouped = boardIds.map(boardId => {
    const boardTitle = boards.find(b => b.id === boardId)?.title;
    const tasksByBoard = resultTasks.filter(x => x.board_id === boardId);
    const tasksAbc = tasksByBoard?.sort((a, b) => a.title.localeCompare(b.title)) ?? [];
    const taskList = tasksAbc.map(task => <div key={boardId}>
        <Label>
          <PriorityIcon priority={task.priority} />
          {task.title}
        </Label>
        <div>
          {task.users?.map(user => <ReactAvatar round size="30px" src={user.avatar.thumb.url} name={`${user.firstname} ${user.lastname}`} key={user.id} />)}
        </div>
      </div>);
    return <Media key={boardId}>
        <MediaContent>
          <h3 className="tasksTitle">{t('result.tasks.assignedBoard')}</h3>
          <Label>{boardTitle}</Label>
          <h3 className="tasksTitle">{t('result.tasks.assignedTask')}</h3>
          {taskList}
        </MediaContent>
      </Media>;
  });
  // resultTasks.groupBy('board_id')

  const initialValue: ITask = {
    project_ids: [],
    ressource_ids: [],
    board_id: null,
    result_id: result.id,
    result_set_ids: [resultSetId],
    id: v4(),
    team_ids: [],
    title: '',
    user_ids: [],
    lane_id: null,
    priority: 'medium',
    position: null
  };
  const onSubmit = async (values: ITask, actions: FormikActions<ITask>) => {
    try {
      const task = await api.tasks.create(values.board_id, values, result, accessToken);
      const tasks = [...resultTasks, task];
      const updatedResult = {
        ...result,
        tasks
      };
      await onUpdate(result.question_id, updatedResult, 'full');
      actions.setFieldValue('title', '');
      actions.setFieldTouched('title', false);
      toast(t('result.tasks.taskCreated'), {
        type: 'success'
      });
    } catch (error) {
      // actions.setError(error)
    } finally {
      actions.setSubmitting(false);
    }
  };
  const boardOptions = boards.map(o => <option value={o.id} key={o.id}>{o.title}</option>);
  const schema = yup.object({
    title: yup.string().required(t('task.validation.title')),
    priority: yup.string().required(t('task.validation.priority')),
    board_id: yup.number().required(t('task.validation.board')).typeError(t('task.validation.board')),
    user_ids: yup.array(),
    due_date: yup.date().nullable()
  });
  return /*@million jsx-skip*/<ResultTasks_1 v0={<Modal isActive className={isMobile ? 'is-huge modal-full-screen modal-fx-fadeInScale' : 'is-huge modal-fx-fadeInScale'}>
      <BrowserView>
        <ModalBackground />
      </BrowserView>
      <ModalCard className="modal-content" style={{
      margin: 0
    }}>
        <ModalCardHeader>
          <ModalCardTitle>
            {t('menu.tasks')}
          </ModalCardTitle>
        </ModalCardHeader>
        <ModalCardBody>
          {boardIds.length > 0 && <>
              <h3 className="has-margin-bottom-8">
                {t('resultSet.assignedTask')}
              </h3>
              <div className="task-list">
                {tasksGrouped}
              </div>
              <hr />
            </>}
          <h3 className="has-margin-bottom-8">
            {t('resultSet.newTask')}
          </h3>
          <Formik initialValues={initialValue} onSubmit={onSubmit} validationSchema={schema} render={({
          values,
          setFieldValue,
          isSubmitting
        }) => <Form>
                <Field>
                  <Control>
                    <Label>{t('task.title')}</Label>
                    <FastField name="title" className="input" placeholder={t('task.title')} />
                    <ErrorMessage component="span" name="title" className="has-text-danger help" />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Label>{t('task.priority')}</Label>
                    <PrioritySelect value={values.priority} onChange={value => setFieldValue('priority', value)} />
                    <ErrorMessage component="span" name="priority" className="has-text-danger help" />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Label>{t('task.responsible')}</Label>
                    <UserSelect selected={values.user_ids} isMulti onChange={value => setFieldValue('user_ids', value)} placeholder={t('task.responsible')} />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Label>{t('task.dueDate')}</Label>
                    <VelianDateInput selected={values.due_date && new Date(values.due_date)} onChange={date => setFieldValue('due_date', date)} name="due_date" className="input" placeholderText={t('task.dueDate')} mode="datetime" />
                    <ErrorMessage component="span" name="due_date" className="helper has-text-danger" />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Label>{t('task.board')}</Label>
                    <div className="select is-fullwidth">
                      <FastField name="board_id" component="select" placeholder={t('task.board')}>
                        <option />
                        {boardOptions}
                      </FastField>
                      <ErrorMessage component="span" name="board_id" className="has-text-danger help" />
                    </div>
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Button className="has-margin-top-5" type="submit" isColor="link" isLoading={isSubmitting}>
                      {t('task.addTask')}
                    </Button>
                  </Control>
                </Field>
              </Form>} />
        </ModalCardBody>
        <ModalCardFooter>
          <Button onClick={onClose}>{t('Close')}</Button>
        </ModalCardFooter>
      </ModalCard>
    </Modal>} />;
};
import { block as _block } from "million/react";
import { Button, Control, Field, Label, Media, MediaContent, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, ModalCardTitle } from 'bloomer';
import { ErrorMessage, FastField, Form, Formik, FormikHelpers as FormikActions } from 'formik';
import ReactAvatar from 'react-avatar';
import { BrowserView, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import * as yup from 'yup';
import api from '../../../api';
import { IBoard } from '../../../api/boardsApi';
import { ITask } from '../../../api/tasksApi';
import IResult from '../../../api/types/result';
import PriorityIcon from '../../boards/PriorityIcon';
import PrioritySelect from '../../boards/PrioritySelect';
import UserSelect from '../../widgets/selects/UserSelect';
import VelianDateInput from '../../widgets/VelianDateInput';
// import { groupBy } from '../../../util/index'

interface IProps {
  onUpdate: (id: number, data: any, field?: string) => Promise<IResult>;
  result: IResult;
  boards: IBoard[];
  onClose: () => void;
  accessToken?: string | null;
  resultSetId: number;
}
export default ResultTasks;