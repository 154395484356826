import { Button, DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger, Icon, Dropdown } from 'bloomer';
import { format } from 'date-fns';
import { useCallback, useRef, useState, FunctionComponent, ChangeEvent } from 'react';
import { isAndroid } from 'react-device-detect';
import { Trans } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import IResult from '../../../../../api/types/result';
import { CordovaNavigator, CordovaWindow } from '../../../../../typings/global';
import client from '../../../../../api';
import { blob2file, dataURItoBlob } from '../../../../../util';
interface Props {
  resultId: string;
  checklistId: number;
  resultSetId: number;
  questionId: number;
  areaPosition: number;
  accessToken?: string;
  required?: boolean;
  disabled?: boolean;
  onFileUploaded: (result: IResult) => void;
}
declare let navigator: CordovaNavigator;
declare let window: CordovaWindow;
const CordovaAttachmentButtons: FunctionComponent<Props> = ({
  checklistId,
  resultSetId,
  questionId,
  accessToken,
  areaPosition,
  onFileUploaded,
  disabled = false,
  required = false
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const fileInput = useRef<HTMLInputElement>();
  const handleImage = useCallback((type: 'camera' | 'gallery') => {
    setIsDropdownOpen(false);
    navigator.camera.getPicture(imageData => {
      const dataUrl = `data:image/jpeg;base64,${imageData}`;
      const blob = dataURItoBlob(dataUrl);
      setUploading(true);
      const theFile = blob2file(blob, `photo_${format(new Date(), 'yyyy-MM-dd')}.jpg`);
      client.results.addAttachment(checklistId, resultSetId, questionId, theFile, areaPosition, accessToken).then(response => {
        onFileUploaded(response);
      }).finally(() => {
        setUploading(false);
      });
    }, () => {}, {
      destinationType: 0,
      encodingType: 0,
      mediaType: 0,
      sourceType: type === 'camera' ? 1 : 0,
      correctOrientation: true
    });
  }, [accessToken, areaPosition, checklistId, onFileUploaded, questionId, resultSetId]);
  const handleFile = useCallback(async () => {
    const file = await window.chooser.getFile();
    if (file) {
      const blob = new Blob([file.data.buffer]);
      const result = await client.results.addAttachment(checklistId, resultSetId, questionId, blob2file(blob, file.name), areaPosition, accessToken);
      onFileUploaded(result);
    }
  }, [accessToken, areaPosition, checklistId, onFileUploaded, questionId, resultSetId]);
  const handleCamera = useCallback(() => handleImage('camera'), [handleImage]);
  const handleGallery = useCallback(() => {
    if (isAndroid) {
      if (!fileInput.current) return;
      fileInput.current.click();
    } else {
      handleImage('gallery');
    }
  }, [handleImage, fileInput]);
  const toggleDropdownMenu = useCallback(() => {
    setIsDropdownOpen(prev => !prev);
  }, []);
  const onClickOutSide = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);
  const handleFileChanged = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length) {
      for (const file of e.target.files) {
        const result = await client.results.addAttachment(checklistId, resultSetId, questionId, file, areaPosition, accessToken);
        onFileUploaded(result);
      }
    }
  }, [accessToken, areaPosition, checklistId, onFileUploaded, questionId, resultSetId]);
  return <>
      <input type="file" ref={fileInput} className="is-hidden" multiple accept="image/*" onChange={handleFileChanged} />
      <Dropdown isActive={isDropdownOpen} className="attachment-button">
        <DropdownTrigger onClick={toggleDropdownMenu}>
          <Button disabled={disabled} isLoading={isUploading} className="has-margin-top-5 has-text-weight-bold">
            <Icon className="fas fa-camera has-margin-right-5" isSize="small" />
            <span className={required ? 'is-required is-inline-block' : 'is-inline-block'}>
              <Trans>result.attachment.image</Trans>
            </span>
          </Button>
        </DropdownTrigger>
        <OutsideClickHandler onOutsideClick={onClickOutSide}>
          <DropdownMenu>
            <DropdownContent>
              <DropdownItem onClick={handleCamera}>
                <Trans>result.attachment.camera</Trans>
              </DropdownItem>
              <DropdownItem onClick={handleGallery}>
                <Trans>result.attachment.gallery</Trans>
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </OutsideClickHandler>
      </Dropdown>
      <Button disabled={disabled} isLoading={isUploading} onClick={handleFile} className="has-margin-top-5 has-text-weight-bold attachment-button">
        <Icon className="fas fa-paperclip has-margin-right-5" isSize="small" />
        <span className={required ? 'is-required is-inline-block' : 'is-inline-block'}>
          <Trans>result.attachment.file</Trans>
        </span>
      </Button>
    </>;
};
export default CordovaAttachmentButtons;