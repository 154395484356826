import { gql } from '@apollo/client'

export interface CreateAreaPayload {
  checklistId: number;
  title: string;
  position: number;
  description: string;
  allowClone: boolean;
  hasPageBreak: boolean;
}

export interface CreateArea {
  createArea: {
    errors: string[]
    area: {
      id: string;
      title: string;
    } | null
  }
}

export default gql`
mutation createArea(
  $checklistId: ID!
  $title: String!
  $description: String!
  $position: Int!
  $allowClone: Boolean!
  $hasPageBreak: Boolean
) {
  createArea(
    checklistId: $checklistId
    title: $title
    description: $description
    position: $position
    allowClone: $allowClone
    hasPageBreak: $hasPageBreak
  ) {
    errors
    area {
      id
      title
    }
  }
}
`
