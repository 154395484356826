import { compiledBlock as _compiledBlock } from "million/react";
const ResultComments_1 = _compiledBlock(_props => <div className={_props.v0}>
      <ul>{_props.v1}</ul>
      {_props.v2}
    </div>, {
  name: "ResultComments_1",
  portals: ["v1", "v2"]
});
const ResultComments = function ResultComments({
  comments,
  onAddComment,
  areaPosition,
  onDeleteComment,
  questionId,
  readonly,
  hasResult
}: IProps) {
  const {
    t
  } = useTranslation();
  const [isHighlighted, setIsHighlited] = useState<boolean>(false);
  useEventBus('comment', questionId, () => {
    setIsHighlited(true);
    setTimeout(() => setIsHighlited(false), 3000);
  });
  const isPowerUser = useIsPowerUser() && !readonly;
  const onKeyDown = useCallback((e: any) => {
    const target = (e.currentTarget as HTMLInputElement);
    const {
      key
    } = e;
    const text = target.value;
    // ADF-326: if the key is a char (not a ctrl, shift or something)
    // and the pressed key isn't within the allowed regexp pattern
    // then reject the key event
    if (key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!hasResult) {
        alert(t('result.comment.resultRequired'));
        return;
      }

      // only add comment if it has a text
      if (text && text.length) {
        onAddComment(questionId, areaPosition, text);
        target.value = '';
      }
    }
  }, [areaPosition, hasResult, onAddComment, questionId, t]);
  const displayComments = comments.filter(c => !c._destroy).map(comment => <li key={`comment-${comment.id}`}>
      <ResultComment comment={comment} canDelete={isPowerUser} onDeleteComment={onDeleteComment} />
    </li>);
  return /*@million jsx-skip*/<ResultComments_1 v0={clsx('result-comments', isHighlighted && 'is-highlighted')} v1={displayComments} v2={<Control hasIcons="right" className="comment-input">
        <Textarea className="input comment-input" placeholder={t('result.comment.add')} onKeyDown={onKeyDown} disabled={readonly} />
        <Icon className="fa fa-comment-dots" isAlign="right" isSize="small" />
      </Control>} />;
};
import { block as _block } from "million/react";
import { Control, Icon } from 'bloomer';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Textarea from 'react-textarea-autosize';
import { IComment } from '../../../api/types/result';
import { useIsPowerUser } from '../../../hooks/authHooks';
import { useEventBus } from '../../../pages/checklists/ResultView/EventBus';
import { alert } from '../../../util';
import ResultComment from './ResultComment';
interface IProps {
  comments: IComment[];
  onAddComment: (questionId: number, areaPosition: number, comment: string) => void;
  areaPosition: number;
  onDeleteComment: (commentId: number | string) => void;
  questionId: number;
  readonly: boolean;
  hasResult?: boolean;
}
export default ResultComments;