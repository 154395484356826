import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FunctionComponent, useCallback } from 'react';
import { TemplateStoreEntriesEntity } from '../../graphql/queries/FetchTemplateStoreEntries';
import SvgIconStyle from '../SvgIconStyle';
import { CardMediaStyle, TitleStyle } from './TemplateStoreEntryDetailsCard';
interface Props {
  entry: TemplateStoreEntriesEntity;
  onClick: (entry: TemplateStoreEntriesEntity) => void;
}
const CoverImgStyle = styled('img')(() => ({
  top: 0,
  width: '150px',
  height: 'auto',
  objectFit: 'cover',
  position: 'absolute'
}));
const TemplateStoreEntryCard: FunctionComponent<Props> = ({
  entry,
  onClick
}) => {
  const imageUrl = entry.image ? `${process.env.REACT_APP_API_BASE_URL}${entry.image}` : null;
  const handleClick = useCallback(() => onClick(entry), [entry, onClick]);
  return <Card onClick={handleClick} sx={{
    cursor: 'pointer',
    mr: 2,
    minHeight: '170px',
    maxHeight: '170px'
  }}>
      <CardContent sx={{
      padding: 2,
      pb: 2
    }}>
        {imageUrl && <CardMediaStyle>
            <SvgIconStyle color="paper" src="/static/icons/shape-avatar.svg" sx={{
          width: 80,
          height: 36,
          zIndex: 9,
          bottom: -15,
          position: 'absolute'
        }} />

            <CoverImgStyle alt={entry.name} src={imageUrl} />
          </CardMediaStyle>}
        <TitleStyle>
          {entry.name}
        </TitleStyle>
      </CardContent>
    </Card>;
};
export default TemplateStoreEntryCard;