import { useMediaQuery, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Tag } from 'bloomer';
import { isToday, isBefore } from 'date-fns';
import DateUtil from '../../../util/DateUtil';
interface IProps {
  date: Date;
}
function getColor(date: Date): string {
  if (isToday(date)) {
    return 'warning';
  }
  if (isBefore(date, new Date())) {
    return 'danger';
  }
  return 'success';
}
export function TableDueDate({
  date
}: IProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const parsedDate = DateUtil.parse(date);
  const formattedDate = DateUtil.format(parsedDate, isMobile ? DateUtil.ShortFormat : DateUtil.LongFormat);
  return <Tooltip title={formattedDate} enterTouchDelay={0}>
      <Tag isColor={getColor(parsedDate)} data-tip={formattedDate} className="is-rounded is-light">
        {formattedDate}
      </Tag>
    </Tooltip>;
}