import { Notification } from 'bloomer';
import { Component } from 'react';
import LoadSpinner from './loadSpinner/LoadSpinner';
interface IState {
  hasError: boolean;
  errorMessage: string;
}
const chunkFailedMessage = /Loading chunk [\d]+ failed/;
export default class AsyncErrorBoundary extends Component<{}, IState> {
  public state: IState = {
    errorMessage: '',
    hasError: false
  };
  public static getDerivedStateFromError(error: Error) {
    if ((error.name === 'TypeError' || chunkFailedMessage.test(error.message)) && process.env.NODE_ENV !== 'development') {
      return {
        errorMessage: '',
        hasError: true
      };
    }
    return {
      hasError: true,
      errorMessage: error.message
    };
  }
  public render() {
    if (this.state.hasError) {
      if (!this.state.errorMessage) {
        return <LoadSpinner className="centered" />;
      }
      return <Notification isColor="danger">
          Ein kritischer Fehler ist aufgetreten. Bitte wenden Sie sich an support@alphaprocess.io
          <p>
            {this.state.errorMessage}
          </p>
        </Notification>;
    }
    return <>
        {this.props.children}
      </>;
  }
}