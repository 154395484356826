import { Icon } from 'bloomer';
import { createRef, Component } from 'react';
import * as ReactDOM from 'react-dom';
import './slider.css';
interface ISliderState {
  sliderValue: number;
  sliderHeight: number;
  stepHeight: number;
  active: boolean;
  currentY: number;
  initialY: number;
  handleId: string;
  questHeight: number;
  clicked: boolean;
}
interface ISliderProps {
  anchorIds: Array<{
    priority: boolean;
    id: string;
  }>;
  innerRefs: object;
  containerId?: string;
}
export default class Slider extends Component<ISliderProps, ISliderState> {
  public references: object;
  public listenerOpts: object;
  constructor(props: ISliderProps) {
    super(props);
    const dotsHeight = 30; // 1rem == 16px
    this.state = {
      sliderValue: 0,
      sliderHeight: dotsHeight * Math.max(0, props.anchorIds.length - 1),
      stepHeight: dotsHeight,
      active: false,
      currentY: 0,
      initialY: 0,
      handleId: 'drag-handle',
      questHeight: 1,
      clicked: false
    };
    this.references = {};
    this.listenerOpts = {
      capture: false,
      once: false,
      passive: false
    };
  }
  public componentDidMount() {
    const firstElm = document.getElementById(`${this.props.anchorIds[0].id}`);
    const containerScrollHeight = firstElm && firstElm.parentElement ? firstElm.parentElement.scrollHeight : 0;
    const questHeight = containerScrollHeight / this.props.anchorIds.length;
    const initialY = this.calculateOffsets(this.references[0].current);
    this.setState({
      questHeight,
      initialY
    }, () => {
      this.handleScroll();
    });
    document.addEventListener('scroll', this.handleScroll, this.listenerOpts);
    document.addEventListener('touchstart', this.dragStart, this.listenerOpts);
    document.addEventListener('touchend', this.dragEnd, this.listenerOpts);
    document.addEventListener('mousedown', this.dragStart, this.listenerOpts);
    document.addEventListener('mouseup', this.dragEnd, this.listenerOpts);
    document.addEventListener('touchcancel', this.dragEnd, this.listenerOpts);
    document.addEventListener('mousemove', this.drag, this.listenerOpts);
    document.addEventListener('touchmove', this.drag, this.listenerOpts);
  }
  private calculateOffsets = (ele: any): number => {
    if (!ele.parentElement || ele.className.includes('mainslider')) {
      return ele.offsetTop + 15; // ADJUST OFFSET BUFFER IF NEEDED FOR THE INITIAL Y ATTRIBUTE OF DRAG HANDLE
    }
    return ele.offsetTop + this.calculateOffsets(ele.parentElement);
  };
  private handleScroll = () => {
    const ele = document.scrollingElement || document.documentElement;
    const step = Math.floor((ele.scrollTop + this.state.questHeight / 2) / this.state.questHeight);
    if (this.state.sliderValue !== step && !this.state.active && !this.state.clicked) {
      const currentY = this.state.stepHeight * (step < 0 ? 0 : step);
      this.setState({
        sliderValue: step,
        currentY
      });
    } else if (this.state.clicked) {
      this.setState({
        clicked: false
      });
    }
  };
  private handleClick = (e: any, index: number) => {
    e.preventDefault();
    const posY = this.state.stepHeight * index;
    this.setState({
      currentY: posY,
      sliderValue: index,
      clicked: true
    }, () => {
      this.scrollToElement(index);
    });
  };
  private getOrCreateRef = (id: number) => {
    if (!this.references.hasOwnProperty(id)) {
      this.references[id] = createRef();
    }
    return this.references[id];
  };
  private getAnchorClasses = (anchor: {
    priority: boolean;
    id: string;
  }) => {
    const classNames: string[] = [];
    if (anchor.priority) {
      classNames.push('prior');
    }
    return classNames.join(' ');
  };
  private scrollToElement = (id: number) => {
    const element: any = ReactDOM.findDOMNode(this.props.innerRefs[id].current);
    if (this.props.anchorIds.length >= id && this.props.innerRefs[id].current) {
      // Change by velian 45 is the height of the sticky progressbar
      const container = document.getElementById(this.props.containerId);
      if (container) {
        if (element === this.props.innerRefs[0].current) {
          container.scrollTop = 0;
        } else {
          container.scrollTop = element.offsetTop - 245;
        }
      } else if (element === this.props.innerRefs[0].current) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, element.offsetTop - 45);
      }
    }
    return null;
  };
  private dragStart = (e: any) => {
    // ATTACH EVENT LISTENER FOR DRAG SCROLL
    let active = false;
    let initialY = this.state.initialY || 0;
    if (initialY === 0) {
      if (e.type === 'touchstart') {
        initialY = e.touches[0].clientY;
      } else {
        initialY = e.clientY;
      }
    }
    if (e.target.id === this.state.handleId || e.target.className.includes('fa')) {
      active = true;
      this.setState({
        active,
        initialY
      }, () => {
        document.body.className += ' overflowHidden';
      });
    }
  };
  private drag = (e: any) => {
    if (this.state.active) {
      e.preventDefault();
      e.stopPropagation();
      let {
        currentY
      } = this.state;
      if (e.type === 'touchmove') {
        currentY = e.touches[0].clientY - this.state.initialY;
      } else {
        currentY = e.clientY - this.state.initialY;
      }
      if (currentY < 0 || currentY >= this.state.sliderHeight) {
        currentY = this.state.currentY;
      }
      const sliderValue = this.calculateStep(currentY);
      const prevStep = this.state.sliderValue;
      this.setState({
        currentY,
        sliderValue
      }, () => {
        if (prevStep !== sliderValue) {
          this.scrollToElement(sliderValue);
        }
      });
    }
  };
  private dragEnd = () => {
    // REMOVE EVENT LISTENER FOR DRAG SCROLL
    if (this.state.active) {
      const step = this.calculateStep(this.state.currentY);
      const currentY = this.state.stepHeight * step;
      this.setState({
        active: false,
        sliderValue: step,
        currentY
      }, () => {
        this.scrollToElement(step);
        document.body.className = document.body.className.replace(/overflowHidden/g, '');
      });
    }
  };
  private calculateStep = (distance: any) => {
    const currentY = distance || this.state.currentY;
    let step = currentY / this.state.stepHeight;
    const points = step - Math.floor(step);
    step = Math.floor(step);
    step += points >= 0.5 ? 1 : 0;
    return step < 0 ? 0 : step;
  };
  public componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll, this.listenerOpts);
    document.removeEventListener('touchstart', this.dragStart, this.listenerOpts);
    document.removeEventListener('touchend', this.dragEnd, this.listenerOpts);
    document.removeEventListener('touchcancel', this.dragEnd, this.listenerOpts);
    document.removeEventListener('touchmove', this.drag, this.listenerOpts);
    document.removeEventListener('mousedown', this.dragStart, this.listenerOpts);
    document.removeEventListener('mouseup', this.dragEnd, this.listenerOpts);
    document.removeEventListener('mousemove', this.drag, this.listenerOpts);
  }
  public render() {
    const style: {} = {
      transform: `translateY(${this.state.currentY}px)`
    };
    return <div className="slider mainslider">
        <div className="slider-wrap">
          <span id={this.state.handleId} style={style} className={this.state.handleId}>
            <Icon className="fa fa-arrows-alt-v" isSize="medium" />
          </span>
          <div className="range-labels">
            {this.props.anchorIds.map((q, index) => <a key={`anchor_${q.id}_${index}`} ref={this.getOrCreateRef(index)} href={`#${q.id}`} onClick={e => this.handleClick(e, index)} className={this.getAnchorClasses(q)}>
                  &nbsp;
                </a>)}
          </div>
        </div>
      </div>;
  }
}