import { compiledBlock as _compiledBlock } from "million/react";
const TabPanel_1 = _compiledBlock(_props => <div role="tabpanel" hidden={_props.v0} id={_props.v1} aria-labelledby={_props.v2} {..._props.v3}>
      {_props.v4}
    </div>, {
  name: "TabPanel_1",
  portals: ["v4"]
});
const TabPanel = function TabPanel(props: TabPanelProps) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return /*@million jsx-skip*/<TabPanel_1 v0={value !== index} v1={`simple-tabpanel-${index}`} v2={`simple-tab-${index}`} v3={other} v4={value === index && <Box>
          {children}
        </Box>} />;
};
import { block as _block } from "million/react";
import { Box } from '@mui/material';
import { ReactNode } from 'react';
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}
export default TabPanel;
export function a11yProps(index: string | number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index
  };
}