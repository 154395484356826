import querystring from 'query-string'
import { ApiBase, IPaginatedData } from './ApiBase'
import { ICloudAttachment } from './types/attachment'
import { IResultAttachment } from './types/result'

export interface ICompany {
  id?: string
  name: string
  description: string
  full_address?: string
  cloud_attachments?: ICloudAttachment[]
}

export interface FullCompany extends ICompany {
  address: string
  address_supplement: string
  zip_code: string
  city: string
  attachment?: IResultAttachment
}

interface CompanyParams {
  page: number
  items?: number
  name?: string
  sort: string
  direction: 'asc' | 'desc'
}

export default class CompanyApi extends ApiBase {
  public async all(params: CompanyParams): Promise<IPaginatedData<ICompany>> {
    if (this.isOffline()) {
      return {
        data: [],
        totalCount: 0,
        currentPage: 1,
        totalPages: 1
      }
    }
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    const response = await this.get<ICompany[]>(`/companies?${query}`)
    return this.paginateResponse(response)
  }

  public async select() {
    if (this.isOffline()) {
      return []
    }
    const response = await this.get<ICompany[]>('/companies/select')
    return response.data
  }

  public async find(id: string) {
    const response = await this.get<FullCompany>(`/companies/${id}`)
    return response.data
  }

  public async create(company: FullCompany, file: File | null) {
    const response = await this.post<FullCompany>('/companies', this.buildFormData(company, file))
    return response.data
  }

  public deleteCompany(id: string) {
    return this.delete(`/companies/${id}`)
  }

  public async update(id: string, values: FullCompany, file: File | null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { full_address, ...rest } = values
    const response = await this.put<FullCompany>(`/companies/${id}`, this.buildFormData(rest, file))
    return response.data
  }

  private buildFormData(company: FullCompany, file: File | null) {
    const formData = new FormData()
    Object.keys(company).forEach((key) => {
      formData.append(key, company[key])
    })
    if (file) {
      formData.append('file', file)
    }
    return formData
  }
}
