import { compiledBlock as _compiledBlock } from "million/react";
const ResultHistoryEntry_1 = _compiledBlock(_props => <div className={_props.v0}>
      {_props.v1}
    </div>, {
  name: "ResultHistoryEntry_1",
  portals: ["v1"]
});
const ResultHistoryEntry = function ResultHistoryEntry({
  entry,
  questions
}) {
  const {
    t
  } = useTranslation();
  const object = typeof entry.object === 'object' ? entry.object : JSON.parse(entry.object);
  const objectChanges = JSON.parse(entry.object_changes);
  const filteredKeys = Object.keys(objectChanges).filter(x => x !== 'question_id' && x !== 'checklist_id' && x !== 'project_id' && x !== 'created_at' && x !== 'updated_at' && x !== 'id' && x !== 'creator_id' && x !== 'score' && x !== 'corporation_id' && x !== 'folder_id' && x !== 'area_permissions' && x !== 'area-position' && x !== 'result_set_id' && x !== 'report' && x !== 'schedule_id' && x !== 'area_names');
  let question_id = null;
  if (object) {
    question_id = object.question_id;
  } else if (objectChanges.question_id) {
    // eslint-disable-next-line prefer-destructuring
    question_id = objectChanges.question_id[1];
  }
  const changes = filteredKeys.map(x => {
    const title = questions.find(q => q.id === question_id) ? questions.find(q => q.id === question_id).title : '-';
    const label = x === 'value' && question_id ? <div className="history-field-title">{title}</div> : <TranslatedField field={x} />;
    const oldValue = objectChanges[x][0];
    const newValue = objectChanges[x][1];
    if (!newValue && !oldValue) {
      return null;
    }
    return <div className="history-entry has-padding-bottom-10" key={x}>
        <div className="is-flex">
          <div className="history-field-label">
            {label}
          </div>
          <span>
            {DateUtil.fromNow(entry.created_at)}
            <br />
            {entry.changed_by}
          </span>
        </div>
        <div>
          <span className="history-value">{t('History-OldValue')}</span>
          {translateValue(x, oldValue, entry)}
        </div>
        <div>
          <span className="history-value">
            {t('History-NewValue')}
          </span>
          <span className="history-value">
            {translateValue(x, newValue, entry)}
          </span>
        </div>
      </div>;
  }).filter(x => x !== null);
  return /*@million jsx-skip*/<ResultHistoryEntry_1 v0={changes.length > 0 ? 'history-list' : 'is-hidden'} v1={changes} />;
};
import { block as _block } from "million/react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import TranslatedField from './TranslatedField';
import DateUtil from '../../util/DateUtil';
export function translateValue(field, value, entry) {
  if (!value) {
    return '-';
  }
  if (field === 'status') {
    if (value === 1) {
      return i18n.t('Closed');
    }
    if (value === 0) {
      return i18n.t('Open');
    }
    if (value === 2) {
      return i18n.t('ReOpened');
    }
  }
  if (field === 'value') {
    if (value === 'true') {
      return i18n.t('Yes');
    }
    if (value === 'false') {
      return i18n.t('No');
    }
    if (value === 'n/a') {
      return 'N/A';
    }
  }
  if (value) {
    if (field === 'tester_id') {
      return entry.tester;
    }
    if (field === 'ressource_id') {
      return entry.real_estate;
    }
    if (field === 'contact_id') {
      return entry.contact;
    }
  }
  return value;
}
ResultHistoryEntry.propTypes = {
  entry: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired
};
export default ResultHistoryEntry;