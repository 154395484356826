import { Box, Button, Control, Field, Input, Label, Title } from 'bloomer';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IFolder } from '../../../../../api/FolderApi';
interface Props {
  onClose: () => void;
  onEdit: (newName: string) => void;
  folder?: IFolder;
  mode: 'rename' | 'create';
}
function NameFolderDialog({
  folder,
  onClose,
  onEdit,
  mode
}: Props) {
  const [name, setName] = useState<string>(folder?.name ?? '');
  const {
    t
  } = useTranslation();
  const handleEdit = useCallback(() => {
    onEdit(name);
    onClose();
  }, [name, onClose, onEdit]);
  const handleKeyPress = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && name) {
      handleEdit();
    }
  }, [handleEdit, name]);
  return <Box className="rename-folder-box">
      <Title isSize={6} className="has-text-centered">
        {t(mode === 'rename' ? 'folder.renameFolderHeader' : 'folder.newFolder')}
      </Title>
      <Field>
        <Control>
          <Label>{t('Name')}</Label>
          <Input name="name" onKeyPress={handleKeyPress} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} value={name} placeholder={t('Name')} />
        </Control>
      </Field>
      <Field isGrouped>
        <Control>
          <Button id="save-team" className="has-margin-bottom-10" isPulled="right" disabled={!name} onClick={handleEdit}>
            {t('common.save')}
          </Button>
        </Control>
        <Control isExpanded>
          <Button className="has-margin-bottom-10" isPulled="right" onClick={onClose}>
            {t('common.cancel')}
          </Button>
        </Control>
      </Field>
    </Box>;
}
export default NameFolderDialog;