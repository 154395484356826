import { Icon, Tag } from 'bloomer';
import clsx from 'clsx';
import { useState } from 'react';
interface IProps {
  title?: string | null;
  openTitle?: string | null;
  closedTitle?: string | null;
  count?: number;
  children?: any;
  isOpen?: boolean;
  initialOpen?: boolean;
  setIsOpen?: (status: boolean) => void;
  className?: string | null;
}
export default function MiniAccordion({
  title,
  openTitle,
  closedTitle,
  count,
  children,
  isOpen,
  setIsOpen,
  className,
  initialOpen = false
}: IProps) {
  const [expanded, setExpanded] = useState(initialOpen);
  const isCurrentlyOpen = typeof isOpen === 'undefined' ? expanded : isOpen;
  const countTag = count ? <Tag className="folder-tag is-small is-rounded" isColor="link">
        {count}
      </Tag> : null;
  const onToggle = () => {
    if (typeof setIsOpen !== 'undefined') {
      setIsOpen(!isOpen);
    } else {
      setExpanded(!expanded);
    }
  };
  let displayTitle = title;
  if (!displayTitle && openTitle && closedTitle) {
    displayTitle = isCurrentlyOpen ? openTitle : closedTitle;
  }
  const iconClass = isCurrentlyOpen ? 'fas fa-angle-up' : 'fas fa-angle-down';
  return <div className={clsx('mini-accordion', className)}>
      <div className="is-blue mini-accordion-title has-pointer" onClick={onToggle}>
        {displayTitle}
        {countTag}
        <span className="is-pulled-right">
          <Icon className={iconClass} />
        </span>
      </div>
      {isCurrentlyOpen && children}
    </div>;
}