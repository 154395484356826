export interface IToggleMenuAction {
  type: 'MENU_TOGGLE'
}

export const toggleMenu = (): IToggleMenuAction => ({
  type: 'MENU_TOGGLE'
})

export interface ISetLanguageAction {
  type: 'SET_LANGUAGE'
  lang: string
}

export const setLanguageAction = (lang: string): ISetLanguageAction => ({
  lang,
  type: 'SET_LANGUAGE'
})

export interface SetBrowserNotificationCloseDate {
  closedDate: number
  type: 'SET_BROWSER_NOTIFICATION_CLOSE_DATE'
}

export const setBrowserNotificationCloseDate = (date: Date): SetBrowserNotificationCloseDate => ({
  closedDate: date.getUTCDate(),
  type: 'SET_BROWSER_NOTIFICATION_CLOSE_DATE'
})

export interface ToggleStartWithTemplate {
  type: 'TOGGLE_START_WITH_TEMPLATE'
}

export const toggleStartWithTemplate = (): ToggleStartWithTemplate => ({
  type: 'TOGGLE_START_WITH_TEMPLATE'
})

export type MenuActions = IToggleMenuAction | ISetLanguageAction |
  SetBrowserNotificationCloseDate | ToggleStartWithTemplate
