import { compiledBlock as _compiledBlock } from "million/react";
const JSX_details_1 = _compiledBlock(_props => <div>
      Status Code:
      {_props.v0}
      <br />
      Message:
      {_props.v1}
      <br />
      Body:
      {_props.v2}
      <br />
      URL:
      {_props.v3}
      <br />
    </div>, {
  name: "JSX_details_1",
  portals: ["v0", "v1", "v2", "v3"]
});
const ErrorView_1 = _compiledBlock(_props2 => _props2.v0, {
  name: "ErrorView_1",
  portals: ["v0"]
});
const ErrorView = function ErrorView({
  error,
  customErrorMessages = []
}: Props) {
  const {
    t
  } = useTranslation();
  const isOffline = useSelector((state: IAlphaprocessState) => state.core.isOffline);
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = useCallback(() => {
    setShowDetails(!showDetails);
  }, [showDetails]);
  let message: any = t('Error-System');

  // eslint-disable-next-line no-console
  console.error('error', error);
  const data = (error?.response?.data as any);
  if (error.name === 'DatabaseClosedError') {
    message = t('errorPages.databaseClosed');
  } else if (data?.translation_key?.length) {
    const placeholders = data?.placeholders;
    message = t(data.translation_key, placeholders);
  } else if (error && error.name === 'ValidationError') {
    message = error.message;
  } else if (error && error.response && typeof error.response.data === 'string') {
    // in some cases we get an empty response.
    // then show the default error message
    if (data.length) {
      message = error.response.data;
    } else if (error.response.status === 401) {
      // If we get an 401 error with an empty response then show generic error message
      // for missing permissions
      message = t('errorPages.unauthorizedAction');
    }
  } else if (error.response?.status === 404) {
    const cm = customErrorMessages.find(m => m.status === 404);
    message = cm?.message ?? t('Error-Resource');
  } else if (error && error.response && data.message) {
    message = data.message;
  } else if (error && error.response && data.error) {
    message = data.error;
  } else if (error && error.response && error.response.status) {
    if (error.response.status === 404) {
      message = t('Error-Resource');
    } else if (error.response && error.response.status === 403 && data.license_expired) {
      // force logout if the license expired
      message = data.license_expired;
      // doLogout()
      // history.push('/login')
    }
  } else {
    message = t('ConnectBackendFailed');
    if (isOffline) {
      return null;
    }
  }
  const details = showDetails ? /*@million jsx-skip*/<JSX_details_1 v0={error.response?.status} v1={error.message} v2={error.response?.data ? JSON.stringify(error.response.data) : 'No response data'} v3={error.request?.responseURL} /> : null;
  return /*@million jsx-skip*/<ErrorView_1 v0={<Notification isColor="danger" className="has-line-breaks error-view">
      {message}
      <div className="error-details-toggle">
        {showDetails ? <i onClick={toggleDetails} className="fas fa-chevron-up" /> : <i onClick={toggleDetails} className="fas fa-chevron-down" />}
      </div>
      {details}
    </Notification>} />;
};
import { block as _block } from "million/react";
import { AxiosError } from 'axios';
import { Notification } from 'bloomer';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IAlphaprocessState } from '../store';
interface CustomMessage {
  status: number;
  message: string;
}
interface Props {
  error: AxiosError;
  customErrorMessages?: CustomMessage[];
}

/**
 *  This component renders information about axios errors
 *
 * @param  props
 */
export default ErrorView;