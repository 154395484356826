import { AxiosError } from 'axios'
import Dexie from 'dexie'
import { TFunction } from 'i18next'
import { toast } from 'react-toastify'
import uniqid from 'uniqid'
import { v4 } from 'uuid'
import api from '../api'

import { IBoard } from '../api/boardsApi'
import { ITask } from '../api/tasksApi'
import { IChecklist, IQuestion } from '../api/types/checklist'
import IContact from '../api/types/contact'
import IProject from '../api/types/project'
import IResource from '../api/types/ressource'
import IResult, {
  IComment, IResultAttachementObject, IResultAttachment, ISignature
} from '../api/types/result'
import IResultSet from '../api/types/resultSet'
import ITeam from '../api/types/team'
import IUser from '../api/types/user'
import { alert, confirm } from '../util'
import DateUtil from '../util/DateUtil'
import RuleEngine from './RuleEngine'

const DB_NAME = 'auditify-offline-db'

export interface IOfflineDb {
  updateResult(data: IResult): Promise<IResult>

  addComment(resultSetId: number, resultId: string, text: string, user: IUser): Promise<IComment>

  deleteComment(resultSetId: number, resultId: string, commentId: string | number): Promise<void>

  createSignature(resultSetId: number, data: ISignature): Promise<ISignature>

  updateSignature(resultSetId: number, id: number | string, data: ISignature): Promise<ISignature>

  updateResultSet(id: number, data: IResultSet): Promise<IResultSet>

  addAttachment(resultSetId: number, resultId: string, question: IQuestion, areaPosition: number,
  filename: string, dataUrl: string): Promise<IResult>

  createTask(boardId: number, task: ITask, resultSetId: number, resultId: string): Promise<ITask>
}

export class AlphaprocessDatabase extends Dexie implements IOfflineDb {
  public checklists: Dexie.Table<IChecklist, number>

  public resultSets: Dexie.Table<IResultSet, number>

  public teams: Dexie.Table<ITeam, number>

  public boards: Dexie.Table<IBoard, number>

  public resources: Dexie.Table<IResource, number>

  public contacts: Dexie.Table<IContact, number>

  public users: Dexie.Table<IUser, number>

  public projects: Dexie.Table<IProject, number>

  constructor() {
    super(DB_NAME)
    this.version(1).stores({
      checklists: 'id,title',
      resultSets: 'id,title',
      teams: 'id,name',
      boards: 'id,title',
      contacts: 'id,firstname,lastname',
      resources: 'id,title',
      users: 'id,firstname,lastname',
      projects: 'id,name'
    })
    this.version(2).stores({
      resultSets: 'id,title,progress'
    })

    this.version(3).stores({
      checklists: 'id,created_at,title'
    })
    this.version(4).stores({
      resultSets: 'id,title,progress,due_date'
    })
    this.version(6).stores({
      resultSets: 'id,title,progress,due_date,resource,project'
    })

    this.version(7).stores({
      resultSets: 'id,title,progress,due_date,resource,project,closed_at'
    })

    this.version(8).stores({
      resultSets: 'id,title,progress,due_date,resource,project,closed_at,created_at'
    })

    this.checklists = this.table('checklists')
    this.resultSets = this.table('resultSets')
    this.teams = this.table('teams')
    this.boards = this.table('boards')
    this.contacts = this.table('contacts')
    this.resources = this.table('resources')
    this.users = this.table('users')
    this.projects = this.table('projects')
  }

  /**
   * Loads all required data from the API and stores it in the database
   */
  public async loadDataFromBackend() {
    const projects = await api.projects.open(1, 1, null)
    await this.projects.clear()
    await this.projects.bulkAdd(projects)

    const teams = await api.teams.all(1, false)
    await this.teams.clear()
    await this.teams.bulkAdd(teams)

    const contacts = await api.contacts.all()
    await this.contacts.clear()
    await this.contacts.bulkAdd(contacts)

    const users = await api.users.all()
    await this.users.clear()
    await this.users.bulkAdd(users)

    const resources = await api.resources.select()
    await this.resources.clear()
    await this.resources.bulkAdd(resources)
  }

  public async addResult(result: IResult) {
    const resultSet = await this.resultSets.get(result.result_set_id)
    result.id = v4()
    result._dirty = true
    result._new = true
    resultSet.results = [...resultSet.results, RuleEngine.checkRules(result, resultSet.results)]
    await this.resultSets.update(resultSet.id, resultSet)
    return result
  }

  public async updateResult(data: IResult) {
    const resultSet = await this.resultSets.get(data.result_set_id)
    if (!resultSet) return data
    data._dirty = true
    const result = RuleEngine.checkRules(data, resultSet.results)
    const results = resultSet.results ?? []
    resultSet.results = [...results.filter((r) => r.id !== data.id), result]
    await this.resultSets.update(resultSet.id, resultSet)
    return result
  }

  public async addComment(
    resultSetId: number,
    resultId: string,
    text: string,
    user: IUser
  ): Promise<IComment> {
    const resultSet = await this.resultSets.get(resultSetId)
    const result = resultSet.results.find((r) => r.id === resultId)

    if (!result.comments) {
      result.comments = []
    }

    const comment: IComment = {
      commentable_type: 'Result',
      commentable_id: resultId,
      text,
      // commentable: result,
      user,
      id: uniqid(),
      _dirty: true,
      created_at: new Date()
    }

    result.comments.push(comment)
    result._dirty = true
    await this.resultSets.update(resultSetId, resultSet)
    return comment
  }

  public async deleteComment(resultSetId: number, resultId: string, commentId: string | number) {
    const resultSet = await this.resultSets.get(resultSetId)
    const result = resultSet.results.find((r) => r.id === resultId)

    const comment = result.comments.find((c) => c.id === commentId)
    if (typeof comment.id === 'number') {
      comment._destroy = true
      comment._dirty = true
    } else {
      result.comments = result.comments.filter((c) => c.id !== commentId)
    }

    await this.resultSets.update(resultSetId, { results: [...resultSet.results] })
  }

  public async createSignature(resultSetId: number, data: ISignature) {
    const resultSet = await this.resultSets.get(resultSetId)
    data._dirty = true
    data.id = uniqid()
    resultSet.signatures.push(data)
    await this.resultSets.update(resultSetId, resultSet)
    return data
  }

  public async updateSignature(resultSetId: number, id: number | string, data: ISignature) {
    const resultSet = await this.resultSets.get(resultSetId)
    data._dirty = true
    resultSet.signatures = [data, ...resultSet.signatures.filter((s) => s.id !== id)]

    await this.resultSets.update(resultSetId, resultSet)

    return data
  }

  public async updateResultSet(id: number, data: IResultSet) {
    data._dirty = true
    data.testers = await this.users.filter((u) => data.tester_ids.includes(u.id)).toArray()
    data.teams = await this.teams.filter((t) => data.team_ids.includes(t.id)).toArray()
    await this.resultSets.update(id, data)
    return data
  }

  public async addAttachment(
    resultSetId: number,
    resultId: string,
    question: IQuestion,
    areaPosition: number,
    filename: string,
    dataUrl: string
  ) {
    const resultSet = await this.resultSets.get(resultSetId)
    let result = resultSet.results.find((r) => r.id === resultId)
    if (!result) {
      result = {
        id: v4(),
        _dirty: true,
        _new: true,
        attachments: [],
        value: '',
        question_id: question.id,
        question,
        audit_points: 0,
        area_position: areaPosition,
        result_set_id: resultSetId,
        versions: [],
        cloud_attachments: [],
        question_position: 1
      }
      resultSet.results.push(result)
    }
    const mime = dataUrl.split(',')[0].match(/:(.*?);/)[1] // get mime type from data url
    const attachment: IResultAttachementObject = {
      url: dataUrl,
      thumb: mime.startsWith('image') ? {
        url: dataUrl
      } : null
    }

    const resultAttachment: IResultAttachment = {
      attachment,
      filename
    }

    if (!result.attachments) {
      result.attachments = []
    }

    result.attachments.push(resultAttachment)
    result._dirty = true
    await this.resultSets.update(resultSetId, resultSet)

    return result
  }

  public async createTask(boardId: number, task: ITask, resultSetId: number, resultId: string) {
    const resultSet = await this.resultSets.get(resultSetId)
    const result = resultSet.results.find((r) => r.id === resultId)

    if (!result.tasks) {
      result.tasks = []
    }

    task.id = uniqid()
    task.board_id = boardId
    task.users = await this.users.filter((u) => task.user_ids.includes(u.id)).toArray()
    result.tasks.push(task)
    result._dirty = true

    await this.resultSets.update(resultSetId, resultSet)

    return task
  }

  public async deleteAttachment(resultSetId: number, resultId: string, id: number): Promise<void> {
    const resultSet = await this.resultSets.get(resultSetId)
    const result = resultSet.results.find((r) => r.id === resultId)
    const attachment = result.attachments.find((a) => a.id === id)

    if (attachment.id) {
      attachment._destroy = true
    } else {
      result.attachments = result.attachments.filter((a) => a.id !== id)
    }
    result._dirty = true

    await this.resultSets.update(resultSetId, resultSet)
  }

  public async syncChecklist(
    resultSet: IResultSet,
    t: TFunction,
    setProgress: (progress: number) => void
  ) {
    const originalId = resultSet.id
    let progress = 0
    let serverResultSet: IResultSet | null = null
    try {
      serverResultSet = resultSet.id > 0 ? await api.resultSets.find(resultSet.checklist_id, resultSet.id)
        : null
    } catch (e) {
      const error = e as AxiosError
      if (error.isAxiosError && error.response.status === 404) {
        resultSet.id = -1000

        if (resultSet.results) {
          for (const result of resultSet.results) {
            result.id = undefined
            result._new = true
            result._dirty = true
            if (result.comments) {
              for (const comment of result.comments) {
                comment.id = undefined
                comment._dirty = true
              }
            }
          }
        }
      } else {
        toast(error.message, { type: 'error' })
        return
      }
    }

    if (serverResultSet && serverResultSet.status === 'closed') {
      alert(t('offlineMode.checklistClosed', { name: serverResultSet.title }))
      return
    }

    try {
      if (resultSet.id < 0) {
        const response = await api.resultSets.create(resultSet.checklist_id, resultSet, true)
        resultSet.id = response.id
        progress++
        setProgress(progress)
      } else if (resultSet._dirty) {
        await api.resultSets.update(resultSet.checklist_id, resultSet.id, resultSet)
        resultSet._dirty = false
        progress++
        setProgress(progress)
      }

      for (const result of resultSet.results) {
        await this.syncResult(resultSet, result, t, serverResultSet)
        progress++
        setProgress(progress)
      }

      if (resultSet.signatures) {
        for (const s of resultSet.signatures.filter((sig) => sig._dirty)) {
          if (typeof s.id === 'string') {
            const signature = await api.resultSets.createSignature(resultSet.checklist_id, resultSet.id, s)
            s.id = signature.id
          } else {
            await api.resultSets.updateSignature(resultSet.checklist_id, resultSet.id, s.id, s)
          }
          s._dirty = false
          progress++
          setProgress(progress)
        }
      }
      await this.resultSets.delete(originalId)
    } catch (error) {
      toast((error as Error).message, { type: 'error' })
      await this.resultSets.update(resultSet.id, resultSet)
    }
  }

  private async syncResult(
    resultSet: IResultSet,
    result: IResult,
    t: TFunction,
    serverResultSet: IResultSet | null
  ) {
    const doSync = async () => {
      const response = result._new
        ? await api.results.create(
          resultSet.checklist_id,
          resultSet.id,
          result,
          resultSet.access_token
        )
        : await api.results.update(resultSet.checklist_id, resultSet.id, result)
      if (response.action) {
        const createMails = response.action.filter((x) => x.action === 'send_email')
        for (const createMail of createMails) {
          // eslint-disable-next-line no-console
          console.log('mail_action', createMail)
          confirm(null, t('resultSet.confirmSendEmailRule'), async () => {
            await api.results.sendRuleEmails(resultSet.checklist_id, resultSet.id, result.id, resultSet.access_token)
          })
        }
      }
      if (result.attachments) {
        for (const a of result.attachments.filter((at) => !at.id)) {
          const mime = a.attachment.url.split(',')[0].match(/:(.*?);/)[1] // get mime type from data url
          const file = await this.srcToFile(a.attachment.url, a.filename, mime)
          a.id = parseInt((await api.results.addAttachment(
            resultSet.checklist_id,
            resultSet.id,
            result.question_id,
            file,
            result.area_position
          )).id)
        }

        for (const a of result.attachments.filter((at) => at._destroy)) {
          await api.results.deleteAttachment(resultSet.id, result.id, a.id)
        }
      }

      if (result.comments) {
        for (const comment of result.comments.filter((c) => c._dirty)) {
          if (typeof comment.id === 'string') {
            comment.id = (
              await api.comments.create(resultSet.id, result.id, comment.text, null, 'Result')
            ).id
            comment._dirty = false
          }
          if (comment._destroy) {
            await api.comments.deleteComment(resultSet.checklist_id, resultSet.id, result.id, comment.id)
            result.comments = result.comments.filter((c) => c.id !== comment.id)
          }
        }
      }

      if (result.tasks) {
        for (const task of result.tasks.filter((ts) => typeof ts.id === 'string')) {
          const createdTask = await api.tasks.create(task.board_id, task)
          task.id = createdTask.id
        }
      }

      result._dirty = false
    }
    const resultFromServer = serverResultSet
      ? serverResultSet.results.find((x) => x.question_id === result.question_id) : null
    if (result._new) {
      if (resultFromServer) {
        result._new = false
        result._dirty = true
        result.id = resultFromServer.id
      } else {
        await doSync()
      }
    } else if (resultFromServer) {
      if (DateUtil.isAfter(result.updated_at, resultFromServer.updated_at)) {
        confirm(
          null,
          t('offlineMode.confirmSyncResult', {
            offlineValue: result.value,
            onlineValue: resultFromServer.value,
            question: resultFromServer.question.title
          }),
          async () => {
            await doSync()
          }
        )
      } else {
        await doSync()
      }
    }
  }

  /**
   * load src and convert to a File instance object
   * work for any type of src, not only image src.
   * return a promise that resolves with a File instance
   */
  private srcToFile(src: string, fileName: string, mimeType: string) {
    return (fetch(src)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], fileName, { type: mimeType }))
    )
  }
}
