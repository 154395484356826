// drop in replacement for ReactDatepicker.
// uses ReactDatepicker on desktop computers
// and a datetime-local input on mobiles

import { TextField } from '@mui/material';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';
import { BrowserView, MobileView } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import ITranslationProps from '../../types/translationProps';
import { toDateString, toDatetimeLocal } from '../../util';
import DateUtil from '../../util/DateUtil';
export type DateInputMode = 'date' | 'datetime' | 'time';
interface IProps extends ITranslationProps {
  name?: string;
  mode?: DateInputMode;
  dateFormat?: string;
  placeholderText?: string;
  className?: string;
  selected: Date;
  timeCaption?: string;
  onChange?: (date?: Date) => void;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isClearable?: boolean;
  disabled?: boolean;
  label?: string;
}
function getNativeInputType(mode: DateInputMode) {
  switch (mode) {
    case 'datetime':
      return 'datetime-local';
    case 'time':
      return 'time';
    default:
      return 'date';
  }
}
function getNativeValue(mode: DateInputMode, value: Date) {
  switch (mode) {
    case 'datetime':
      return toDatetimeLocal(value);
    case 'time':
      return DateUtil.formatTime(value);
    default:
      return toDateString(value);
  }
}
export default withTranslation()((props: IProps) => {
  const onChangeDatetimeLocal = (event: any) => {
    const value = event.target.value ? new Date(event.target.value) : null;
    props.onChange(value);
  };
  const mode = props.mode ?? 'date';
  return <>
      <BrowserView>
        {mode === 'date' && <DatePicker label={props.label} value={props.selected} ignoreInvalidInputs onChange={newValue => {
        props.onChange((newValue as Date));
      }} disabled={props.disabled} minDate={props.minDate} maxDate={props.maxDate} renderInput={params => <TextField fullWidth placeholder={props.placeholderText} {...params} />} />}
        {mode === 'time' && <TimePicker label={props.label} value={props.selected} onChange={newValue => {
        props.onChange((newValue as Date));
      }} ignoreInvalidInputs mask="__:__" inputFormat="HH:mm" disabled={props.disabled} renderInput={params => <TextField fullWidth placeholder={props.placeholderText} {...params} />} />}
        {mode === 'datetime' && <DateTimePicker renderInput={params => <TextField fullWidth placeholder={props.placeholderText} {...params} />} label={props.label} value={props.selected} disabled={props.disabled} minDate={props.minDate} maxDate={props.maxDate} ignoreInvalidInputs onChange={newValue => {
        props.onChange((newValue as Date));
      }} />}
      </BrowserView>
      {
      /* Browser support for datetime-local is limited, however the support
         in Google Chrome, Mobile Safari and Firefox Mobile is good enough */
    }
      <MobileView>
        <div className="control has-icons-left has-icons-right">
          <input type={getNativeInputType(mode)} name={props.name} className={clsx('input', props.className)} value={props.selected ? getNativeValue(mode, props.selected) : null} onChange={onChangeDatetimeLocal} required={props.required} disabled={props.disabled} autoComplete="off" />
          <span className="icon is-right">
            <i className="fas fa-calendar" />
          </span>
        </div>
      </MobileView>
    </>;
});