import { Box, Icon, Title } from 'bloomer';
import { Link } from 'react-router-dom';
import { IChart } from '../../api/chartsApi';
import { useIsPowerUser } from '../../hooks/authHooks';
interface IProps {
  chart: IChart;
  onSelect: (board: IChart) => void;
}
export default function ChartBox({
  chart,
  onSelect
}: IProps) {
  const isPowerUser = useIsPowerUser();
  const editIcon = isPowerUser && <span className="has-text-right has-pointer" style={{
    flexGrow: 1
  }}>
      <Icon isPulled="right" className="fas fa-cog fa-2x" onClick={e => {
      e.stopPropagation();
      e.preventDefault();
      onSelect(chart);
    }} />
    </span>;
  return <Link to={`/charts/${chart.id}`}>
      <Box className="chart-box" isFullWidth style={{
      backgroundColor: chart.color
    }}>
        <Title isDisplay="flex" hasTextColor="white" isSize={6}>
          {chart.title}
          {editIcon}
        </Title>
        <div className="mini-chart">
          <div className="mini-list" style={{
          height: '72%',
          width: '33%'
        }} />
          <div className="mini-list" style={{
          height: '54%',
          width: '33%'
        }} />
          <div className="mini-list" style={{
          height: '23%',
          width: '33%'
        }} />
        </div>
      </Box>
    </Link>;
}