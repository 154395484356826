import { AxiosError } from 'axios';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import api from '../../../../api';
import { IFolder } from '../../../../api/FolderApi';
import ErrorView from '../../../ErrorView';
import LoadSpinner from '../../../loadSpinner/LoadSpinner';
import Folder, { FolderMode } from './Folder';
interface Props {
  onSelect: (item: any) => void;
  selectedId?: string | number;
  checklistStatus: 'open' | 'closed' | 'due' | 'invited';
  mode: FolderMode;
  onUpdateCurrentFolder?: (folderId: string) => void;
  currentFolderId?: string;
  tabIndex: number;
}
const FolderWrapper: FunctionComponent<Props> = ({
  currentFolderId,
  onUpdateCurrentFolder,
  mode,
  onSelect,
  selectedId,
  tabIndex,
  checklistStatus
}) => {
  const [currentFolder, setCurrentFolder] = useState<IFolder | null>();
  const [error, setError] = useState<AxiosError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchFolder = useCallback(async () => {
    setIsLoading(true);
    try {
      const rootFolder = await api.folders.root();
      let currentFolder = rootFolder;
      if (currentFolderId?.length) {
        currentFolder = await api.folders.find(currentFolderId);
      }
      setCurrentFolder(currentFolder);
    } catch (e) {
      setError((e as AxiosError));
    } finally {
      setIsLoading(false);
    }
  }, [currentFolderId]);
  const handleUpdateFolder = useCallback(async (id: string) => {
    const isSameFolder = id === currentFolder?.id;
    onUpdateCurrentFolder(id);
    const folder = await api.folders.find(id);
    setCurrentFolder(folder);
    if (isSameFolder) {
      await fetchFolder();
    }
  }, [currentFolder, fetchFolder, onUpdateCurrentFolder]);
  const handleSelect = useCallback(async ({
    type,
    item
  }) => {
    if (type === 'checklist') {
      onSelect(item);
    } else if (type === 'folder') {
      onUpdateCurrentFolder(item.id);
      await handleUpdateFolder(item.id);
    }
  }, [handleUpdateFolder, onSelect, onUpdateCurrentFolder]);
  useEffect(() => {
    fetchFolder();
  }, [fetchFolder]);
  if (isLoading) {
    return <LoadSpinner className="centered" />;
  }
  return <div className="folder-wrapper">
      {error && <ErrorView error={error} />}
      {currentFolder && <Folder onError={setError} onSelect={handleSelect} folder={currentFolder} mode={mode} selectedId={selectedId} onUpdateFolder={handleUpdateFolder} checklistStatus={checklistStatus} tabIndex={tabIndex} />}
    </div>;
};
export default FolderWrapper;