import { ApiBase } from './ApiBase';
import { IDashboardEntry } from './types/user';
export default class DashboardApi extends ApiBase {
  public async addEntry(type: string) {
    const response = await this.post<IDashboardEntry>('/dashboards/add_entry', {
      dashboard_type: type
    });
    return response.data;
  }
  public async report<T>(id: string) {
    const response = await this.get<T>(`/dashboards/report/${id}`);
    return response.data;
  }
  public removeEntry(id: string) {
    return this.delete(`/dashboards/remove_entry/${id}`);
  }
  public async updateEntry(id: string, updatedEntry: IDashboardEntry) {
    const response = await this.put<IDashboardEntry>(`dashboards/update_entry/${id}`, updatedEntry);
    return response.data;
  }
  public async entries() {
    const response = await this.get<IDashboardEntry[]>('/dashboards/entries');
    return response.data;
  }
}