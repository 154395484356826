import { ApiBase } from './ApiBase'
import { ICloudAttachment } from './types/attachment'

interface ICloudAttachmentRequestData {
  cloud_attachment: ICloudAttachment
  access_token?: string | null
}

export default class CloudAttachmentsApi extends ApiBase {
  public create(data: ICloudAttachment, accessToken?: string | null) {
    delete data._new
    delete data.id
    return this.post<ICloudAttachment>(
      '/cloud_attachments',
      this.buildRequestObject(data, accessToken)
    )
  }

  public update(id: number, data: ICloudAttachment, accessToken?: string | null) {
    return this.put<ICloudAttachment>(
      `/cloud_attachments/${id}`,
      this.buildRequestObject(data, accessToken)
    )
  }

  public deleteCloudAttachment(id: string, accessToken?: string | null) {
    let url = `/cloud_attachments/${id}`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    return this.delete<ICloudAttachment>(url)
  }

  private buildRequestObject(
    data: ICloudAttachment,
    accessToken?: string | null
  ): ICloudAttachmentRequestData {
    data.access_token = data.access_token ? data.access_token : ''
    return {
      cloud_attachment: data,
      // Access token for external access to the checklist
      access_token: accessToken
    }
  }
}
