import clsx from 'clsx';
import Lottie from 'react-lottie-player';
import * as animationData from './round-spinner-1715591047415.json';
interface IProps {
  className?: string;
}
export default function SmallLoadSpinner({
  className
}: IProps) {
  return <span className={clsx(className, 'small-load-spinner')} style={{
    display: 'flex',
    alignItems: 'flex-start'
  }}>
      <Lottie loop play animationData={(animationData as any).default} style={{
      height: 25
    }}
    /*rendererSettings={{
      preserveAspectRatio: 'xMidYMid slice'
    }}*/ />
    </span>;
}