import { AxiosError } from 'axios';
import { Modal, ModalBackground } from 'bloomer';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import api from '../../api';
import { IChart, IChartTag } from '../../api/chartsApi';
import { IChecklistSelectItem } from '../../api/checklistsApi';
import IProject from '../../api/types/project';
import ErrorView from '../../components/ErrorView';
import LoadSpinner from '../../components/loadSpinner/LoadSpinner';
import ITranslationProps from '../../types/translationProps';
import ChartForm from './ChartForm';
interface IOwnProps {
  chartId: number;
  onUpdate: (chart: IChart) => void;
  onCancel: () => void;
  tags: IChartTag[];
  onAddTag: (tag: IChartTag) => void;
  projects: IProject[];
  templates: IChecklistSelectItem[];
  checklists: IChecklistSelectItem[];
  onDelete: () => void;
}
type Props = IOwnProps & ITranslationProps;
interface State {
  loading: boolean;
  chart: IChart;
  error: AxiosError | null;
}
class ChartDetailsEdit extends PureComponent<Props, State> {
  private schema = yup.object({
    title: yup.string().required(this.props.t('chart.validation.title')),
    user_ids: yup.array(),
    team_ids: yup.array(),
    project_ids: yup.array(),
    checklist_ids: yup.array(),
    color: yup.string()
  });
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      chart: null,
      error: null
    };
  }
  public async componentDidMount() {
    try {
      const response = await api.charts.show(this.props.chartId);
      this.setState({
        chart: response.data
      });
    } catch (error) {
      this.setState({
        error: (error as AxiosError)
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  }
  private handleSubmit = async (values: IChart, formikActions: FormikActions<IChart>) => {
    try {
      const response = await api.charts.update(values.id, values);
      this.props.onUpdate(response.data);
    } catch (error) {
      this.setState({
        error: (error as AxiosError)
      });
    } finally {
      formikActions.setSubmitting(false);
    }
  };
  public render() {
    const {
      templates,
      checklists,
      onCancel,
      projects,
      tags
    } = this.props;
    const {
      loading,
      chart,
      error
    } = this.state;
    if (loading) {
      return <LoadSpinner />;
    }
    return <Modal isActive>
        <ModalBackground />
        {error && <ErrorView error={error} />}
        {chart && <Formik validationSchema={this.schema} initialValues={{
        ...chart
      }} onSubmit={this.handleSubmit} render={() => <ChartForm onDelete={this.props.onDelete} onAddTag={this.props.onAddTag} projects={projects} tags={tags} templates={templates} checklists={checklists} onCancel={onCancel} />} />}
      </Modal>;
  }
}
export default withTranslation()(ChartDetailsEdit);