import { Column, Columns, Icon } from 'bloomer';
import ReactAvatar from 'react-avatar';
import { IComment } from '../../../api/types/result';
import DateUtil from '../../../util/DateUtil';
interface Props {
  comment: IComment;
  canDelete: boolean;
  onDeleteComment: (id: number | string) => void;
}
export default function ResultComment({
  comment,
  canDelete,
  onDeleteComment
}: Props) {
  const deleteIcon = canDelete && comment.id ? <span className="delete-comment-icon" onClick={() => onDeleteComment(comment.id)}>
      <Icon key={1} className="fas fa-trash-alt has-pointer fa-lg" />
    </span> : null;
  return <div className="has-position-relative">
      <Columns className="is-marginless comment" isMobile>
        <Column isSize={{
        desktop: 1,
        mobile: 2,
        tablet: 1
      }} className="is-paddingless">
          <div className="avatar">
            <ReactAvatar name={comment.user ? `${comment.user.firstname} ${comment.user.lastname}` : 'Gast'} src={comment.user?.avatar.thumb?.url} round size="35" />
          </div>
        </Column>
        <Column className="is-paddingless">
          <div className="creator-and-time">
            <span className="creator">
              {comment.user ? `${comment.user.firstname} ${comment.user.lastname}` : 'Gast'}
            </span>
            <span className="time-ago">
              {comment.created_at && DateUtil.fromNow(comment.created_at)}
            </span>
          </div>
          <div className="comment-content">
            <div className="comment-text">
              {comment.text}
            </div>
          </div>
        </Column>
      </Columns>
      {deleteIcon}
    </div>;
}