import { Button, Dropdown, DropdownContent, DropdownDivider, DropdownItem, DropdownMenu, DropdownTrigger, Icon } from 'bloomer';
import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import useCorporationSettings from '../../../hooks/useCorporationSettings';
import { isCordova } from '../../../util';
import Dropbox from '../providers/dropbox';
import GoogleDrive from '../providers/google-drive';
import OneDrivePicker from '../providers/one-drive';
interface Props {
  onSelect: (file: any[], accessToken?: string) => void;
}
const AttachmentPicker: FunctionComponent<Props> = ({
  onSelect
}) => {
  const [active, setActive] = useState(false);
  const {
    t
  } = useTranslation();
  const {
    use_onedrive,
    use_google_drive,
    use_dropbox
  } = useCorporationSettings();
  const onFileSelect = useCallback(async (files: any, accessToken: any) => {
    onSelect(files, accessToken);
  }, [onSelect]);
  const hideDropdown = useCallback(() => {
    setActive(false);
  }, []);
  const togglePicker = useCallback(() => {
    setActive(!active);
  }, [active]);
  if (!use_google_drive && !use_dropbox && !use_onedrive) return null;

  // ADF-985: upload documents from cloud in Cordova is not supported yet
  // There is a separate issue for this in Jira: ADF-1011
  if (isCordova()) {
    /* return (
      <Notification isColor="info" isMarginless>
        { t('cloudAttachments.errors.uploadNotSupportedInApp') }
      </Notification>
    ) */
    return null;
  }
  return <div className="cloud-attachment-picker">
      <OutsideClickHandler onOutsideClick={hideDropdown}>
        <Dropdown isActive={active} className="cloud-file-selector">
          <DropdownTrigger>
            <Button isOutlined aria-haspopup="true" aria-controls="dropdown-menu" onClick={togglePicker}>
              <Icon className="fa fa-cloud" isSize="small" />
              <span>
                {t('cloudAttachments.fileUpload.addFromCloudStorage')}
                {' '}
              </span>
              <Icon className="fa fa-angle-down" isSize="small" isAlign="right" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu className="cloud-file-selector">
            <DropdownContent>
              <div style={{
              fontSize: '12px',
              textAlign: 'center',
              color: 'grey'
            }}>
                {t('cloudAttachments.fileUpload.selectFileFrom')}
              </div>
              <DropdownDivider />
              {use_google_drive && <DropdownItem className="has-pointer">
                <GoogleDrive onSelect={onFileSelect} onClick={hideDropdown} />
              </DropdownItem>}
              {use_dropbox && <DropdownItem className="has-pointer">
                  <Dropbox onSelect={onFileSelect} onClick={hideDropdown} />
                </DropdownItem>}
              {use_onedrive && <DropdownItem className="has-pointer">
                  <OneDrivePicker onSelect={onFileSelect} onClick={hideDropdown} />
                </DropdownItem>}
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </OutsideClickHandler>
    </div>;
};
export default AttachmentPicker;