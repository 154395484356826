import { AxiosError } from 'axios';
import { Card, Column, Columns, Tag } from 'bloomer';
import equal from 'deep-equal';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FilterLocation } from '../actions/filterActions';
import api from '../api';
import { IResultSetListDto } from '../api/resultSetApi';
import { IQuestion } from '../api/types/checklist';
import IContact from '../api/types/contact';
import IProject from '../api/types/project';
import IResource from '../api/types/ressource';
import IUser from '../api/types/user';
import TaskCardsStack from '../components/cards/components/TaskCardsStack';
import '../components/cards/styles/index.scss';
import ErrorView from '../components/ErrorView';
import Caption from '../components/home/Caption';
import LoadSpinner from '../components/loadSpinner/LoadSpinner';
import Filters from '../components/widgets/search/Filters';
import filterIcon from '../images/icon_filter_mobile.png';
import nothingTodo from '../images/nothing-todo.png';
import { AlphaprocessDatabase } from '../offlineMode/offlineDb';
import { FilterSettings } from '../reducers/filterReducer';
import { IAlphaprocessState } from '../store';
import ITranslationProps from '../types/translationProps';
import { selectMany } from '../util';
import DateUtil from '../util/DateUtil';
import ResultView from './checklists/ResultView/ResultView';
interface IStateProps {
  user: IUser;
  filter: FilterSettings;
  isOffline: boolean;
}
interface IParams {
  id: string;
  templateId?: string | null;
  tabIndex: string;
}
type Props = IStateProps & ITranslationProps & RouteComponentProps<IParams>;
type ShowType = 'today' | 'overdue' | 'open';
interface IState {
  projects: IProject[];
  resources: IResource[];
  contacts: IContact[];
  error: AxiosError;
  items: any[];
  displayItems: any[];
  isLoading: boolean;
  notification?: string;
  showFilters: boolean;
  show: ShowType;
  openChecklist: number | null;
  todayItemsLength: number;
  overDueItemsLength: number;
  allItemsLength: number;
}
class HomeMobile extends Component<Props, IState> {
  private offlineDb = new AlphaprocessDatabase();
  public state = {
    projects: [],
    error: null,
    items: [],
    displayItems: [],
    isLoading: true,
    showFilters: false,
    show: ('today' as ShowType),
    contacts: [],
    resources: [],
    openChecklist: null,
    todayItemsLength: 0,
    overDueItemsLength: 0,
    allItemsLength: 0
  };
  public async componentDidMount() {
    const {
      id,
      tabIndex
    } = this.props.match.params;
    if (id) {
      this.setState({
        openChecklist: parseInt(id)
      });
      document.getElementsByTagName('html')[0].style.overflowY = null;
    } else {
      // disable vertical scroll on home screen
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    }
    if (tabIndex) {
      this.setState({
        show: (tabIndex as ShowType)
      });
    }
    if (!this.props.isOffline) {
      await this.fetchData();
    } else {
      await this.fetchDataOffline();
    }
    try {
      const projects = await api.projects.select(this.props.user.corporation_id, 0);
      const resources = await api.resources.select();
      const contacts = await api.contacts.all();
      this.setState({
        projects,
        resources,
        contacts
      });
    } catch (error) {
      this.setState({
        error: (error as AxiosError)
      });
    }
  }
  public componentWillUnmount(): void {
    document.getElementsByTagName('html')[0].style.overflowY = null;
  }
  private async fetchDataOffline() {
    // @ts-ignore
    const resultSets = await this.offlineDb.resultSets.toArray();
    const {
      t
    } = this.props;
    for (const rs of resultSets) {
      rs.checklist = await this.offlineDb.checklists.get(rs.checklist_id);
    }
    const items = resultSets.map(rs => {
      const questions: IQuestion[] = selectMany(rs.checklist.areas, a => a.questions);
      const openTasks = questions.filter(q => {
        const result = rs.results.find(r => r.question_id === q.id);
        return result && result.value;
      }).length;
      const tasksOpenText = t('resultSet.tasksOpenInXAreas', {
        tasksOpen: openTasks,
        areasCount: rs.checklist.areas.length
      });
      return {
        id: rs.id,
        checklist_id: rs.checklist_id,
        title: rs.title,
        taskProgress: tasksOpenText,
        progress: rs.progress,
        information: rs.checklist.description || '',
        dueDate: rs.due_date,
        avgTimeNeeded: '1h 23m',
        location: rs.location,
        question_count: questions.length,
        result_count: rs.results.length,
        isOffline: true
      };
    });
    this.setState({
      items,
      isLoading: false
    });
  }
  private async fetchData() {
    const {
      t,
      filter
    } = this.props;
    this.setState({
      isLoading: true
    });
    try {
      const {
        data: resultSets
      } = await api.resultSets.due({
        page: 1,
        tester_ids: filter.selectedTesters.map(x => x.value),
        contact_id: filter.selectedContacts.map(x => x.value),
        title: filter.term,
        ressource_id: filter.selectedRessources.map(x => x.value),
        project_id: filter.selectedProjects.map(x => x.value),
        startDate: filter.startDate ? filter.startDate.toISOString() : null,
        endDate: filter.endDate ? filter.endDate.toISOString() : null,
        team_ids: filter.selectedTeams.map(x => x.value),
        items: 25,
        direction: 'desc',
        sort: 'due_date'
      });
      const tasks = resultSets.map(async (resultSet: IResultSetListDto) => {
        const tasksOpenText = t('resultSet.tasksOpenInXAreas', {
          tasksOpen: resultSet.tasks_remaining,
          areasCount: resultSet.area_count
        });
        const offlineResultSet = await this.offlineDb.resultSets.get(resultSet.id);
        return {
          id: resultSet.id,
          checklist_id: resultSet.checklist_id,
          title: resultSet.title,
          taskProgress: tasksOpenText,
          progress: resultSet.percentage,
          information: resultSet.description || '',
          dueDate: resultSet.due,
          avgTimeNeeded: '1h 23m',
          location: resultSet.location,
          question_count: resultSet.question_count,
          result_count: resultSet.result_count,
          isOffline: !!offlineResultSet
        };
      });
      const items = [];
      for (const x of tasks) {
        items.push(await x);
      }
      const todayItems = items.filter(i => DateUtil.isToday(i.dueDate));
      const overDueItems = items.filter(i => DateUtil.isAfter(new Date(), i.dueDate));
      let initialTab: ShowType = items.length === 0 ? 'today' : 'open';
      if (todayItems.length > 0) {
        initialTab = 'today';
      } else if (overDueItems.length > 0) {
        initialTab = 'overdue';
      }
      this.setState({
        items,
        show: initialTab
      });
    } catch (error) {
      this.setState({
        error: (error as AxiosError)
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  }
  public async componentDidUpdate(prevProps: Props, prevState: IState) {
    if (this.props.match.params.tabIndex !== prevProps.match.params.tabIndex) {
      this.setState({
        show: (this.props.match.params.tabIndex as ShowType)
      });
    }
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        openChecklist: this.props.match.params.id ? parseInt(this.props.match.params.id) : null
      });
    }
    if (prevProps.filter.term !== this.props.filter.term || prevProps.filter.selectedRessources !== this.props.filter.selectedRessources || prevProps.filter.selectedProjects !== this.props.filter.selectedProjects || prevProps.filter.selectedTeams !== this.props.filter.selectedTeams || prevProps.filter.selectedContacts !== this.props.filter.selectedContacts || prevProps.filter.selectedTesters !== this.props.filter.selectedTesters || prevProps.filter.startDate !== this.props.filter.startDate || prevProps.filter.endDate !== this.props.filter.endDate) {
      await this.fetchData();
    }

    // If the view ("Today" or "Overdue") or the items were changed update state for the card stack
    if (this.state.show !== prevState.show || !equal(prevState.items, this.state.items)) {
      // if there are no open checklists for the current user show the "Alles erledigt"
      // message
      const todayItems = this.state.items.filter(i => DateUtil.isToday(i.dueDate));
      const overDueItems = this.state.items.filter(i => DateUtil.isAfter(new Date(), i.dueDate));
      const allItems = this.state.items;
      let displayItems = [];
      switch (this.state.show) {
        case 'today':
          displayItems = todayItems;
          break;
        case 'overdue':
          displayItems = overDueItems;
          break;
        case 'open':
          displayItems = allItems;
      }
      this.setState({
        displayItems,
        overDueItemsLength: overDueItems.length,
        todayItemsLength: todayItems.length,
        allItemsLength: allItems.length
      });
    }
  }
  private doNothing = (): boolean => true;
  private removeCardFromDeck = () => {
    const {
      displayItems = []
    } = this.state;
    const lastCards = displayItems.shift();
    this.setState({
      displayItems
    }, () => {
      const newItems = [...displayItems, lastCards];
      this.setState({
        displayItems: newItems
      });
    });
  };
  private toggleFilters = () => {
    this.setState(prevState => ({
      showFilters: !prevState.showFilters
    }), () => {
      window.scroll(0, 0);
    });
  };
  private getLink(tab: ShowType) {
    const {
      match
    } = this.props;
    if (match.params.id) {
      return `/checklists/${match.params.id}?tab=${tab}`;
    }
    return `/tab/${tab}`;
  }
  private handleResultSetViewClosed = () => {
    this.setState({
      openChecklist: null
    });
    document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    this.props.history.replace('/');
  };
  public render() {
    const {
      t
    } = this.props;
    const {
      displayItems
    } = this.state;
    if (this.state.isLoading) {
      return <LoadSpinner className="centered" />;
    }
    if (this.state.openChecklist) {
      return <div className="checklist-overview">
          <Columns className="overview-columns">
            <Column id="result-column" isSize={12}>
              <Card className="result-view-card">
                <ResultView resultSetId={this.state.openChecklist} onClose={this.handleResultSetViewClosed} context="overview" onFullscreen={null} isFullscreen={false} isExternalAccess={false} tabIndex={0} folderId={null} />
              </Card>
            </Column>
          </Columns>
        </div>;
    }
    const projectOptions = this.state.projects.map(p => ({
      label: p.name,
      value: p.id
    }));
    const filterActive = this.props.filter.startDate || this.props.filter.endDate || this.props.filter.selectedProjects.length || this.props.filter.selectedRessources.length || this.props.filter.selectedContacts.length || this.props.filter.selectedTeams.length || this.props.filter.selectedTesters.length;
    return <div className="mobile-home has-margin-left-10 has-margin-right-10">
        {this.state.error && <ErrorView error={this.state.error} />}
        <div className="has-text-centered">
          <Columns isMobile className="is-marginless-bottom">
            <Column isSize={10} className="is-paddingless-bottom is-flex">
              <Caption isActive={this.state.show === 'today'} to={this.getLink('today')}>
                <Tag className="tooltip is-tooltip-left tasks-todo-tag" isColor="link">
                  {this.state.todayItemsLength}
                </Tag>
                {t('mobileHome.todoToday')}
              </Caption>
              <Caption isActive={this.state.show === 'overdue'} to={this.getLink('overdue')}>
                <Tag className="tooltip is-tooltip-left tasks-todo-tag" isColor="danger">
                  {this.state.overDueItemsLength}
                </Tag>
                {t('mobileHome.overDue')}
              </Caption>
              <Caption isActive={this.state.show === 'open'} to={this.getLink('open')}>
                <Tag className="tooltip is-warning tasks-todo-tag" isColor="link">
                  {this.state.allItemsLength}
                </Tag>
                {t('mobileHome.open')}
              </Caption>
            </Column>
            <Column hasTextAlign="right" isSize={2} className="is-paddingless-bottom toggle-filter-button-container">
              <div className="has-pointer has-position-relative filter-toggle-button" onClick={this.toggleFilters}>
                <img src={filterIcon} alt={t('mobileHome.showFilters')} />
                {!!filterActive && <div className="dot" />}
              </div>
            </Column>
          </Columns>
          <TaskCardsStack cards={displayItems} cardAccepted={this.doNothing} cardRejected={this.doNothing} cardSkipped={this.doNothing} hideCard={this.removeCardFromDeck} />
          <Filters companies={[]} isOpen={this.state.showFilters} projects={projectOptions} resources={this.state.resources} contacts={this.state.contacts} onApply={this.toggleFilters} showTags={false} showCompanies={false} showContacts showDatepicker showProjects showResources showTeams showUsers showStartDate showCreator showCloser={false} tags={[]} filterLocation={FilterLocation.checklists} menuPlacement="bottom" isClosedTab={false} />
        </div>
        {!this.state.items.length && <>
            <div className="artwork">
              <img src={nothingTodo} className="person" alt={t('mobileHome.allDone')} />
            </div>
            <div className="has-text-centered third-caption">
              {!filterActive && t(this.state.show === 'overdue' ? 'mobileHome.noOverdue' : 'mobileHome.allDoneLong')}
            </div>
          </>}
      </div>;
  }
}
const mapState = (state: IAlphaprocessState): IStateProps => ({
  filter: state.filter.checklistFilterSettings,
  user: state.core.user,
  isOffline: state.core.isOffline
});
export default withRouter(connect(mapState)(withTranslation()(HomeMobile)));