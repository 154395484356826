import { Delete } from 'bloomer';
interface IProps {
  url: string;
  userName?: string;
  onDelete?: () => void;
}
const signatureColumn = ({
  url,
  userName,
  onDelete
}: IProps) => <>
    <figure className="signature-column image signature-pad has-margin-bottom-5">
      <img src={url} alt="signatur" />
    </figure>
    <div onClick={onDelete} className={!onDelete ? 'is-hidden' : 'delete-signature'}>
      <Delete />
    </div>
    <div>{userName}</div>
  </>;
export default signatureColumn;