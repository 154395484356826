import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';
interface Props {
  value: string;
}
function resolveError(value: string) {
  const [, name] = value.split('|');
  if (name.startsWith('no value provided for variables')) {
    return 'calculatedValueErrors.missingVariables';
  }
  switch (name) {
    case 'Dentaku::ZeroDivisionError':
      return 'calculatedValueErrors.divisionByZero';
    default:
      return name;
  }
}
const QuestionCalculatedValue: FunctionComponent<Props> = ({
  value
}) => {
  if (value.startsWith('#error')) {
    return <Typography sx={{
      color: 'error.main'
    }}>
        <Trans>{resolveError(value)}</Trans>
      </Typography>;
  }
  return <Typography variant="subtitle2">
      {value}
    </Typography>;
};
export default QuestionCalculatedValue;