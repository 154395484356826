import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { IChecklist, IQuestion } from '../api/types/checklist'
import CREATE_AREA, { CreateArea, CreateAreaPayload } from '../graphql/mutations/createAreaMutation'
import CREATE_DROPDOWN_ITEM, {
  CreateDropdownItemPayload,
  CreateDropdownItemResponse
} from '../graphql/mutations/createDropdownItemMutation'
import CREATE_MEASURE, { CreateMeasurePayload, CreateMeasureResponse } from '../graphql/mutations/createMeasureMutation'
import CREATE_QUESTION, { CreateQuestionPayload, CreateQuestionResponse }
  from '../graphql/mutations/createQuestionMutation'
import CREATE_RULE_ACTION, { CreateRuleActionPayload, CreateRuleActionResponse }
  from '../graphql/mutations/createRuleActionMutation'
import CREATE_RULE, { CreateRulePayload, CreateRuleResponse } from '../graphql/mutations/createRuleMutation'
import CREATE_RULESET, { CreateRuleSetPayload, CreateRuleSetResponse }
  from '../graphql/mutations/createRulesetMutation'
import CREATE_SCHEDULE, {
  CreateSchedulePayload,
  CreateScheduleResponse
} from '../graphql/mutations/createScheduleMutation'
import CREATE_TEMPLATE, { CreateTemplateMutation, CreateTemplatePayload }
  from '../graphql/mutations/createTemplateMutation'

function getTemplateVariables(template: IChecklist, corporationId: number): CreateTemplatePayload {
  return {
    additionalEmails: template.additional_emails,
    addTimestampToImages: template.add_timestamp_to_images,
    attachFiles: template.attach_files,
    continouslyCreate: template.continously_create,
    corporationId,
    description: template.description,
    externalCanCreate: template.external_can_create,
    externalCanEdit: template.external_can_edit,
    externalCanReopen: template.external_can_reopen,
    externalCanRestart: template.external_can_restart,
    employeesCanRestart: false,
    hideProgressInReport: template.hide_progress_in_report,
    incompleteMailTeamIds: template.incomplete_mail_team_ids,
    incompleteMailUserIds: template.incomplete_mail_user_ids,
    isAudit: template.is_audit,
    lockChecklistAfterDueDate: template.lock_checklist_after_due_date,
    reportEmptyResults: template.report_empty_results ?? false,
    sendEmailReport: template.send_email_report,
    showDescription: template.show_description ?? false,
    teamIds: [],
    title: template.title,
    userIds: [],
    useStartDate: template.use_start_date,
    signeeFreeText: template.signee_free_text ?? false,
    folderId: template.folder_id ?? null
  }
}

function getQuestionVariables(question: IQuestion, areaId: number): CreateQuestionPayload {
  return {
    title: question.title,
    description: question.description ?? '',
    areaId,
    defaultValue: question.default_value ?? '',
    disableNa: question.disable_na ?? false,
    displayAsCheckbox: question.display_as_checkbox ?? false,
    enableAttachments: question.enable_attachments ?? false,
    enableComments: question.enable_comments ?? false,
    enableSpeechToText: question.enable_speech_to_text ?? false,
    excludeFromProgress: question.exclude_from_progress ?? false,
    hasAttachmentsRequired: question.has_attachments_required ?? false,
    hasAuditPoints: question.has_audit_points ?? false,
    hasDefaultToday: question.has_default_today ?? false,
    hasTime: question.has_time ?? false,
    infotext: question.infotext ?? '',
    isDescriptionCollapsed: question.is_description_collapsed ?? false,
    isHorizontal: question.is_horizontal ?? false,
    isMulti: question.is_multi ?? false,
    isRequired: question.is_required ?? false,
    max: question.max ?? null,
    min: question.min ?? null,
    position: question.position,
    questionType: question.question_type ?? '',
    isTextfield: question.is_textfield ?? false,
    isTimespan: question.is_timespan ?? false,
    showTasksButton: question.show_tasks_button ?? false,
    signeeFreeText: question.signee_free_text ?? false,
    unit: question.unit ?? ''
  }
}

function countSteps(template: IChecklist) {
  let count = 1
  if (template.areas) {
    count += template.areas.length
    const questions = template.areas.flatMap((area) => area.questions)
    count += questions.length
    const ruleSets = questions.flatMap((x) => x.rule_sets ?? [])
    count += ruleSets.length
  }
  if (template.schedules) {
    count += template.schedules.length
  }
  if (template.measures) {
    count += template.measures.length
  }

  return count
}

function checkForErrors(response: {errors: string[] } | undefined) {
  if (!response) throw new Error('No response')
  if (response.errors && response.errors.length > 0) {
    throw new Error(response.errors.join('\n'))
  }
}

export default function useTemplateImporter(corporationId: number, setProgress: (progress: number) => void) {
  const [createTemplate] = useMutation<CreateTemplateMutation, CreateTemplatePayload>(CREATE_TEMPLATE)
  const [createArea] = useMutation<CreateArea, CreateAreaPayload>(CREATE_AREA)
  const [createQuestion] = useMutation<CreateQuestionResponse, CreateQuestionPayload>(CREATE_QUESTION)
  const [createMeasure] = useMutation<CreateMeasureResponse, CreateMeasurePayload>(CREATE_MEASURE)
  const [createDropdownItem] = useMutation<CreateDropdownItemResponse, CreateDropdownItemPayload>(CREATE_DROPDOWN_ITEM)
  const [createSchedule] = useMutation<CreateScheduleResponse, CreateSchedulePayload>(CREATE_SCHEDULE)
  const [createRuleSet] = useMutation<CreateRuleSetResponse, CreateRuleSetPayload>(CREATE_RULESET)
  const [createRule] = useMutation<CreateRuleResponse, CreateRulePayload>(CREATE_RULE)
  const [createRuleAction] = useMutation<CreateRuleActionResponse, CreateRuleActionPayload>(CREATE_RULE_ACTION)

  return useCallback(async (template: IChecklist) => {
    let progress = 0
    const steps = countSteps(template)

    const importedTemplate = await createTemplate({
      variables: getTemplateVariables(template, corporationId)
    })

    checkForErrors(importedTemplate.data?.createTemplate)

    setProgress((++progress / steps) * 100)

    const measureMap: Record<string, string> = {}

    if (template.measures) {
      for (const measure of template.measures) {
        const importedMeasure = await createMeasure({
          variables: {
            title: measure.title,
            measure: measure.measure,
            checklistId: importedTemplate.data?.createTemplate.template.id ?? 0,
            position: measure.position,
            detailedAnswer: measure.detailed_answer,
            severity: measure.severity,
            code: measure.code
          }
        })
        checkForErrors(importedMeasure.data?.createMeasure)
        measureMap[measure.id] = importedMeasure.data?.createMeasure.measure.id ?? ''
        setProgress((++progress / steps) * 100)
      }
    }

    if (template.schedules) {
      for (const schedule of template.schedules) {
        const importedSchedule = await createSchedule({
          variables: {
            title: schedule.title,
            checklistId: importedTemplate.data?.createTemplate?.template?.id ?? 0,
            autostart: schedule.autostart,
            contactId: schedule.contact_id,
            dueDate: schedule.due_date as any,
            location: schedule.location,
            repeat: schedule.repeat,
            projectId: schedule.project_id,
            ressourceId: schedule.ressource_id,
            startDate: schedule.start_date as any,
            testerId: null
          }
        })
        checkForErrors(importedSchedule.data?.createSchedule)
        setProgress((++progress / steps) * 100)
      }
    }

    const areaMap: Record<number, number> = {}
    const questionMap: Record<number, number> = {}

    for (const area of template.areas) {
      const importedArea = await createArea({
        variables: {
          title: area.title,
          description: area.description ?? '',
          allowClone: area.allow_clone ?? false,
          checklistId: importedTemplate.data?.createTemplate.template.id ?? 0,
          hasPageBreak: area.has_page_break ?? false,
          position: area.position
        }
      })
      checkForErrors(importedArea.data?.createArea)
      areaMap[area.id as number] = parseInt(importedArea?.data?.createArea?.area?.id ?? '0', 10)
      for (const question of area.questions) {
        const importedQuestion = await createQuestion({
          variables: getQuestionVariables(question, areaMap[area.id])
        })
        questionMap[question.id] = parseInt(importedQuestion?.data?.createQuestion?.question?.id ?? '0', 10)
        setProgress((++progress / steps) * 100)
        if (question.question_dropdown_items) {
          for (const questionDropdownItem of question.question_dropdown_items) {
            await createDropdownItem({
              variables: {
                title: questionDropdownItem.title,
                position: questionDropdownItem.position,
                hasText: questionDropdownItem.has_text ?? false,
                measureId: questionDropdownItem.measure_id ? measureMap[questionDropdownItem.measure_id] : null,
                questionId: parseInt(importedQuestion.data?.createQuestion.question.id ?? '0', 10)
              }
            })
          }
        }
        checkForErrors(importedQuestion.data?.createQuestion)
      }
      setProgress((++progress / steps) * 100)
    }

    const ruleSets = template.areas.flatMap((area) => area.questions)
      .filter((question) => question.rule_sets && question.rule_sets.length > 0)
      .flatMap((question) => question.rule_sets)

    for (const ruleSet of ruleSets) {
      const importedRuleSet = await createRuleSet({
        variables: {
          questionId: questionMap[ruleSet.question_id],
          position: ruleSet.position
        }
      })
      checkForErrors(importedRuleSet.data?.createRuleSet)
      if (ruleSet.rules) {
        for (const rule of ruleSet.rules) {
          const importedRule = await createRule({
            variables: {
              expected: rule.expected,
              linkOperator: rule.link_operator,
              ruleSetId: importedRuleSet.data?.createRuleSet.ruleSet.id ?? '',
              position: rule.position,
              operator: rule.operator
            }
          })
          checkForErrors(importedRule.data?.createRule)
        }
      }
      if (ruleSet.rule_action) {
        const importedRuleAction = await createRuleAction({
          variables: {
            ruleSetId: importedRuleSet.data?.createRuleSet.ruleSet.id ?? '',
            actionType: ruleSet.rule_action.action_type,
            areaIds: ruleSet.rule_action.target_area_ids?.map((areaId) => areaMap[areaId]) ?? [],
            duplicateMode: ruleSet.rule_action.duplicate_mode,
            duplicateQuestionId: ruleSet.rule_action.duplicate_question_id
              ? questionMap[ruleSet.rule_action.duplicate_question_id] ?? null : null,
            emailReceiverAddresses: ruleSet.rule_action.email_receiver_addresses ?? '',
            emailReceiverTeamIds: ruleSet.rule_action.email_receiver_team_ids ?? '',
            emailReceiverUserIds: ruleSet.rule_action.email_receiver_user_ids ?? '',
            emailSubject: ruleSet.rule_action.email_subject ?? '',
            emailText: ruleSet.rule_action.email_text ?? '',
            questionIds: ruleSet.rule_action.target_question_ids?.map((questionId) => questionMap[questionId]) ?? [],
            targetAreaCount: ruleSet.rule_action.target_area_count ?? 0,
            value: ruleSet.rule_action.value ?? ''
          }
        })
        setProgress((++progress / steps) * 100)
        checkForErrors(importedRuleAction.data?.createRuleAction)
      }
    }
    return importedTemplate.data?.createTemplate.template.id ?? 0
  }, [corporationId, createArea, createDropdownItem, createMeasure, createQuestion, createRule,
    createRuleAction, createRuleSet, createSchedule, createTemplate, setProgress])
}
