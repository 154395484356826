import { Autocomplete, TextField } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ReactSelect, { MenuPlacement } from 'react-select';
import IUser from '../../../api/types/user';
import { reactSelectMaxMenuHeight } from '../../../constants';
interface IProps {
  onChange: (value: number | number[]) => void;
  selected: number | number[];
  isMulti?: boolean;
  placeholder: string;
  name?: string;
  id?: string;
  setSelectedAsValue?: boolean;
  onMenuOpen?: any;
  menuPlacement?: MenuPlacement | null;
  readonly?: boolean;
  userOverrides?: IUser[];
}
interface NewUserSelectProps {
  selected: number[];
  label: string;
  onChange: (selected: number[]) => void;
  disabled?: boolean;
}
export function NewUserSelect({
  selected,
  label,
  onChange,
  disabled
}: NewUserSelectProps) {
  const {
    data
  } = useQuery<FetchUsersResponse>(FETCH_USERS_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const users = data?.users ?? [];
  return <Autocomplete fullWidth value={users.filter(x => selected.includes(parseInt(x.id)))} options={users} multiple disabled={disabled} getOptionLabel={user => typeof user === 'string' ? user : `${user.fullName} <${user.email}>`} renderInput={params => <TextField {...params} label={label} />} onChange={(event, newValue) => {
    onChange(newValue.map(x => typeof x === 'string' ? parseInt(x) : parseInt(x.id)));
  }} />;
}
const FETCH_USERS_QUERY = gql`
    query UserSelect {
        users {
            email
            id
            fullName
        }
    }
`;
interface FetchUsersResponse {
  users: Array<{
    id: string;
    email: string;
    fullName: string;
  }>;
}
export default function UserSelect(props: IProps) {
  const {
    t
  } = useTranslation();
  const {
    data
  } = useQuery<FetchUsersResponse>(FETCH_USERS_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const options = (props.userOverrides ?? data?.users ?? []).map(u => ({
    value: u.id,
    label: `${u.fullName ?? u.full_name ?? ''} <${u.email}>`
  }));
  let selected = Array.isArray(props.selected) ? options.filter(o => (props.selected as number[]).includes(o.value)) : options.find(o => o.value === props.selected);
  if (props.selected === null) {
    selected = null;
  }
  const handleChange = (values: any) => {
    if (Array.isArray(values)) {
      props.onChange(values.map(v => v.value));
    } else {
      props.onChange(values.value);
    }
  };
  return <ReactSelect options={options} isMulti={props.isMulti} onChange={handleChange} value={props.setSelectedAsValue ? selected : undefined} placeholder={props.placeholder} isSearchable maxMenuHeight={reactSelectMaxMenuHeight} className="dropdown-select-container teast" classNamePrefix="dropdown-select" name={props.name} id={props.id} onMenuOpen={props.onMenuOpen} noOptionsMessage={() => t('common.noOptions')} isDisabled={props.readonly} menuPortalTarget={document.body} menuPlacement={props.menuPlacement ?? 'auto'} styles={{
    menuPortal: base => ({
      ...base,
      zIndex: 9999
    })
  }} />;
}