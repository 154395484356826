import { gql } from '@apollo/client'

export interface CreateDropdownItemPayload {
  title: string;
  questionId: number;
  position: number;
  hasText: boolean;
  measureId: string | null;
}

export interface DropdownItem {
  id: string;
}

export interface CreateDropdownItem {
  dropdownItem: DropdownItem;
  errors: string[]
}

export interface CreateDropdownItemResponse {
  createDropdownItem: CreateDropdownItem;
}

const CREATE_DROPDOWN_ITEM = gql`
mutation createDropDownItem(
  $title: String!
  $questionId: ID!
  $position: Int!
  $hasText: Boolean!
  $measureId: ID
) {
  createDropdownItem(
    title: $title
    questionId: $questionId
    position: $position
    hasText: $hasText
    measureId: $measureId
  ) {
    dropdownItem {
      id
    }
    errors
  }
}
`

export default CREATE_DROPDOWN_ITEM
