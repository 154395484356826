import { Column, Icon } from 'bloomer';
import { useCallback } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ICloudAttachment, isImage } from '../../api/types/attachment';
import { isCordova } from '../../util';
interface Props {
  file: ICloudAttachment;
  onDelete?: (file: ICloudAttachment) => void | null;
}
const CloudFile: React.FunctionComponent<Props> = ({
  file,
  onDelete
}) => {
  const {
    t
  } = useTranslation();
  const cordova = isCordova();
  let {
    url
  } = file;
  const handleOpen = useCallback(() => {
    window.open(url, '_system');
  }, [url]);
  if (file.access_token) {
    url = `${url}&access_token=${file.access_token}`;
  }
  return <Column className="image-column attachment-thumb" isPaddingless key={file.id} isSize={{
    mobile: '1/2',
    tablet: '1/3',
    desktop: '1/4'
  }}>
      {!!onDelete && <span className="delete-icon" onClick={() => onDelete(file)} data-tip={t('Delete')} data-for="main-tooltip">
          <Icon className="fas fa-trash-alt has-pointer fa-lg" />
        </span>}
      <a href={url} target="_blank" rel="noopener noreferrer" onClick={cordova ? handleOpen : undefined}>
        <Icon className={isImage(file.name) ? 'far fa-file-image ' : 'far fa-file'} />
        <div className="filename">
          {file.name}
        </div>
      </a>
    </Column>;
};
export default CloudFile;