export interface ISetOfflineModeAction {
  type: 'CORE_SET_OFFLINE_MODE'
  isOffline: boolean
}

export const setOfflineMode = (isOffline: boolean): ISetOfflineModeAction => ({
  type: 'CORE_SET_OFFLINE_MODE',
  isOffline
})

export type OfflineActions = ISetOfflineModeAction
