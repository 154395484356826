import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { fallbackCorporationSetting } from '../constants'
import { IAlphaprocessState } from '../store'

export default function useCorporationSettings() {
  const user = useSelector((state: IAlphaprocessState) => state.core.user)

  return useMemo(() => {
    if (user && user.corporation && user.corporation.corporation_setting) {
      return user.corporation.corporation_setting
    }

    return fallbackCorporationSetting
  }, [user])
}
