import { styled } from '@mui/material/styles';
import { Dropdown, DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger } from 'bloomer';
import clsx from 'clsx';
import ReactAvatar from 'react-avatar';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { MenuPlacement } from 'react-select';
import { logout } from '../../actions/authActions';
import { FilterLocation } from '../../actions/filterActions';
import { useCurrentUser, useIsPowerUser } from '../../hooks/authHooks';
import useCorporationSettings from '../../hooks/useCorporationSettings';
import useIsOffline from '../../hooks/useIsOffline';
import { AdminOnlyView } from '../AdminOnlyView';
import ActionTableSearch from '../widgets/search/ActionTableSearch';
const Root = styled('div')(({
  theme
}) => ({
  [theme.breakpoints.down('sm')]: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.modal,
    background: '#f3f5f9',
    width: '100%'
  }
}));
export default function TopMenu() {
  const user = useCurrentUser();
  const corporationSettings = useCorporationSettings();
  const {
    t
  } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isOffline = useIsOffline();
  const isAdmin = useIsPowerUser();
  const location = useLocation();
  const renameResources = corporationSettings.rename_resources;
  const routeMatchChecklist = useRouteMatch('/checklists/:id?');

  // Is templates overview and not template editor
  const routeMatchTemplatesOverview = useRouteMatch('/templates');
  const routeMatchTemplatesEditor = useRouteMatch('/templates/editor/:id?');
  const routeMatchTemplates = !!routeMatchTemplatesOverview && !routeMatchTemplatesEditor?.isExact;
  const routeMatchOverview = useRouteMatch<{
    id: string;
    tabIndex: string;
  }>('/checklists/tab/:tabIndex/:id?');
  const routeMatchPeople = useRouteMatch('/people');
  const routeMatchResources = useRouteMatch('/resources');
  const routeMatchBoards = useRouteMatch('/boards');
  const routeMatchTasks = useRouteMatch('/boards/:id');
  const routeMatchCalendar = useRouteMatch('/calendar');
  const routeMatchContacts = useRouteMatch('/contacts');
  const routeMatchCompanies = useRouteMatch('/companies');
  const routeMatchCharts = useRouteMatch('/charts');
  const routeMatchChartsEdit = useRouteMatch('/charts/:id');
  const routeMatchChat = useRouteMatch('/chat');
  const routeMatchNewTemplateStoreEntry = useRouteMatch('/template_store/new');
  const matchChecklistOpen = useRouteMatch('/checklists/tab/:tabIndex/folders/:folderId/:checklistId/audit/:id/');
  const matchChecklistOpenSimple = useRouteMatch('/checklists/:id');
  const matchChecklistTabUrl = useRouteMatch('/checklists/tab/:tabIndex/');
  const matchChecklistAreaOpen = useRouteMatch('/checklists/tab/:tabIndex/folders/:folderId/:checklistId/audit/:id/areas/:areaId/position/:areaPosition');
  const showSearch = !!routeMatchChecklist || routeMatchTemplates || !!routeMatchOverview || !!routeMatchBoards || location.pathname === '/' || !!routeMatchPeople || !!routeMatchResources || !!routeMatchCalendar || !!routeMatchCharts && !routeMatchChartsEdit || !!routeMatchContacts || !!routeMatchCompanies || routeMatchChat || routeMatchNewTemplateStoreEntry || location.pathname.startsWith('/tab/') || location.pathname.startsWith('/checklists/tab/');
  let searchPlaceholder = t('searchChecklists');
  const className = !(routeMatchResources || routeMatchBoards || routeMatchCalendar) ? 'top-menu-default' : 'top-menu-small';
  let filterLocation: FilterLocation = FilterLocation.checklists;
  if (routeMatchPeople) {
    searchPlaceholder = t('searchPeople');
    filterLocation = FilterLocation.people;
  } else if (routeMatchResources) {
    searchPlaceholder = t('searchResources');
    filterLocation = FilterLocation.resources;
  } else if (routeMatchCalendar) {
    searchPlaceholder = t('searchCalendar');
    filterLocation = FilterLocation.calendar;
  } else if (routeMatchTasks) {
    searchPlaceholder = t('searchTasks');
    filterLocation = FilterLocation.tasks;
  } else if (routeMatchBoards) {
    searchPlaceholder = t('searchBoards');
    filterLocation = FilterLocation.boards;
  } else if (routeMatchCharts) {
    searchPlaceholder = t('searchCharts');
    filterLocation = FilterLocation.charts;
  } else if (routeMatchContacts) {
    searchPlaceholder = t('searchContacts');
    filterLocation = FilterLocation.contacts;
  } else if (routeMatchTemplates) {
    searchPlaceholder = t('searchTemplates');
    filterLocation = FilterLocation.checklists;
  } else if (routeMatchCompanies) {
    filterLocation = FilterLocation.companies;
    searchPlaceholder = t('company.search');
  }
  const menuPlacement = routeMatchCalendar || routeMatchBoards ? 'bottom' : 'top';
  const hideFilter = !!routeMatchPeople || !!routeMatchCompanies || !!routeMatchCharts;
  if (isMobileOnly && !showSearch || location.pathname.startsWith('/todos')) {
    return null;
  }
  if (isMobileOnly && (matchChecklistOpen || matchChecklistAreaOpen || matchChecklistOpenSimple && !matchChecklistTabUrl)) {
    return null;
  }
  return <Root className={`top-menu ${searchPlaceholder ? 'top-menu-calendar' : null} ${className}`}>
      <div className="top-menu-section">
        <ActionTableSearch hideFilter={hideFilter} showUsers={!routeMatchResources && !routeMatchBoards && !routeMatchContacts} showTeams={!routeMatchResources && !routeMatchContacts} showStartDate={!!routeMatchChecklist} showCreator={!!routeMatchChecklist} showCloser={!!routeMatchChecklist} showResources={!routeMatchResources && !routeMatchTemplates && !routeMatchContacts} showProjects={!renameResources && !routeMatchResources && !routeMatchContacts} showDatepicker={!routeMatchResources && !routeMatchBoards && !routeMatchCalendar && !routeMatchContacts} showContacts={!routeMatchResources && !routeMatchBoards && !routeMatchTemplates && !routeMatchContacts} showTags={!!routeMatchResources && !routeMatchContacts} showCompanies={!!routeMatchContacts && !corporationSettings.use_companies} searchPlaceholder={searchPlaceholder} menuPlacement={(menuPlacement as MenuPlacement)} filterLocation={filterLocation} isClosedTab={routeMatchOverview?.params?.tabIndex === '1'} disabled={!showSearch} />
      </div>
      <div className="is-right is-hidden-mobile">
        <span className={clsx('icon', 'is-large', 'beamerTrigger', isOffline && 'is-disabled')}>
          <i className="fas fa-bell fa-2x" />
        </span>
        <Dropdown isHoverable>
          <DropdownTrigger className={clsx('with-chevron', 'has-pointer', isOffline && 'is-disabled')}>
            {t('topMenu.create')}
          </DropdownTrigger>
          <DropdownMenu>
            <div className="dropdown-content">
              <DropdownItem isHidden={!isAdmin}>
                <Link className={isOffline ? 'is-disabled' : undefined} to="/projects/new">
                  {t('topMenu.project')}
                </Link>
              </DropdownItem>
              <DropdownItem isHidden={!isAdmin}>
                <Link className={isOffline ? 'is-disabled' : undefined} to="/templates/editor">
                  {t('topMenu.template')}
                </Link>
              </DropdownItem>
              <DropdownItem isHidden={!user.corporation?.corporation_setting?.use_resources}>
                <Link className={isOffline ? 'is-disabled' : undefined} to="/resources/new">
                  {t('topMenu.resource')}
                </Link>
              </DropdownItem>
              <DropdownItem isHidden={!user.corporation?.corporation_setting?.use_contacts}>
                <Link className={isOffline ? 'is-disabled' : undefined} to="/contacts/new">
                  {t('topMenu.contact')}
                </Link>
              </DropdownItem>
              <DropdownItem isHidden={!user.corporation?.corporation_setting?.use_contacts}>
                <Link className={isOffline ? 'is-disabled' : undefined} to="/companies/new">
                  {t('topMenu.company')}
                </Link>
              </DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
        <Dropdown isAlign="right" style={{
        marginTop: 0
      }} isHoverable>
          <DropdownTrigger className="has-pointer">
            <ReactAvatar className="top-menu-item is-right is-online" src={user && user.avatar.thumb.url} name={user.full_name} round size="56px" />
            <DropdownMenu>
              <DropdownContent>
                <DropdownItem>
                  <Link className={isOffline ? 'is-disabled' : undefined} to="/profile">
                    {t('menu.profile')}
                  </Link>
                </DropdownItem>
                <AdminOnlyView>
                  <DropdownItem>
                    <Link className={isOffline ? 'is-disabled' : undefined} to="/team/invite">
                      {t('menu.invite')}
                    </Link>
                  </DropdownItem>
                </AdminOnlyView>
                <DropdownItem className={isOffline ? 'is-disabled' : undefined} id="logout-button" onClick={isOffline ? undefined : () => {
                history.push('/login');
                dispatch(logout());
              }}>
                  <a>{t('Logout')}</a>
                </DropdownItem>
              </DropdownContent>
            </DropdownMenu>
          </DropdownTrigger>
        </Dropdown>
      </div>
    </Root>;
}