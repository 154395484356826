import { Button, Table } from 'bloomer';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import * as React from 'react';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import DataTableHeader, { IDataTableHeaderProps } from './DataTableHeader';
import DataTablePagination, { IDataTablePaginationProps } from './DataTablePagination';
import { DataTableRow } from './DataTableRow';
import '../../sass/datatable.sass';
export interface DataTableData {
  id: string | number;
}
interface MultiSelectAction<T> {
  label: string;
  callback: (items: T[]) => void;
  show?: boolean;
}
interface IOwnProps<T extends DataTableData> extends IDataTableHeaderProps<T>, IDataTablePaginationProps {
  data: T[];
  selectedId?: string | number;
  isLoading: boolean;
  hasLayoutFixed?: boolean | null;
  selectable?: boolean;
  multiSelectActions?: Array<MultiSelectAction<T>>;
}

/**
 * Component for tabular data includes pagination.
 * This component only renders data it does not handle any data manipulation.
 */
export default function DataTable<T extends DataTableData>(props: IOwnProps<T>) {
  const {
    data,
    selectable = false,
    actions,
    headers,
    selectedId,
    sortColumn,
    sortDir,
    onSort,
    isLoading,
    hasLayoutFixed,
    totalPages,
    onPagination,
    currentPage,
    multiSelectActions = []
  } = props;
  const [selected, setSelected] = useState<T[]>([]);
  const visibleHeaders = useMemo(() => headers.filter(x => !x.hidden), [headers]);
  const handleSelectAll = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(data.map(x => x));
    } else {
      setSelected([]);
    }
  }, [data]);
  const handleSelect = useCallback((checked: boolean, item: T) => {
    if (checked) {
      setSelected([...selected, item]);
    } else {
      setSelected(selected.filter(x => x !== item));
    }
  }, [selected]);
  const rows = useMemo(() => data.map(x => <DataTableRow selectable={selectable} selected={selected.includes(x)} actions={actions} onSelect={e => handleSelect(e.target.checked, x)} headers={visibleHeaders} isSelected={selectedId === x.id} data={x} key={x.id} dataId={(x.id as string)} />), [actions, data, handleSelect, visibleHeaders, selectable, selected, selectedId]);
  useEffect(() => {
    setSelected([]);
  }, [data]);
  useLayoutEffect(() => {
    const table = document.getElementsByTagName('table')[0];
    const root = document.getElementById('root-container');
    const ths = document.getElementsByTagName('th');
    for (let i = 0; i < ths.length; i++) {
      const {
        width
      } = ths[i].style;
      const tds = table.querySelectorAll<HTMLElement>(`td:nth-child(${i})`);
      for (const td of tds) {
        const calcWidth = root.clientWidth * (parseInt(width.replace('%', ''), 10) / 100) - 50;
        td.style.maxWidth = `${calcWidth}px`;
      }
    }
  });
  const body = rows.length > 0 ? rows : <tr>
      <td colSpan={visibleHeaders.length + (actions.length ? 1 : 0) + 1}>
        <div className="no-data"><Trans>datatable.noData</Trans></div>
      </td>
    </tr>;
  return <>
      <Table className={clsx('data-table', hasLayoutFixed && 'has-layout-fixed')} isFullWidth>
        <DataTableHeader onSelectAll={handleSelectAll} selectable={selectable} actions={actions} headers={visibleHeaders} sortColumn={sortColumn} sortDir={sortDir} onSort={onSort} />
        <tbody>
          {!isLoading && body}
        </tbody>
      </Table>
      {(totalPages > 1 || selected.length > 0) && <div className="data-table-footer">
        {totalPages > 1 && <DataTablePagination currentPage={currentPage} onPagination={onPagination} totalPages={totalPages} />}
          {selected.length > 0 && <div className="data-table-multiselect-actions">
            {multiSelectActions.filter(x => x.show !== false).map(action => <Button isLink key={action.label} onClick={() => action.callback(selected)}>
                {action.label}
              </Button>)}
          </div>}
      </div>}
    </>;
}