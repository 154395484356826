import { Button, Icon, Tag } from 'bloomer';
import interact from 'interactjs';
import { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Trans, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import ITranslationProps from '../../../types/translationProps';
import DateUtil from '../../../util/DateUtil';
import { ICard, ITaskCardsStackProps } from './TaskCardsStack';
const ACCEPT_CARD = 'cardAccepted';
const REJECT_CARD = 'cardRejected';
const SKIP_CARD = 'cardSkipped';
const configInteract = {
  interactMaxRotation: 15,
  interactOutOfSightXCoordinate: 1500,
  interactOutOfSightYCoordinate: 1800,
  interactYThreshold: 150,
  interactXThreshold: 100
};
interface ITaskCardProps {
  card: ICard;
  isCurrent: boolean;
}
interface ITaskCardState {
  isShowing: boolean;
  isInteractAnimating: boolean;
  isInteractDragged: boolean;
  interactPosition: {
    x: number;
    y: number;
    rotation: number;
  };
}
type ITaskProps = ITaskCardProps & ITaskCardsStackProps & ITranslationProps & RouteComponentProps;
class TaskCard extends Component<ITaskProps, ITaskCardState> {
  public state = {
    isShowing: true,
    isInteractAnimating: true,
    isInteractDragged: false,
    interactPosition: {
      x: 0,
      y: 0,
      rotation: 0
    }
  };
  public ref: HTMLDivElement | null = null;
  public componentDidMount() {
    if (!this.ref) {
      return null;
    }
    interact(this.ref).draggable({
      onstart: () => {
        this.setState({
          isInteractAnimating: false
        });
      },
      onmove: event => {
        const {
          interactPosition
        } = this.state;
        const {
          interactMaxRotation,
          interactXThreshold
        } = configInteract;
        const x = interactPosition.x + event.dx;
        const y = interactPosition.y + event.dy;
        let rotation = interactMaxRotation * (x / interactXThreshold);
        if (rotation > interactMaxRotation) {
          rotation = interactMaxRotation;
        } else if (rotation < -interactMaxRotation) {
          rotation = -interactMaxRotation;
        }
        this.interactSetPosition({
          x,
          y,
          rotation
        });
      },
      onend: () => {
        const {
          interactPosition
        } = this.state;
        const {
          x,
          y
        } = interactPosition;
        const {
          interactXThreshold,
          interactYThreshold
        } = configInteract;
        this.setState({
          isInteractAnimating: true
        });
        if (x > interactXThreshold) {
          this.playCard(ACCEPT_CARD);
        } else if (x < -interactXThreshold) {
          this.playCard(REJECT_CARD);
        } else if (y > interactYThreshold) {
          this.playCard(SKIP_CARD);
        } else {
          this.resetCardPosition();
        }
      }
    });
  }
  public componentWillUnmount() {
    interact(this.ref || '').unset();
  }
  public hideCard() {
    setTimeout(() => {
      this.setState({
        isShowing: false
      });
      this.props.hideCard();
    }, 300);
  }
  public playCard(interaction: string) {
    const {
      interactOutOfSightXCoordinate,
      interactOutOfSightYCoordinate,
      interactMaxRotation
    } = configInteract;
    this.interactUnsetElement();
    switch (interaction) {
      case ACCEPT_CARD:
        this.props[ACCEPT_CARD]();
        this.interactSetPosition({
          x: interactOutOfSightXCoordinate,
          rotation: interactMaxRotation
        });
        break;
      case REJECT_CARD:
        this.props[REJECT_CARD]();
        this.interactSetPosition({
          x: -interactOutOfSightXCoordinate,
          rotation: -interactMaxRotation
        });
        break;
      case SKIP_CARD:
        this.props[SKIP_CARD]();
        this.interactSetPosition({
          y: interactOutOfSightYCoordinate
        });
        break;
    }
    this.hideCard();
  }
  public interactSetPosition(coordinates: {
    x?: number;
    y?: number;
    rotation?: number;
  }) {
    const {
      x = 0,
      y = 0,
      rotation = 0
    } = coordinates;
    this.setState({
      interactPosition: {
        x,
        y,
        rotation
      }
    });
  }
  public interactUnsetElement() {
    interact(this.ref || '').unset();
    this.setState({
      isInteractDragged: true
    });
  }
  public resetCardPosition() {
    this.interactSetPosition({
      x: 0,
      y: 0,
      rotation: 0
    });
  }
  public onOpenChecklist = event => {
    event.preventDefault();
    const {
      card
    } = this.props;

    // remove scroll lock
    document.getElementsByTagName('html')[0].style.overflowY = null;
    this.props.history.push(`/checklists/${card.id}?ref=home`);
  };
  public render() {
    const {
      card,
      isCurrent,
      t
    } = this.props;
    const {
      isInteractAnimating,
      isInteractDragged,
      interactPosition
    } = this.state;
    const transformString = () => {
      if (!isInteractAnimating || isInteractDragged) {
        const {
          x,
          y,
          rotation
        } = interactPosition;
        return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
      }
      return null;
    };
    const dueDate = card.dueDate ? new Date(Date.parse(card.dueDate)) : new Date();
    const isOverdue = dueDate < new Date();
    const dueDateIconClasses = new Date() < new Date(dueDate) ? 'fa fa-info-circle fa_orange' : 'fa fa-info-circle fa_red';
    return <div ref={ref => this.ref = ref} className={`card
          ${isCurrent ? ' isCurrent' : ''}
          ${isInteractAnimating ? ' isAnimating' : ''}
        `} style={{
      transform: transformString() || ''
    }}>
        <div className="cardContent">
          <div className="cardHeader">
            <div className="cardTitle is-inline-block">
              <div className="is-ellipsed">
                {card.title}
              </div>
              {card.isOffline && <Tag isColor="warning" className="has-margin-right-5">
                <Trans>Offline</Trans>
              </Tag>}
              {isOverdue && <Tag isColor="danger">
                  <Trans>result.overDue</Trans>
                </Tag>}
            </div>
            <div className="cardProgress">
              <CircularProgressbar value={card.progress} strokeWidth={15} />
            </div>
          </div>
          <div className="taskProgress">
            <div className="task">{card.taskProgress}</div>
            <div className="percent">
              {card.progress}
              %
            </div>
          </div>
          <div className="info" dangerouslySetInnerHTML={{
          __html: card.information
        }} />
        </div>
        <div className="cardFooter">
          <div className="item">
            <Icon className={dueDateIconClasses} />
            {t('Due')}
            {': '}
            {DateUtil.format(dueDate, 'yyyy-MM-dd HH:mm')}
          </div>
          {/* ausgeblendet, weil Feature noch nicht vorhanden */}
          <div className="item is-hidden">
            <Icon className="far fa-clock fa_orange" />
            {t('resultSet.averageTime')}
            {': '}
            {card.avgTimeNeeded}
          </div>
          <div className="item">
            <Icon className="fa fa-map-marker-alt fa_orange" />
            {t('resultSet.locationShort')}
            {': '}
            {card.location}
          </div>
          <div className="item">
            <div className="is-100">
              <Button isColor="info" className="btnOpen" onClick={this.onOpenChecklist}>
                {t('common.open')}
              </Button>
            </div>
          </div>
        </div>
      </div>;
  }
}
export default withRouter(withTranslation()(TaskCard));