import { Icon, Label } from 'bloomer';
import { useCallback } from 'react';
interface Props {
  value: boolean | null;
  onChange: (value: string) => void;
  isRequired: boolean;
  disableNa: boolean;
  text: string;
  shownBecauseOfRuleTooltip?: JSX.Element;
}

// ADF-374: We now have an option to show yes/no questions as a pretty checkbox
// with Font Awesome icons
export default function PrettyCheckbox({
  value,
  onChange,
  isRequired,
  text,
  shownBecauseOfRuleTooltip,
  disableNa
}: Props) {
  const handleChange = useCallback(() => {
    let newValue: string = null;
    if (value === null || value === false && disableNa) {
      newValue = 'true';
    } else if (value === true) {
      newValue = null;
    } else {
      newValue = 'n/a';
    }
    onChange(newValue);
  }, [disableNa, onChange, value]);
  switch (value) {
    case true:
      return <Label className={isRequired ? 'is-required is-inline-block' : 'is-inline-block'} onClick={handleChange}>
        <span className="has-text-success pretty-checkbox">
          <Icon className="far fa-check-square" />
        </span>
        {text}
        {' '}
        {shownBecauseOfRuleTooltip}
      </Label>;
    case false:
      return <Label className={isRequired ? 'is-required is-inline-block' : 'is-inline-block'} onClick={handleChange}>
        <span className="has-text-red pretty-checkbox">
          <Icon className="far fa-square" />
        </span>
        {text}
        {' '}
        {shownBecauseOfRuleTooltip}
      </Label>;
    default:
      return <Label className={isRequired ? 'is-required is-inline-block' : 'is-inline-block'} onClick={handleChange}>
        <span className="has-text-grey pretty-checkbox">
          <Icon className="far fa-square" />
          <span className="pretty-checkbox-na has-text-link"> n/A</span>
        </span>
        {text}
        {' '}
        {shownBecauseOfRuleTooltip}
      </Label>;
  }
}