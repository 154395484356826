import clsx from 'clsx';
import { Link } from 'react-router-dom';
interface Props {
  isActive: boolean;
  children: any;
  to: string;
}
export default function Caption({
  isActive,
  children,
  to
}: Props) {
  return <Link to={to} className={clsx('has-text-left', 'is-uppercase', 'caption', isActive && 'caption-is-active')}>
      {children}
    </Link>;
}