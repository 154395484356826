import { Progress } from 'bloomer';
import { getProgressColor } from './ActiveResultsTable';
interface Props {
  progress: number;
}
export default function ChecklistProgressbar({
  progress
}: Props) {
  return <div className="is-inline-flex checklist-progress">
      <span className={`has-text-${getProgressColor(progress)} has-text-weight-bold`}>
        {`${progress?.toLocaleString('en-Us', {
        useGrouping: false
      })}%`}
      </span>
      <Progress isColor={getProgressColor(progress)} value={progress} max={100} />
    </div>;
}