import { Control, Field, Input } from 'bloomer';
import clsx from 'clsx';
import { ChangeEvent, FocusEvent, useCallback, useState } from 'react';
import * as React from 'react';
import IResult, { numberValueFormat } from '../../../../../api/types/result';
import { highlightTimeout } from '../../../../../constants';
import { useEventBus } from '../../../../../pages/checklists/ResultView/EventBus';
interface Props {
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
  onBlur: (value: string) => Promise<IResult>;
  unit: string;
  resultId: string;
}
const handleKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const enteredChar = e.key;
  // ADF-326: if the key is a char (not a ctrl, shift or something)
  // and the pressed key isn't within the allowed regexp pattern
  // then reject the key event
  if (enteredChar.length === 1 && !enteredChar.match(numberValueFormat)) {
    e.preventDefault();
  }
};
const DELAY = 1000;
function QuestionNumberInput({
  disabled,
  value,
  onBlur,
  onChange,
  unit,
  resultId
}: Props) {
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
  const handleChanged = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const {
      value: newVal
    } = e.target;
    onChange(newVal);
    if (timer) {
      clearTimeout(timer);
    }
    const id = setTimeout(() => {
      onBlur(newVal);
      setTimer(null);
    }, DELAY);
    setTimer(id);
  }, [onBlur, onChange, timer]);
  const handleBlur = useCallback(async (e: FocusEvent<HTMLInputElement>) => {
    if (timer) {
      clearTimeout(timer);
    }
    await onBlur(e.target.value);
  }, [onBlur, timer]);
  useEventBus('result', resultId, () => {
    setIsHighlighted(true);
    setTimeout(() => setIsHighlighted(false), highlightTimeout);
  });
  return <Field>
      <Control>
        <Input className={clsx('number-input', isHighlighted && 'is-highlighted')} disabled={disabled} onChange={handleChanged} onKeyPress={handleKeyPressed} onBlur={handleBlur} type="text" value={value || ''} />
        <span className="result-question-unit">{unit}</span>
      </Control>
    </Field>;
}
export default QuestionNumberInput;