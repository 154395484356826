import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { createContext, FunctionComponent, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { DirectUpload } from '@rails/activestorage';
import ATTACH_FILE_TO_MESSAGE, { AttachFileToMessageVariables } from '../graphql/mutations/attachFileToMessageMutation';
import CreateConversationMutation, { CreateConversationParams, CreateConversationResponse } from '../graphql/mutations/CreateConversationMutation';
import markConversationAsReadMutation, { MarkConversationAsReadVariables } from '../graphql/mutations/markConversationAsReadMutation';
import SendMessageMutation, { SendMessageMutationVariables, SendMessageResponse } from '../graphql/mutations/SendMessageMutation';
import UpdateConversationMutation, { UpdateConversationMutationVariables, UpdateConversationResponse } from '../graphql/mutations/UpdateConversationMutation';
import FetchChatData, { FetchChatDataResponse } from '../graphql/queries/FetchChatData';
import ConversationUpdated from '../graphql/subscriptions/conversationUpdated';
import { useCurrentUser } from '../hooks/authHooks';
import { Contact, Conversation } from '../types/chat';
export interface ChatContextState {
  conversations: {
    allIds: string[];
    byId: Record<string, Conversation>;
  };
  activeConversationId: string | null;
  contacts: {
    byId: Record<string, Contact>;
  };
  error: ApolloError | null;
  setActiveConversationId: (id: string | null) => void;
  sendMessage: (variables: SendMessageMutationVariables, files: FileList | null) => void;
  addParticipants: (participantIds: string[]) => void;
  totalUnreadMessages: number;
}
const defaultState: ChatContextState = {
  conversations: {
    byId: {},
    allIds: []
  },
  activeConversationId: null,
  contacts: {
    byId: {}
  },
  error: null,
  totalUnreadMessages: 0,
  setActiveConversationId: () => {},
  sendMessage: () => {},
  addParticipants: () => {}
};

// A function to compare if two arrays have the same elements regardless of their order
const equalsCheck = (a: Array<unknown>, b: Array<unknown>) => {
  // If they point to the same instance of the array
  if (a === b) return true;

  // If they point to the same instance of date
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();

  // If both of them are not null and their type is not an object
  if (!a || !b || typeof a !== 'object' && typeof b !== 'object') return a === b;

  // Check if both of the objects have the same number of keys
  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;

  // Check recursively for every key in both
  return keys.every(k => equalsCheck(a[k], b[k]));
};
export const ChatContext = createContext<ChatContextState>(defaultState);
const ChatProvider: FunctionComponent = ({
  children
}) => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const {
    loading,
    data,
    error,
    subscribeToMore,
    refetch
  } = useQuery<FetchChatDataResponse>(FetchChatData, {
    fetchPolicy: 'cache-and-network'
  });
  const [sendMessage] = useMutation<SendMessageResponse, SendMessageMutationVariables>(SendMessageMutation);
  const [markConversationAsRead] = useMutation<any, MarkConversationAsReadVariables>(markConversationAsReadMutation);
  const [attachFileToMessage] = useMutation<any, AttachFileToMessageVariables>(ATTACH_FILE_TO_MESSAGE);
  const [createConversation] = useMutation<CreateConversationResponse, CreateConversationParams>(CreateConversationMutation);
  const [updateConversation] = useMutation<UpdateConversationResponse, UpdateConversationMutationVariables>(UpdateConversationMutation);
  const [activeConversationId, setActiveConversationId] = useState<string | null>(null);
  const handleCreateConversation = useCallback(async (participantIds: string[]) => {
    const response = await createConversation({
      variables: {
        participantIds
      }
    });
    await refetch();
    history.push(`/chat/${response.data.createConversation.conversation.id}`);
  }, [createConversation, history, refetch]);
  const handleUpdateConversation = useCallback(async (id: string, participantIds: string[]) => {
    await updateConversation({
      variables: {
        id,
        participantIds
      }
    });
  }, [updateConversation]);
  const handleAddParticipants = useCallback(async (participantIds: string[]) => {
    if (!participantIds.includes(currentUser.id.toString())) {
      participantIds.push(currentUser.id.toString());
    }
    if (activeConversationId) {
      await handleUpdateConversation(activeConversationId, participantIds);
    } else {
      const existingConversation = data?.conversations.find(c => equalsCheck(c.participants.map(x => x.id.toString()), participantIds));
      if (existingConversation) {
        setActiveConversationId(existingConversation.id);
        history.push(`/chat/${existingConversation.id}`);
      } else {
        await handleCreateConversation(participantIds);
      }
    }
  }, [currentUser.id, activeConversationId, handleUpdateConversation, data?.conversations, history, handleCreateConversation]);
  const handleSendMessage = useCallback(async (variables: SendMessageMutationVariables, files: FileList | null) => {
    const response = await sendMessage({
      variables
    });
    if (files && response.data.createChatMessage.errors.length === 0) {
      for (const file of files) {
        const upload = new DirectUpload(file, `${process.env.REACT_APP_API_BASE_URL}/rails/active_storage/direct_uploads`);
        upload.create((err, blob) => {
          const url = `${process.env.REACT_APP_API_BASE_URL}/rails/active_storage/blobs/${blob.signed_id}/${file.name}`;
          attachFileToMessage({
            variables: {
              id: response.data.createChatMessage.message.id,
              file: url
            }
          });
        });
      }
    }
  }, [sendMessage, attachFileToMessage]);
  const state = useMemo<ChatContextState>((): ChatContextState => {
    if (loading) {
      return defaultState;
    }
    if (error) {
      return {
        ...defaultState,
        error,
        setActiveConversationId
      };
    }
    const conversations = {
      allIds: [],
      byId: {}
    };
    const unreadMessageCount = data.conversations.flatMap(x => x.messages).filter(x => !x.read).length;
    data.conversations.forEach(conversation => {
      conversations.byId[conversation.id] = {
        id: conversation.id,
        messages: conversation.messages,
        participants: conversation.participants.map(user => ({
          address: '',
          avatar: user.picture,
          email: user.email,
          name: user.fullName,
          lastActivity: user.lastActive,
          status: user.status,
          username: user.email,
          id: user.id.toString(),
          position: '',
          phone: user.phone
        })),
        unreadCount: conversation.unreadCount,
        type: conversation.type ?? ''
      };
      conversations.allIds.push(conversation.id);
    });
    const contacts = {
      byId: {}
    };
    data.users.forEach(user => {
      contacts.byId[user.id] = {
        address: '',
        avatar: user.picture,
        email: user.email,
        name: user.fullName,
        lastActivity: user.lastActive,
        status: user.status,
        username: user.email,
        id: user.id.toString(),
        position: '',
        phone: user.phone
      };
    });
    return {
      error: null,
      contacts,
      activeConversationId,
      conversations,
      setActiveConversationId,
      sendMessage: handleSendMessage,
      addParticipants: handleAddParticipants,
      totalUnreadMessages: unreadMessageCount
    };
  }, [loading, error, activeConversationId, data, handleSendMessage, handleAddParticipants]);
  useEffect(() => {
    const conversation = state.conversations.byId[activeConversationId];
    if (conversation && conversation.unreadCount > 0) {
      markConversationAsRead({
        variables: {
          id: activeConversationId
        }
      });
    }
  }, [state.conversations.byId, activeConversationId, markConversationAsRead]);
  useLayoutEffect(() => {
    if (!loading) {
      subscribeToMore({
        document: ConversationUpdated,
        variables: {
          userId: currentUser.id
        },
        updateQuery: (prev, {
          subscriptionData
        }: any) => {
          if (!subscriptionData.data || Object.keys(subscriptionData.data).length === 0) return prev;
          if (Object.keys(prev).length === 0) {
            return prev;
          }
          if (subscriptionData.data) {
            const newData: FetchChatDataResponse = {
              ...prev,
              conversations: [...prev.conversations.filter(x => x.id !== subscriptionData.data.conversationUpdated.id), subscriptionData.data.conversationUpdated]
            };
            return newData;
          }
          return prev;
        }
      });
    }
  }, [subscribeToMore, loading, currentUser]);
  return <ChatContext.Provider value={state}>
      {children}
    </ChatContext.Provider>;
};
export default ChatProvider;