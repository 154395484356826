import { ApiBase } from './ApiBase'
import IResult, { IResultAttachment } from './types/result'

export default class ResultApi extends ApiBase {
  public async create(
    checklistId: number,
    resultSetId: number,
    data: IResult,
    accessToken?: string | null
  ) {
    if (this.isOffline()) {
      const checklist = await this.offlineDb.checklists.get(checklistId)
      const question = checklist.areas.flatMap((x) => x.questions)
        .find((x) => x.id === data.question_id)
      const result: IResult = {
        ...data,
        result_set_id: resultSetId,
        question,
        question_id: question.id,
        action: [],
        _dirty: true,
        cloud_attachments: [],
        attachments: []
      }
      return this.offlineDb.addResult(result)
    }
    let url = `/checklists/${checklistId}/result_sets/${resultSetId}/results`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    delete data.cloud_attachments
    const response = await this.post<IResult>(
      url,
      data
    )

    return response.data
  }

  /**
   * Updates an existing result
   *
   * @param checklistId
   * @param resultSetId
   * @param data
   * @param accessToken
   */
  public async update(
    checklistId: number,
    resultSetId: number,
    data: IResult,
    accessToken?: string | null
  ) {
    if (this.isOffline()) {
      return this.offlineDb.updateResult(data)
    }
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cloud_attachments, attachments, comments, versions, question, ...rest
    } = data

    let url = `/checklists/${checklistId}/result_sets/${resultSetId}/results/${
      data.id
    }`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    delete data.cloud_attachments

    const response = await this.put<IResult>(url, rest)

    return response.data
  }

  public async sendRuleEmails(
    checklistId: number,
    resultSetId: number,
    resultId: string,
    accessToken?: string | null
  ) {
    let url = `/checklists/${checklistId}/result_sets/${resultSetId}/results/${resultId}/execute_mail_actions`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    return await this.post<void>(url)
  }

  public deleteResult(
    checklistId: number,
    resultSetId: number,
    data: IResult,
    accessToken?: string | null
  ) {
    let url = `/checklists/${checklistId}/result_sets/${resultSetId}/results/${
      data.id
    }`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    return this.delete(url)
  }

  public async addAttachment(
    checklistId: number,
    resultSetId: number,
    questionId: number,
    file: File,
    areaPosition: number,
    accessToken?: string | null
  ) {
    const formData = new FormData()
    formData.append('filepond', file)
    formData.append('area_position', areaPosition.toString())

    formData.append('access_token', areaPosition.toString())

    let url = `/checklists/${checklistId}/result_sets/${resultSetId}/attachment?question_id=${questionId}`

    if (accessToken) {
      url += `&access_token=${accessToken}`
    }

    const response = await this.post<IResult>(url, formData)
    return response.data
  }

  public updateAttachment(
    resultSetId: number,
    resultId: string,
    id: number,
    data: string,
    accessToken?: string | null
  ) {
    let url = `/attachments/${id}`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    const params = {
      upload: data
    }

    return this.put<IResultAttachment>(url, params)
  }

  public async deleteAttachment(
    resultSetId: number,
    resultId: string,
    id: number,
    accessToken?: string | null
  ): Promise < void > {
    if (this.isOffline()) {
      await this.offlineDb.deleteAttachment(resultSetId, resultId, id)
      return
    }
    let url = `/attachments/${id}`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    await super.delete(url)
  }
}
