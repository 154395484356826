import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IAlphaprocessState } from '../store';
import { consumer } from '../util/consumer';
const GraphQlProvider: FunctionComponent = ({
  children
}) => {
  const jwt = useSelector((state: IAlphaprocessState) => state.core.jwt);
  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const hasSubscriptionOperation = ({
      query: {
        definitions
      }
    }) => definitions.some(({
      kind,
      operation
    }) => kind === 'OperationDefinition' && operation === 'subscription');
    const link = ApolloLink.split(hasSubscriptionOperation, new ActionCableLink({
      cable: consumer
    }), httpLink);
    const cache = new InMemoryCache({
      typePolicies: {
        Lane: {
          fields: {
            tasks: {
              merge(existing = [], incoming: any[]) {
                return uniqBy([...incoming], '__ref');
              }
            }
          }
        }
      }
    });
    return new ApolloClient({
      link,
      cache,
      connectToDevTools: true
    });
  }, [jwt]);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default GraphQlProvider;