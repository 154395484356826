import { createFormData } from '../util'
import { ApiBase } from './ApiBase'

export interface TemplateStoreEntryCreateParams {
  name: string
  description: string
  tag_ids: number[]
  template_store_category_id: string
  published: boolean
  favored: boolean
  image: File | any
  template_file: Array<File | any>
  position: number | null
}

export default class TemplateStoreEntryApi extends ApiBase {
  public async create(entity: TemplateStoreEntryCreateParams) {
    const { image, template_file, ...rest } = entity
    const formData = createFormData(rest)

    if (image) {
      formData.append('image', image)
    }
    if (template_file) {
      for (const file of template_file) {
        formData.append('template_file', file)
      }
    }

    const response = await this.post('/template_store_entries', formData)
    return response.data
  }

  async update(id: string, entity: TemplateStoreEntryCreateParams) {
    console.log('entity', entity)
    const { image, template_file, ...rest } = entity
    const formData = createFormData(rest)

    if (image) {
      formData.append('image', image)
    }
    if (template_file) {
      for (const file of template_file) {
        console.log('file', file)
        formData.append('template_file[]', file)
      }
    }

    const response = await this.put(`/template_store_entries/${id}`, formData)
    return response.data
  }
}
