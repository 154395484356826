import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuestion } from '../../../../../api/types/checklist';
import IResult from '../../../../../api/types/result';
import { highlightTimeout } from '../../../../../constants';
import { useEventBus } from '../../../../../pages/checklists/ResultView/EventBus';
import VelianDateInput, { DateInputMode } from '../../../../widgets/VelianDateInput';
interface IProps {
  item: IQuestion;
  onUpdate: (id: number, data: any, field?: string, areaPosition?: number, questionPosition?: number) => Promise<IResult>;
  value: string;
  areaPosition: number;
  resultId: string;
  disabled: boolean;
  questionPosition: number;
}
export default function QuestionDateInput({
  value,
  item,
  onUpdate,
  areaPosition,
  resultId,
  disabled,
  questionPosition
}: IProps) {
  const {
    t
  } = useTranslation();
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  useEventBus('result', resultId, () => {
    setIsHighlighted(true);
    setTimeout(() => setIsHighlighted(false), highlightTimeout);
  });
  const realValue = !value && item.has_default_today ? new Date() : value;
  const handleChange = useCallback((newDate: Date) => onUpdate(item.id, newDate ? newDate.toISOString() : null, 'value', areaPosition, questionPosition), [onUpdate, areaPosition, questionPosition, item.id]);
  let mode: DateInputMode = 'date';
  if (item.timeonly) {
    mode = 'time';
  } else if (item.has_time) {
    mode = 'datetime';
  }
  return <div className={clsx('datetime-question', isHighlighted && 'is-highlighted')}>
      <VelianDateInput selected={realValue ? new Date(realValue) : null} onChange={handleChange} dateFormat={item.has_time ? 'dd.MM.yyyy  HH:mm' : 'dd.MM.yyyy'} className="is-fullwidth date-input input" mode={mode} timeCaption={t('Time')} isClearable disabled={disabled} />
    </div>;
}