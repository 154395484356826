import { Card, CardContent, Dropdown, DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger, Icon, Progress } from 'bloomer';
import { useState, FunctionComponent, useCallback, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { isMobile } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import { useIsAdmin } from '../../../../hooks/authHooks';
import { confirm } from '../../../../util';
import { IArea } from '../../../../api/types/checklist';
import settingsIcon from '../../../../images/icon_settings.png';
import { getProgressColor } from '../../../../components/checklists/result/ActiveResultsTable';
import RenameAreaDialog from './RenameAreaDialog';
import { QuestionCount } from '../types';
interface IProps {
  area?: IArea;
  isSignatureArea?: boolean;
  filledQuestionCount: QuestionCount;
  areaPosition: number;
  onClick: (areaId: number | string, areaPosition: number) => void;
  onCloneArea: (areaId: number | string) => void;
  onDeleteArea: (areaId: number | string, areaPosition: number) => void;
  onRenameArea: (areaId: number | string, areaPosition: number, name: string) => void;
  onOpenPermissionsModal?: (area: IArea) => void | null;
  totalQuestionCount?: number;
  title?: string;
  canOpen: boolean;
}
const AreaCard: FunctionComponent<IProps> = props => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    t
  } = useTranslation();
  const isAdmin = useIsAdmin();
  const {
    area,
    filledQuestionCount,
    areaPosition,
    canOpen,
    isSignatureArea,
    onClick,
    onDeleteArea,
    onOpenPermissionsModal,
    onCloneArea,
    onRenameArea
  } = props;
  const title = !props.title ? area.title : props.title;
  const handleOpenPermissionsModal = useCallback(event => {
    event.preventDefault();
    onOpenPermissionsModal(area);
  }, [area, onOpenPermissionsModal]);
  const toggleDropdown = useCallback(() => {
    // if the current user is not permitted to open this abort
    if (!canOpen && !isAdmin) {
      return;
    }
    setIsDropdownOpen(state => !state);
  }, [canOpen, isAdmin]);
  const cloneArea = useCallback(event => {
    // if the current user is not permitted to open this abort
    if (!canOpen && !isAdmin) {
      return;
    }
    event.preventDefault();
    onCloneArea(area.id);
    setIsDropdownOpen(false);
  }, [area, canOpen, isAdmin, onCloneArea]);
  const closeDropdown = useCallback(event => {
    // if the current user is not permitted to open this abort
    if (!canOpen && !isAdmin) {
      return;
    }
    const target = (event.target as HTMLElement);
    const parent = document.querySelector(`#area-card-dropdown-${area.id}-${areaPosition}`);
    // ClickOutSide Handler is also called if I click the toggle icon for reasons
    // this is a workaround to prevent this
    if (!parent.contains(target) || !(target.classList.contains('gear-menu-toggle') || target.classList.contains('icon-settings'))) {
      setIsDropdownOpen(false);
    }
  }, [area, areaPosition, canOpen, isAdmin]);
  const delegateOnClick = useCallback(event => {
    // if the current user is not permitted to open this abort
    if (!canOpen) {
      return;
    }
    // this will open the area except the user clicks on an icon
    // then it will do nothing and another onClick handler handles the mouse click
    const {
      target
    } = event;
    if (!target.classList.contains('clickable-icon') && !target.classList.contains('icon')) {
      if (!isSignatureArea) {
        onClick(area.id, areaPosition);
      } else {
        // Signature Area
        onClick(-1, 1);
      }
    }
  }, [area, areaPosition, canOpen, isSignatureArea, onClick]);
  const deleteArea = useCallback(event => {
    event.preventDefault();
    confirm(null, t('result.deleteArea'), () => {
      onDeleteArea(area.id, areaPosition);
    });
    setIsDropdownOpen(false);
  }, [area, areaPosition, onDeleteArea, t]);
  const handleRenameArea = useCallback(() => {
    const doRename = (name: string) => {
      onRenameArea(area.id, areaPosition, name);
    };
    const customUI = ({
      onClose
    }) => <RenameAreaDialog currentName={title} onClose={onClose} onRename={doRename} />;
    confirmAlert({
      customUI
    });
    setIsDropdownOpen(false);
  }, [area, areaPosition, onRenameArea, title]);
  useEffect(() => {
    if (!isMobile) {
      ReactTooltip.rebuild();
    }
  });

  /*let realQuestionCount: number = 0
  if (typeof totalQuestionCount === 'undefined') {
    area.questions.filter(
      (q) => !q.hide && q.question_type !== 'information' && !q.exclude_from_progress
    ).forEach((q) => {
      realQuestionCount += q.showCount ?? 1
    })
  } else {
    realQuestionCount = totalQuestionCount
  }*/

  const tasksOpen = Math.max(0, filledQuestionCount.total - filledQuestionCount.filled);
  const percentage = filledQuestionCount.total === 0 ? 100 : Math.round(filledQuestionCount.filled / filledQuestionCount.total * 100);
  const cursorClass = canOpen || isAdmin ? 'has-pointer' : 'has-not-allowed';
  const cloneIcon = <Icon className={`fa fa-plus ${cursorClass} clickable-icon is-block`} />;
  const deleteIcon = area && area.allow_clone && areaPosition >= 2 ? <DropdownItem href="#" className="area-icons clickable-icon is-block" onClick={deleteArea}>
        <Icon className={`fa fa-minus ${cursorClass} clickable-icon is-block`} />
        {t('checklist.gearMenu.removeArea')}
      </DropdownItem> : null;
  const permissionsIcon = isAdmin && props.onOpenPermissionsModal ? <DropdownItem href="#" className="area-icons clickable-icon is-block is-right" onClick={handleOpenPermissionsModal}>
        <Icon className="fas fa-user-lock clickable-icon is-block" />
        {t('checklist.gearMenu.permissions')}
      </DropdownItem> : null;
  const additionalIcons = area && (area.allow_clone || permissionsIcon) ? <Dropdown isActive={isDropdownOpen} className={`${cursorClass} is-pulled-right clickable-icon is-block clone-area-dropdown`} id={`area-card-dropdown-${props.area.id}-${props.areaPosition}`}>
        <DropdownTrigger className="clickable-icon is-block">
          <span onClick={toggleDropdown} className={`${cursorClass} clickable-icon is-block gear-menu-toggle`}>
            <img src={settingsIcon} className={`icon-settings is-pulled-right${cursorClass} clickable-icon`} alt={t('resultSet.settings')} />
          </span>
        </DropdownTrigger>
        <OutsideClickHandler onOutsideClick={closeDropdown}>
          <DropdownMenu className="clickable-icon area-card-view-drowpdown">
            <DropdownContent className="clickable-icon is-block">
              <DropdownItem href="#" className="area-icons clickable-icon is-block" onClick={cloneArea} isHidden={!area.allow_clone}>
                {cloneIcon}
                {t('checklist.gearMenu.cloneArea')}
              </DropdownItem>
              {deleteIcon}
              {permissionsIcon}
              <DropdownItem className="area-icons clickable-icon is-block" onClick={handleRenameArea}>
                <Icon className="fa fa-pencil-alt clickable-icon is-block" />
                {t('common.rename')}
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </OutsideClickHandler>
      </Dropdown> : null;
  const realPercentage = !Number.isNaN(percentage) ? percentage : 0;
  // cap percentage value to 100
  const percentageValue = realPercentage > 100 ? 100 : realPercentage;
  const cardClass = canOpen ? 'has-pointer zoom' : 'has-not-allowed area-card-forbidden';

  // set higher z-index if the menu is open. The menu must not behind another card
  const zIndexClass = isDropdownOpen ? 'menu-is-open' : null;
  return <Card className={`area area-card ${cardClass} ${zIndexClass}`} onClick={delegateOnClick}>
      <CardContent>
        {additionalIcons}
        <div className="is-ellipsed">
          <strong>
            {title}
          </strong>
        </div>
        <div className="has-text-grey tasks-open">
          {tasksOpen}
          {' '}
          {tasksOpen === 1 ? t('resultSet.taskOpen') : t('resultSet.tasksOpen')}
        </div>
        <div className={`has-text-${getProgressColor(percentageValue)} has-text-weight-bold`}>
          {percentageValue}
          {' '}
          %
        </div>
        <Progress isColor={getProgressColor(percentageValue)} value={percentageValue} max={100} />
      </CardContent>
    </Card>;
};
export default AreaCard;