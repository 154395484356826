export default interface IAttachment {
  url: string | null
}

export type ICloudStorageProvider = 'one-drive' | 'dropbox' | 'google-drive'

export type AttachableType = 'Ressource' | 'Result' | 'Task' | 'TodoListItem' | 'Contact' | 'Checklist'

export interface ICloudAttachment {
  id: string | null
  name: string
  url: string | null
  access_token?: string | null
  provider: ICloudStorageProvider
  _new?: boolean | null
  _destroy?: boolean | null
  attachable_type?: AttachableType
  attachable_id?: string | number
}

// check if a file is an image based on it's name extension
export function isImage(fileName: string): boolean {
  const extensions = ['.jpg', '.png', '.jpeg', '.svg', '.tiff', '.gif']
  return extensions.some((extension) => fileName.toLowerCase().endsWith(extension))
}
