import { gql } from '@apollo/client'

export interface AttachFileToMessageVariables {
    id: string
    file: string
}

const ATTACH_FILE_TO_MESSAGE = gql`
  mutation attachFileToMessage($id: ID!, $file: String!) {
      attachFileToMessage(id: $id, file: $file) {
          errors
          message {
              id
              read
              attachments
              senderId
              contentType
              body
              createdAt
          }
      }
  }
`

export default ATTACH_FILE_TO_MESSAGE
