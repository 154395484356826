import { ApiBase } from './ApiBase'
import { IQuestion } from './types/checklist'

export default class QuestionsApi extends ApiBase {
  public async create(title: string, areaId: number) {
    const question: IQuestion = {
      default_value: '',
      disable_na: false,
      display_as_checkbox: false,
      exclude_from_progress: false,
      has_attachments_required: false,
      has_audit_points: false,
      is_required: false,
      position: 0,
      question_type: 'checkbox',
      unit: '',
      title,
      area_id: areaId,
      id: null,
      description: '',
      enable_speech_to_text: true,
      enable_cloud_attachments: false,
      test_question: false,
      correct_answers: [],
      timeonly: false,
      formula: '',
      action: []
    }

    const response = await this.post<IQuestion>('/questions', question)
    return response.data
  }
}
