import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
const FETCH_OBJECT_COUNT = gql`
    query BadgeData {
        openObjectCount
    }
`;
function CordovaBadge() {
  const {
    data
  } = useQuery<{
    openObjectCount: number;
  }>(FETCH_OBJECT_COUNT, {
    pollInterval: 100000
  });
  useEffect(() => {
    if (data) {
      (window.cordova.plugins as any).notification?.badge?.set(data.openObjectCount);
    }
  }, [data]);
  return null;
}
export default CordovaBadge;