import { ApiBase } from './ApiBase'
import { IChartTag } from './chartsApi'
import { ITag } from './types/ressource'

export default class ChartsTagsApi extends ApiBase {
  public async all() {
    const tags = await this.get<IChartTag[]>(
      '/charts_tags'
    )
    return tags.data
  }

  public existing_tag(title: string) {
    return this.get<IChartTag>(
      '/charts_tags/existing_tag',
      {
        params: {
          title
        }
      }
    )
  }

  public create(title: string) {
    return this.post<IChartTag>(
      '/charts_tags',
      {
        title
      }
    )
  }

  public update(data: ITag) {
    return this.put<ITag>(
      `/charts_tags/${data.id}${
        data.id
      }`,
      data
    )
  }

  public deleteTag(data: ITag) {
    return this.delete(
      `/charts_tags/tags/${data.id}`
    )
  }
}
