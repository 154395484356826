import { compiledBlock as _compiledBlock } from "million/react";
const TableAvatarDisplay_1 = _compiledBlock(_props => <div data-tip={_props.v0} data-for={_props.v1} data-place={_props.v2} className="table-avatar-display">
          {_props.v3}
          {_props.v4}
        </div>, {
  name: "TableAvatarDisplay_1",
  portals: ["v3", "v4"]
});
const TableAvatarDisplay_2 = _compiledBlock(_props2 => <div data-tip={_props2.v0} data-for={_props2.v1} data-place={_props2.v2} className="table-avatar-display">
        {_props2.v3}
        {_props2.v4}
      </div>, {
  name: "TableAvatarDisplay_2",
  portals: ["v3", "v4"]
});
const TableAvatarDisplay_3 = _compiledBlock(_props3 => <div data-tip={_props3.v0} data-for={_props3.v1} data-multiline="true" data-place={_props3.v2} className="table-avatar-display">
      {_props3.v3}
      {_props3.v4}
    </div>, {
  name: "TableAvatarDisplay_3",
  portals: ["v3", "v4"]
});
const TableAvatarDisplay = function TableAvatarDisplay({
  users = [],
  teams = [],
  place
}: IProps) {
  if (users.length === 0 && teams.length === 0) {
    return null;
  }
  const usersAndTeams: any[] = [...users, ...teams];
  const tooltipId = v4();
  if (usersAndTeams.length === 1) {
    const tester = usersAndTeams[0];

    // if it has a firstname it is a user
    if (typeof tester.firstname !== 'undefined') {
      return /*@million jsx-skip*/<TableAvatarDisplay_1 v0={`${tester.firstname} ${tester.lastname}`} v1={tooltipId} v2={place} v3={<ReactAvatar src={tester.avatar && tester.avatar.thumb ? tester.avatar.thumb.url : null} size="32" round name={`${tester.firstname} ${tester.lastname}`} />} v4={<ReactTooltip id={tooltipId} isCapture />} />;
    }
    // if it isn't a user then it is a team
    return /*@million jsx-skip*/<TableAvatarDisplay_2 v0={`${tester.name}`} v1={tooltipId} v2={place} v3={<ReactAvatar src={tester.avatar && tester.avatar.thumb ? tester.avatar.thumb.url : null} size="32" round name={`${tester.name}`} />} v4={<ReactTooltip id={tooltipId} isCapture />} />;
  }
  const tooltip = usersAndTeams.map(u => typeof u.firstname !== 'undefined' ? `${u.firstname} ${u.lastname}` : u.name).join('<br/>');
  return /*@million jsx-skip*/<TableAvatarDisplay_3 v0={tooltip} v1={tooltipId} v2={place} v3={<ReactAvatar name={usersAndTeams.length.toString()} round size="32" />} v4={<ReactTooltip id={tooltipId} isCapture />} />;
};
import { block as _block } from "million/react";
import { FunctionComponent } from 'react';
import ReactAvatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import { v4 } from 'uuid';
import ITeam from '../../../api/types/team';
import IUser from '../../../api/types/user';
import { Assignee } from '../../../types/FolderChecklist';
interface IProps {
  users: IUser[];
  teams: ITeam[];
  place?: string | null;
}
interface AssigneeProps {
  assignees?: Assignee[] | null;
  place?: string | null;
}
const TableAssignees_1 = _compiledBlock(_props4 => <div data-tip={_props4.v0} data-for={_props4.v1} data-place={_props4.v2} className="table-avatar-display">
        {_props4.v3}
        {_props4.v4}
      </div>, {
  name: "TableAssignees_1",
  portals: ["v3", "v4"]
});
const TableAssignees_2 = _compiledBlock(_props5 => <div data-tip={_props5.v0} data-for={_props5.v1} data-multiline="true" data-place={_props5.v2} className="table-avatar-display">
      {_props5.v3}
      {_props5.v4}
    </div>, {
  name: "TableAssignees_2",
  portals: ["v3", "v4"]
});
export const TableAssignees: FunctionComponent<AssigneeProps> = ({
  assignees,
  place
}) => {
  if (!assignees || !Array.isArray(assignees) || assignees.length === 0) return null;
  const tooltipId = v4();
  if (assignees.length === 1) {
    const [tester] = assignees;
    return /*@million jsx-skip*/<TableAssignees_1 v0={tester.name} v1={tooltipId} v2={place} v3={<ReactAvatar src={tester.url ? tester.url : null} size="32" round name={tester.name} />} v4={<ReactTooltip id={tooltipId} isCapture />} />;
  }
  const tooltip = assignees.map(u => u.name).join('<br/>');
  return /*@million jsx-skip*/<TableAssignees_2 v0={tooltip} v1={tooltipId} v2={place} v3={<ReactAvatar name={assignees.length.toString()} round size="32" />} v4={<ReactTooltip id={tooltipId} isCapture />} />;
};

// shows the avatars of responsible users
// if there are 3 or more users then only the avatar of the first user is shown
// and the count of users
export default TableAvatarDisplay;