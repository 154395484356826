import { toast } from 'react-toastify'
import { IQuestion, IRuleAction, IRuleSet } from '../api/types/checklist'
import IResult, { ResultAction } from '../api/types/result'

const chars_to_remove = ['.', ' ', '-']

const equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false
  const uniqueValues = new Set([...a, ...b])
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length
    const bCount = b.filter((e) => e === v).length
    if (aCount !== bCount) return false
  }
  return true
}

export default class RuleEngine {
  public static checkRules(result: IResult, results: IResult[]): IResult {
    if (result.question && result.question.rule_sets) {
      const actions = result.question.rule_sets.map((x) => RuleEngine.processRuleSet(
        x,
        result.question,
        result.value,
        results
      ))
      result.action = actions.flat()
    }
    return result
  }

  private static processRuleSet(
    ruleSet: IRuleSet,
    question: IQuestion,
    value: string,
    results: IResult[]
  ): ResultAction[] {
    const actions: ResultAction[] = []
    const checkOk = RuleEngine.compareRules(question, ruleSet, value)
    const action = ruleSet.rule_action
    if (action) {
      console.log(`Processing action of type ${action.action_type}`);
      switch (action.action_type) {
        case 'show_question':
          actions.push(checkOk
            ? {
              action: 'show_question',
              target_question_ids: action.target_question_ids
            }
            : {
              action: 'hide_question',
              target_question_ids: action.target_question_ids
            })
          break
        case 'hide_question':
          actions.push(checkOk
            ? {
              action: 'hide_question',
              target_question_ids: action.target_question_ids
            }
            : {
              action: 'hide_area',
              target_question_ids: action.target_question_ids
            })
          break
        case 'show_area':
          actions.push(checkOk
            ? {
              action: 'show_area',
              target_area_ids: action.target_area_ids,
              target_area_count: action.target_area_count
            }
            : {
              action: 'hide_area',
              target_area_ids: action.target_area_ids,
              target_area_count: action.target_area_count
            })
          break
        case 'hide_area':
          actions.push(checkOk
            ? {
              action: 'hide_area',
              target_area_ids: action.target_area_ids,
              target_area_count: action.target_area_count
            }
            : {
              action: 'hide_question',
              target_area_ids: action.target_area_ids,
              target_area_count: action.target_area_count
            })
          break
        case 'duplicate_area': {
          actions.push(checkOk
            ? {
              action: 'duplicate_area',
              target_area_ids: action.target_area_ids,
              target_area_count: this.targetCount(action, value, results)
            }
            : {
              action: 'show_area',
              target_area_ids: action.target_area_ids,
              target_area_count: 1
            })
          break
        }
        case 'duplicate_question': {
          actions.push(checkOk
            ? {
              action: 'duplicate_question',
              target_question_ids: action.target_question_ids,
              target_question_count: this.targetCount(action, value, results)
            }
            : {
              action: 'show_question',
              target_question_ids: action.target_question_ids,
              target_question_count: 1
            })
          break
        }
        case 'set_question_value': {
          if (checkOk) {
            actions.push(
              {
                action: 'set_question_value',
                target_question_ids: action.target_question_ids,
                value: action.value
              }
            )
          }
          break
        }
        case 'create_task':
          if (checkOk) {
            actions.push({
              action: 'create_task',
              task_data: action.task_data
            })
          }
          break
        case 'send_email':
          if (checkOk) {
            toast('result.mailOnSync')
          }
      }
      console.log(`Resulting actions:`, actions);
    }

    return actions
  }

  private static targetCount(action: IRuleAction, value: string, results: IResult[]): number {
    if (action.duplicate_mode === 'static') {
      return parseInt(value)
    }

    const result = results.find((x) => x.question.id === action.duplicate_question_id)
    if (result) {
      return parseInt(result.value)
    }

    return 0
  }

  private static compareRules(question: IQuestion, ruleSet: IRuleSet, value: string): boolean {
    let checkOk = false

    let link_operator = 'or_operator'
    ruleSet.rules.forEach((rule) => {
    // if we have a numeric question we have to normalize and cast to float the value
    // to compare it as numeric
      let numeric_value = null
      const expected_numeric_value = question.question_type === 'number'
      || question.question_type === 'rating' || question.question_type === 'dropdown'
        ? parseFloat(rule.expected) : null
      if ((question.question_type === 'number'
        || question.question_type === 'rating' || question.question_type === 'dropdown') && value) {
        numeric_value = value

        if (typeof numeric_value === 'string') {
          chars_to_remove.forEach((char) => {
            numeric_value = numeric_value.replace(char, '')
          })
          numeric_value = numeric_value.replace(',', '.')
          numeric_value = !Number.isNaN(parseFloat(numeric_value))
            ? parseFloat(numeric_value) : null
        }
      }

      // if no value is present then the condition is rated as false
      if (!value) checkOk = false
      else if (rule.operator === 'equals') {
        checkOk = RuleEngine.joinValues(checkOk, RuleEngine.checkEquals(value, rule.expected, question), link_operator)
      } else if (rule.operator === 'not_equals') {
        checkOk = RuleEngine.joinValues(
          checkOk,
          !RuleEngine.checkEquals(value, rule.expected, question),
          link_operator
        )
      } else if (rule.operator === 'greater_than') {
        checkOk = RuleEngine.joinValues(
          checkOk,
          typeof numeric_value === 'number' && numeric_value > expected_numeric_value,
          link_operator
        )
      } else if (rule.operator === 'greater_or_equal_than') {
        checkOk = RuleEngine.joinValues(
          checkOk,
          typeof numeric_value === 'number' && numeric_value >= expected_numeric_value,
          link_operator
        )
      } else if (rule.operator === 'less_than') {
        checkOk = RuleEngine.joinValues(
          checkOk,
          typeof numeric_value === 'number' && numeric_value < expected_numeric_value,
          link_operator
        )
      } else if (rule.operator === 'less_or_equal_than') {
        checkOk = RuleEngine.joinValues(
          checkOk,
          typeof numeric_value === 'number' && numeric_value <= expected_numeric_value,
          link_operator
        )
      } else if (rule.operator === 'contains') {
        checkOk = RuleEngine.joinValues(checkOk, value && value.includes(rule.expected), link_operator)
      } else if (rule.operator === 'starts_with') {
        checkOk = RuleEngine.joinValues(checkOk, value && value.startsWith(rule.expected), link_operator)
      } else if (rule.operator === 'ends_with') {
        checkOk = RuleEngine.joinValues(checkOk, value && value.endsWith(rule.expected), link_operator)
      }
      link_operator = rule.link_operator
    })
    return checkOk
  }

  private static joinValues(
    previous_check_result: boolean,
    current_check_result: boolean,
    link_operator: string
  ): boolean {
    if (link_operator === 'and_operator') return RuleEngine.andValue(previous_check_result, current_check_result)
    return RuleEngine.orValue(previous_check_result, current_check_result)
  }

  private static orValue(previous_check_result: boolean, current_check_result: boolean) {
    return previous_check_result || current_check_result
  }

  private static andValue(previous_check_result: boolean, current_check_result: boolean) {
    return previous_check_result && current_check_result
  }

  private static checkEquals(value: string, expected: string, question: IQuestion): boolean {
    if (question.question_type === 'dropdown' && question.is_multi) {
      if (!value) return false

      const values = value.split(',')
      const expected_values = expected.split(',')
      return equalsIgnoreOrder(values, expected_values)
    }

    // eslint-disable-next-line eqeqeq
    return value == expected
  }
}
