import { FilterActions, FilterLocation } from '../actions/filterActions'
import ISelectListValueModel from '../types/selectListModel'

export interface FilterSettings {
  resourceIndex: Array<ISelectListValueModel<any>>
  resourceDetails: Array<ISelectListValueModel<any>>
  resourceAdd: Array<ISelectListValueModel<any>>
  selectedProjects: Array<ISelectListValueModel<any>>
  selectedTeams: Array<ISelectListValueModel<any>>
  selectedTesters: Array<ISelectListValueModel<any>>
  selectedContacts: Array<ISelectListValueModel<any>>
  selectedRessources: Array<ISelectListValueModel<any>>
  selectedCompanies: string[]
  selectedTags: Array<ISelectListValueModel<any>>
  startDate: Date
  endDate: Date
  term: string
  selectedCreators: number[]

  createdStartDate: Date | null
  createdEndDate: Date | null

  selectedClosers: number[]
}

export interface IFilterState {
  chartFilterSettings: FilterSettings
  checklistFilterSettings: FilterSettings
  resourceFilterSettings: FilterSettings
  contactsFilterSettings: FilterSettings
  calendarFilterSettings: FilterSettings
  boardFilterSettings: FilterSettings
  peopleFilterSetting: FilterSettings
  companyFilterSetting: FilterSettings
  tasksFilterSettings: FilterSettings
}

export const emptyFilterSettings: FilterSettings = {
  resourceIndex: [],
  resourceAdd: [],
  resourceDetails: [],
  selectedProjects: [],
  selectedTeams: [],
  selectedTesters: [],
  endDate: null,
  startDate: null,
  term: '',
  selectedContacts: [],
  selectedRessources: [],
  selectedTags: [],
  selectedCompanies: [],
  createdEndDate: null,
  createdStartDate: null,
  selectedClosers: [],
  selectedCreators: []
}

export const initialFilterState: IFilterState = {
  chartFilterSettings: {
    ...emptyFilterSettings
  },
  calendarFilterSettings: {
    ...emptyFilterSettings
  },
  checklistFilterSettings: {
    ...emptyFilterSettings
  },
  contactsFilterSettings: {
    ...emptyFilterSettings
  },
  resourceFilterSettings: {
    ...emptyFilterSettings
  },
  boardFilterSettings: {
    ...emptyFilterSettings
  },
  peopleFilterSetting: {
    ...emptyFilterSettings
  },
  companyFilterSetting: {
    ...emptyFilterSettings
  },
  tasksFilterSettings: {
    ...emptyFilterSettings
  }
}

export function getFilterSettings(state: IFilterState, location: FilterLocation): FilterSettings {
  switch (location) {
    case FilterLocation.calendar:
      return state.calendarFilterSettings
    case FilterLocation.checklists:
      return state.checklistFilterSettings
    case FilterLocation.contacts:
      return state.contactsFilterSettings
    case FilterLocation.resources:
      return state.resourceFilterSettings
    case FilterLocation.boards:
      return state.boardFilterSettings
    case FilterLocation.people:
      return state.peopleFilterSetting
    case FilterLocation.companies:
      return state.companyFilterSetting || emptyFilterSettings
    case FilterLocation.charts:
      return state.chartFilterSettings || emptyFilterSettings
    case FilterLocation.tasks:
      return state.tasksFilterSettings || emptyFilterSettings
    default:
      return emptyFilterSettings
  }
}

export function updateFilterState(
  state: IFilterState,
  settings: FilterSettings,
  location: FilterLocation
): IFilterState {
  switch (location) {
    case FilterLocation.boards:
      return {
        ...state,
        boardFilterSettings: settings
      }
    case FilterLocation.tasks:
      return {
        ...state,
        tasksFilterSettings: settings
      }
    case FilterLocation.resources:
      return {
        ...state,
        resourceFilterSettings: settings
      }
    case FilterLocation.contacts:
      return {
        ...state,
        contactsFilterSettings: settings
      }
    case FilterLocation.checklists:
      return {
        ...state,
        checklistFilterSettings: settings
      }
    case FilterLocation.calendar:
      return {
        ...state,
        calendarFilterSettings: settings
      }
    case FilterLocation.people:
      return {
        ...state,
        peopleFilterSetting: settings
      }
    case FilterLocation.companies:
      return {
        ...state,
        companyFilterSetting: settings
      }
    case FilterLocation.charts:
      return {
        ...state,
        chartFilterSettings: settings
      }
    default:
      return state
  }
}

export function filterReducer(
  state: IFilterState = initialFilterState,
  action: FilterActions
): IFilterState {
  let settings = getFilterSettings(state, action.location)
  if (!settings) {
    return state
  }
  switch (action.type) {
    case 'FILTER_TAGS_INDEX':
      settings = {
        ...settings,
        resourceIndex: action.selected
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_TAGS_DETAILS':
      settings = {
        ...settings,
        resourceDetails: action.selected
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_TAGS_ADD':
      settings = {
        ...settings,
        resourceAdd: action.selected
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_SET_FILTER_DATES':
      settings = {
        ...settings,
        startDate: action.startDate ? new Date(action.startDate) : null,
        endDate: action.endDate ? new Date(action.endDate) : null
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_SET_TESTERS':
      settings = {
        ...settings,
        selectedTesters: action.testers
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_SET_TEAMS':
      settings = {
        ...settings,
        selectedTeams: action.teams
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_SET_PROJECTS':
      settings = {
        ...settings,
        selectedProjects: action.projects
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_SET_TERM':
      settings = {
        ...settings,
        term: action.term
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_SET_RESOURCES':
      settings = {
        ...settings,
        selectedRessources: action.resources
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_SET_CONTACTS':
      settings = {
        ...settings,
        selectedContacts: action.contacts
      }
      return updateFilterState(state, settings, action.location)
    case 'FILTER_RESET':
      return updateFilterState(state, emptyFilterSettings, action.location)
    case 'OVERRIDE_FILTERS':
      return action.filters
    default:
      return state
  }
}
