/* eslint-disable no-console */
import { Component } from 'react';
import Btn from '../button';
import * as Def from './definitions';
interface OneDriveProps {
  onSelect: (files: any, accessToken: any) => Promise<void>;
  onClick: () => void;
}
class OneDrivePicker extends Component<OneDriveProps, {}> {
  private onSelect = (data: any) => {
    console.log('OneDrive Picker :=> Selected =>', data.value);
    const selected = data.value.map((file: Def.OneDriveFile) => ({
      id: file.id,
      name: file.name,
      url: file['@microsoft.graph.downloadUrl'],
      provider: 'one-drive'
    }));
    this.props.onSelect(selected, null);
  };
  private onError = (files: any) => {
    console.log('OneDrive Picker :=> Error', files);
  };
  private onCancel = () => {
    console.log('OneDrive Picker :=> Cancelled');
  };
  private openPicker = () => {
    const odOptions: Def.OneDriveOpenOptions = {
      clientId: `${process.env.REACT_APP_ONEDRIVE_APP_ID}`,
      action: 'download',
      multiSelect: true,
      openInNewWindow: true,
      advanced: {
        redirectUri: process.env.REACT_APP_ONEDRIVE_REDIRECT_URL
        // filter: "folder,.png" // Show only folders and png files
        // filter: "folder,photo" // Show only folders and photos
      },
      success: this.onSelect,
      cancel: this.onCancel,
      error: this.onError
    };
    window.OneDrive.open(odOptions);
    this.props.onClick();
  };
  public render() {
    return <Btn text="One Drive" onClick={this.openPicker} />;
  }
}
export default OneDrivePicker;