import { ChangeEvent, useCallback } from 'react';
import { Pagination } from '@mui/material';
export interface IDataTablePaginationProps {
  currentPage: number;
  totalPages: number;
  onPagination: (page: number) => void;
}
export default function DataTablePagination({
  currentPage,
  totalPages,
  onPagination
}: IDataTablePaginationProps) {
  const handleChange = useCallback((event: ChangeEvent<unknown>, value: number) => {
    onPagination(value);
  }, [onPagination]);
  return <Pagination count={totalPages} variant="text" shape="rounded" onChange={handleChange} page={currentPage} color="primary" />;
}