import { Box, Button, Column, Columns, Content, Media, MediaContent, Notification, Progress } from 'bloomer';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import IResultSet from '../../api/types/resultSet';
import ErrorView from '../../components/ErrorView';
import LoadSpinner from '../../components/loadSpinner/LoadSpinner';
import { AlphaprocessDatabase } from '../../offlineMode/offlineDb';
import ITranslationProps from '../../types/translationProps';
interface IMatch {
  id: string;
}
type Props = ITranslationProps & RouteComponentProps<IMatch>;
interface IState {
  isLoading: boolean;
  resultSet: IResultSet;
  stepCount: number;
  progress: number;
  error: any;
}
class SyncOfflineChecklist extends Component<Props, IState> {
  private offlineDb = new AlphaprocessDatabase();
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      resultSet: null,
      stepCount: 0,
      progress: 0,
      error: null
    };
  }
  public async componentDidMount() {
    const id = parseInt(this.props.match.params.id);
    const resultSet = await this.offlineDb.resultSets.get(id);
    let stepCount = resultSet.results.length;
    if (resultSet.signatures) {
      stepCount += resultSet.signatures.filter(s => s._dirty).length;
    }
    if (resultSet._dirty || resultSet.id < 0) {
      stepCount++;
    }
    this.setState({
      resultSet,
      stepCount,
      isLoading: false
    }, this.syncChecklist);
  }
  private async syncChecklist() {
    const {
      resultSet
    } = this.state;
    const {
      t
    } = this.props;
    const setProgress = (progress: number) => this.setState({
      progress
    });
    await this.offlineDb.syncChecklist(resultSet, t, setProgress);
  }
  private handleBack = () => {
    window.history.back();
  };
  public render() {
    if (this.state.isLoading) {
      return <LoadSpinner className="centered" />;
    }
    const {
      t
    } = this.props;
    const {
      stepCount,
      progress,
      resultSet,
      error
    } = this.state;
    if (stepCount === 0) {
      return <Notification className="sync-offline-checklists" isColor="info">
          {t('offlineMode.noChanges')}
          <Button isPulled="right" isLoading={progress < stepCount} onClick={this.handleBack}>
            {t('Continue')}
          </Button>
        </Notification>;
    }
    const errorView = error ? <ErrorView error={error} /> : null;
    return <Columns isCentered>
        <Column isSize="1/3">
          <Box className="sync-offline-checklists">
            <Media>
              <MediaContent>
                {errorView}
                <Content>
                  <strong>{t('offlineMode.syncInProgress', {
                    title: resultSet.title
                  })}</strong>
                  <Columns>
                    <Column isSize="full">
                      <div className="has-margin-top-5">
                        <Progress max={stepCount} value={progress} style={{
                        marginBottom: '5px'
                      }} />
                        {t('offlineMode.progressMessage', {
                        stepCount,
                        progress
                      })}
                      </div>
                    </Column>
                  </Columns>
                  {progress === stepCount && !error && <Link className=" button is-pulled-right has-margin-right-5" to={`/checklists/${resultSet.id}`}>
                      {t('offlineMode.navigateToChecklist')}
                    </Link>}
                  <Button className="has-margin-right-5" isPulled="right" isLoading={progress < stepCount} onClick={this.handleBack}>
                    {t('common.back')}
                  </Button>
                </Content>
              </MediaContent>
            </Media>
          </Box>
        </Column>
      </Columns>;
  }
}
export default withTranslation()(SyncOfflineChecklist);