import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
export default function FromOverviewToChecklist() {
  const {
    tabIndex,
    areaId,
    areaPosition,
    checklistId,
    id,
    folderId
  } = useParams<{
    tabIndex;
    folderId;
    checklistId;
    id;
    areaId;
    areaPosition;
  }>();

  // eslint-disable-next-line max-len
  const url = `/checklists/tab/${tabIndex}/folders/${folderId}/${checklistId}/audit/${id}/areas/${areaId}/position/${areaPosition}`;
  return <Redirect to={url} />;
}