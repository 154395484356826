import { ApiBase } from './ApiBase'

export class BulkJobsApi extends ApiBase {
  async generateReports(folderIds: string[], checklistIds: number[]) {
    return this.post('/bulk_jobs/report', { folder_ids: folderIds, checklist_ids: checklistIds })
  }

  async generateCombinedReport(folderIds: string[], checklistIds: number[]) {
    return this.post('/bulk_jobs/combined_report', { folder_ids: folderIds, checklist_ids: checklistIds })
  }

  async generateExcel(folderIds: string[], checklistIds: number[]) {
    return this.post('/bulk_jobs/excel', { folder_ids: folderIds, checklist_ids: checklistIds })
  }

  async bulkDelete(folderIds: string[], checklistIds: number[], templateIds: number[]) {
    return this.post(
      '/bulk_jobs/delete',
      { folder_ids: folderIds, checklist_ids: checklistIds, template_ids: templateIds }
    )
  }

  async move(folderIds: any[], checklistIds: any[], templateIds: any[], targetId: string) {
    return this.post('/bulk_jobs/move', {
      folder_ids: folderIds,
      checklist_ids: checklistIds,
      template_ids: templateIds,
      target_id: targetId
    })
  }
}
