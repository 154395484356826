import { Control, Field, Input, TextArea } from 'bloomer';
import clsx from 'clsx';
import { ChangeEvent, useCallback, useState, FocusEvent } from 'react';
import IResult from '../../../../../api/types/result';
import { highlightTimeout } from '../../../../../constants';
import { useEventBus } from '../../../../../pages/checklists/ResultView/EventBus';
interface Props {
  textField: boolean;
  disabled: boolean;
  onChange: (value: string) => void;
  onBlur: (value: string) => Promise<IResult>;
  setTextInputUpdating: (updating: boolean) => void;
  value: string;
  resultId: string;
}
const DELAY = 1000;
function QuestionTextInput({
  textField,
  disabled,
  onChange,
  onBlur,
  value,
  resultId,
  setTextInputUpdating
}: Props) {
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
  const handleChanged = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const {
      value: newVal
    } = e.target;
    onChange(newVal);
    setTextInputUpdating(true);
    if (timer) {
      clearTimeout(timer);
    }
    const id = setTimeout(() => {
      onBlur(newVal);
      setTimer(null);
    }, DELAY);
    setTimer(id);
  }, [onBlur, onChange, timer]);
  const handleBlur = useCallback(async (e: FocusEvent<HTMLInputElement>) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTextInputUpdating(false);
    await onBlur(e.target.value);
  }, [onBlur, timer]);
  const handleFocus = () => {
    setTextInputUpdating(true);
  };
  useEventBus('result', resultId, () => {
    setIsHighlighted(true);
    setTimeout(() => setIsHighlighted(false), highlightTimeout);
  });
  const InputControl = textField ? Input : TextArea;
  return <Field className={clsx('text-input', isHighlighted && 'is-highlighted')}>
      <Control>
        <InputControl type="text" className="has-margin-bottom-5" disabled={disabled} onChange={handleChanged} onBlur={handleBlur} onFocus={handleFocus} value={value || ''} />
      </Control>
    </Field>;
}
export default QuestionTextInput;