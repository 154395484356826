import { Field, Input } from 'bloomer';
import * as React from 'react';
interface Props {
  value: string | null;
  onChange: (value: string | null) => void;
}
function ExternalEmailInput({
  value,
  onChange
}: Props) {
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value.length) {
      onChange('');
      return;
    }
    if (e.target.reportValidity()) {
      onChange(e.target.value);
    }
  };
  return <Field isGrouped>
      <Input type="email" name="external_email" defaultValue={value} required onBlur={onBlur} />
    </Field>;
}
export default ExternalEmailInput;