import { Column, Columns, Container, Notification, Panel, PanelBlock, PanelHeading } from 'bloomer';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { useCurrentUser } from '../../hooks/authHooks';

/**
 *   Displays a message if the user account has no valid license
 */
export default function NoLicense() {
  const {
    t
  } = useTranslation();
  const user = useCurrentUser();
  if (user && user.has_license) {
    return <Redirect to="/" />;
  }
  return <Container className="is-vertical-center">
      <Columns isCentered>
        <Column isSize="1/2">
          <Panel className="signup-box">
            <PanelHeading>
              {t('license.noValidLicense')}
            </PanelHeading>
            <PanelBlock>
              <Notification isColor="warning" isFullWidth>
                {t('license.licenseMustBeActivated')}
              </Notification>
            </PanelBlock>
          </Panel>
        </Column>
      </Columns>
    </Container>;
}