import { compiledBlock as _compiledBlock } from "million/react";
const JSX_headers_1 = _compiledBlock(_props => <th key="multi-select" style={_props.v0}>
        {_props.v1}
      </th>, {
  name: "JSX_headers_1",
  portals: ["v1"]
});
const DataTableHeader_1 = _compiledBlock(_props2 => <thead>
      <tr>
        {_props2.v0}
        <th style={_props2.v1} />
      </tr>
    </thead>, {
  name: "DataTableHeader_1",
  portals: ["v0"]
});
const DataTableHeader = function DataTableHeader(props: Props<T>) {
  const theme = useTheme();
  const isMobileOnly = useMediaQuery(theme.breakpoints.down('sm'));
  let headers = props.selectable ? [/*@million jsx-skip*/<JSX_headers_1 v0={{
    width: isMobileOnly ? '10%' : '1%'
  }} v1={<Checkbox checked={props.allSelected} onChange={props.onSelectAll} />} />] : [];
  headers = headers.concat(props.headers.map(h => {
    let iconClass = defaultSortIcon;
    const sortField = h.sortField || h.field;
    if (props.sortColumn === sortField) {
      iconClass = props.sortDir === 'asc' ? ascSortIcon : descSortIcon;
    }
    const colWidth = h.width || `calc(${100 / props.headers.length}%)`;
    return <th key={h.field} style={{
      width: colWidth,
      minWidth: h.minWidth,
      maxWidth: colWidth
    }} colSpan={h.colSpan} onClick={h.noSort ? null : () => props.onSort(sortField, props.sortDir === 'asc' ? 'desc' : 'asc')}>
        {h.label}
        {' '}
        <Icon className={iconClass} style={{
        visibility: h.noSort ? 'hidden' : undefined
      }} />
        {' '}
      </th>;
  }));
  return /*@million jsx-skip*/<DataTableHeader_1 v0={headers} v1={{
    width: '4%'
  }} />;
};
import { block as _block } from "million/react";
import { Checkbox, Icon } from 'bloomer';
import { ChangeEvent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
export interface IDataTableHeader {
  field: string;
  sortField?: string;
  label: string;
  width?: string;
  noSort?: boolean;
  formatAs?: string;
  colSpan?: number;
  bold?: boolean;
  minWidth?: string;
  hidden?: boolean;
}
export interface IDataTableAction<T> {
  icon: string;
  onClick: (item: T) => void;
  isValidForItem?: (item: T) => boolean;
  isDisabledForItem?: (item: T) => boolean;
  tooltip?: string;
  isHidden?: string;
  className?: string;
}
export interface IDataTableHeaderProps<T> {
  /**
   * Data to be rendered in the <thead> of the table. Not visible on mobile devices.
   */
  headers: IDataTableHeader[];
  /**
   * current sorting column
   */
  sortColumn: string;
  sortDir: string;
  /**
   * The actions this table can execute
   */
  actions: Array<IDataTableAction<T>>;
  /**
   * Function to be called on sort
   */
  onSort: (field: string, direction: 'asc' | 'desc') => void;
  selectable?: boolean;
}
interface Props<T> extends IDataTableHeaderProps<T> {
  onSelectAll: (e: ChangeEvent<HTMLInputElement>) => void;
  allSelected?: boolean;
}
const defaultSortIcon = 'fas fa-sort';
const ascSortIcon = 'fas fa-sort-up';
const descSortIcon = 'fas fa-sort-down';

/**
 * Headers for the DataTable component
 */
export default DataTableHeader;