import { gql } from '@apollo/client'

export interface FetchChatDataResponse {
  users?: (UsersEntity)[]
  conversations?: (ConversationsEntity)[]
}

export interface UsersEntity {
  id: number;
  email: string;
  fullName: string;
  picture?: string | null;
  lastActive: string;
  phone: string;
  status: 'online' | 'offline'
}

export interface ConversationsEntity {
  id: string;
  type?: string | null;
  participants: (UsersEntity)[]
  messages: (MessagesEntity)[]
  unreadCount: number
}

export interface MessagesEntity {
  id: string;
  body: string;
  contentType: string;
  senderId: string
  attachments: string[]
  createdAt: string
  read: boolean
}

const FetchChatData = gql(`
query fetchChatData {
  users {
    id
    email
    fullName
    picture
    lastActive
    phone
    status
  }

  conversations {
    id
    type
    unreadCount
    participants {
      id
      email
      fullName
      picture
      lastActive
      phone
      status
    }
    messages {
      id
      body
      createdAt
      senderId
      attachments
      contentType
      read
    }
  }
}
`)

export default FetchChatData
