import { ApiBase } from './ApiBase'

export interface Lane {
  id?: number
  title: string
  position: number
}

export interface IBoard {
  id?: number
  title: string
  lanes?: Lane[]
  color?: string
  user_ids: number[]
  team_ids: number[]
  project_ids: number[]
  folder_id: string
  corporation_id: number
  restricted: boolean
}

export default class BoardsApi extends ApiBase {
  public async all(accessToken?: string | null, folderId: string = null): Promise<IBoard[]> {
    if (this.isOffline()) {
      return this.offlineDb.boards.toArray()
    }

    const params = {
      access_token: accessToken,
      folder_id: folderId
    }

    const response = await this.get<IBoard[]>('/boards', params)
    return response.data
  }

  public find(id: number) {
    return this.get<IBoard>(`/boards/${id}`)
  }

  public create(board: IBoard) {
    return this.post<IBoard>('/boards', board)
  }

  public update(id: number, values: IBoard) {
    return this.put<IBoard>(`/boards/${id}`, values)
  }

  public deleteBoard(id: number) {
    return super.delete(`/boards/${id}`)
  }
}
