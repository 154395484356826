import { AxiosError } from 'axios'
import {
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from '../../api'
import { FolderParams, IFolder } from '../../api/FolderApi'
import IResultSet from '../../api/types/resultSet'
import { FolderMode } from '../../components/checklists/result/folders/Folder'
import { AlphaprocessDatabase } from '../../offlineMode/offlineDb'
import { ISortSetting } from '../../reducers/sortReducer'
import { IAlphaprocessState } from '../../store'
import { FolderChecklist } from '../../types/FolderChecklist'
import { confirm } from '../../util'
import { consumer } from '../../util/consumer'
import { useCurrentUser } from '../authHooks'

type ChecklistStatus = 'open' | 'closed' | 'due' | 'invited'

export default function useFolderData(
  checklistStatus: ChecklistStatus,
  folder: IFolder,
  mode: FolderMode,
  page: number,
  tabIndex: number
) {
  const { t } = useTranslation()
  const history = useHistory()
  const offlineDb = useMemo(() => new AlphaprocessDatabase(), [])
  const user = useCurrentUser()
  const filters = useSelector((state: IAlphaprocessState) => state.filter.checklistFilterSettings)
  const sort = useSelector((state: IAlphaprocessState) => state.sort)
  const [error, setError] = useState<AxiosError>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const [offlineIds, setOfflineIds] = useState<string[]>([])

  const handleCableUpdate = useCallback(async ({ data: cableData }: {data: IResultSet, user: number}) => {
    setData((current) => {
      const entry = (current as FolderChecklist[]).find((x) => x.id === cableData.id.toString())
      if (!entry) {
        return current
      }

      const updatedEntry: FolderChecklist = {
        ...entry,
        title: cableData.title,
        project: cableData.project,
        resource: cableData.resource,
        progress: cableData.progress,
        audit_points: cableData.audit_points ?? 0
      }

      const dataCopy = [...current] as FolderChecklist[]

      dataCopy[dataCopy.indexOf(entry)] = updatedEntry
      return dataCopy
    })
  }, [])

  const [channel, setChannel] = useState<any>(null)

  const getSortSettings = useCallback((): ISortSetting => {
    if (mode === FolderMode.templates) {
      return sort.createdTemplatesSort
    }

    if (mode === FolderMode.checklists) {
      return sort.activeResultsSort
    }

    return { direction: 'asc', column: 'id' }
  }, [mode, sort.activeResultsSort, sort.createdTemplatesSort])

  const getParams = useCallback((): FolderParams => {
    const sortSettings = getSortSettings()
    return {
      page,
      tester_ids: filters.selectedTesters.map((x) => x.value),
      contact_id: filters.selectedContacts.map((x) => x.value),
      title: filters.term,
      ressource_id: filters.selectedRessources.map((x) => x.value),
      project_id: filters.selectedProjects.map((x) => x.value),
      sort: sortSettings.column,
      direction: sortSettings.direction,
      start_date: filters.startDate ? filters.startDate : null,
      end_date: filters.endDate ? filters.endDate : null,
      team_ids: filters.selectedTeams.map((x) => x.value),
      // ADF-1219 - workaround until recursive filtering is implemented
      parent_id: folder.id,
      filter_mode: 'checklists',
      status: checklistStatus,
      created_end_date: filters.createdEndDate,
      created_start_date: filters.createdStartDate,
      closer_ids: filters.selectedClosers,
      creator_ids: filters.selectedCreators
    }
  }, [checklistStatus, filters, folder.id, getSortSettings, page])

  const fetchChecklists = useCallback(async () => {
    try {
      const params = getParams()
      params.status = checklistStatus
      const result = await api.folders.folderChecklists(params, user.corporation_id)

      const oIds: string[] = []

      for (const checklist of result.data) {
        if (checklist.entity_type !== 'folder' && (await offlineDb.resultSets.get(parseInt(checklist.id)))) {
          oIds.push(checklist.id)
        }
      }

      setTotalPages(result.totalPages)
      setData(result.data)
      setOfflineIds(oIds)

      setChannel((current) => {
        if (current) {
          current.unsubscribe()
        }

        if (user) {
          return consumer.subscriptions.create({
            channel: 'FolderUpdateChannel',
            id: folder.id,
            user_id: user.id
          }, {
            received: handleCableUpdate
          })
        }

        return null
      })
    } catch (e) {
      setError(e as AxiosError)
    } finally {
      setIsLoading(false)
    }
  }, [checklistStatus, folder.id, getParams, handleCableUpdate, offlineDb, user])

  const fetchTemplates = useCallback(async () => {
    const { corporation_id } = user
    try {
      const params = getParams()
      const result = await api.folders.folderTemplates(params, corporation_id)
      const oIds = []

      for (const template of result.data) {
        if (template.entity_type !== 'folder' && (await offlineDb.checklists.get(parseInt(template.id)))) {
          oIds.push(template.id)
        }
      }

      setData(result.data)
      setTotalPages(result.totalPages)
      setOfflineIds(oIds)
    } catch (e) {
      setError(e as AxiosError)
    } finally {
      setIsLoading(false)
    }
  }, [getParams, offlineDb, user])

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    if (mode === FolderMode.checklists) {
      await fetchChecklists()
    } else {
      await fetchTemplates()
    }
  }, [fetchChecklists, fetchTemplates, mode])

  const handleResultSetUpdated = useCallback((resultSet: IResultSet) => {
    setData((stateData) => {
      const dto = stateData.find((x) => x.id === resultSet.id.toString())
      dto.title = resultSet.title
      // dto.testers = resultSet.testers
      // dto.ressource_id = resultSet.ressource_id
      dto.title = resultSet.title

      return [...stateData]
    })
  }, [])

  const onMakeOffline = useCallback(async (item) => {
    await offlineDb.loadDataFromBackend()
    let templateId: number = typeof item.id === 'string' ? parseInt(item.id) : item.id
    try {
      if (item.type === 'result_set') {
        const id = typeof item.id === 'string' ? parseInt(item.id) : item.id
        const resultSet = await api.resultSets.find(0, id)
        if (await offlineDb.resultSets.get(id)) {
          await offlineDb.resultSets.delete(id)
        }
        await offlineDb.resultSets.add(resultSet)
        templateId = resultSet.checklist_id
      }

      const template = await api.checklists.find(templateId)
      if (await offlineDb.checklists.get(templateId)) {
        await offlineDb.checklists.update(templateId, template)
      } else {
        await offlineDb.checklists.add(template)
      }
      toast(t('offlineMode.checklistNowAvailable'), { type: 'success' })
      await fetchData()
    } catch (e) {
      toast((e as Error).message, { type: 'error' })
    }
  }, [fetchData, offlineDb, t])

  const handleClone = useCallback(async ({ id, type }) => {
    setIsLoading(true)
    if (type === 'template') {
      api.checklists.clone(id).then(async () => {
        await fetchData()
      }).catch((e: AxiosError) => {
        setError(e)
      })
    } else {
      try {
        const clone = await api.resultSets.clone(1, id)
        history.push(
          `/checklists/tab/${tabIndex}/folders/${folder.id}/${clone.checklist_id}/audit/${clone.id}`
        )
        await fetchData()
      } catch (e) {
        setError(e as AxiosError)
      }
    }
  }, [fetchData, folder.id, history, tabIndex])

  const updateOfflineChecklist = useCallback(async (item) => {
    const id = typeof item.id === 'number' ? item.id : parseInt(item.id)
    const rs = await offlineDb.resultSets.get(id)
    if (rs._dirty) {
      confirm(null, t('offlineMode.confirm.makeOfflineAvailable'), async () => {
        await onMakeOffline(item)
      })
    } else {
      await onMakeOffline(item)
    }
  }, [offlineDb.resultSets, onMakeOffline, t])

  const handleDeleteTemplate = useCallback(({ id }) => {
    confirm(null, t('ChecklistDeleteConfirmation'), () => {
      api.checklists.deleteChecklist(id).then(() => {
        fetchData()
      })
        .catch((e) => {
          setError(e as AxiosError)
          setIsLoading(false)
        })
    })
  }, [fetchData, t])

  useEffect(() => {
    fetchData().then(() => {}).catch((e:AxiosError) => setError(e))
  }, [fetchData])

  useEffect(() => function () {
    if (channel) {
      channel.unsubscribe()
    }
  }, [channel])

  return {
    error,
    data,
    isLoading,
    totalPages,
    offlineIds,
    refresh: fetchData,
    handleResultSetUpdated,
    handleClone,
    onMakeOffline,
    updateOfflineChecklist,
    handleDeleteTemplate
  }
}
