import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { Badge } from '@mui/material';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';
import SVG from 'react-inlinesvg';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { isCordova } from '../../util';
interface Props {
  id?: string;
  onClick?: () => void;
  isOpen?: boolean;
  text: string;
  icon: string;
  imageIcon?: any;
  to: string;
  isHidden?: boolean;
  disabled?: boolean;
  badgeCount?: number;
  external?: boolean;
}
const JSX_menuEntryIcon_1 = _compiledBlock(_props => <span className="image-icon">
      <i className={_props.v0} />
    </span>, {
  name: "JSX_menuEntryIcon_1"
});
const JSX_menuEntryIcon_2 = _compiledBlock(_props2 => <span className="image-icon">
        {_props2.v0}
      </span>, {
  name: "JSX_menuEntryIcon_2",
  portals: ["v0"]
});
const JSX_content_1 = _compiledBlock(_props3 => <div data-tip={_props3.v0} data-for="main-tooltip" data-place="right">
      {_props3.v1}
      <span className={_props3.v2}>
        {' '}
        {_props3.v3}
      </span>
    </div>, {
  name: "JSX_content_1",
  portals: ["v1", "v3"]
});
const JSX_link_1 = _compiledBlock(_props4 => _props4.v0, {
  name: "JSX_link_1",
  portals: ["v0"]
});
const JSX_link_2 = _compiledBlock(_props5 => <a>
      {_props5.v0}
    </a>, {
  name: "JSX_link_2",
  portals: ["v0"]
});
const RootMenuItem_1 = _compiledBlock(_props6 => <a href="#" onClick={_props6.v0}>{_props6.v1}</a>, {
  name: "RootMenuItem_1",
  portals: ["v1"]
});
const RootMenuItem_2 = _compiledBlock(_props7 => <div className={_props7.v0} id={_props7.v1} onClick={_props7.v2}>
      {_props7.v3}
    </div>, {
  name: "RootMenuItem_2",
  portals: ["v3"]
});
const RootMenuItem: FunctionComponent<Props> = ({
  imageIcon,
  text,
  to,
  icon,
  isHidden = false,
  isOpen,
  id,
  onClick,
  disabled,
  badgeCount,
  external = false
}) => {
  const location = useLocation();
  const menuEntryIcon = !imageIcon ? /*@million jsx-skip*/<JSX_menuEntryIcon_1 v0={icon} /> : /*@million jsx-skip*/<JSX_menuEntryIcon_2 v0={<SVG src={imageIcon} />} />;
  const content = /*@million jsx-skip*/<JSX_content_1 v0={!isMobile && !isCordova() && !isOpen ? text : null} v1={menuEntryIcon} v2={clsx('root-menu-text', isOpen && 'is-open')} v3={text} />;
  let active = location.pathname === to;
  if (to === '/checklists') {
    active = location.pathname.startsWith('/checklists');
  }
  let link = to && !disabled ? /*@million jsx-skip*/<JSX_link_1 v0={<Link to={to}>
      {content}
    </Link>} /> : /*@million jsx-skip*/<JSX_link_2 v0={content} />;
  if (external) {
    link = /*@million jsx-skip*/<RootMenuItem_1 v0={() => window.open(to, '_system')} v1={content} />;
  }
  return /*@million jsx-skip*/<RootMenuItem_2 v0={clsx('root-menu-item', active && 'is-active', isHidden && 'is-hidden', disabled && 'is-disabled')} v1={id} v2={disabled ? undefined : onClick} v3={<Badge badgeContent={badgeCount} color="primary">
        {link}
      </Badge>} />;
};
export default RootMenuItem;