import localforage from 'localforage'
import 'localforage-cordovasqlitedriver/dist/localforage-cordovasqlitedriver'
import { combineReducers, createStore } from 'redux'
import {
  createMigrate, createTransform, PersistConfig, persistReducer, persistStore
} from 'redux-persist'
import * as yup from 'yup'
import yupLocaleDe from 'yup-locale-de'

import api from './api'
import { fallbackCorporationSetting, fallbackEmailSetting } from './constants'
import i18n from './i18n'
import coreReducer, { ICoreState } from './reducers/coreReducer'
import { dataReducer, DataStore } from './reducers/dataReducer'
import {
  emptyFilterSettings, filterReducer, IFilterState, initialFilterState
} from './reducers/filterReducer'
import { initialState, ISortState, sortReducer } from './reducers/sortReducer'
import { isCordova } from './util'
import DateUtil from './util/DateUtil'

if (isCordova()) {
  localforage.defineDriver((window as any).cordovaSQLiteDriver).then(() => localforage.setDriver([
    // Try setting cordovaSQLiteDriver if available,F
    (window as any).cordovaSQLiteDriver?._driver,
    // otherwise use one of the default localforage drivers as a fallback.
    // This should allow you to transparently do your tests in a browser
    localforage.INDEXEDDB,
    localforage.WEBSQL,
    localforage.LOCALSTORAGE
  ]))
} else {
  localforage.config({
    driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name: 'alphaprocess',
    version: 1.0,
    size: 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName: 'alphaprocess', // Should be alphanumeric, with underscores.
    description: 'storage for alphaprocess'
  })
}

const migrations: any = {
  1: (state: any) => {
    // migration clear out device state
    const { selectedFilterTags, ...rest } = state

    const filter = {
      ...selectedFilterTags,
      selectedProjects: [],
      selectedTeams: [],
      selectedTesters: [],
      endDate: null,
      startDate: null,
      term: ''
    }

    return {
      core: rest,
      filter
    }
  },
  2: (state: any) => {
    // reset filters

    const { selectedFilterTags } = state

    const filter = {
      ...selectedFilterTags,
      selectedRessources: [],
      selectedContacts: []
    }

    return {
      ...state,
      filter
    }
  },
  3: (state: any) => ({
    ...state,
    sort: initialState
  }),
  4: (state: IAlphaprocessState) => {
    const core: ICoreState = {
      ...state.core,
      currentLanguage: navigator.language.substr(0, 2)
    }
    return {
      ...state,
      core
    }
  },
  5: (state: IAlphaprocessState): IAlphaprocessState => ({
    ...state,
    filter: initialFilterState
  }),
  6: (state: IAlphaprocessState): IAlphaprocessState => ({
    ...state,
    filter: {
      ...state.filter,
      companyFilterSetting: {
        ...emptyFilterSettings
      }
    }
  }),
  7: (state: IAlphaprocessState): IAlphaprocessState => ({
    ...state,
    filter: {
      ...state.filter,
      chartFilterSettings: {
        ...emptyFilterSettings
      }
    }
  }),
  8: (state: IAlphaprocessState): IAlphaprocessState => ({
    ...state,
    core: {
      ...state.core,
      startWithTemplateOpen: true
    }
  })
}

const coreTransform = createTransform(null, (state: ICoreState) => {
  if (state.user && state.user.corporation && !state.user.corporation.corporation_setting) {
    state.user.corporation.corporation_setting = fallbackCorporationSetting
  }

  if (state.user && state.user.corporation && !state.user.corporation.email_setting) {
    state.user.corporation.email_setting = fallbackEmailSetting
  }

  return state
}, { whitelist: ['core'] })

const persistConfig: PersistConfig<IAlphaprocessState> = {
  key: 'alphaprocess',
  storage: localforage,
  version: 2,
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV !== 'production' }),
  transforms: [coreTransform],
  blacklist: ['data']
}

export interface IAlphaprocessState {
  core: ICoreState
  filter: IFilterState
  sort: ISortState
  data: DataStore
}

const combinedReducers = combineReducers<IAlphaprocessState>({
  core: coreReducer, filter: filterReducer, sort: sortReducer, data: dataReducer
})

const persistedReducer = persistReducer(persistConfig, combinedReducers)

const store = createStore(persistedReducer)
const persistor = persistStore(store, null, () => {
  const lng = store.getState().core.currentLanguage || navigator.language.substr(0, 2)
  i18n.changeLanguage(lng)
  DateUtil.setLocale(lng)
  yup.setLocale(yupLocaleDe)
  api.addToken(store.getState().core.jwt)
})

export {
  store,
  persistor
}
