import { styled } from '@mui/material/styles';
import { Card, Column, Columns, Notification } from 'bloomer';
import { PureComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FilterLocation, setProjects } from '../../actions/filterActions';
import api from '../../api';
import { ICorporationSetting } from '../../api/types/corporation';
import { FolderMode } from '../../components/checklists/result/folders/Folder';
import FolderWrapper from '../../components/checklists/result/folders/FolderWrapper';
import ScheduledChecklistsTable from '../../components/checklists/result/ScheduledChecklistsTable';
import { PowerUserView } from '../../components/PowerUserView';
import TabPanel, { a11yProps } from '../../components/TabPanel';
import StartWithTemplate from '../../components/template_store/StartWithTemplate';
import ActiveFilterMessage from '../../components/widgets/ActiveFilterMessage';
import { IAlphaprocessState } from '../../store';
import ISelectListValueModel from '../../types/selectListModel';
import ITranslationProps from '../../types/translationProps';
import ResultView from './ResultView/ResultView';
interface IState {
  openChecklist: number | null;
  currentTab: number;
  isFullscreen: boolean;
  hasTemplates: boolean;
  folderId: string | null;
}
interface IStateProps {
  corporationSettings?: ICorporationSetting | null;
  isLoggedIn?: boolean | null;
  isOffline: boolean;
}
interface IDispatchProps {
  setProjects: (projects: Array<ISelectListValueModel<any>>) => void;
}
interface IParams {
  id: string;
  folderId?: string | null;
  templateId?: string | null;
  tabIndex?: string | null;
}
type Props = IStateProps & RouteComponentProps<IParams> & IDispatchProps & ITranslationProps;
const ResultColumn = styled('div')(({
  theme
}) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: '25%',
    marginLeft: '25%'
  }
}));
const StyledTab = styled(Tab)(({
  theme
}) => ({
  [theme.breakpoints.down('sm')]: {
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(1)
    }
  }
}));
class ProcessOverview extends PureComponent<Props, IState> {
  constructor(props: Props) {
    super(props);
    const {
      id,
      tabIndex,
      folderId
    } = this.props.match.params;
    const openChecklist = id ? parseInt(id) : null;
    this.state = {
      openChecklist,
      currentTab: tabIndex ? parseInt(tabIndex) : 0,
      isFullscreen: false,
      hasTemplates: true,
      folderId: folderId ?? null
    };
  }
  public async componentDidMount() {
    const {
      id,
      templateId,
      tabIndex
    } = this.props.match.params;
    const urlParams = new URLSearchParams(this.props.location.search);
    const accessToken = urlParams.get('access_token');
    if (typeof tabIndex === 'undefined' && !accessToken && !id && !templateId) {
      this.handleChangeTab(this.state.currentTab, true);
    }
    const fullscreen = urlParams.get('fullscreen');
    if (id && templateId || fullscreen) {
      this.setState({
        isFullscreen: true
      });
    }

    // User accesses checklist authenticated with a token
    if (this.isExternalAccess()) {
      return;
    }
    if (!this.isExternalAccess() && !this.props.isLoggedIn) {
      this.props.history.push('/login');
    }
    const params = new URLSearchParams(this.props.location.search);
    const project_id = params.get('project_id');
    const project_name = params.get('project_name');
    if (project_id) {
      this.props.setProjects([{
        value: parseInt(project_id),
        label: project_name
      }]);
    }
    const templates = await api.checklists.all({
      page: 1
    });
    if (templates.totalCount === 0) {
      this.setState({
        hasTemplates: false
      });
    }
  }
  public componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        openChecklist: this.props.match.params.id ? parseInt(this.props.match.params.id) : null
      });
    }
    if (this.props.match.params.folderId !== prevProps.match.params.folderId) {
      this.setState({
        folderId: this.props.match.params.folderId
      });
    }
    if (this.props.match.params.tabIndex !== prevProps.match.params.tabIndex) {
      const currentTab = Number.isInteger(parseInt(this.props.match.params.tabIndex)) ? parseInt(this.props.match.params.tabIndex) : 0;
      this.setState({
        currentTab,
        folderId: null
      });
    }
    if (!this.props.isLoggedIn && prevProps.isLoggedIn && !this.isExternalAccess()) {
      this.props.history.push('/login');
    }
  }
  private handleResultSetViewClosed = (reload: boolean) => {
    this.setState({
      openChecklist: null
    });
    this.props.history.replace('/checklists');
    if (reload) {
      this.setState({
        currentTab: 1
      });
    }
  };
  private handleUpdateCurrentFolder = (folderId: string) => {
    this.setState({
      folderId
    });
    const url = `/checklists/tab/${this.state.currentTab}/folders/${folderId}`;
    this.props.history.push(url);
  };

  // @ts-ignore
  private handleSelect = ({
    id
  }) => {
    this.setState({
      openChecklist: id
    });
    this.props.history.push(`/checklists/${id}`);
  };
  private handleChangeTab = (tabIndex: number, noPush = false) => {
    this.setState({
      currentTab: tabIndex,
      folderId: null
    });
    const url = `/checklists/tab/${tabIndex}`;
    if (!noPush) {
      this.props.history.push(url);
    }
  };

  // toggle fullscreen
  private handleFullScreen = resultSet => {
    const isFullscreen = !this.state.isFullscreen;
    const url = isFullscreen ? `/checklists/${resultSet.checklist_id}/audit/${resultSet.id}` : `/checklists/${resultSet.id}`;
    this.props.history.push(url);
    this.setState({
      isFullscreen
    });
  };
  private isExternalAccess = () => {
    if (this.props.isLoggedIn) {
      return false;
    }
    const urlParams = new URLSearchParams(this.props.location.search);
    const accessToken = urlParams.get('access_token');
    return !!accessToken;
  };
  public render() {
    const {
      isOffline,
      t
    } = this.props;
    const {
      currentTab,
      openChecklist,
      isFullscreen,
      folderId
    } = this.state;
    const desktopColumnSize = openChecklist ? '1/2' : 'full';
    const wideScreenColumnSize = openChecklist ? 7 : 'full';
    const resultViewContent = <Card className="result-view-card">
        <ResultView resultSetId={openChecklist} onClose={this.handleResultSetViewClosed} context="checklists" onFullscreen={this.handleFullScreen} isFullscreen={isFullscreen} isExternalAccess={this.isExternalAccess()} tabIndex={currentTab} folderId={folderId} />
      </Card>;
    const resultView = openChecklist && !isMobileOnly ? <Column id="result-column">
        {resultViewContent}
      </Column> : null;

    // external users that have no account see only the checklist and not the columns based layout
    if (openChecklist && (this.isExternalAccess() || isMobileOnly)) {
      return <ResultColumn id="result-column">
          {resultViewContent}
        </ResultColumn>;
    }

    // required because without this it will crash if a user does logout while he is on this page
    if (!this.isExternalAccess() && !this.props.isLoggedIn) {
      return null;
    }

    // Doesn't accept string[] but can't import AllPlatforms[] type from bloomer
    const hideOverview: any[] = openChecklist && isFullscreen || isMobileOnly && openChecklist ? ['mobile', 'desktop', 'tablet'] : null;
    const containerClass = isFullscreen ? 'checklist-overview has-fullscreen-margin' : 'checklist-overview';
    return <div className={containerClass}>
        <Columns className="overview-columns">
          <Column isSize={{
          mobile: desktopColumnSize,
          tablet: desktopColumnSize,
          desktop: desktopColumnSize,
          widescreen: wideScreenColumnSize
        }} isHidden={hideOverview} className="overview-content">
            <ActiveFilterMessage filterLocation={FilterLocation.checklists} />
            <Notification className="is-hidden-tablet" isColor="info" isHidden={this.state.hasTemplates}>
              <Trans>overview.createTemplatesOnDesktop</Trans>
            </Notification>
            <Tabs value={currentTab} onChange={(e, index) => this.handleChangeTab(index)} sx={{
            mb: {
              xs: 1,
              md: 2
            },
            ml: {
              sm: 2
            }
          }} variant="scrollable" scrollButtons="auto">
              <StyledTab label={t('overview.tabs.open')} {...a11yProps(0)} />
              <StyledTab label={t('overview.tabs.closed')} {...a11yProps(1)} />
              <StyledTab label={t('overview.tabs.invited')} {...a11yProps(2)} />
              {this.props.corporationSettings.use_schedules && <StyledTab label={t('overview.tabs.scheduled')} disabled={isOffline} sx={{
              display: isOffline ? 'none' : undefined
            }} {...a11yProps(3)} />}
              <StyledTab label={t('overview.tabs.templates')} {...a11yProps(4)} />
            </Tabs>
            {!this.props.isOffline && <PowerUserView>
              <StartWithTemplate compact={!!openChecklist} />
            </PowerUserView>}
            <TabPanel index={0} value={currentTab}>
              <FolderWrapper onSelect={this.handleSelect} selectedId={openChecklist} mode={FolderMode.checklists} checklistStatus="open" currentFolderId={folderId} onUpdateCurrentFolder={this.handleUpdateCurrentFolder} tabIndex={currentTab} />
            </TabPanel>
            <TabPanel index={1} value={currentTab}>
              <FolderWrapper onSelect={this.handleSelect} checklistStatus="closed" mode={FolderMode.checklists} currentFolderId={folderId} onUpdateCurrentFolder={this.handleUpdateCurrentFolder} tabIndex={currentTab} />
            </TabPanel>
            <TabPanel index={2} value={currentTab}>
              <FolderWrapper onSelect={this.handleSelect} checklistStatus="invited" mode={FolderMode.checklists} currentFolderId={folderId} onUpdateCurrentFolder={this.handleUpdateCurrentFolder} tabIndex={currentTab} />
            </TabPanel>
            {this.props.corporationSettings.use_schedules && <TabPanel index={3} value={currentTab}>
              <ScheduledChecklistsTable />
            </TabPanel>}
            <TabPanel index={4} value={currentTab}>
              <FolderWrapper onSelect={this.handleSelect} checklistStatus="open" mode={FolderMode.templates} currentFolderId={folderId} onUpdateCurrentFolder={this.handleUpdateCurrentFolder} tabIndex={currentTab} />
            </TabPanel>
          </Column>
          {resultView}
        </Columns>

      </div>;
  }
}
const mapState = (state: IAlphaprocessState): IStateProps => ({
  corporationSettings: state.core.user?.corporation?.corporation_setting,
  isLoggedIn: !!state.core.jwt,
  isOffline: state.core.isOffline
});
const mapDispatch = (dispatch: (x: any) => void): IDispatchProps => ({
  setProjects: (projects: Array<ISelectListValueModel<any>>) => dispatch(setProjects(projects, FilterLocation.checklists))
});
export default connect(mapState, mapDispatch)(withTranslation()(ProcessOverview));