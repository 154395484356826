import { isIOS, isSafari } from 'react-device-detect'
import ChecklistUtil from './ChecklistUtil'
import { alert, confirm } from './dialogs'

export function isEmpty(obj: any) {
  return Object.keys(obj).length > 0
}

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

export function setWithExpiry(key: string, value: any, ttl: number) {
  const item = {
    value,
    expiry: new Date().getTime() + ttl
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export function blob2file(blobData: Blob, filename: string) {
  const fd = new FormData()
  fd.set('a', blobData, filename)
  return fd.get('a') as File
}
export function singularOrPlural(count: number, singularText: string, pluralText) {
  return count === 1 ? `${count} ${singularText}` : `${count} ${pluralText}`
}

// Filter an array of objects by a search String.
// This is used for some search features
export function filterObjects<T>(objects: T[], searchString: string): T[] {
  return objects.filter((object) => {
    if (!searchString) {
      return true
    }
    let result = false
    Object.keys(object).forEach((key) => {
      if (typeof object[key] === 'string'
        && object[key].toLowerCase().includes(searchString.toLowerCase())) {
        result = true
      }
    })

    return result
  })
}

export const isCordova = () => ('cordova' in window)

const prependZeroIfLowerThanTen = (i: number) => (i < 10 ? '0' : '') + i

export function toDateString(date: Date) {
  const YYYY = date.getFullYear()
  const MM = prependZeroIfLowerThanTen(date.getMonth() + 1)
  const DD = prependZeroIfLowerThanTen(date.getDate())
  return `${YYYY}-${MM}-${DD}`
}

// Convert Javascript Date to the datetime-local value format
export function toDatetimeLocal(date: Date) {
  const YYYY = date.getFullYear()
  const MM = prependZeroIfLowerThanTen(date.getMonth() + 1)
  const DD = prependZeroIfLowerThanTen(date.getDate())
  const HH = prependZeroIfLowerThanTen(date.getHours())
  const II = prependZeroIfLowerThanTen(date.getMinutes())
  const SS = prependZeroIfLowerThanTen(date.getSeconds())

  // Workaround for buggy implementation of datetime-local in older iOS Safari versions
  // https://www.reddit.com/r/webdev/comments/6pxfn3/ios_datetimelocal_inputs_broken_universally/
  if (isSafari || isIOS) {
    return `${YYYY}-${MM}-${DD}T${HH}:${II}`
  }

  return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`
}

export function toDate(date: Date) {
  const YYYY = date.getFullYear()
  const MM = prependZeroIfLowerThanTen(date.getMonth() + 1)
  const DD = prependZeroIfLowerThanTen(date.getDate())

  return `${YYYY}-${MM}-${DD}`
}

export function compareValues(key: string, order = 'asc') {
  return (a: any, b: any) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0
    }
    const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
    const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    )
  }
}

export function range(start: number, stop: number, step = 1) {
  if (stop === null) {
    stop = start || 0
    start = 0
  }

  const length = Math.max(Math.ceil((stop - start) / step), 0)
  const rangeArray = Array(length)

  for (let idx = 0; idx < length; idx++, start += step) {
    rangeArray[idx] = start
  }

  return rangeArray
}

export function createFormData(object: object, form?: FormData, namespace?: string): FormData {
  const formData = form || new FormData()
  for (const property in object) {
    if (!object.hasOwnProperty(property) || typeof object[property] === 'undefined') {
      continue
    }
    const formKey = namespace ? `${namespace}[${property}]` : property
    if (object[property] instanceof Date) {
      formData.append(formKey, object[property].toISOString())
    } else if (Array.isArray(object[property])) {
      for (const i of object[property]) {
        formData.append(`${formKey}[]`, i)
      }
    } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
      createFormData(object[property], formData, formKey)
    } else {
      formData.append(formKey, object[property])
    }
  }
  return formData
}

export function selectMany<T>(arr: any[], keyGetter: (x: any) => T[]): T[] {
  // part of the fix for ADF-735: calling reduce() with an empty array causes a javascript error
  if (!arr.length) {
    return []
  }
  return arr.map((x) => keyGetter(x)).reduce((a, b) => a.concat(b))
}

export const groupBy = (key: string, array: any[]): any[] => array.reduce((objectsByKeyValue, obj) => {
  const value = obj[key]
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
  return objectsByKeyValue
}, {})

const ellipsis = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text
  }
  return `${text.substr(0, maxLength - 3)}...`
}

export {
  alert,
  confirm,
  ChecklistUtil,
  ellipsis
}
