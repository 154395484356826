import { gql } from '@apollo/client'
import { ConversationsEntity } from '../queries/FetchChatData'

export interface CreateConversationParams {
    participantIds: string[]
}

export interface CreateConversationResponse {
    createConversation: {
        errors: string[]
        conversation: ConversationsEntity
    }
}

const CreateConversationMutation = gql`
    mutation createConversation($participantIds: [ID!]!) {
        createConversation(participantIds: $participantIds) {
            errors
            conversation {
                id
                messages {
                    attachments
                    body
                    contentType
                    createdAt
                    id
                    senderId
                }
                participants {
                    id
                    email
                    fullName
                    lastActive
                    picture
                }
                type
            }
        }
    }
`

export default CreateConversationMutation
