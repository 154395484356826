import Select from 'react-select';
import { ICompany } from '../../../api/CompanyApi';
interface Props {
  companies: ICompany[];
  isMulti?: boolean;
  selected: string | string[];
  placeholder: string;
  onChange: (value: string | string[]) => void;
}
function CompanySelect(props: Props) {
  const handleChange = (values: any) => {
    if (Array.isArray(values)) {
      props.onChange(values.map(v => v.value));
    } else {
      props.onChange(values.value);
    }
  };
  const options = props.companies.map(x => ({
    label: x.name,
    value: x.id
  }));
  const selected = Array.isArray(props.selected) ? options.filter(o => (props.selected as string[]).includes(o.value)) : options.find(o => o.value === props.selected);
  return <Select options={options} value={selected} isMulti={props.isMulti} placeholder={props.placeholder} onChange={handleChange} menuPortalTarget={document.body} menuPlacement="auto" styles={{
    menuPortal: base => ({
      ...base,
      zIndex: 9999
    })
  }} />;
}
export default CompanySelect;