/* tslint:disable:no-console */
import * as Sentry from '@sentry/browser';
import Axios from 'axios';
import { Button, Notification } from 'bloomer';
import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import ITranslationProps from '../types/translationProps';
import LoadSpinner from './loadSpinner/LoadSpinner';
import Overlay from './widgets/Overlay';
interface IState {
  newVersionAvailable: boolean;
  timer: NodeJS.Timeout;
  canceled: boolean;
  isLoading: boolean;
}
const interval = 5 * 60 * 1000;
interface Props extends ITranslationProps {
  alwaysVisible?: boolean;
}

/**
 * This component checks if a new version is available.
 * A request is made to /version every five minutes.
 * The result is then compared to REACT_APP_COMMIT_SHA.
 */
class VersionChecker extends PureComponent<Props, IState> {
  public state: IState = {
    newVersionAvailable: false,
    canceled: false,
    timer: null,
    isLoading: false
  };
  public checkForNewVersion = async () => {
    if ('cordova' in window) {
      return;
    }
    try {
      const result = await Axios.get<string>('/version');
      if (typeof result.data !== 'string') {
        return;
      }
      const newVersion = result.data.trimEnd();
      if (process.env.REACT_APP_COMMIT_SHA !== newVersion) {
        this.setState({
          newVersionAvailable: true
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error checking for new version', error);
    }
  };
  private handleReload = async () => {
    if ('serviceWorker' in navigator) {
      this.setState({
        isLoading: true
      });

      // no service worker just reload
      if (!navigator.serviceWorker.controller) {
        window.location.reload();
      }
      try {
        // remove the service worker then reload
        const registration = await navigator.serviceWorker.ready;
        await registration.unregister();
        const cacheNames = await window.caches.keys();
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
        window.location.reload();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error removing service worker', error);
        this.setState({
          isLoading: false
        });
        Sentry.withScope(() => {
          Sentry.captureException(error);
          window.location.reload();
        });
      }
    } else {
      window.location.reload();
    }
  };
  public async componentDidMount() {
    (window as any).versionChecker = this;
    if (process.env.NODE_ENV === 'production') {
      await this.checkForNewVersion();
      const timer = setInterval(this.checkForNewVersion, interval);
      this.setState({
        timer
      });
    }
  }
  public componentWillUnmount() {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  }
  public render() {
    const {
      t,
      alwaysVisible
    } = this.props;
    if (this.state.canceled) {
      return null;
    }
    if (this.state.newVersionAvailable || alwaysVisible) {
      return <Notification isColor="info">
          <p>
            {t('InfoNewVersionAvailable')}
          </p>
          <Button onClick={this.handleReload}>{t('ReloadNow')}</Button>
          {this.state.isLoading && <Overlay>
            {' '}
            <LoadSpinner className="centered" />
            {' '}
          </Overlay>}
        </Notification>;
    }
    return null;
  }
}
export default withTranslation()(VersionChecker);