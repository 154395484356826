// material
import { styled } from '@mui/material/styles';
import { Card, Grid, CardContent, Typography, Chip, Stack } from '@mui/material';
import { useCallback } from 'react';
import { TemplateStoreEntriesEntity } from '../../graphql/queries/FetchTemplateStoreEntries';
import SvgIconStyle from '../SvgIconStyle';
export const CardMediaStyle = styled('div')(() => ({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
}));
export const TitleStyle = styled('div')(({
  theme
}) => ({
  ...theme.typography.subtitle2,
  height: 44,
  color: 'inherit',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));
export const CoverImgStyle = styled('img')(() => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
}));

// ----------------------------------------------------------------------

type BlogPostCardProps = {
  entry: TemplateStoreEntriesEntity;
  onClick: (entry: TemplateStoreEntriesEntity) => void;
};
export default function TemplateStoreEntryDetailsCard({
  entry,
  onClick
}: BlogPostCardProps) {
  const {
    image,
    name,
    templateStoreCategory,
    tags
  } = entry;
  const handleClick = useCallback(() => onClick(entry), [entry, onClick]);
  const imageUrl = entry.image ? `${process.env.REACT_APP_API_BASE_URL}${image}` : null;
  return <Grid item xs={12} sm={6} md={3}>
      <Card onClick={handleClick} sx={{
      position: 'relative'
    }}>
        <CardMediaStyle>
          <SvgIconStyle color="paper" src="/static/icons/shape-avatar.svg" sx={{
          width: 80,
          height: 36,
          zIndex: 9,
          bottom: -15,
          position: 'absolute'
        }} />

          <CoverImgStyle alt={name} src={imageUrl} />
        </CardMediaStyle>

        <CardContent sx={{
        pt: 4
      }}>
          <Typography gutterBottom variant="caption" sx={{
          color: 'text.disabled',
          display: 'block'
        }}>
            {templateStoreCategory.name}
          </Typography>
          <TitleStyle>
            {name}
          </TitleStyle>
          <Stack direction="row" sx={{
          flexWrap: 'wrap',
          mt: 1
        }}>
            {tags.map(tag => <Chip sx={{
            mr: 1
          }} key={tag.id} label={tag.title} color="primary" size="small" />)}
          </Stack>
        </CardContent>
      </Card>
    </Grid>;
}