import {
  confirmAlert
} from 'react-confirm-alert'
import {
  getI18n
} from 'react-i18next'

export const confirm = (
  title: string,
  message: string,
  onYes: () => void,
  onNo?: () => void
) => confirmAlert({
  buttons: [{
    label: getI18n().t('Yes'),
    onClick: onYes
  },
  {
    label: getI18n().t('No'),
    onClick: onNo || null
  }
  ],
  message,
  title
})

export const alert = (message: string) => confirmAlert({
  buttons: [{
    label: getI18n().t('Ok'),
    onClick: () => { }
  }],
  message
})
