/* eslint import/no-webpack-loader-syntax: off */

import { Image } from 'bloomer';
import { useCallback, useEffect } from 'react';
import ReactAvatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { isMobile, isTablet } from 'react-device-detect';
import clsx from 'clsx';
import { toggleMenu } from '../../actions/menuActions';
import { ICorporationSetting } from '../../api/types/corporation';
import { fallbackCorporationSetting } from '../../constants';
import { useCurrentUser, useIsPowerUser } from '../../hooks/authHooks';
import useChatContext from '../../hooks/useChatContext';
import useIsOffline from '../../hooks/useIsOffline';
import alphaprocessLogo from '../../images/alphaprocess-logo.png';
import { IAlphaprocessState } from '../../store';
import { isCordova } from '../../util';
import { AdminOnlyView } from '../AdminOnlyView';
import RootMenuItem from './RootMenuItem';
import calendarIcon from '!!url-loader!../../images/menu-icons/calendar.svg';
import chartIcon from '!!url-loader!../../images/menu-icons/chart.svg';
import chatIcon from '!!url-loader!../../images/menu-icons/chat.svg';
import supportIcon from '!!url-loader!../../images/menu-icons/support.svg';
import checksIcon from '!!url-loader!../../images/menu-icons/checks.svg';
import companiesIcon from '!!url-loader!../../images/menu-icons/companies.svg';
import contactsIcon from '!!url-loader!../../images/menu-icons/contacts.svg';
import corporationIcon from '!!url-loader!../../images/menu-icons/corporation.svg';
import recteangleList from '!!url-loader!../../images/menu-icons/rectangle-list.svg';
import homeIcon from '!!url-loader!../../images/menu-icons/home.svg';
import leftIcon from '!!url-loader!../../images/menu-icons/Left.svg';
import peopleIcon from '!!url-loader!../../images/menu-icons/people.svg';
import resourcesIcon from '!!url-loader!../../images/menu-icons/resources.svg';
import rightIcon from '!!url-loader!../../images/menu-icons/Right.svg';
import tasksIcon from '!!url-loader!../../images/menu-icons/tasks.svg';
import todoIcon from '!!url-loader!../../images/menu-icons/todo.svg';
import wikiIcon from '!!url-loader!../../images/menu-icons/wiki.svg';
export default function SideMenu() {
  const {
    t
  } = useTranslation();
  const user = useCurrentUser();
  const isPowerUser = useIsPowerUser();
  const isOpen = useSelector((state: IAlphaprocessState) => state.core.isMenuOpen);
  const isOffline = useIsOffline();
  const logo = user.corporation?.logo?.menu?.url;
  const wikiLink = user.corporation.wiki_link;
  const chatLink = user.corporation.chat_link;
  const dispatch = useDispatch();
  const {
    totalUnreadMessages
  } = useChatContext();
  useEffect(() => {
    if (!isMobile && !isCordova()) {
      ReactTooltip.rebuild();
    }
  });
  const toggle = useCallback(() => {
    dispatch(toggleMenu());
  }, [dispatch]);
  const settings: ICorporationSetting = user.corporation?.corporation_setting ?? fallbackCorporationSetting;
  const appLogo = logo ? <Image src={logo} className="app-logo has-image-centered" style={{
    width: '128px'
  }} /> : <img src={alphaprocessLogo} alt="logo" className="default-app-logo" />;

  //const { intercom } = window as any

  // open specific links in external Window (e.g. for Comwave GmbH [492])          
  const isSpecificCorporation = user.corporation?.id === 492; // 

  return <div className={clsx('side-menu', 'menu-items-wrapper', isOpen && 'is-open')}>
      <div className="has-text-centered app-logo">
        {isOpen ? <Link to="/">
            {appLogo}
          </Link> : <div className="spacingBlock" />}
      </div>
      <div className="menu-items">

        <RootMenuItem to="/" text={t('menu.home')} icon="fas fa-home" imageIcon={homeIcon} isOpen={isOpen} />
        <RootMenuItem to="/checklists" text={t('menu.checklists')} icon="fas fa-check-double" imageIcon={checksIcon} isOpen={isOpen} />
        <RootMenuItem to="/templates" text={t('menu.templates')} icon="far fa-list-alt" imageIcon={recteangleList} isOpen={isOpen} />
        {settings && settings.use_tasks && <RootMenuItem to="/boards" text={t('menu.tasks')} icon="fas fa-tasks" imageIcon={tasksIcon} isOpen={isOpen} disabled={isOffline} />}
        {settings && settings.use_bpmn && (!isMobile || isTablet) && <RootMenuItem to="/charts" text={t('menu.charts')} icon="fas fa-chart-bar" imageIcon={chartIcon} isOpen={isOpen} disabled={isOffline} />}
        {settings && settings.use_todo_lists && <RootMenuItem to="/todos" text={t('menu.todo')} icon="fas fa-list-ul" imageIcon={todoIcon} isOpen={isOpen} disabled={isOffline} />}
        <RootMenuItem to="/calendar" text={t('menu.calendar')} icon="fas fa-calendar" imageIcon={calendarIcon} isHidden={!user.has_valid_license} isOpen={isOpen} disabled={isOffline} />
        <AdminOnlyView>
          <RootMenuItem to="/tenant/profile" text={t('menu.companyProfile')} icon="fas fa-building" imageIcon={corporationIcon} isOpen={isOpen} disabled={isOffline} />
        </AdminOnlyView>
        {isPowerUser && <RootMenuItem to="/people" text={t('People')} icon="fas fa-users" imageIcon={peopleIcon} isOpen={isOpen} disabled={isOffline} />}
        {settings && settings.use_resources && <RootMenuItem to="/resources" text={settings.rename_resources ? t('menu.projects') : t('menu.resources')} icon="fas fa-cubes" imageIcon={resourcesIcon} isOpen={isOpen} disabled={isOffline} />}
        {settings && settings.use_contacts && <RootMenuItem to="/contacts" text={t('menu.contacts')} icon="far fa-id-card" imageIcon={contactsIcon} isOpen={isOpen} disabled={isOffline} />}
        {settings && settings.use_companies && <RootMenuItem to="/companies" text={t('menu.companies')} icon="far fa-id-card" imageIcon={companiesIcon} isOpen={isOpen} disabled={isOffline} />}
        <RootMenuItem to={chatLink || "/chat"} text={t('menu.chat')} icon="fa-solid fa-comment" imageIcon={supportIcon} isOpen={isOpen} disabled={isOffline} badgeCount={totalUnreadMessages} external={!!chatLink} />
        {!!wikiLink && <RootMenuItem to={isCordova() || isSpecificCorporation ? wikiLink : '/wiki'} external={isCordova() || isSpecificCorporation} text={t('menu.wiki')} icon="fa-solid fa-comment" imageIcon={wikiIcon} isOpen={isOpen} disabled={isOffline} />}
      </div>
      <div className="menu-items menu-items-bottom">
        {/*
          <RootMenuItem
            id="show-support"
            text="Support"
            icon=""
            to={null}
            isOpen={isOpen}
            imageIcon={chatIcon}
            onClick={() => intercom?.displayMessenger()}
            disabled={isOffline}
          />
         */}
        <RootMenuItem id="show-support" text="Support" icon="" to={null} isOpen={isOpen} imageIcon={chatIcon} onClick={() => {
        const zammadChatButton = (document.querySelector('.zammad-chat .chat-toggle') as HTMLElement);
        if (zammadChatButton) {
          zammadChatButton.click();
        }
      }} disabled={isOffline} />
        <RootMenuItem text={isOpen ? t('menu.collapse') : t('menu.expand')} icon="" to={null} isOpen={isOpen} imageIcon={isOpen ? leftIcon : rightIcon} onClick={toggle} />
        <ReactAvatar src={user && user.avatar.thumb.url} name={user.full_name} round size="30px" />
      </div>
    </div>;
}