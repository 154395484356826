import { useMemo, ReactNode } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { deDE, enUS } from '@mui/x-date-pickers';
import { useCurrentUser } from '../hooks/authHooks';
// hooks
import useSettings from '../hooks/useSettings';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

type ThemeConfigProps = {
  children: ReactNode;
};
const localeMap = {
  de: deDE,
  en: enUS
};
export default function ThemeConfig({
  children
}: ThemeConfigProps) {
  const {
    themeMode,
    themeDirection
  } = useSettings();
  const isLight = themeMode === 'light';
  const currentUser = useCurrentUser();
  const locale = currentUser?.language ?? 'de';
  const themeOptions: ThemeOptions = useMemo(() => ({
    palette: isLight ? {
      ...palette.light,
      mode: 'light'
    } : {
      ...palette.dark,
      mode: 'dark'
    },
    shape,
    typography,
    breakpoints,
    direction: themeDirection,
    shadows: isLight ? shadows.light : shadows.dark,
    customShadows: isLight ? customShadows.light : customShadows.dark
  }), [isLight, themeDirection]);
  console.log(localeMap[locale]);
  const theme = createTheme(themeOptions, localeMap[locale]);
  theme.components = componentsOverride(theme);
  return <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>;
}