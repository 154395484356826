import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import de from './i18n/de.json'
import en from './i18n/en.json'

// the translations
const resources = {
  de,
  en
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: navigator.language.substr(0, 2),
    nsSeparator: false,
    // set fallback to english in prod environment
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
