/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiBase } from './ApiBase'
import IUser from './types/user'

export default class UserApi extends ApiBase {
  public async all(accessToken?: string | null) {
    if (this.isOffline()) {
      return this.offlineDb.users.toArray()
    }
    let url = '/users'
    if (accessToken) {
      url += `?access_token=${accessToken}`
    }
    const response = await this.get<IUser[]>(url)
    return response.data
  }

  public async self() {
    const response = await this.get<IUser>('/users/self')
    return response.data
  }

  public async allByToken(accessToken: string) {
    if (this.isOffline()) {
      return this.offlineDb.users.toArray()
    }
    const response = await this.get<IUser[]>(`/users/index_by_token?access_token=${accessToken}`)
    return response.data
  }

  public find(id: number) {
    return this.get<IUser>(`/users/${id}`)
  }

  public update(id: number, user: IUser) {
    // only send fields we actually can change
    const {
      corporation,
      corporations,
      full_name,
      teams,
      current_role,
      email_setting,
      avatar, ...rest
    } = user
    rest.email_setting_attributes = email_setting
    return this.put<IUser>(`/users/${id}`, rest)
  }

  public teamLess() {
    return this.get<IUser[]>('/users/team_less')
  }

  public async forUser() {
    if (this.isOffline()) {
      return this.offlineDb.users.toArray()
    }
    const response = await this.get<IUser[]>('/users/for_user')
    return response.data
  }

  public deleteUser(id: number) {
    return this.delete(`/users/${id}`)
  }

  public async emails() {
    const response = await this.get<string[]>('/users/emails')
    return response.data
  }

  public async create(data: IUser) {
    const {
      corporation, corporations, teams, ...user
    } = data
    const response = await this.post<IUser>('/users', user)
    return response.data
  }

  public resendInvite(id: number) {
    return this.post(`/users/${id}/resend_invite`)
  }

  public uploadAvatar(file: File) {
    const form = new FormData()
    form.append('filepond', file)
    return this.post<IUser>('/users/avatar', form)
  }
}
