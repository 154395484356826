import { gql } from '@apollo/client'
import { ConversationsEntity } from '../queries/FetchChatData'

export interface UpdateConversationMutationVariables {
  id: string
  participantIds: string[]
}

export interface UpdateConversationResponse {
  updateConversation: {
    id: string
    conversationId: ConversationsEntity
  }
}

const UpdateConversationMutation = gql`
  mutation updateConversation($id: ID!, $participantIds: [ID!]!) {
    updateConversation(id: $id, participantIDs: $participantIds) {
      errors
      conversation {
        id
        messages {
          attachments
          body
          contentType
          createdAt
          id
          senderId
        }
        participants {
          id
          email
          fullName
          lastActive
          picture
        }
        type
      }
    }
}
`

export default UpdateConversationMutation
