import { Button, Column, Columns, Delete, Image, Input, Label, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, ModalCardTitle } from 'bloomer';
import { PureComponent } from 'react';
import { BrowserView, isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import ISignature from '../../../api/types/attachment';
import IContact from '../../../api/types/contact';
import IResult from '../../../api/types/result';
import IUser from '../../../api/types/user';
import { reactSelectMaxMenuHeight, signaturePseudoQuestionIds } from '../../../constants';
import logo from '../../../images/Logo-Icon.png';
import ITranslationProps from '../../../types/translationProps';
import { confirm } from '../../../util';
import SignatureWidget from '../../widgets/SignatureWidget';
import SignatureColumn from './SignatureColumn';
interface IOwnProps {
  onUpdate: (id: number, data: any, field?: string, resultAreaPosition?: number) => Promise<IResult>;
  result: IResult;
  questionId: number;
  contacts: IContact[];
  users: IUser[];
  freeSelection: boolean;
  signeeFreeText?: boolean;
  resultAreaPosition?: number;
  user?: IUser | null;
  userId?: number | null;
  contactId?: number | null;
  signature?: ISignature | null;
  disabled?: boolean;
}
interface IState {
  isModalOpen: boolean;
  svg: string | null;
  message: string;
  isSubmitting: boolean;
  initialTopScrollOffset: number;
  onTouchMove: (event) => any;
  userId?: number | null;
  contactId?: number | null;
  signeeName?: string;
}
type Props = IOwnProps & ITranslationProps;
class QuestionSignature extends PureComponent<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
      svg: null,
      message: null,
      isSubmitting: false,
      initialTopScrollOffset: 0,
      onTouchMove: () => null,
      contactId: props.contactId,
      userId: props.userId,
      signeeName: props.result?.signee_name ?? ''
    };
  }
  private handleDelete = () => {
    const {
      t
    } = this.props;

    // ADF-798: user must confirm deletion of a signature
    confirm(null, t('Signature-Delete'), () => {
      this.setState({
        svg: null
      });
      this.props.onUpdate(this.props.questionId, null, 'signature', this.props.resultAreaPosition);
    });
  };
  private handleSave = () => {
    if (!this.state.contactId && !this.state.userId && this.props.freeSelection && !this.props.signeeFreeText) {
      this.setState({
        message: this.props.t('UserOrContactRequired')
      });
      return;
    }
    if (this.props.signeeFreeText && !this.state.signeeName.length) {
      this.setState({
        message: this.props.t('Signature-Name-Missing')
      });
      return;
    }
    if (!this.state.svg) {
      this.setState({
        message: this.props.t('Sign')
      });
      return;
    }
    this.setState({
      isSubmitting: true
    });
    this.props.onUpdate(this.props.questionId, this.state.svg, 'signature', this.props.resultAreaPosition).finally(() => {
      this.setState({
        isSubmitting: false
      });
      this.closeModal();
    });
  };
  private handleContactSelect = (value: any) => {
    this.setState({
      message: null,
      contactId: value.value
    });
    this.props.onUpdate(this.props.questionId, value.value, 'contact', this.props.resultAreaPosition).then(response => {
      this.setState({
        contactId: response.contact_id
      });
      this.hideMobileBrowserToolbar();
    });
  };
  private handleChangeSigneeName = event => {
    const {
      value
    } = event.target;
    this.setState({
      message: null,
      signeeName: value
    });
    this.props.onUpdate(this.props.questionId, value, 'signee_name', this.props.resultAreaPosition).then(() => {
      this.hideMobileBrowserToolbar();
    });
  };
  private handleUserChanged = (value: any) => {
    this.setState({
      message: null,
      userId: value.value
    });
    this.props.onUpdate(this.props.questionId, value.value, 'user', this.props.resultAreaPosition).then(response => {
      this.setState({
        userId: response.user_id
      });
      this.hideMobileBrowserToolbar();
    });
  };
  private handleSignatureChange = (svg: string) => {
    this.setState({
      message: null
    });
    this.setState({
      svg
    });
  };
  private openModal = () => {
    // workaround to make the signature_pad working correctly on iOS 13 devices
    // disable scrolling for the page
    // See this issue @uschmidt created at GitHub
    // https://github.com/szimek/signature_pad/issues/455

    // save initial scroll position
    // to restore it after the user closes the modal
    const scrollTopPosition = window.pageYOffset;
    if (this.isAppleMobile()) {
      this.hideMobileBrowserToolbar();
    }

    // disable the onTouchMove event, to be able to draw up and down
    // in the signature field
    // this basically prevents scrolling on
    // touch devices without having to use overflow: hidden
    const rootElement: HTMLElement = document.querySelector('#root');
    const onTouchMove = event => {
      const {
        target
      } = event;

      // ADF-791 disable scroll dragging only when the user touches the signature canvas
      // without the check it isn't possible to scroll dropdowns on Android
      if (target.tagName.toLowerCase() === 'canvas') {
        event.preventDefault();
      }
    };

    // save the event handler in our state to be able
    // to remove it after closing the modal
    this.setState({
      onTouchMove
    });
    rootElement.addEventListener('touchmove', onTouchMove, false);
    this.setState({
      isModalOpen: true,
      // store the initial scroll offset in state
      initialTopScrollOffset: scrollTopPosition
    });
  };
  private closeModal = () => {
    // remove the touchmove event on the root container
    // to re-enable scrolling
    const rootElement: HTMLElement = document.querySelector('#root');
    rootElement.removeEventListener('touchmove', this.state.onTouchMove, false);

    // hide modal and restore previous scroll position
    this.setState({
      isModalOpen: false
    }, () => {
      if (this.isAppleMobile()) {
        window.scrollTo(0, this.state.initialTopScrollOffset);
      }
    });
  };
  private isAppleMobile = () => /iPad|iPhone|iPod/.test(navigator.userAgent);

  // scroll to bottom of the page
  // because without it in landscape mode on iPhones the buttons would be seen
  private hideMobileBrowserToolbar = () => {
    if (this.isAppleMobile()) {
      // 40000 is magic number which represent the end of the page
      // i tried to detect the height of the page but this gave me wrong results
      window.scrollTo(0, 80000);
    }
  };
  public render() {
    const {
      t
    } = this.props;
    const contactOptions = [{
      value: null,
      label: t('ResultPleaseChooseContact'),
      disabled: false
    }, ...this.props.contacts.map(contact => ({
      label: `${contact.firstname} ${contact.lastname} `,
      value: contact.id,
      disabled: false
    }))];
    const userOptions = [{
      value: null,
      label: t('ResultPleaseChooseUser'),
      disabled: false
    }, ...this.props.users.map(u => ({
      label: `${u.firstname} ${u.lastname}`,
      value: u.id,
      disabled: false
    }))];
    let url = null;
    // offline signatures are saved as data url
    if (this.props.result && typeof this.props.result.signature === 'string') {
      url = this.props.result.signature;
    } else if (this.props.result && this.props.result.signature && this.props.result.signature.url) {
      url = this.props.result.signature.url;
    }
    const signature = url ? <SignatureColumn onDelete={this.handleDelete} url={url} userName={this.props.result.signee} /> : null;
    const selectedContact = contactOptions.find(option => option.value === this.props.result.contact_id);
    const selectedUser = userOptions.find(option => option.value === this.props.result.user_id);
    let signeeName = !this.props.freeSelection && !this.props.signeeFreeText ? t('users.roles.guest') : null;
    if (this.props.user && !this.props.freeSelection) {
      signeeName = this.props.user.full_name;
    }
    const message = this.state.message ? <span className="help is-danger">{this.state.message}</span> : null;
    const textInput = this.props.signeeFreeText ? <Input isFullWidth type="text" className="input has-margin-bottom-5" name="signee_name" placeholder={t('Signature-Name')} defaultValue={this.state.signeeName} onBlur={this.handleChangeSigneeName} /> : null;

    // show the dropdowns only if caching the signee is allowed
    // whe have two html codes for the dropdowns
    // the html below is only used for screens where the with is >= 500
    const dropdownsForWideScreen = this.props.freeSelection || this.props.questionId !== signaturePseudoQuestionIds.tester ? <Columns isGapless className="has-margin-bottom-15 is-shown-on-wide-screens" isMobile>
          <Column isSize={5}>
            <Select isOptionDisabled={option => option.disabled} value={selectedContact} onChange={this.handleContactSelect} options={contactOptions} onMenuClose={this.hideMobileBrowserToolbar} id="result_contact" classNamePrefix="dropdown-select" isSearchable name="result_contact" placeholder={t('Contact')} isDisabled={!this.props.freeSelection && this.props.questionId === signaturePseudoQuestionIds.tester} noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999
          })
        }} />
          </Column>
          <Column isSize={2} hasTextAlign="centered">
            <Button disabled>{t('or')}</Button>
          </Column>
          <Column isSize={5}>
            <Select isOptionDisabled={option => option.disabled} value={selectedUser} options={userOptions} isSearchable name="result_user" id="result_user" classNamePrefix="dropdown-select" placeholder={t('User')} onMenuClose={this.hideMobileBrowserToolbar} onChange={this.handleUserChanged} isDisabled={!this.props.freeSelection} noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999
          })
        }} />
          </Column>
        </Columns> : null;

    // this dropdowns are used for screens where the width is smaller hen 500
    const dropdownsForSmallScreen = this.props.freeSelection || this.props.questionId !== signaturePseudoQuestionIds.tester ? <div className="is-shown-on-small-screens">
          <Columns isGapless className="has-margin-bottom-15" isMobile>
            <Column isSize={9}>
              <Select isOptionDisabled={option => option.disabled} value={selectedContact} onChange={this.handleContactSelect} options={contactOptions} isSearchable maxMenuHeight={reactSelectMaxMenuHeight} name="result_contact" onMenuClose={this.hideMobileBrowserToolbar} placeholder={t('Contact')} isDisabled={!this.props.freeSelection && this.props.questionId === signaturePseudoQuestionIds.tester} menuPortalTarget={document.body} menuPlacement="auto" styles={{
            menuPortal: base => ({
              ...base,
              zIndex: 9999
            })
          }} />
            </Column>
            <Column isSize={3} hasTextAlign="right">
              <Button disabled>{t('or')}</Button>
            </Column>
          </Columns>
          <Columns isGapless isMobile className="has-margin-bottom-15">
            <Column isSize={12}>
              <Select isOptionDisabled={option => option.disabled} value={selectedUser} options={userOptions} isSearchable name="result_user" maxMenuHeight={reactSelectMaxMenuHeight} onMenuClose={this.hideMobileBrowserToolbar} placeholder={t('User')} onChange={this.handleUserChanged} isDisabled={!this.props.freeSelection} noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
            menuPortal: base => ({
              ...base,
              zIndex: 9999
            })
          }} />
            </Column>
          </Columns>
        </div> : null;
    const dropdowns = !this.props.signeeFreeText ? <>
        {dropdownsForWideScreen}
        {dropdownsForSmallScreen}
      </> : textInput;
    const signee = !(this.props.freeSelection || !this.props.signeeFreeText || this.props.questionId !== signaturePseudoQuestionIds.tester) ? <div>
          {`${signeeName}`}
        </div> : null;
    const modal = this.state.isModalOpen ? <Modal isActive className={isMobile ? 'is-huge modal-full-screen modal-fx-fadeInScale' : 'is-huge modal-fx-fadeInScale'}>
        <BrowserView>
          <ModalBackground />
        </BrowserView>
        <ModalCard className="signature-card modal-content">
          <ModalCardHeader className="signature-modal-header">
            <Image src={logo} isSize="32x32" className="is-inverted" />
            <ModalCardTitle>{t('Signature-Modal-Title')}</ModalCardTitle>
            <Delete onClick={this.closeModal} />
          </ModalCardHeader>
          <ModalCardBody className="is-clipped">

            <a id={`jumper-${this.props.result.id}`} />
            {dropdowns}
            <Columns>
              <Column>
                <Label>
                  {t('Signature-Signature')}
                  :
                </Label>
                <div className={this.props.freeSelection ? 'signature-free-selection' : 'signature-without-selection'}>
                  <SignatureWidget padId="question-signature-pad" onChange={this.handleSignatureChange} />
                </div>
                {signee}
                {message}
              </Column>
            </Columns>
          </ModalCardBody>
          <ModalCardFooter style={{
          justifyContent: 'flex-start'
        }}>
            <Columns isFullWidth>
              <Column isSize="1/2">
                <Button isColor="default" onClick={this.closeModal}>
                  {t('common.cancel')}
                </Button>
                <Button isColor="link" onClick={this.handleSave} isLoading={this.state.isSubmitting}>
                  {t('common.save')}
                </Button>
              </Column>
            </Columns>
          </ModalCardFooter>
        </ModalCard>
      </Modal> : null;
    return <>
        {signature}
        {modal}
        <Button isColor="link" className="has-margin-bottom-5 has-margin-top-5 sign-button" onClick={this.openModal} disabled={this.props.disabled}>
          {t('Sign')}
        </Button>
      </>;
  }
}
const mapStateToProps = state => ({
  user: state.core.user
});
export default connect(mapStateToProps)(withTranslation()(QuestionSignature));