import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FolderMode } from '../../components/checklists/result/folders/Folder'
import { IDataTableHeader } from '../../components/widgets/DataTableHeader'
import useIsOffline from '../useIsOffline'

export default function useFolderTableHeaders(
  isSelected: boolean,
  mode: FolderMode,
  status: 'open' | 'closed' | 'due' | 'invited'
): IDataTableHeader[] {
  const { t } = useTranslation()
  const isOffline = useIsOffline()

  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'))
  const isTabletPortrait = useMediaQuery(theme.breakpoints.down('md'))
  const isTableLandscape = useMediaQuery(theme.breakpoints.down('lg'))

  const titleWidth = useMemo(() => {
    if (isPhone) return '90%'
    if (isSelected) return '56%'
    return '36%'
  }, [isPhone, isSelected])

  return useMemo<IDataTableHeader[]>(() => {
    if (mode === FolderMode.checklists) {
      return [
        {
          field: 'title',
          label: t('resultSet.title'),
          width: titleWidth
        },
        {
          field: 'closed_at',
          label: t('resultSet.closedAt'),
          width: isPhone ? '20%' : '10%',
          hidden: status !== 'closed' || isTableLandscape
        },
        {
          field: 'created_at',
          label: t('resultSet.createdAtShort'),
          width: isSelected ? '10%' : '18%',
          noSort: isOffline || isTableLandscape,
          hidden: isTableLandscape
        },
        {
          field: 'due_date',
          label: t('resultSet.dueShort'),
          formatAs: 'rawDue',
          width: isSelected ? '18%' : '10%',
          hidden: status === 'closed' || isTableLandscape
        },
        {
          field: 'duration',
          label: t('resultSet.duration'),
          width: '12%',
          hidden: isTabletPortrait || status !== 'closed'
        },
        {
          field: 'creator',
          label: t('Creator'),
          width: '6%',
          noSort: true,
          hidden: isTableLandscape || isSelected || status === 'closed'
        },
        {
          field: 'tester',
          label: t('resultSet.testerShort'),
          noSort: true,
          width: '6%',
          hidden: isSelected || isTableLandscape
        },
        {
          field: 'project',
          label: t('resultSet.project'),
          width: '10%',
          noSort: isOffline,
          hidden: isSelected || isTableLandscape
        },
        {
          field: 'resource',
          noSort: isOffline,
          label: t('resultSet.resource'),
          width: '9%',
          hidden: isSelected || isTableLandscape
        },
        {
          field: 'progress',
          label: t('resultSet.progress'),
          width: '6%',
          hidden: isSelected || isTableLandscape
        }
      ]
    }
    return [
      {
        field: 'title',
        label: t('checklist.fields.title'),
        width: isPhone ? '90%' : '50%'
      },
      {
        field: 'created_at', label: t('checklist.fields.createdAt'), formatAs: 'date', width: '15%', hidden: isPhone
      },
      {
        field: 'creator', label: t('checklist.fields.creator'), noSort: true, width: '35%', hidden: isPhone
      }
    ] as IDataTableHeader[]
  }, [isOffline, isPhone, isSelected, isTableLandscape, isTabletPortrait, mode, status, t])
}
