import { Column, Columns } from 'bloomer';
import { useTranslation } from 'react-i18next';
import { IQuestion } from '../../../api/types/checklist';
import VelianDateInput from '../../widgets/VelianDateInput';
interface IProps {
  item: IQuestion;
  onUpdateFrom: (data: any) => void;
  onUpdateTo: (data: any) => void;
  valueFrom: string;
  valueTo: string;
  disabled: boolean;
}
export default function ResultTimespanInput({
  valueFrom,
  valueTo,
  item,
  onUpdateFrom,
  onUpdateTo,
  disabled
}: IProps) {
  const {
    t
  } = useTranslation();
  const realValueFrom = !valueFrom && item.has_default_today ? new Date() : !Number.isNaN(Date.parse(valueFrom)) ? valueFrom : null;
  const realValueTo = !valueTo && item.has_default_today ? new Date() : !Number.isNaN(Date.parse(valueTo)) ? valueTo : null;
  return <div className="datetime-question">
      <Columns>
        <Column isSize={6}>
          <VelianDateInput selected={realValueFrom ? new Date(realValueFrom) : null} onChange={(newDate: Date) => onUpdateFrom(newDate ? newDate.toISOString() : '')} dateFormat={item.has_time ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'} className="is-fullwidth date-input input" mode={item.has_time ? 'datetime' : 'date'} timeCaption={t('Time')} isClearable disabled={disabled} />
        </Column>
        <Column isSize={6}>
          <VelianDateInput selected={realValueTo ? new Date(realValueTo) : null} onChange={(newDate: Date) => onUpdateTo(newDate ? newDate.toISOString() : null)} dateFormat={item.has_time ? 'dd.MM.yyyy  HH:mm' : 'dd.MM.yyyy'} className="is-fullwidth date-input input" mode={item.has_time ? 'datetime' : 'date'} timeCaption={t('Time')} minDate={realValueFrom ? new Date(realValueFrom) : null} isClearable disabled={disabled} />
        </Column>
      </Columns>
    </div>;
}