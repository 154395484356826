import { Button, ModalBackground, Notification } from 'bloomer';
import { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setOfflineMode } from '../actions/offlineActions';
import api from '../api';
import { AlphaprocessDatabase } from '../offlineMode/offlineDb';
import { IAlphaprocessState, store } from '../store';
import { confirm } from '../util';
import LoadSpinner from './loadSpinner/LoadSpinner';
(window as any).enableOfflineMode = () => {
  store.dispatch(setOfflineMode(true));
};
export default function OfflineIndicator() {
  const isOffline = useSelector((state: IAlphaprocessState) => state.core.isOffline);
  const {
    t
  } = useTranslation();
  const dispatch = useDispatch();
  const [busy, setBusy] = useState(false);
  const onReconnected = useCallback(async () => {
    const db = new AlphaprocessDatabase();
    const dirty = await db.resultSets.filter(x => x._dirty).toArray();
    if (dirty.length) {
      confirm(null, t('offlineMode.confirmSyncAll'), async () => {
        setBusy(true);
        for (const rs of dirty) {
          await db.syncChecklist(rs, t, () => {});
          await db.resultSets.delete(rs.id);
        }
        toast(t('offlineMode.bulkSyncSuccess'), {
          type: 'success'
        });
        setBusy(false);
        window.location.reload();
      });
    }
  }, [t]);
  const reconnect = useCallback(async (showError: boolean) => {
    try {
      await api.ping();
      if (isOffline) {
        dispatch(setOfflineMode(false));
        await onReconnected();
        toast(t('offlineMode.reconnected'), {
          type: 'success'
        });
      }
    } catch (error) {
      if (showError) {
        toast(t('offlineMode.reconnectFailed'), {
          type: 'default'
        });
      }
    }
  }, [dispatch, isOffline, onReconnected, t]);
  const handleReconnectClicked = useCallback(async () => {
    await reconnect(true);
  }, [reconnect]);
  useEffect(() => {
    if (isOffline) {
      window.onbeforeunload = () => t('offlineMode.confirmExit');
    } else {
      window.onbeforeunload = null;
    }
  }, [isOffline, reconnect, t]);
  if (busy) {
    return <ModalBackground>
        <LoadSpinner className="centered" />
      </ModalBackground>;
  }
  if (isOffline) {
    return <Notification isColor="warning" className="offline-indicator">
        <div className="indicator-text">
          {t('offlineMode.currentOffline')}
        </div>
        <Button isSize="small" isPulled={isMobileOnly ? null : 'right'} onClick={handleReconnectClicked}>
          {t('offlineMode.reconnect')}
        </Button>
      </Notification>;
  }
  return null;
}