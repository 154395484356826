import { Input } from 'bloomer';
import { useCallback } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
interface IProps {
  value: string | null;
}

// Provides a text input to copy a string on click to the clipboard
const InputToClipboard: React.FunctionComponent<IProps> = ({
  value
}: IProps) => {
  const onCopy = useCallback((event: React.MouseEvent<HTMLElement>) => {
    const target = (event.target as HTMLFormElement);
    // select all text of the text input
    target.select();

    // Try to copy the link to clipboard
    // show a success or error message as toast
    try {
      const url = target.value;
      const el = document.createElement('textarea');
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      toast(i18n.t('InputToClipboard.linkCopiedToClipboard'), {
        type: 'success'
      });
      target.select();
    } catch (error) {
      toast(i18n.t('InputToClipboard.linkCopyFailed'), {
        type: 'error'
      });
    }
  }, []);
  return <Input value={value ?? ''} readOnly onClick={onCopy} />;
};
export default InputToClipboard;