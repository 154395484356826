import { SortActions } from '../actions/sortActions'

export interface ISortSetting {
  column: string
  direction: 'asc' | 'desc'
}

export interface ISortState {
  createdTemplatesSort: ISortSetting
  closedResultsSort: ISortSetting
  activeResultsSort: ISortSetting
  projectsSort: ISortSetting
  nextInspectionsSort: ISortSetting
  dueResultSetsSort: ISortSetting
}

export const initialState: ISortState = {
  createdTemplatesSort: {
    column: 'created_at',
    direction: 'desc'
  },
  closedResultsSort: {
    column: 'closed_at',
    direction: 'desc'
  },
  activeResultsSort: {
    column: 'due_date',
    direction: 'desc'
  },
  projectsSort: {
    column: 'name',
    direction: 'asc'
  },
  nextInspectionsSort: {
    column: 'start_date',
    direction: 'asc'
  },
  dueResultSetsSort: {
    column: 'due_date',
    direction: 'asc'
  }
}

export function sortReducer(state: ISortState = initialState, action: SortActions): ISortState {
  switch (action.type) {
    case 'SORT_SET_NEXT_INSPECTIONS_SORT': return {
      ...state,
      nextInspectionsSort: action.settings
    }
    case 'SORT_SET_PROJECTS_SORT': return {
      ...state,
      projectsSort: action.settings
    }
    case 'SORT_SET_ACTIVE_RESULTS_SORT': return {
      ...state,
      activeResultsSort: action.settings
    }
    case 'SORT_SET_CLOSED_RESULTS_SORT': return {
      ...state,
      closedResultsSort: action.settings
    }
    case 'SORT_SET_CREATED_TEMPLATE_SORT': return {
      ...state,
      createdTemplatesSort: action.settings
    }
    case 'SORT_SET_DUE_RESULT_SETS_SORT': return {
      ...state,
      dueResultSetsSort: action.settings
    }
    default: return state
  }
}
