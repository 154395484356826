import querystring from 'query-string'
import rename from 'rename-property'

import { selectMany } from '../util'
import { ApiBase } from './ApiBase'
import api from './index'
import {
  IChecklist, IQuestion, ISchedule, ITemplateDto, Measure
} from './types/checklist'

interface IParams {
  page: number
  title?: string
  sort?: string
  direction?: string
  startDate?: string
  endDate?: string
  project_ids?: number[] | null
}

export interface IChecklistSelectItem {
  id: number
  title: string
}

class ChecklistsApi extends ApiBase {
  public async all(params: IParams) {
    if (params.title === null) {
      delete params.title
    }
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    const response = await this.get<IChecklist[]>(`/checklists?${query}`)

    return this.paginateResponse(response)
  }

  public async select(): Promise<IChecklistSelectItem[]> {
    const response = await this.get<IChecklistSelectItem[]>('/checklists/select')
    return response.data
  }

  public async allByCurrentUser(params: IParams, currentUserId: number): Promise<IChecklist[]> {
    if (this.isOffline()) {
      const query = this.offlineDb.checklists.filter((x) => x.creator_id === currentUserId)

      if (params.title && params.title.length) {
        query.filter((x) => x.title.toLowerCase().includes(params.title.toLowerCase()))
      }

      return query.toArray()
    }

    if (params.title === null) {
      delete params.title
    }
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    const response = await this.get<ITemplateDto[]>(`/templates?${query}`)

    return response.data
  }

  public async find(id: number, accessToken?: string) {
    if (this.isOffline()) {
      if (!id) return null
      return this.offlineDb.checklists.get(id)
    }

    let url = `/checklists/${id}`

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    const response = await this.get<IChecklist>(url)

    return response.data
  }

  public withSchedules() {
    return this.get<IChecklist[]>('/checklists/with_schedules')
  }

  public deleteChecklist(id: number) {
    if (this.isOffline()) {
      return Promise.resolve(() => {
        this.offlineDb.checklists.delete(id)
      })
    }
    return this.delete(`/checklists/${id}`)
  }

  public clone(id: number) {
    return this.post<IChecklist>(`/checklists/${id}/clone`)
  }

  public share(id: number, emails: string[]) {
    return this.post(`/checklists/${id}/share`, { emails })
  }

  public toggleFavorite(id: number, project_id?: number | null) {
    return this.post<boolean>(`/checklists/${id}/toggle_favorite`, { project_id })
  }

  // TODO: fix typing, use for-of loop
  public create(data: any) {
    rename.property(data, 'areas', 'areas_attributes')
    ChecklistsApi.updateMeasuresAttributes(data)
    delete data.selectedIndex
    delete data.doEdit
    delete data.contacts
    delete data.external_create_url
    delete data.controller
    delete data.selectedArea
    delete data.selectedQuestion
    delete data.isDropdownOpen
    delete data.attachments

    data = this.setScheduleAttributes(data)
    data = this.setRuleActionAttributes(data)
    delete data.schedules
    delete data.isLoading

    for (let i = 0; i < data.areas_attributes.length; i++) {
      if (typeof data.areas_attributes[i].isNew !== 'undefined' && data.areas_attributes[i].isNew) {
        delete data.areas_attributes[i].id
      }

      data = this.setPermissionAttributes(data, i)

      rename.property(data.areas_attributes[i], 'questions', 'questions_attributes')
      // tslint:disable-next-line: prefer-for-of
      for (let n = 0; n < data.areas_attributes[i].questions_attributes.length; n++) {
        delete data.areas_attributes[i].questions_attributes[n].id
        data = ChecklistsApi.setDropdownItemAttributes(data, i, n)
        data = this.setRulesAttributes(data, i, n)
      }
    }

    for (let j = 0; j < data.areas_attributes.length; j++) {
      delete data.areas_attributes[j].isNew

      for (let k = 0; k < data.areas_attributes[j].questions_attributes.length; k++) {
        delete data.areas_attributes[j].questions_attributes[k].isNew
      }
    }

    return this.post<IChecklist>('/checklists', data)
  }

  private static setDropdownItemAttributes(data: any, areaIndex: number, questionIndex: number) {
    // we use a random string as id from the unsaved dropdown items
    // we have to remove them before posting to backend
    if (!data.areas_attributes[areaIndex].questions_attributes[questionIndex]
      .question_dropdown_items) {
      return data
    }

    data.areas_attributes[areaIndex].questions_attributes[questionIndex]
      .question_dropdown_items.forEach((item) => {
        if (item.isNew) {
          delete item.isNew
          delete item.id
        }
      })

    rename.property(
      data.areas_attributes[areaIndex].questions_attributes[questionIndex],
      'question_dropdown_items',
      'question_dropdown_items_attributes'
    )
    return data
  }

  private setRulesAttributes(data: any, areaIndex: number, questionIndex) {
    // we use a random string as id from the unsaved rules
    // we have to remove them before posting to backend
    const question: IQuestion = data.areas_attributes[areaIndex].questions_attributes[questionIndex]

    if (question.rule_sets) {
      question.rule_sets.forEach((ruleSet) => {
        if (ruleSet.isNew) {
          delete ruleSet.isNew
          delete ruleSet.id
        }
        ruleSet.rules.forEach((item) => {
          if (item.isNew) {
            delete item.isNew
            delete item.id
          }
        })
        rename.property(ruleSet, 'rules', 'rules_attributes')
      })
    }
    rename.property(question, 'rule_sets', 'rule_sets_attributes')
    // we use a random string as id from the unsaved rules
    // we have to remove them before posting to backend

    return data
  }

  private setPermissionAttributes(data: any, i: number) {
    if (data.areas_attributes[i].user_ids) {
      data.areas_attributes[i].area_users_attributes = data.areas_attributes[i].user_ids
        .map((x) => ({ user_id: x }))
      delete data.areas_attributes[i].user_ids
    }

    if (data.areas_attributes[i].users) {
      delete data.areas_attributes[i].users
    }

    if (data.areas_attributes[i].team_ids) {
      data.areas_attributes[i].area_teams_attributes = data.areas_attributes[i]
        .team_ids.map((x) => ({ team_id: x }))
      delete data.areas_attributes[i].team_ids
    }

    if (data.areas_attributes[i].teams) {
      delete data.areas_attributes[i].teams
    }
    return data
  }

  private setRuleActionAttributes(data: any) {
    const questions: IQuestion[] = selectMany(data.areas_attributes, (a) => a.questions)
    questions.forEach((question) => {
      if (question.api_call_config) {
        rename.property(question, 'api_call_config', 'api_call_config_attributes')
      }
      if (question.rule_sets) {
        question.rule_sets.forEach((ruleSet) => {
          if (ruleSet.rule_action) {
            if (ruleSet.rule_action.isNew) {
              delete ruleSet.rule_action.isNew
              delete ruleSet.rule_action.id
            }
          }
          if (ruleSet.rule_action) {
            rename.property(ruleSet.rule_action, 'target_area_ids', 'area_ids')
            rename.property(ruleSet.rule_action, 'target_question_ids', 'question_ids')
            rename.property(ruleSet, 'rule_action', 'rule_action_attributes')
          }
        })
      }
    })

    return data
  }

  private setScheduleAttributes(data: any) {
    // prepare schedules for submit
    if (!data.schedules) { return data }
    data.schedules_attributes = data.schedules.map((schedule: ISchedule) => ({
      title: schedule.title,
      id: schedule.id,
      repeat: schedule.repeat,
      start_date: schedule.start_date,
      due_date: schedule.due_date,
      user_ids: schedule.users.map((user) => user.id),
      team_ids: schedule.teams.map((team) => team.id),
      project_id: schedule.project_id,
      autostart: schedule.autostart,
      contact_id: schedule.contact_id,
      location: schedule.location,
      ressource_id: schedule.ressource_id,
      incomplete_mail_team_ids: schedule.incomplete_mail_team_ids,
      incomplete_mail_user_ids: schedule.incomplete_mail_user_ids,
      _destroy: schedule._destroy,
      exclude_non_working_days: schedule.exclude_non_working_days
    }))
    return data
  }

  private static updateMeasuresAttributes(data: any) {
    if (!data.measures) { return data }
    data.measures_attributes = data.measures.map((m: Measure) => {
      const ma = {
        ...m
      }
      if (ma._new) {
        delete ma.id
      }
      delete ma._new
      return ma
    })
    delete data.measures
    return data
  }

  // TODO: fix typing
  public update(id: number, data: any) {
    rename.property(data, 'areas', 'areas_attributes')
    ChecklistsApi.updateMeasuresAttributes(data)
    delete data.access_token
    delete data.selectedIndex
    delete data.doEdit
    delete data.controller
    delete data.external_create_url
    delete data.selectedArea
    delete data.selectedQuestion
    delete data.isDropdownOpen
    delete data.attachments
    delete data.contacts
    delete data.result_sets
    delete data.users
    delete data.teams
    delete data.isLoading

    if (data.schedules) {
      data = this.setScheduleAttributes(data)
      delete data.schedules
    }
    if (data.areas_attributes) {
      data = this.setRuleActionAttributes(data)
      for (let i = 0; i < data.areas_attributes.length; i++) {
        if (typeof data.areas_attributes[i].isNew !== 'undefined' && data.areas_attributes[i].isNew) {
          delete data.areas_attributes[i].id
        }

        data = this.setPermissionAttributes(data, i)

        rename.property(data.areas_attributes[i], 'questions', 'questions_attributes')

        for (let n = 0; n < data.areas_attributes[i].questions_attributes.length; n++) {
          if (
            typeof data.areas_attributes[i].questions_attributes[n].isNew !== 'undefined'
            && data.areas_attributes[i].questions_attributes[n].isNew
          ) {
            delete data.areas_attributes[i].questions_attributes[n].id
          }
        }
      }

      for (let a = 0; a < data.areas_attributes.length; a++) {
        delete data.areas_attributes[a].isNew

        for (let q = 0; q < data.areas_attributes[a].questions_attributes.length; q++) {
          delete data.areas_attributes[a].questions_attributes[q].isNew

          data = ChecklistsApi.setDropdownItemAttributes(data, a, q)
          data = this.setRulesAttributes(data, a, q)
        }
      }
    }
    return this.put<IChecklist>(`/checklists/${id}`, data)
  }

  async deleteAttachment(id: number) {
    return this.delete(`/checklists/${id}/delete_attachment`)
  }

  async fetchWithLinkedTemplates(id: number) {
    const checklist = await this.find(id)

    const areas = [...checklist.areas]
    const templateLinkAreas = areas.filter((x) => x.linked_template_id)
    for (const area of templateLinkAreas) {
      const linkedTemplate = await api.checklists.find(area.linked_template_id)
      for (const linkedArea of linkedTemplate.areas) {
        linkedArea.original_area_id = area.id
      }
      areas.splice(areas.indexOf(area), 1, ...linkedTemplate.areas)
    }

    for (const area of areas) {
      area.showCount = 1
      area.hide = false
      for (const question of area.questions) {
        if (question.question_type === 'apicall' && question.api_call_config?.execute_on_close) {
          question.hide = true
        }
      }
    }

    return {
      ...checklist,
      areas
    }
  }
}

export default ChecklistsApi
