import { ApiBase } from './ApiBase'
import IResource from './types/ressource'

export interface IAddScheduleDto {
  schedule_id?: number
  checklist_id: number
}

interface ResourceParams {
  title: string
  tag_ids: number[]
  page: number
}

class ResourcesApi extends ApiBase {
  public find(id: number) {
    return this.get<IResource>(`/ressources/${id}`)
  }

  public async all(params: ResourceParams) {
    const response = await this.get<IResource[]>('/ressources', params)
    return this.paginateResponse(response)
  }

  public async select(accessToken?: string | null) {
    if (this.isOffline()) {
      return this.offlineDb.resources.toArray()
    }
    let url = '/ressources/select'

    if (accessToken) {
      url += `?access_token=${accessToken}`
    }

    const response = await this.get<IResource[]>(url)
    return response.data
  }

  public byParent(parentId: number) {
    return this.get<IResource[]>(`/ressources/by_parent?parent_id=${parentId}`)
  }

  public bySchedule(scheduleId: number) {
    return this.get<IResource[]>(`/ressources/by_schedule?schedule_id=${scheduleId}`)
  }

  public create(data: IResource) {
    return this.post<IResource>('/ressources', data)
  }

  public update(id: number, data: IResource | FormData) {
    // FIXME: this is neccessary due to a workaround in the rails backend
    const updateData = data instanceof FormData ? data : {
      ...data,
      tag_ids: data.tag_ids ? data.tag_ids.join(',') : '',
      team_ids: data.team_ids ? data.team_ids.join(',') : '',
      user_ids: data.user_ids ? data.user_ids.join(',') : ''
    }

    return this.put<IResource>(`/ressources/${id}`, updateData)
  }

  public deleteResource(id: number) {
    return this.delete(`/ressources/${id}`)
  }

  public addSchedule(id: number, data: IAddScheduleDto) {
    return this.post<IResource>(`/ressources/${id}/add_schedule`, data)
  }

  public removeSchedule(id: number, scheduleId: number) {
    return this.post(`/ressources/${id}/remove_schedule`, { schedule_id: scheduleId })
  }

  public async removePhoto(id: number) {
    const response = await this.post<IResource>(`/ressources/${id}/remove_photo`)
    return response.data
  }
}

export default ResourcesApi
