import { gql } from '@apollo/client'

const ConversationUpdated = gql`
    subscription conversationUpdated($userId: ID!) {
        conversationUpdated(userId: $userId) {
            id
            type
            unreadCount
            participants {
                id
                email
                fullName
                picture
                lastActive
                phone
                status
            }
            messages {
                id
                body
                createdAt
                senderId
                attachments
                contentType
                read
            }
        }
    }
`

export default ConversationUpdated
