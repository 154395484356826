import { useQuery } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import { useCallback } from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client/core';
import IResource from '../../../api/types/ressource';
import { reactSelectMaxMenuHeight } from '../../../constants';
import LoadSpinner from '../../loadSpinner/LoadSpinner';
interface IProps {
  ressources: IResource[];
  onChange: (value: number | number[]) => void;
  selected: number | number[];
  isMulti?: boolean;
  placeholder: string;
  onMenuOpen?: any;
  menuPlacement?: MenuPlacement | null;
  readonly?: boolean;
  noClasses?: boolean;
  name?: string;
  id?: string;
}
const FETCH_RESOURCES = gql`
    query ResourceSelect {
        resources {
            id
            code
            title
        }
    }
`;
interface FetchResourcesResponse {
  resources: Array<{
    id: string;
    code: string;
    title: string;
  }>;
}
interface NewResourceSelectProps {
  selected: string[];
  label: string;
  onChange: (selected: string[]) => void;
  disabled?: boolean;
}
export function NewResourceSelect({
  selected,
  label,
  onChange,
  disabled
}: NewResourceSelectProps) {
  const {
    loading,
    data
  } = useQuery<FetchResourcesResponse>(FETCH_RESOURCES);
  if (loading) {
    return <LoadSpinner />;
  }
  return <Autocomplete fullWidth value={data.resources.filter(x => selected.includes(x.id))} options={data.resources} multiple disabled={disabled} getOptionLabel={project => typeof project === 'string' ? project : `${project.code} ${project.title}`} renderInput={params => <TextField {...params} label={label} />} onChange={(event, newValue) => {
    onChange(newValue.map(x => typeof x === 'string' ? x : x.id));
  }} />;
}
export default function ResourceSelect(props: IProps) {
  const {
    t
  } = useTranslation();
  const options = props.ressources.map(r => ({
    value: r.id,
    label: r.title
  }));
  let selected = Array.isArray(props.selected) ? options.filter(o => (props.selected as number[]).includes(o.value)) : options.find(o => o.value === props.selected);
  if (props.selected === null) {
    selected = null;
  }
  const handleChange = useCallback((values: any) => {
    if (Array.isArray(values)) {
      props.onChange(values.map(v => v.value));
    } else {
      props.onChange(values.value);
    }
  }, [props]);
  return <ReactSelect id={props.id} name={props.name} options={options} isMulti={props.isMulti} onChange={handleChange} value={selected} placeholder={props.placeholder} isSearchable maxMenuHeight={reactSelectMaxMenuHeight} className={clsx(!props.noClasses && 'dropdown-select-container')} classNamePrefix={clsx(!props.noClasses && 'dropdown-select')} onMenuOpen={props.onMenuOpen} noOptionsMessage={() => t('common.noOptions')} isDisabled={props.readonly} menuPortalTarget={document.body} menuPlacement="auto" styles={{
    menuPortal: base => ({
      ...base,
      zIndex: 9999
    })
  }} />;
}