import {
  QuestionType, RuleActionTypes, RuleLinkOperator, RuleOperator
} from './api/types/checklist'
import { ICorporationSetting, IEmailSetting } from './api/types/corporation'

export const version = '1.37.8'

export const colors = {
  yellow: '#f6c342',
  red: '#d04437',
  green: '#8eb021',
  blue: '#3b7fc4'
}

export const fallbackCorporationSetting: ICorporationSetting = {
  use_contacts: true,
  use_resources: true,
  use_schedules: true,
  use_tasks: true,
  allow_username_login: false,
  use_reduced_report_header: false,
  use_time_tracking: true,
  use_todo_lists: true,
  rename_resources: false,
  use_bpmn: false,
  auto_delete_overdue: false,
  overdue_interval: 1,
  overdue_unit: 'week',
  auto_delete_notification_emails: [],
  use_onedrive: true,
  use_google_drive: true,
  use_dropbox: true,
  use_offline_mode: false,
  use_catalog_of_measures: false,
  use_companies: true
}

export const fallbackEmailSetting: IEmailSetting = {
  use_create_task_notification: true,
  use_update_task_notification: true,
  use_comments_notification: true,
  send_calendar_invites: true,
  send_schedule_Reminders: true,
  locked_by_admin: false,
  send_complete_notification: true,
  send_assignment_notification: true,
  send_overdue_notification: true,
  send_start_notification: true
}

export const highlightTimeout = 5000

// Finished Tests
export const closedInspectionsTabIndex = 1

export const dashboardTypes = {
  audit_point_top_twenty: 'dashboard.types.auditPointTopTwenty',
  completion_rate_top_twenty: 'dashboard.types.completionRateTopTwenty',
  checklists_by_status: 'dashboard.types.checklistsByStatus',
  completion_rate_per_day: 'dashboard.types.completionRatePerDay',
  overdue_checklists_per_week: 'dashboard.types.overdueChecklistsPerWeek',
  checklists_by_due: 'dashboard.types.checklistsByDue'
}

export const actions = {
  auth: {
    login: 'AUTH_LOGIN',
    logout: 'AUTH_LOGOUT'
  },
  company: {
    add: 'COMPANY_ADD'
  },
  menu: {
    toggle: 'MENU_TOGGLE'
  },
  project: {
    select: 'PROJECT_SELECT'
  },
  team: {
    add: 'TEAM_ADD'
  },
  users: {
    setNotificationToken: 'USER_SET_NOTIFICATION_TOKEN',
    update: 'USER_UPDATE'
  }
}

export const reactDatepickerTimeFormat = 'HH:mm'

const patterns = {
  alphanumeric: /^[a-zA-Z0-9_äÄöÖüÜ-]*$/,
  alphanumericWithSpaces: /'^[a-zA-Z0-9_äÄöÖüÜ -]*$/,
  tel: /^[+0-9 ]*$/
}

export interface ISelectOption<T> {
  value: T
  translationKey: string
  disabled?: boolean
}

const signatureOptions: Array<ISelectOption<string>> = [
  {
    translationKey: 'checklist.signatureOptions.no',
    value: 'no'
  },
  {
    translationKey: 'checklist.signatureOptions.single',
    value: 'single'
  },
  {
    translationKey: 'checklist.signatureOptions.double',
    value: 'double'
  }
]

const repeatOptions: Array<ISelectOption<string>> = [
  {
    translationKey: 'Repeat-Once',
    value: 'once'
  },
  {
    translationKey: 'Repeat-Daily',
    value: 'daily'
  },
  {
    translationKey: 'Repeat-Weekly',
    value: 'weekly'
  },
  {
    translationKey: 'Repeat-Monthly',
    value: 'monthly'
  },
  {
    translationKey: 'Repeat-Quarterly',
    value: 'quarterly'
  },
  {
    translationKey: 'Repeat-Yearly',
    value: 'yearly'
  }
]

const scheduleRepeatOptions: Array<ISelectOption<string>> = [
  {
    translationKey: 'schedule.intervals.workingDays',
    value: 'working_days'
  },
  {
    translationKey: 'schedule.intervals.workingDaysExcludingSaturday',
    value: 'working_days_excluding_saturday'
  },
  {
    translationKey: 'schedule.intervals.daily',
    value: 'daily'
  },
  {
    translationKey: 'schedule.intervals.weekly',
    value: 'weekly'
  },
  {
    translationKey: 'schedule.intervals.monthly',
    value: 'monthly'
  },
  {
    translationKey: 'schedule.intervals.quarterly',
    value: 'quarterly'
  },
  {
    translationKey: 'schedule.intervals.yearly',
    value: 'yearly'
  }
]

export const roleOptions = [
  {
    value: 'employee',
    translationKey: 'users.roles.employee'
  },
  {
    value: 'power_user',
    translationKey: 'users.roles.powerUser'
  },
  {
    value: 'admin',
    translationKey: 'users.roles.admin'
  }
]

export interface IRuleOperatorOption extends ISelectOption<RuleOperator> {
  availableForTypes: QuestionType[]
}

export const ruleOperatorOptions: IRuleOperatorOption[] = [
  {
    value: 'equals',
    translationKey: 'rules.operators.equals',
    availableForTypes: ['checkbox', 'real_checkbox', 'dropdown', 'number', 'rating', 'text', 'datetime']
  },
  {
    value: 'not_equals',
    translationKey: 'rules.operators.notEquals',
    availableForTypes: ['checkbox', 'real_checkbox', 'dropdown', 'number', 'rating', 'text', 'datetime']
  },
  {
    value: 'contains',
    translationKey: 'rules.operators.contains',
    availableForTypes: ['text']
  },
  {
    value: 'greater_than',
    translationKey: 'rules.operators.greaterThen',
    availableForTypes: ['number', 'rating']
  },
  {
    value: 'greater_or_equal_than',
    translationKey: 'rules.operators.greaterOrEqualThen',
    availableForTypes: ['number', 'rating']
  },
  {
    value: 'less_than',
    translationKey: 'rules.operators.lessThen',
    availableForTypes: ['number', 'rating']
  },
  {
    value: 'less_or_equal_than',
    translationKey: 'rules.operators.lessOrEqualThen',
    availableForTypes: ['number', 'rating']
  },
  {
    value: 'starts_with',
    translationKey: 'rules.operators.startsWith',
    availableForTypes: ['text']
  },
  {
    value: 'ends_with',
    translationKey: 'rules.operators.endsWith',
    availableForTypes: ['text']
  }
]

export const ruleLinkOperatorOptions: Array<ISelectOption<RuleLinkOperator>> = [
  {
    value: 'and_operator',
    translationKey: 'common.and'
  },
  {
    value: 'or_operator',
    translationKey: 'or'
  }
]

export const reactSelectMaxMenuHeight = 170
export const ruleActionTypeOptions: Array<ISelectOption<RuleActionTypes>> = [
  {
    translationKey: 'rules.ruleActions.showQuestion',
    value: 'show_question',
    disabled: false
  },
  {
    translationKey: 'rules.ruleActions.hideQuestion',
    value: 'hide_question',
    disabled: false
  },
  {
    translationKey: 'rules.ruleActions.setValue',
    value: 'set_question_value',
    disabled: false
  },
  /* {
    translationKey: 'rules.ruleActions.countAsError',
    value: 'count_as_error',
    disabled: false
  }, */
  {
    translationKey: 'rules.ruleActions.showArea',
    value: 'show_area',
    disabled: false
  },
  {
    translationKey: 'rules.ruleActions.hideArea',
    value: 'hide_area',
    disabled: false
  },
  {
    translationKey: 'rules.ruleActions.duplicateArea',
    value: 'duplicate_area',
    disabled: false
  },
  {
    translationKey: 'rules.ruleActions.duplicateQuestion',
    value: 'duplicate_question',
    disabled: false
  },
  {
    translationKey: 'rules.ruleActions.sendEmail',
    value: 'send_email',
    disabled: false
  },
  {
    disabled: false,
    translationKey: 'rules.ruleActions.createTask',
    value: 'create_task'
  }
]

// these actions must be ignored by the frontend,
// because they are implemented in backend
export const ignoredActions = ['send_email']

export const signaturePseudoQuestionIds = {
  tester: -10,
  validator: -20
}

const requestTimeout = 2 * 60 * 1000

const dragDelay = 120

const appName = 'auditify_web'

export const AUDIT_POINT_MAX = 99

export const allowedImageFileTypes = [
  'image/*'
]

const IFRAME_SRC = 'https://cdn.iframe.ly/api/iframe'

export const ckEditorConfig = {
  mediaEmbed: {
    previewsInData: true,
    providers: [
      {
        // hint: this is just for previews. Get actual HTML codes by making API calls from your CMS
        name: 'iframely previews',

        // Match all URLs or just the ones you need:
        url: /http[s]?:\/\/(?!youtube.*$)\S+/gm,

        html: (match: any) => {
          const url = match[0]
          const iframeUrl = `${IFRAME_SRC}?app=1&api_key=${process.env.REACT_APP_IFRAMELY_KEY}&url=${encodeURIComponent(
            url
          )}`
          // alternatively, use &key= instead of &api_key with the MD5 hash of your api_key
          // more about it: https://iframely.com/docs/allow-origins

          return (
            // If you need, set maxwidth and other styles for
            // 'iframely-embed' class - it's yours to customize
            `<div class="iframely-embed">
              <div class="iframely-responsive">
                <iframe src="${iframeUrl}" frameborder="0" allow="encrypted-media" 
                  allowfullscreen></iframe>
              </div>
            </div>`
          )
        }
      }
    ]
  },
  link: {
    defaultProtocol: 'http://',
    addTargetToExternalLinks: true
  },
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'imageUpload',
      'blockQuote',
      'mediaEmbed',
      'undo',
      'redo'
    ]
  },
  ckfinder: {
    uploadUrl: `${process.env.REACT_APP_API_BASE_URL}/ckeditor_upload`
  }
}

export {
  patterns,
  requestTimeout,
  signatureOptions,
  repeatOptions,
  scheduleRepeatOptions,
  appName,
  dragDelay
}
