import { Progress, Tag } from 'bloomer';
import { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { isMobileOnly } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setActiveResultsSort } from '../../../actions/sortActions';
import api from '../../../api';
import { IPaginatedData } from '../../../api/ApiBase';
import { IFolder } from '../../../api/FolderApi';
import { IResultSetListDto } from '../../../api/resultSetApi';
import { AlphaprocessDatabase } from '../../../offlineMode/offlineDb';
import { ISortSetting } from '../../../reducers/sortReducer';
import { IAlphaprocessState } from '../../../store';
import ITranslationProps from '../../../types/translationProps';
import { confirm } from '../../../util';
import ErrorView from '../../ErrorView';
import LoadSpinner from '../../loadSpinner/LoadSpinner';
import DataTable from '../../widgets/DataTable';
import { IDataTableHeader } from '../../widgets/DataTableHeader';
import EditModalWrapper from './EditModalWrapper';
import MoveFolderDialog from './folders/MoveFolderDialog';
import TableAvatarDisplay from './TableAvatarDisplay';
import { TableDueDate } from './TableDueDate';
interface IOwnProps {
  onSelect: (item: any) => void;
  search: string;
  startDate: Date;
  endDate: Date;
  projects: number[];
  teams: number[];
  testers: number[];
  ressources: number[];
  contacts: number[];
  checklistId?: number;
  selectedId?: string | number;
}
interface IStateProps {
  useOfflineMode: boolean;
  sortSettings: ISortSetting;
  isAdmin: boolean;
  renameResources: boolean;
  isOffline: boolean;
}
interface IDispatchProps {
  setSort: (setting: ISortSetting) => void;
}
interface IState {
  data: IPaginatedData<IResultSetListDto>;
  isLoading: boolean;
  currentPage: number;
  error: any;
  selectedEditResultSet: number;
  inProgressData: any[];
}
type Props = IOwnProps & ITranslationProps & IStateProps & IDispatchProps & RouteComponentProps;
export function getProgressColor(progress: number) {
  if (progress === 0) {
    return 'grey';
  }
  return 'link';
}
class ActiveResultsTable extends Component<Props, IState> {
  public state: IState = {
    currentPage: 1,
    isLoading: false,
    data: {
      currentPage: 1,
      totalCount: 0,
      totalPages: 0,
      data: []
    },
    error: null,
    selectedEditResultSet: null,
    inProgressData: []
  };
  private offlineDb = new AlphaprocessDatabase();
  public async componentDidMount() {
    await this.fetchData();
  }
  public async componentDidUpdate(prevProps: Props) {
    if (prevProps.search !== this.props.search || prevProps.ressources !== this.props.ressources || prevProps.projects !== this.props.projects || prevProps.teams !== this.props.teams || prevProps.contacts !== this.props.contacts || prevProps.testers !== this.props.testers || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate || prevProps.sortSettings.direction !== this.props.sortSettings.direction || prevProps.sortSettings.column !== this.props.sortSettings.column) {
      await this.fetchData();
    }
  }
  private fetchData = async () => {
    try {
      this.setState({
        isLoading: true
      });
      const data = await api.resultSets.inProgress({
        page: this.state.currentPage,
        template_id: this.props.checklistId,
        tester_ids: this.props.testers,
        contact_id: this.props.contacts,
        title: this.props.search,
        ressource_id: this.props.ressources,
        project_id: this.props.projects,
        sort: this.props.sortSettings.column,
        direction: this.props.sortSettings.direction,
        startDate: this.props.startDate ? this.props.startDate.toISOString() : null,
        endDate: this.props.endDate ? this.props.endDate.toISOString() : null,
        team_ids: this.props.teams
      });
      const {
        onSelect,
        t
      } = this.props;
      const offlineResultSets = await this.offlineDb.resultSets.toArray();
      const inProgressData = data.data.map(x => {
        const progressbar = <div className="is-inline-flex">
            <span className={`has-text-${getProgressColor(x.percentage)} has-text-weight-bold`}>
              {`${x.percentage}%`}
            </span>
            <Progress isColor={getProgressColor(x.percentage)} value={x.percentage} max={100} />
          </div>;
        return {
          id: x.id,
          isOffline: !!offlineResultSets.find(y => y.id === x.id),
          ressource_id: x.ressource_id,
          title: <>
              <Link className="is-inline-flex-mobile has-hyphens" to={`/checklists/${x.id}`} onClick={onSelect ? e => {
              e.preventDefault();
              onSelect(x);
            } : null} style={{
              paddingRight: '10px'
            }}>
                {x.is_audit && <Tag className="audit-tag" data-tip={t('questions.auditPoints')} isColor="link">
                    {x.audit_points}
                  </Tag>}
                {x.title}
              </Link>
              {isMobileOnly && progressbar}
              {offlineResultSets.find(y => y.id === x.id) && <span className="offline-icon icon offline-mode-action">
                  <i className="fas fa-download" />
                </span>}
            </>,
          project: <div className="is-ellipsed action-table-project">
              {this.props.renameResources ? x.resource : x.project}
            </div>,
          rawProject: this.props.renameResources ? x.resource : x.project,
          progress: progressbar,
          rawTitle: x.title,
          due_date: <TableDueDate date={x.due} />,
          checklist_id: x.checklist_id,
          show_report: x.show_report,
          can_delete: x.can_delete,
          project_id: x.project_id,
          tester: <TableAvatarDisplay users={x.testers} teams={x.teams} />
        };
      });
      this.setState({
        data,
        currentPage: data.currentPage,
        inProgressData
      });
    } catch (error) {
      this.setState({
        error
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };
  public handlePagination = (page: number) => {
    this.setState({
      currentPage: page
    }, async () => {
      await this.fetchData();
    });
  };
  public handleSort = (field: string, direction: 'desc' | 'asc') => {
    this.props.setSort({
      column: field,
      direction
    });
  };
  private handleEditStart = ({
    id
  }: {
    id: number;
  }) => {
    this.setState({
      selectedEditResultSet: id
    });
  };
  private handleEditModalClose = () => {
    this.setState({
      selectedEditResultSet: null
    });
  };
  private handleResultSetUpdated = resultSet => {
    const dto = this.state.data.data.find(x => x.id === resultSet.id);
    dto.title = resultSet.title;
    dto.testers = resultSet.testers;
    dto.ressource_id = resultSet.ressource_id;
    dto.title = resultSet.title;
    const inProgressResultSets = [...this.state.data.data];
    const data = {
      ...this.state.data,
      data: inProgressResultSets
    };
    this.setState({
      data,
      selectedEditResultSet: null
    });
  };
  private handleDelete = ({
    id,
    checklist_id
  }) => {
    confirm(null, this.props.t('resultSet.confirmDelete'), async () => {
      await api.resultSets.deleteResultSet(checklist_id, id);
      await this.fetchData();
    });
  };
  private handleClone = async item => {
    await api.resultSets.clone(item.checklist_id, item.id);
    await this.fetchData();
  };
  private showMoveDialog = item => {
    const customUI = ({
      onClose
    }) => <MoveFolderDialog onClose={onClose} onMove={target => this.handleMove(item, target)} />;
    confirmAlert({
      customUI
    });
  };
  private handleMove = async (item, target: IFolder) => {
    await api.resultSets.update(item.checklist_id, item.id, ({
      folder_id: target.id
    } as any));
    await this.fetchData();
  };
  private onMakeOffline = async item => {
    const {
      t
    } = this.props;
    await this.offlineDb.loadDataFromBackend();
    try {
      const resultSet = await api.resultSets.find(0, item.id);
      if (await this.offlineDb.resultSets.get(item.id)) {
        await this.offlineDb.resultSets.delete(item.id);
      }
      await this.offlineDb.resultSets.add(resultSet);
      const templateId = resultSet.checklist_id;
      const template = await api.checklists.find(templateId);
      if (await this.offlineDb.checklists.get(templateId)) {
        await this.offlineDb.checklists.update(templateId, template);
      } else {
        await this.offlineDb.checklists.add(template);
      }
      toast(t('offlineMode.checklistNowAvailable'), {
        type: 'success'
      });
      await this.fetchData();
    } catch (e) {
      toast((e as Error).message, {
        type: 'error'
      });
    }
  };
  private updateOfflineChecklist = async (item: IResultSetListDto) => {
    const {
      t
    } = this.props;
    const {
      id
    } = item;
    const rs = await this.offlineDb.resultSets.get(id);
    if (rs._dirty) {
      confirm(null, t('offlineMode.confirm.makeOfflineAvailable'), async () => {
        await this.onMakeOffline(item);
      });
    } else {
      await this.onMakeOffline(item);
    }
  };
  private handleSync = ({
    id
  }) => {
    const {
      t,
      history
    } = this.props;
    confirm(null, t('offlineMode.confirmSync'), () => {
      history.push(`/checklists/offline/sync/${id}`);
    });
  };
  public render() {
    const {
      sortSettings,
      isAdmin,
      t,
      isOffline,
      useOfflineMode,
      selectedId
    } = this.props;
    const {
      isLoading,
      inProgressData,
      error,
      selectedEditResultSet
    } = this.state;
    const spinner = isLoading ? <LoadSpinner className="centered" /> : null;
    if (error) {
      return <ErrorView error={error} />;
    }
    const editModal = selectedEditResultSet ? <EditModalWrapper id={selectedEditResultSet} onSubmit={this.handleResultSetUpdated} onClose={this.handleEditModalClose} /> : null;
    const headers: IDataTableHeader[] = [{
      field: 'title',
      label: t('resultSet.title'),
      width: isMobileOnly ? '50%' : '40%'
    }, {
      field: 'due_date',
      label: t('resultSet.dueShort'),
      formatAs: 'rawDue',
      width: isMobileOnly || this.props.selectedId ? '30%' : '20%',
      colSpan: isMobileOnly && this.props.selectedId ? 2 : 1
    }];
    if (!selectedId) {
      headers.push({
        field: 'tester',
        label: t('resultSet.tester'),
        noSort: true,
        width: isMobileOnly ? '20%' : '10%',
        colSpan: isMobileOnly ? 2 : 1
      });
      if (!isMobileOnly) {
        headers.push({
          field: 'project',
          label: t('resultSet.project'),
          width: '20%',
          noSort: true
        });
      }
    }
    if (!isMobileOnly) {
      headers.push({
        field: 'progress',
        label: t('resultSet.progress'),
        width: this.props.selectedId ? '25%' : '15%',
        colSpan: 2
      });
    }
    return <>
        {spinner}
        <DataTable isLoading={isLoading} data={inProgressData} actions={[{
        icon: 'fas fa-pencil-alt has-pointer',
        tooltip: t('common.edit'),
        onClick: this.handleEditStart,
        isValidForItem: item => item.can_delete || isAdmin
      }, {
        icon: 'fas fa-trash-alt has-pointer',
        tooltip: t('common.delete'),
        onClick: this.handleDelete,
        isValidForItem: item => item.can_delete && isAdmin
      }, {
        icon: 'far fa-copy has-pointer',
        onClick: this.handleClone,
        tooltip: t('resultSet.copy'),
        isValidForItem: () => isAdmin,
        isDisabledForItem: () => isOffline
      }, {
        icon: 'fas fa-arrow-right has-pointer',
        onClick: this.showMoveDialog,
        tooltip: t('folder.moveToFolder'),
        isValidForItem: () => isAdmin,
        isDisabledForItem: () => isOffline
      }, {
        icon: 'fas fa-download has-pointer',
        onClick: this.onMakeOffline,
        tooltip: t('offlineMode.makeAvailable'),
        isValidForItem: (item: any) => useOfflineMode && !item.isOffline,
        isDisabledForItem: () => isOffline,
        className: 'offline-mode-action'
      }, {
        icon: 'fas fa-download has-pointer',
        onClick: this.updateOfflineChecklist,
        tooltip: t('offlineMode.updateChecklist'),
        isValidForItem: (item: any) => useOfflineMode && item.isOffline,
        isDisabledForItem: () => isOffline,
        className: 'offline-mode-action'
      }, {
        icon: 'fas fa-upload',
        onClick: this.handleSync,
        tooltip: t('offlineMode.syncChecklist'),
        isValidForItem: (item: any) => useOfflineMode && item.isOffline,
        isDisabledForItem: () => isOffline
      }]} headers={headers} currentPage={this.state.currentPage} onPagination={this.handlePagination} onSort={this.handleSort} sortColumn={sortSettings.column} sortDir={sortSettings.direction} totalPages={this.state.data.totalPages} selectedId={selectedId} />
        {editModal}
      </>;
  }
}
const mapState = (state: IAlphaprocessState): IStateProps => ({
  useOfflineMode: state.core.user.corporation.corporation_setting.use_offline_mode,
  isOffline: state.core.isOffline,
  sortSettings: state.sort.activeResultsSort,
  isAdmin: state.core.user?.current_role === 'admin' || state.core.user?.current_role === 'power_user',
  renameResources: state.core.user?.corporation.corporation_setting.rename_resources ?? false
});
const mapDispatch = (dispatch: (x: any) => void): IDispatchProps => ({
  setSort: settings => dispatch(setActiveResultsSort(settings))
});
export default connect(mapState, mapDispatch)(withRouter(withTranslation()(ActiveResultsTable)));