import { createContext, useContext } from 'react';
export interface IEvent {
  entity: string;
  id: number | string;
  action: string;
  processed?: boolean;
}
export interface EventBus {
  events: IEvent[];
  onEventProcessed: (e: IEvent) => void;
}
const defaultEventBus: EventBus = {
  events: [],
  // tslint:disable-next-line:no-empty
  onEventProcessed: () => {}
};
export const EventBusContext = createContext<EventBus>(defaultEventBus);
export const useEventBus = (entity: string, id: number | string, onEvent: (e: IEvent) => void) => {
  const context = useContext(EventBusContext);
  if (context.events) {
    const events = context.events.filter(x => !x.processed && x.entity === entity && x.id === id);
    for (const x of events) {
      x.processed = true;
      context.onEventProcessed(x);
      onEvent(x);
    }
  }
};