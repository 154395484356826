import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import api from '../../api';
import { IQuestion } from '../../api/types/checklist';
import ITranslationProps from '../../types/translationProps';
import ResultHistoryEntry from './ResultHistoryEntry';
interface Props extends ITranslationProps {
  questions: IQuestion[];
  resultSetId: number;
}
interface State {
  isLoading: boolean;
  history: any[];
}
class ResultSetHistory extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      history: []
    };
  }
  public async componentDidMount() {
    const history = await api.resultSets.history(this.props.resultSetId);
    this.setState({
      history,
      isLoading: false
    });
  }
  public render() {
    const {
      questions,
      t
    } = this.props;
    const {
      history,
      isLoading
    } = this.state;
    if (isLoading) {
      return <div>
          {' '}
          {t('common.loading')}
          {' '}
        </div>;
    }
    const entries = history.filter(x => x).map(x => <ResultHistoryEntry key={x.id} entry={x} questions={questions} />);
    return <div>{entries}</div>;
  }
}
export default withTranslation()(ResultSetHistory);