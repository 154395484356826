/* tslint:disable:no-console */

import { Button, Column, Columns, Field, Media, MediaContent, MediaLeft, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, Notification } from 'bloomer';
import { Component } from 'react';
import ReactAvatar from 'react-avatar';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { IArea, IChecklist } from '../../../api/types/checklist';
import { IAreaPermissions } from '../../../api/types/resultSet';
import ITeam from '../../../api/types/team';
import IUser from '../../../api/types/user';
import ITranslationProps from '../../../types/translationProps';
interface IProps {
  checklist: IChecklist;
  area: IArea;
  users: IUser[];
  teams: ITeam[];
  onCancel: () => void;
  onSubmit: (Permissions: IAreaPermissions) => void;
  permissions: IAreaPermissions;
}
interface IState {
  teamIds: number[];
  userIds: number[];
  isSubmitting: boolean;
}
type Props = IProps & ITranslationProps;

// Managers can change the permissions of an area in a checklist
// When the user hits save the changed permissions are saved as an json object of the result_set
// When no customized permissions for an area are set for a result set then the permissions of the
// checklist template are used
class AreaPermissionsModal extends Component<Props, IState> {
  public constructor(props) {
    super(props);
    this.state = {
      teamIds: props.permissions.team_ids,
      userIds: props.permissions.user_ids,
      isSubmitting: false
    };
  }
  public componentDidUpdate(prevProps) {
    if (prevProps.area.id !== this.props.area.id) {
      this.setState({
        isSubmitting: false
      });
    }
  }
  private onSubmit = () => {
    this.setState({
      isSubmitting: true
    });
    const {
      permissions,
      onSubmit
    } = this.props;
    const {
      teamIds,
      userIds
    } = this.state;
    const updatedPermissions: IAreaPermissions = {
      id: permissions.id,
      team_ids: teamIds,
      user_ids: userIds
    };
    onSubmit(updatedPermissions);
  };
  private onSelectUser = (id: number) => {
    const {
      userIds
    } = this.state;
    if (userIds.some(x => x === id)) {
      const updatedIds = userIds.filter(x => x !== id);
      this.setState({
        userIds: updatedIds
      });
    } else {
      const updatedIds = [...userIds, id];
      this.setState({
        userIds: updatedIds
      });
    }
  };
  private onSelectTeam = (id: number) => {
    const {
      teamIds
    } = this.state;
    if (teamIds.some(x => x === id)) {
      const updatedIds = teamIds.filter(x => x !== id);
      this.setState({
        teamIds: updatedIds
      });
    } else {
      const updatedIds = [...teamIds, id];
      this.setState({
        teamIds: updatedIds
      });
    }
  };
  public render() {
    const {
      t,
      area,
      onCancel,
      users,
      teams
    } = this.props;
    const {
      userIds,
      teamIds
    } = this.state;
    const userList = users.map(user => {
      const isSelected = userIds.some(x => x === user.id);
      const userAvatar = user.avatar && user.avatar.thumb && user.avatar.thumb.url ? user.avatar.thumb.url : null;
      return <Columns isMobile className="list-entry" key={`user-${user.id}`}>
          <Column isSize={9} isPaddingless>
            <Media>
              <MediaLeft>
                <ReactAvatar size="64" round src={userAvatar} name={`${user.full_name}`} />
              </MediaLeft>
              <MediaContent>
                <div className="entry-text">
                  <h5 className="is-marginless">
                    {user.full_name}
                  </h5>
                </div>
              </MediaContent>
            </Media>
          </Column>
          <Column isSize={3} className="switch-container" isPaddingless>
            <Field className="has-position-relative">
              <input type="checkbox" id={`user-${user.id}`} className="switch is-rounded" checked={isSelected} onChange={() => this.onSelectUser(user.id)} />
              <label htmlFor={`user-${user.id}`} />
            </Field>
          </Column>
        </Columns>;
    });
    const teamList = teams.map(team => {
      const isSelected = teamIds.some(x => x === team.id);
      // teams have no

      return <Columns isMobile className="list-entry" key={`team-${team.id}`}>
          <Column isSize={9} isPaddingless>
            <Media>
              <MediaLeft>
                <ReactAvatar size="64" round name={`${team.name}`} />
              </MediaLeft>
              <MediaContent>
                <div className="entry-text">
                  <h5 className="is-marginless">
                    {team.name}
                  </h5>
                </div>
              </MediaContent>
            </Media>
          </Column>
          <Column isSize={3} className="switch-container" isPaddingless>
            <Field className="has-position-relative">
              <input type="checkbox" id={`user-${team.id}`} className="switch is-rounded" checked={isSelected} onChange={() => this.onSelectTeam(team.id)} />
              <label htmlFor={`user-${team.id}`} />
            </Field>
          </Column>
        </Columns>;
    });
    const modalClasses = isMobile ? 'area-permissions-modal is-huge modal-full-screen modal-fx-fadeInScale' : 'area-permissions-modal';
    return <Modal isActive style={{
      zIndex: ('80 !important' as any)
    }} className={modalClasses}>
        <ModalBackground />
        <ModalCard className="modal-content">
          <ModalCardHeader className="has-text-weight-semibold">
            {t('checklist.permissionsModal.modalTitle', {
            title: area.title
          })}
          </ModalCardHeader>
          <ModalCardBody>
            <Notification isColor="info">
              <p>
                {t('checklist.permissionsModal.infoText')}
              </p>
            </Notification>
            <Columns isCentered>
              <Column isSize="full">
                <Tabs>
                  <TabList>
                    <Tab>
                      {t('User')}
                    </Tab>
                    <Tab>
                      {t('Teams')}
                    </Tab>
                  </TabList>
                  <TabPanel>
                    {userList}
                  </TabPanel>
                  <TabPanel>
                    {teamList}
                  </TabPanel>
                </Tabs>
              </Column>
            </Columns>
          </ModalCardBody>
          <ModalCardFooter>
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button isColor="link" onClick={this.onSubmit} isLoading={this.state.isSubmitting}>
              {' '}
              {t('common.save')}
            </Button>
          </ModalCardFooter>
        </ModalCard>
      </Modal>;
  }
}
export default withTranslation()(AreaPermissionsModal);