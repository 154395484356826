import { FunctionComponent, useEffect } from 'react';
import OneSignal from 'react-onesignal';
import { useSelector } from 'react-redux';
import { IAlphaprocessState } from './store';
const OneSignalSetup: FunctionComponent = () => {
  const user = useSelector((state: IAlphaprocessState) => state.core.user);
  useEffect(() => {
    // Ensure OneSignal is initialized
    const setOneSignalUser = async () => {
      OneSignal.setEmail(user.email);
      OneSignal.setExternalUserId(user.id.toString());
      OneSignal.registerForPushNotifications();
    };
    setOneSignalUser();
  }, [user]);
  return null;
};
export default OneSignalSetup;