import { TFunction } from 'i18next'
import { IFolder } from '../api/FolderApi'

export const getFolderName = (f: IFolder, t: TFunction) => {
  if (f.parent === null) {
    return t('folder.root')
  }

  return f.name
}
