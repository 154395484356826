// External libraries
import { Alert } from '@mui/material';
import { AxiosError } from 'axios';
import { Button, Column, Columns, Content, Control, Field, Icon, Notification, Panel, Tag } from 'bloomer';
import clsx from 'clsx';
import querystring from 'query-string';
import { PureComponent } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

// Actions
import { updateUser } from '../../../actions/userActions';

// API and types
import api from '../../../api';
import { ICloudAttachment } from '../../../api/types/attachment';
import { IArea, IQuestion, TaskData } from '../../../api/types/checklist';
import IResult, { IComment, ISignature, ResultAction } from '../../../api/types/result';
import IResultSet, { IAreaPermissions } from '../../../api/types/resultSet';
import IUser from '../../../api/types/user';

// Components
import AreaPermissionsModal from '../../../components/checklists/result/AreaPermissionsModal';
import CreateCatalogDialog from '../../../components/checklists/forms/measures/CreateCatalogDialogSuspense';
import CreateResultSetFollowerSettings, { ICreateResultSetFollowerData } from '../../../components/checklists/result/CreateResultSetFollowerSettings';
import EditResultSetModal from '../../../components/checklists/result/EditResultSetModal';
import NotFilledRequiredFieldsModal from '../../../components/checklists/result/NotFilledRequiredFieldsModal';
import ResultAttachments from '../../../components/checklists/result/question/attachments';
import ResultCloudAttachments from '../../../components/checklists/result/question/cloudAttachments';
import ResultArea from '../../../components/checklists/result/ResultArea';
import ResultSettingsMenu from '../../../components/checklists/result/ResultSettingsMenu';
import StatusText from '../../../components/checklists/result/StatusText';
import ErrorView from '../../../components/ErrorView';
import ResultSetHistory from '../../../components/history/ResultSetHistory';
import LoadSpinner from '../../../components/loadSpinner/LoadSpinner';
import { makeApiCall } from '../../../components/checklists/result/question/inputs/ApiButton';

// Constants and utilities
import { AUDIT_POINT_MAX, closedInspectionsTabIndex, ignoredActions, signatureOptions, signaturePseudoQuestionIds } from '../../../constants';
import closeIcon from '../../../images/filters_close.png';
import { AlphaprocessDatabase } from '../../../offlineMode/offlineDb';
import { IAlphaprocessState } from '../../../store';
import { alert, confirm, selectMany } from '../../../util';
import { checkFileSelection } from '../../../util/CloudUploadUtil';
import { consumer } from '../../../util/consumer';
import DateUtil from '../../../util/DateUtil';
import { isFilled } from '../../../util/ReportUtil';
import ResultSetUtil from '../../../util/ResultSetUtil';
import log from '../../../util/logger';

// Local components and types
import AreaCard from './components/AreaCard';
import { EventBusContext, IEvent } from './EventBus';
import { CableMessage, INotFilledQuestion, IState, IStateProps, Props, QuestionCount } from './types';
interface IDispatchProps {
  updateUser: (user: IUser) => void;
}
type IProps = Props & IDispatchProps & RouterProps;
class ResultView extends PureComponent<IProps, IState> {
  _isMounted: boolean = false;
  public state: IState = {
    allUsers: [],
    boards: [],
    checklist: null,
    companies: [],
    contactAttachmentsExpanded: false,
    contacts: [],
    disabled: false,
    error: null,
    events: [],
    isClosing: false,
    isDropdownOpen: false,
    isDueDateExceeded: false,
    isLoading: true,
    isRequestRunning: false,
    isSignedValid: true,
    jumpToQuestionId: null,
    projects: [],
    resourceAttachmentsExpanded: false,
    resources: [],
    resultSet: null,
    resultsByQuestionId: {},
    showAreaPermissionsModal: null,
    showCatalogDialog: false,
    showCloseResultFailedModal: false,
    showEditModal: false,
    showHistory: false,
    showShareModal: false,
    teams: [],
    templateAttachmentsExpanded: true,
    users: [],
    validationErrors: [],
    visibleAreas: [],
    updateQueue: []
  };
  private channel: any = null;

  /*
  The setTimeout function with a delay of 0 milliseconds is a common JavaScript technique to defer the execution of an operation until the call stack is clear. This is often referred to as "yielding to the event loop".
   In JavaScript, the event loop handles all asynchronous callbacks. Callbacks for timers (like setTimeout) are added to the event queue and are processed when the call stack is empty and all other higher priority tasks have been processed.
   When you use setTimeout with a delay of 0, it doesn't mean the callback will fire immediately after the timeout is set. Instead, it means the callback is added to the event queue and will be executed once all other synchronous code and higher priority tasks have completed.
   In your case, using setTimeout with a delay of 0 in the processNextArea function allows the browser to update the UI and respond to user interactions between processing each area. This can prevent the UI from becoming unresponsive if processing the areas is a heavy operation.
  */
  private readonly timerInterval = 0;
  private timer: ReturnType<typeof setTimeout> | null = null;
  private offlineDb = new AlphaprocessDatabase();
  private getBackLink = () => {
    const {
      context,
      location
    } = this.props;
    const toHome = context === 'overview' || location.search.includes('ref=home');
    if (toHome) {
      return '/';
    }
    if (location.search.includes('ref=myTemplates')) {
      return '/templates';
    }
    return '/checklists';
  };
  public async componentDidMount() {
    this._isMounted = true;
    window.addEventListener('beforeunload', e => {
      if (this.state.isRequestRunning) {
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = 'Ungespeicherte Änderungen'; //this.props.t('resultSet.notifications.unsavedChanges');
      }
    });
    log.debug('<ResultView> componentDidMount()');
    const accessToken = this.getAccessToken();
    if (accessToken) {
      // user is not logged in but uses an access token
      const html = document.getElementsByTagName('html')[0];
      html.classList.remove('has-bgcolor'); // get rid of ugly orange background
      await this.fetchDataWithAccessToken(accessToken);
      this.fetchCoreData();
    } else if (this.props.isLoggedIn) {
      // user is logged in
      await this.fetchDataWithUser();
      this.fetchCoreData();
    } else {
      // user is not authenticated
      this.props.history.push('/login');
    }

    // ADF-997: timer for queuing the requests for questions with rules
    // They can't be submitted parallel because there are issues with rules then
    if (!this.timer) {
      this.timer = setTimeout(this.processUpdateQueue, this.timerInterval);
    }
  }

  // ADF-1070: Jump to question if the user clicks a link of a task
  private getInitialArea = () => {
    const params = new URLSearchParams(this.props.location.search);
    if (this.props.match && this.props.match.params.areaId && !this.state.jumpToQuestionId) {
      return {
        selectedAreaId: parseInt(this.props.match.params.areaId),
        selectedAreaPosition: parseInt(this.props.match.params.areaPosition),
        jumpToQuestionId: params.get('questionId') ? Number.parseInt(params.get('questionId')) : null
      };
    }
    return {
      selectedAreaId: null,
      selectedAreaPosition: null,
      jumpToQuestionId: null
    };
  };
  private getAccessToken(): string | null {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('access_token') || this.state.resultSet?.access_token;
  }
  private async fetchDataWithUser() {
    if (this.props.resultSetId) {
      await this.fetchResultSet(this.props.resultSetId);
    } else {
      const resultSetId = this.props.match.params.resultSetId ? this.props.match.params.resultSetId : this.props.match.params.id;
      await this.fetchResultSet(parseInt(resultSetId));
    }
  }
  private fetchCoreData = () => {
    if (!this.state.resultSet) {
      return;
    }
    const usersRequest = this.props.isExternalAccess ? api.users.allByToken(this.getAccessToken()) : api.users.forUser();
    usersRequest.then(users => {
      this.setState({
        users
      });
    }).catch(error => {
      this.setState({
        error
      });
    });
    const allUsersRequest = this.props.isExternalAccess ? api.users.allByToken(this.getAccessToken()) : api.users.all();

    // ADF-766: load all users of the corporation
    // this is used to show the comment creators
    allUsersRequest.then(users => {
      this.setState({
        allUsers: users
      });
    }).catch(error => {
      this.setState({
        error
      });
    });
    api.contacts.all(this.getAccessToken()).then(contacts => {
      this.setState({
        contacts
      });
    }).catch(error => {
      this.setState({
        error
      });
    });
    const corporationId = this.props.isExternalAccess ? this.state.resultSet.corporation_id : this.props.user.corporation_id;
    api.projects.select(corporationId, 0, this.getAccessToken()).then(projects => {
      this.setState({
        projects
      });
    }).catch(error => {
      this.setState({
        error
      });
    });
    api.resources.select(this.getAccessToken()).then(resources => {
      this.setState({
        resources
      });
    }).catch(error => {
      this.setState({
        error
      });
    });
    api.teams.all(corporationId, false, this.getAccessToken()).then(teams => {
      this.setState({
        teams
      });
    }).catch(error => {
      this.setState({
        error
      });
    });
    if (this.props.user) {
      api.companies.select().then(companies => {
        this.setState({
          companies
        });
      }).catch(error => {
        this.setState({
          error
        });
      });
      api.boards.all(this.getAccessToken()).then(boards => {
        this.setState({
          boards
        });
      }).catch(error => {
        this.setState({
          error
        });
      });
    }
  };
  private async fetchDataWithAccessToken(accessToken: string) {
    await this.fetchResultSetByToken(accessToken);
  }
  private handleEventProcessed = (e: IEvent) => {
    this.setState(state => ({
      events: state.events.filter(event => event !== e)
    }));
  };
  private setTemplateAttachmentsExpanded = (value: boolean) => {
    this.setState({
      templateAttachmentsExpanded: value
    });
  };
  private setContactAttachmentsExpanded = (value: boolean) => {
    this.setState({
      contactAttachmentsExpanded: value
    });
  };
  private setResourceAttachmentsExpanded = (value: boolean) => {
    this.setState({
      resourceAttachmentsExpanded: value
    });
  };
  public fetchTemplate = async (id: number) => await api.checklists.fetchWithLinkedTemplates(id);
  public async fetchResultSet(id: number) {
    this.setState({
      isLoading: true
    });
    const offlineResultSet = await this.offlineDb.resultSets.get(typeof id === 'string' ? parseInt(id) : id);
    if (id < 0 && !offlineResultSet) {
      this.props.onClose(false);
      return;
    }
    try {
      if (this.channel) {
        this.channel.unsubscribe();
        this.channel = null;
      }
      const resultSet = await api.resultSets.find(1, id);
      if (resultSet === null) {
        const error = (new Error('Result set not found') as any);
        this.setState({
          error
        });
        return;
      }
      const checklist = await this.fetchTemplate(resultSet.checklist_id);
      resultSet.checklist = checklist;
      checklist.areas.forEach(area => {
        area.area_position = 1;
        area.questions.forEach(question => {
          question.area_position = 1;
        });
      });
      const isDueDateExceeded = checklist.lock_checklist_after_due_date && DateUtil.isBefore(resultSet.due_date, new Date());
      const initialSelectedArea = this.getInitialArea();
      this.setState({
        checklist,
        resultSet,
        ...initialSelectedArea,
        isLoading: false,
        disabled: resultSet.status === 'closed' || this.props.user.current_role !== 'admin' && isDueDateExceeded,
        isDueDateExceeded
      }, this.executeActionsOnLoad);
      if (!resultSet.writable || resultSet.status === 'closed') {
        let url = `/checklists/${resultSet.checklist_id}/audit/${resultSet.id}/report`;
        if (this.getAccessToken()) {
          url += `?access_token=${this.getAccessToken()}`;
        }
        await this.props.history.push(url);
        return;
      }
      try {
        this.offlineDb.resultSets.where('id').equals(resultSet.id).count().then(count => {
          if (count !== 0) {
            this.offlineDb.resultSets.get(resultSet.id).then(rs => {
              if (rs._dirty || rs.results.some(r => r._dirty) || rs.signatures.some(c => c._dirty)) {
                if (!this.props.isOffline) {
                  confirm(null, this.props.t('offlineMode.confirmSyncChanges'), () => {
                    this.props.history.push(`/checklists/offline/sync/${rs.id}`);
                  });
                }
              }
            });
          }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        log.error('error checking for offline availability', error);
      }
      if (!this.channel && !this.props.isOffline) {
        this.channel = consumer.subscriptions.create({
          channel: 'ChecklistUpdateChannel',
          id: resultSet.id,
          user_id: this.props.user?.id
        }, {
          received: this.handleCableUpdate
        });
      }
    } catch (error) {
      this.setState({
        error: (error as AxiosError),
        isLoading: false
      });
    }
  }
  public async fetchResultSetByToken(accessToken: string) {
    this.setState({
      isLoading: true
    });
    await api.resultSets.findByAccessToken(accessToken).then(response => {
      const resultSet = response.data;
      const initialSelectedArea = this.getInitialArea();
      this.setState({
        checklist: resultSet.checklist,
        resultSet,
        ...initialSelectedArea,
        isLoading: false
      }, this.executeActionsOnLoad);
      if (!this.channel) {
        this.channel = consumer.subscriptions.create({
          channel: 'ChecklistUpdateChannel',
          id: resultSet.id,
          user_id: this.props.user?.id
        }, {
          received: this.handleCableUpdate
        });
      }
    }).catch(error => this.setState({
      error,
      isLoading: false
    }));
  }

  // TODO: handle errors
  private handleCableUpdate = (message: CableMessage) => {
    const {
      t
    } = this.props;
    const userId = this.props.user?.id ?? null;
    let updatedResultSet: IResultSet | null = null;
    let events = [];
    let result: IResult | null = null;
    let resultSet: IResultSet | null = null;
    try {
      switch (message.type) {
        case 'user-count':
          if (message.data) {
            toast(t('resultSet.notifications.editedByUsers', {
              count: message.data
            }), {
              type: 'info'
            });
          }
          break;
        case 'checklist-update':
          if (message.user !== userId) {
            this.setState({
              resultSet: message.data
            });
          }
          break;
        case 'result':
          if (message.user !== userId) {
            this.setState(state => ({
              resultSet: {
                ...state.resultSet,
                results: [...state.resultSet.results.filter(r => r.id !== message.data.id), message.data]
              },
              events: [...state.events, {
                entity: 'result',
                id: message.data.id,
                action: 'updated'
              }]
            }), () => message.data.action.forEach(result_action => this.executeAction(result_action)));
          }
          break;
        case 'attachment-upload':
          if (message.user !== userId) {
            result = this.state.resultSet.results.find(r => r.id === message.result_id);
            if (result) {
              result.attachments = [...result.attachments, message.attachment];
              updatedResultSet = {
                ...this.state.resultSet,
                results: [...this.state.resultSet.results.filter(r => r.id !== message.result_id), {
                  ...result
                }]
              };
              events = [...this.state.events, {
                entity: 'attachment',
                id: result.question_id,
                action: 'added'
              }];
              this.setState({
                resultSet: updatedResultSet,
                events
              });
            }
          }
          break;
        case 'attachment-destruction':
          if (message.user !== userId) {
            result = this.state.resultSet.results.find(r => r.id === message.result_id);
            result.attachments = result.attachments.filter(x => x.id !== message.id);
            updatedResultSet = {
              ...this.state.resultSet,
              results: [...this.state.resultSet.results.filter(r => r.id !== message.result_id), {
                ...result
              }]
            };
            events = [...this.state.events, {
              entity: 'attachment',
              id: result.question_id,
              action: 'added'
            }];
            this.setState({
              resultSet: updatedResultSet,
              events
            });
          }
          break;
        case 'cloud-attachment-created':
          if (message.user !== userId) {
            result = this.state.resultSet.results.find(r => r.id === message.result_id);
            if (!result) return;
            result.cloud_attachments = [...result.cloud_attachments, message.attachment];
            updatedResultSet = {
              ...this.state.resultSet,
              results: [...this.state.resultSet.results.filter(r => r.id !== message.result_id), {
                ...result
              }]
            };
            events = [...this.state.events, {
              entity: 'cloud_attachment',
              id: result.question_id,
              action: 'added'
            }];
            this.setState({
              resultSet: updatedResultSet,
              events
            });
          }
          break;
        case 'cloud-attachment-destructed':
          if (message.user !== userId) {
            result = this.state.resultSet.results.find(r => r.id === message.result_id);
            if (!result) return;
            result.cloud_attachments = result.cloud_attachments.filter(x => x.id !== message.attachment.id);
            updatedResultSet = {
              ...this.state.resultSet,
              results: [...this.state.resultSet.results.filter(r => r.id !== message.result_id), {
                ...result
              }]
            };
            events = [...this.state.events, {
              entity: 'cloud_attachment',
              id: result.question_id,
              action: 'added'
            }];
            this.setState({
              resultSet: updatedResultSet,
              events
            });
          }
          break;
        case 'comment-add':
          if (message.user !== userId) {
            result = this.state.resultSet.results.find(r => r.id === message.result_id);
            if (result) {
              result.comments = [...result.comments, message.comment];
              updatedResultSet = {
                ...this.state.resultSet,
                results: [...this.state.resultSet.results.filter(r => r.id !== message.result_id), {
                  ...result
                }]
              };
              events = [...this.state.events, {
                entity: 'comment',
                id: result.question_id,
                action: 'added'
              }];
              this.setState({
                resultSet: updatedResultSet,
                events
              });
            }
          }
          break;
        case 'comment-destroy':
          if (message.user !== userId) {
            result = this.state.resultSet.results.find(r => r.id === message.result_id);
            result.comments = result.comments.filter(x => x.id !== message.id);
            updatedResultSet = {
              ...this.state.resultSet,
              results: [...this.state.resultSet.results.filter(r => r.id !== message.result_id), {
                ...result
              }]
            };
            events = [...this.state.events, {
              entity: 'comment',
              id: result.question_id,
              action: 'destroyed'
            }];
            this.setState({
              resultSet: updatedResultSet,
              events
            });
          }
          break;
        case 'signature_add':
          if (message.user !== userId) {
            resultSet = {
              ...this.state.resultSet,
              signatures: [...this.state.resultSet.signatures, message.data]
            };
            this.setState({
              resultSet
            });
          }
          break;
        case 'signature_update':
          if (message.user !== userId) {
            resultSet = {
              ...this.state.resultSet,
              signatures: [...this.state.resultSet.signatures.filter(s => s.id !== message.data.id), message.data]
            };
            this.setState({
              resultSet
            });
          }
          break;
        case 'result_set_closed':
          toast(this.props.t('resultSet.notifications.closed', {
            user: message.user
          }));
          if (this.state.checklist.measures.length > 0) return;
          if (this.props.isExternalAccess) {
            this.props.history.push(`/checklists/${this.state.resultSet.checklist_id}/audit/${this.state.resultSet.id}/report` + `?access_token=${this.getAccessToken()}`);
          } else if (this.props.onClose) {
            this.props.onClose(true);
          } else {
            const targetUrl = `/checklists/?tab=${closedInspectionsTabIndex}`;
            this.props.history.push(targetUrl);
          }
          break;
        default:
          // eslint-disable-next-line no-console
          log.error('unknown message type', message);
      }

      // ...
    } catch (error) {
      log.error('[handleCableUpdate()] error handling cable message', error);
      // TODO: better Error Handling
      toast.error('Fehler beim Verarbeiten der Nachricht (');
      Sentry.captureException(error);
    }
  };
  private createResultLookup(results: IResult[]): Record<string, IResult> {
    return results.reduce((lookup: Record<string, IResult>, result: IResult) => {
      lookup[result.question_id] = result;
      return lookup;
    }, {});
  }
  public componentWillUnmount(): void {
    this._isMounted = false;
    log.debug('<ResultView> componentWillUmount()');
    if (this.channel) {
      this.channel.unsubscribe();
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    window.onbeforeunload = null;
  }
  public async componentDidUpdate(prevProps: Props, prevState: IState) {
    let newState = {};
    if (prevProps.resultSetId !== this.props.resultSetId && !this.props.isExternalAccess) {
      await this.fetchResultSet(this.props.resultSetId);
    }
    if (prevProps.match.params.areaId !== this.props.match.params.areaId) {
      const areaId = this.props.match.params.areaId ? parseInt(this.props.match.params.areaId, 10) : null;
      newState = {
        ...newState,
        selectedAreaId: areaId
      };
    }
    if (prevProps.match.params.areaPosition !== this.props.match.params.areaPosition) {
      const areaPosition = this.props.match.params.areaPosition ? parseInt(this.props.match.params.areaPosition, 10) : null;
      newState = {
        ...newState,
        selectedAreaPosition: areaPosition
      };
    }
    if (this.state.updateQueue.length === 0 && this.state.isRequestRunning) {
      newState = {
        ...newState,
        isRequestRunning: false
      };
    }
    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }
    if (this.state.resultSet && this.state.resultSet.results !== prevState.resultSet?.results) {
      const results: IResult[] = this.state.resultSet.results;
      this.setState({
        resultsByQuestionId: this.createResultLookup(results)
      });
    }
  }
  private hideDropdown = (): void => {
    this.setState({
      isDropdownOpen: false
    });
  };
  private onExcelExport = (event: React.MouseEvent) => {
    event.preventDefault();
    this.hideDropdown();
    if ('cordova' in window) {
      (window as any).cordova.InAppBrowser.open(this.getExcelExportUrl(), '_system', 'location=yes');
    } else {
      (window as Window & typeof globalThis).open(this.getExcelExportUrl());
    }
  };
  private closeWithoutReload = () => {
    this.props.onClose(false);
  };
  private onEditTemplate = (event: React.MouseEvent) => {
    event.preventDefault();
    this.props.history.push(`/templates/editor/${this.state.checklist.id}`);
    this.hideDropdown();
  };
  private toggleDropdown = (open: boolean) => {
    this.setState({
      isDropdownOpen: open
    });
  };
  private closeDropdown = () => {
    this.toggleDropdown(false);
  };

  /**
   * This function is executed when the component is loaded. It iterates over each area and question in the checklist state.
   * If a question has rule sets and there is no result set for this question yet, it checks the rule action type.
   * If the rule action type is 'show_question', it hides the question.
   * If the rule action type is 'show_area', it hides the area.
   * This function is used to initialize the visibility of questions and areas based on their rule sets and the absence of results.
   */
  private executeActionsOnLoad = async () => {
    // Hide all questions that area affected by rules by default
    // if there is no result  for a question with rules yet
    // the affected area or question is hidden by default
    this.state.checklist.areas.forEach(area => area.questions.forEach(question => {
      question.rule_sets.forEach(ruleSet => {
        // if there is no result set yet for this question
        if (!this.state.resultSet.results.some(x => x.question_id === question.id)) {
          // if the question has a show_question rule_action hide the question
          if (ruleSet.rule_action && ruleSet.rule_action.action_type === 'show_question') {
            this.setQuestionHideOrShow(question, ruleSet.rule_action.target_question_ids, true, 1);
          } else if (ruleSet.rule_action && ruleSet.rule_action.action_type === 'show_area') {
            // if the question has a show_area rule_action hide the area
            this.setAreaHide(ruleSet.rule_action.target_area_ids, true);
          } else if (ruleSet.rule_action && ruleSet.rule_action.action_type === 'duplicate_area') {
            this.setAreaHide(ruleSet.rule_action.target_area_ids, true);
          } else if (ruleSet.rule_action && ruleSet.rule_action.action_type === 'duplicate_question') {
            this.setQuestionHideOrShow(question, ruleSet.rule_action.target_question_ids, true, 1);
          }
        }
      });
    }));

    // make sure that we keep the correct order of actions to execute on load

    const actionsByType: {
      [key: string]: ResultAction[];
    } = {
      show_question: [],
      hide_question: [],
      show_area: [],
      hide_area: [],
      duplicate_area: [],
      duplicate_question: [],
      create_task: [],
      send_email: []
    };

    // Combine filter and forEach operations
    this.state.resultSet.results.forEach(oneResult => {
      if (oneResult.action) {
        const actions = this.filterActions(oneResult.action).filter(x => x.action !== 'create_task');
        actions.forEach(a => {
          if (a.action in actionsByType) {
            actionsByType[a.action].push(a);
          }
        });
      }
    });

    // Execute actions in desired order
    const actionOrder = ['duplicate_area', 'duplicate_question', 'show_question', 'hide_question', 'show_area', 'hide_area', 'create_task', 'send_email'];
    const processedAreaIds: Set<number> = new Set(); // Use a Set instead of an Array
    for (const actionType of actionOrder) {
      for (const action of actionsByType[actionType]) {
        // If executeAction returns a promise, await it
        await this.executeAction(action, processedAreaIds);
      }
    }

    /*
     // Create a map for quick lookup
    const questionMap = new Map();
    const resultMap = new Map();
     this.state.checklist.areas.forEach((area) => {
      area.questions.forEach((question) => {
        questionMap.set(question.id, question);
         // Populate resultMap in the same loop
        const result = this.state.resultSet.results.find(result => result.question_id === question.id);
        if (result) {
          resultMap.set(question.id, result);
        }
      });
    });
     this.state.checklist.areas.forEach((area) => {
      area.questions.forEach((question) => {
        question.rule_sets.forEach((ruleSet) => {
          // if the question has a show_question or hide_question rule_action
          if (ruleSet.rule_action && (ruleSet.rule_action.action_type === 'show_question' || ruleSet.rule_action.action_type === 'hide_question')) {
             // find the first valid target question using the map
            let targetQuestion;
            for (const id of ruleSet.rule_action.target_question_ids) {
              targetQuestion = questionMap.get(id);
              if (targetQuestion) break;
            }
            // if the target question is found
            if (targetQuestion) {
              // find the result in the result_set assigned to the origin question using the map
              const result = resultMap.get(question.id);
               // if the result is found and it has an action array defined
              if (result && result.action) {
                result.action.forEach((a) => {
                  a.origin_question = question;
                  // execute it again, because the first run we should not have had an origin_question for the rule
                  if(a.action === 'show_question' || a.action === 'hide_question') {
                    this.executeAction(a);
                  }
                });
              }
            }
          }
        });
      });
    });
    */
    this.state.checklist.areas.forEach(area => {
      area.questions.forEach(question => {
        question.rule_sets.forEach(ruleSet => {
          // if the question has a show_question or hide_question rule_action
          if (ruleSet.rule_action && (ruleSet.rule_action.action_type === 'show_question' || ruleSet.rule_action.action_type === 'hide_question')) {
            // find the target question in the area of the origin question
            const targetQuestion = this.state.checklist.areas.find(a => a.id === question.area_id && a.area_position === question.area_position).questions.find(q => ruleSet.rule_action.target_question_ids.includes(q.id));

            // if the target question is found
            if (targetQuestion) {
              // add the origin question to the target question
              console.log('checklist.areas', this.state.checklist.areas);
              console.log('area', area);
              console.log('area questions', area.questions);
              console.log('targetQuestion', targetQuestion);

              // find the result in the result_set assigned to the origin question
              const result = this.state.resultSet.results.find(r => r.question_id === question.id && r.area_position === question.area_position);

              // if the result is found and it has an action array defined
              if (result && result.action) {
                result.action.forEach(a => {
                  a.origin_question = question;
                  // execute it again, because the first run we should not have had an origin_question for the rule
                  if (a.action === 'show_question' || a.action === 'hide_question') {
                    this.executeAction(a);
                  }
                });
              }
            }
          }
        });
      });
    });
  };
  private addOneToShowCount = (area: IArea) => {
    const showCount = area.showCount ? area.showCount : 1;
    const newShowCount = showCount + 1;
    this.updateAreaShowCount([area.id], newShowCount);
  };
  private subtractOneFromShowCount = (area: IArea) => {
    const showCount = area.showCount ? area.showCount : 1;
    const newShowCount = showCount - 1 > 0 ? showCount - 1 : 0;
    this.updateAreaShowCount([area.id], newShowCount);
  };

  /**
  * This function performs a series of frontend actions defined based on rules.
  * It takes an action and a list of processed area IDs as parameters.
  * The actions can include setting question values, showing/hiding questions or areas, 
  * duplicating questions or areas, counting as error, and creating tasks.
  * Ignored actions that should only be implemented in the backend are skipped.
  * After each action is executed, a message is displayed if available.
  * 
  * @param frontendAction - The action object defining the action to be executed and the targets of the action.
  * @param processedAreaIds - An optional list of IDs of the areas that have already been processed.
  */
  private executeAction = async (frontendAction: ResultAction, processedAreaIds: Set<number> = new Set()): Promise<void> => {
    log.debug('[executeAction()] Executing action:', frontendAction);

    // ignore actions that must be only implemented in backend
    if (ignoredActions.includes(frontendAction.action)) {
      return;
    }
    const {
      target_area_ids,
      target_question_ids,
      target_question_count,
      area_position
    } = frontendAction;
    switch (frontendAction.action) {
      case 'set_question_value':
        log.debug('[executeAction()] Setting question value:', frontendAction.value);
        if (target_question_ids.length) {
          this.setQuestionValue(target_question_ids[0], frontendAction.value, area_position ?? 1, 1);
        }
        break;
      case 'show_question':
        log.debug('[executeAction()] Showing question:', target_question_ids);
        this.setQuestionHideOrShow(frontendAction.origin_question, target_question_ids, false, area_position ?? 1);
        break;
      case 'hide_question':
        log.debug('[executeAction()] Hiding question:', target_question_ids);

        /*const originQuestionResult = this.state.resultsByQuestionId[frontendAction.origin_question.id];
        log.debug('originQuestionResult', originQuestionResult);*/

        this.setQuestionHideOrShow(frontendAction.origin_question, target_question_ids, true, area_position ?? 1);
        break;
      case 'show_area':
        log.debug('[executeAction()] Showing area:', target_area_ids);
        this.setAreaHide(target_area_ids, false);
        break;
      case 'duplicate_area':
        log.debug('[executeAction()] Duplicating area:', target_area_ids);
        for (const areaId of target_area_ids) {
          if (processedAreaIds.has(areaId)) {
            continue;
          }

          // Use the target_area_count from the current action
          const count = frontendAction.target_area_count ?? 1;

          // Update the show count
          this.updateAreaShowCount([areaId], count);

          // Mark this areaId as processed
          processedAreaIds.add(areaId);
        }
        break;
      case 'duplicate_question':
        log.debug('[executeAction()] Duplicating question:', target_question_ids);
        this.updateQuestionShowCount(target_question_ids, target_question_count, area_position ?? 1);
        break;
      case 'hide_area':
        log.debug('[executeAction()] Hiding area:', target_area_ids);
        this.setAreaHide(target_area_ids, true);
        break;
      case 'count_as_error':
        log.debug('[executeAction()] Counting as error:', target_question_ids);
        log.warn('[executeAction()] Count as error is not implemented yet');
        this.setCountAsError(target_question_ids);
        break;
      case 'create_task':
        log.debug('[executeAction()] Creating task:', frontendAction.task_data);
        this.createTaskFromAction(frontendAction.task_data);
        break;
      default:
        // eslint-disable-next-line no-console
        log.error('[executeAction()] ERROR: Unknown action:', frontendAction);
        break;
    }
    if (frontendAction.message) {
      alert(frontendAction.message);
    }
  };
  private setQuestionValue(target_question_id: number, value: any, areaPosition: number, quesitonPostion: number) {
    // if (!this.state.resultSet) { return }
    // FIXME: This must updated also the shown value

    const result = this.state.resultSet.results.find(x => x.question_id === target_question_id);
    // if the question has already this value then return
    // because setting the same value again would unset the value
    if (result && result.value === value) {
      return;
    }
    this.handleUpdate(target_question_id, value, 'value', areaPosition, quesitonPostion).then(() => {
      this.forceUpdate();
    });
  }

  // TODO
  private setCountAsError = (id: number[]) => {
    // eslint-disable-next-line no-console
    log.debug('Coming Soon');
  };
  private setAreaHide = (area_ids: any[], hide: boolean) => {
    this.setState(({
      checklist
    }) => {
      const {
        areas
      } = checklist;
      area_ids.forEach(area_id => {
        const foundAreas = areas.filter(x => x.id === area_id || x.original_area_id === area_id);
        for (const area of foundAreas) {
          area.hide = hide;
          area.showCount = hide ? 0 : 1;
        }
      });
      return {
        checklist: {
          ...checklist,
          areas
        }
      };
    });
  };

  /**
   * This function sets the display count for a given set of questions in the checklist.
   * It takes an array of question IDs, a count, and an area position as parameters.
   * The function first creates a flat list of all questions in all areas.
   * Then, for each question ID in the provided list, it finds the corresponding question in the flat list that has the same area position.
   * If such a question is found, it sets its showCount to the provided count.
   * Finally, it updates the checklist in the component's state with the modified list of areas.
   * 
   * @param question_ids - An array of IDs of the questions to be updated.
   * @param questionCount - The count to be set as the showCount of the questions.
   * @param area_position - The position of the area that the questions belong to.
   */
  private updateQuestionShowCount = (question_ids: number[], questionCount: number, area_position: number) => {
    log.debug('[updateQuestionShowCount] setting show count: ', questionCount);
    this.setState(({
      checklist,
      resultSet
    }) => {
      const {
        areas
      } = checklist;
      const questions = areas.flatMap(x => x.questions);
      question_ids.forEach(questionId => {
        const question: IQuestion = questions.find(x => x.id === questionId && x.area_position === area_position);
        if (!question) return;
        log.debug('[updateQuestionShowCount()] Question:', question);
        question.showCount = questionCount;

        // Update the resultSet.results as well
        const result = resultSet.results.find(r => r.question_id === questionId && r.area_position === area_position);
        if (result) {
          result.showCount = questionCount;
        }
      });
      return {
        checklist: {
          ...checklist,
          areas
        },
        resultSet
      };
    });
  };

  /**
   * This function sets the display count for a given set of areas in the checklist.
   * It takes an array of area IDs and a count as parameters.
   * The function first filters out areas that are not in the provided list of area IDs or have an area_position greater than 1.
   * Then, for each area ID in the provided list, it finds the corresponding area in the filtered list and sets its showCount and area_position.
   * It also sets the area_position of all questions in the area to 1.
   * If the areaCount is greater than 1, it duplicates the area and its questions, incrementing the area_position each time, and inserts the duplicates into the list of areas.
   * Finally, it updates the checklist in the component's state with the modified list of areas.
   * 
   * @param area_ids - An array of IDs of the areas to be updated.
   * @param areaCount - The count to be set as the showCount of the areas.
   */
  private updateAreaShowCount = (area_ids: any[], areaCount: number) => {
    log.debug('[updateAreaShowCount()] setting show count', area_ids, areaCount);
    this.setState(({
      checklist
    }) => {
      const areas = checklist.areas.filter(area => {
        if (!area_ids.includes(area.id)) return true;
        return !(area.area_position && area.area_position > 1);
      });
      area_ids.forEach(area_id => {
        const areaIndex = areas.findIndex(x => x.id === area_id);
        if (areaIndex <= -1) {
          return;
        }
        const area = areas[areaIndex];
        log.debug('[updateAreaShowCount()] Duplicating area', area);
        const updatedArea = {
          ...area,
          showCount: areaCount,
          area_position: 1,
          questions: area.questions.map(question => ({
            ...question,
            area_position: 1
          }))
        };
        areas[areaIndex] = updatedArea;
        for (let i = 1; i < areaCount; i++) {
          areas.splice(areaIndex + i, 0, {
            ...updatedArea,
            area_position: i + 1,
            original_area_id: area_id,
            is_duplicate: true,
            questions: updatedArea.questions.map(question => ({
              ...question,
              area_id: updatedArea.id,
              area_position: i + 1
            }))
          });
        }

        // TODO? or is the code in executeActionsOnLoad() enough?
        // iterate over every question. find questions in this area_id and area_position. if
        // a question has a rule_set with rule_set.rule_action.action_type "show_question" or "hide_question"
        // and rule_set.rule_action.target_area_ids is empty and for each rule_set.rule_action.target_question_ids in this area
        // we need to set the origin_question of the question to the question that triggered the rule
      });

      // Update the position of each area to match its index in the array
      const updatedAreas = areas.map((area, index) => ({
        ...area,
        position: index
      }));
      log.debug('[updateAreaShowCount()] areas after duplicate', updatedAreas);
      const updatedChecklist = {
        ...checklist,
        areas: updatedAreas
      };
      log.debug('[updateAreaShowCount()] Updated Checklist', updatedChecklist);
      return {
        checklist: updatedChecklist
      };
    });
  };

  /**
   * This function finds and returns all duplicate questions in a given array of questions.
   * A question is considered a duplicate if there is another question in the array with the same ID.
   * The function first counts the occurrences of each question ID in the array.
   * It then filters the array to include only the questions whose ID count is greater than 1, and returns this filtered array.
   * This way, it returns an array of all duplicate question objects, not just their IDs.
   *
   * @param {IQuestion[]} questions - An array of questions to search for duplicates.
   * @returns {IQuestion[]} An array of all duplicate questions found in the input array.
   */
  private findDuplicateQuestions = (questions: IQuestion[]): IQuestion[] => {
    const idCount = new Map();
    questions.forEach(question => {
      idCount.set(question.id, (idCount.get(question.id) || 0) + 1);
    });
    const duplicates = questions.filter(question => idCount.get(question.id) > 1);
    return duplicates;
  };

  /**
   * This function is used to hide or show questions across all areas based on their IDs.
   * It first finds all questions across all areas that match the given IDs.
   * If there are duplicate questions (questions with the same ID), it finds the correct area of the question to show or hide based on the area_position of the origin_question.
   * It then updates the state of the checklist, setting the 'hide' property of the questions to the passed boolean,
   * and the 'shown_because_of_rule' property to the opposite of the passed boolean.
   *
   * @param {IQuestion} origin_question - The original question that triggered the hide/show action.
   * @param {number[]} question_ids - An array of question IDs to be hidden or shown.
   * @param {boolean} hide - A boolean indicating whether to hide (true) or show (false) the questions.
   * @param {number} area_position - The position of the area where the original question is located.
   */
  private setQuestionHideOrShow = (origin_question: IQuestion, question_ids: number[], hide: boolean, area_position: number) => {
    log.debug('[setQuestionHideOrShow()] origin_question:', origin_question);
    log.debug(`[setQuestionHideOrShow()] question_ids: ${JSON.stringify(question_ids)}, hide: ${hide}, area_position: ${area_position}`);
    this.setState(({
      checklist
    }) => {
      const {
        areas
      } = checklist;

      // Find all questions across all areas that match the given IDs
      let questions = [];
      areas.forEach(area => {
        questions = [...questions, ...area.questions.filter(question => question && question_ids.includes(question.id))];
      });
      const duplicate_questions = this.findDuplicateQuestions(questions);
      log.debug('[setQuestionHideOrShow()] duplicate_questions:', duplicate_questions);

      // Create a new array for the areas
      const newAreas = areas.map(area => {
        // Create a new array for the questions
        const newQuestions = area.questions.map(question => {
          // If this question should be updated, create a new object for it
          if (question && question_ids.includes(question.id)) {
            // Check if the question is a duplicate and has the same area_position as the origin_question
            if (duplicate_questions.includes(question)) {
              if (origin_question && question.area_position === origin_question.area_position) {
                // Remove this question from the question_ids array
                question_ids = question_ids.filter(id => id !== question.id);
                // Create a new question object with the updated properties
                const newQuestion = {
                  ...question,
                  hide: hide,
                  shown_because_of_rule: !hide
                };
                log.debug(`[setQuestionHideOrShow()] ${hide ? 'hiding' : 'showing'} question (duplicate):`, newQuestion, `area_position: ${newQuestion.area_position}`);
                // Return the new question object
                return newQuestion;
              }
            } else {
              //TODO: we have no information about the origin_question (happens on load)
              log.warn("[setQuestionHideOrShow()] WARN: we have no origin_question. skipping");
            }
          }

          // Otherwise, return the original question object
          return question;
        });

        // After handling duplicates, handle the remaining questions
        const finalQuestions = newQuestions.map(question => {
          if (question && question_ids.includes(question.id) && !duplicate_questions.includes(question)) {
            log.debug(`[setQuestionHideOrShow()] ${hide ? 'hiding' : 'showing'} question:`, question, `area_position: ${question.area_position}`);
            return {
              ...question,
              hide: hide,
              shown_because_of_rule: !hide
            };
          }

          // Otherwise, return the original question object
          return question;
        });

        // Return a new object for the area with the updated questions
        return {
          ...area,
          questions: finalQuestions
        };
      });

      // Return a new object for the checklist with the updated areas
      return {
        checklist: {
          ...checklist,
          areas: newAreas
        }
      };
    }, () => {
      // After state update is complete, force a re-render
      this.forceUpdate();
    });
  };
  private handleShareSubmit = (data: ICreateResultSetFollowerData) => {
    this.setState({
      isClosing: true
    });
    api.resultSets.share(this.state.resultSet.id, data).then(() => {
      this.closeShareModal();
    }).catch(() => {
      this.setState({
        showShareModal: false,
        isLoading: false
      });
    }).finally(() => {
      this.setState({
        isClosing: false
      });
    });
  };
  private openPermissionsModal = (area: IArea) => {
    this.setState({
      showAreaPermissionsModal: area
    });
  };
  private closePermissionsModal = () => {
    this.setState({
      showAreaPermissionsModal: null
    });
  };
  private closeShareModal = () => {
    this.setState({
      showShareModal: false
    });
  };
  private onShare = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({
      showShareModal: true
    });
    this.hideDropdown();
  };

  /**
   * This function calculates the total and filled question count for a given area and position.
   * It first checks if there are any results in the state. If not, it returns 0 for both total and filled.
   * If there are results, it finds the section in the checklist that matches the given areaId.
   * If such a section is found, it filters the questions in that section that are not excluded from progress and not hidden.
   * The total question count is then the length of these filtered questions.
   * The filled question count is the number of results that match the question ids of the filtered questions,
   * have been filled (as determined by the isFilled function), and have the same area position as the given resultAreaPosition.
   * If signatures are required (i.e., this.state.checklist.signature_required is not 'no'), it also counts the signatures that have a url or are a string.
   * These counts are added to the filled count, and the total count is increased by 2 if double signatures are required, otherwise by 1.
   * The function then returns an object with the total and filled counts.
   *
   * @param {string} areaId - The id of the area for which to calculate the question counts.
   * @param {number} resultAreaPosition - The position of the area in the results for which to calculate the question counts.
   * @returns {QuestionCount} - An object with the total and filled question counts.
   */
  public getFilledQuestionCount = (areaId: string | number, resultAreaPosition: number): QuestionCount => {
    // special handling for signature areas (which have areaId -1)
    if (areaId === -1) {
      const signatureCount = this.state.resultSet.signatures.filter(s => s.signature && s.signature.url || typeof s.signature === 'string').length;
      return {
        total: this.state.checklist.signature_required === 'double' ? 2 : 1,
        filled: signatureCount
      };
    }
    if (!this.state.resultSet.results) {
      return {
        total: 0,
        filled: 0
      };
    }
    const area = this.state.checklist.areas.find(area => area.id === areaId);
    let total = 0;
    let filled = 0;
    if (area) {
      const questions = area.questions.filter(q => !q.exclude_from_progress && !q.hide && q.question_type !== 'information');
      total = questions.length;
      filled = this.state.resultSet.results.filter(result => questions.map(q => q.id).includes(result.question_id) && isFilled(result, this.state.resultSet) && result.area_position === resultAreaPosition).reduce((sum, r) => sum + (r.showCount || 1), 0);
    }
    if (this.state.checklist.signature_required !== 'no' && areaId === -1) {
      const signatureCount = this.state.resultSet.signatures.filter(s => s.signature && s.signature.url || typeof s.signature === 'string').length;
      filled += signatureCount;
      total += this.state.checklist.signature_required === 'double' ? 2 : 1;
    }
    return {
      total,
      filled
    };
  };
  public hasAttachments = (questionId: any) => {
    let hasAttachments = false;
    if (this.state.resultSet && this.state.resultSet.results) {
      const results = this.state.resultSet.results.filter((item: {
        question_id: number;
      }) => item.question_id === questionId);
      if (results.length) {
        hasAttachments = true;
        results.forEach(result => {
          if (result.attachments.length === 0) {
            hasAttachments = false;
          }
        });
      }
    }
    return hasAttachments;
  };
  public getAuditPoints = (questionId: number, areaPosition: number) => {
    if (this.state.resultSet && this.state.resultSet.results) {
      const results = this.state.resultSet.results.filter(item => item.question_id === questionId && item.area_position === areaPosition);
      if (results.length) {
        const [result] = results;
        return result.question && result.question.has_audit_points && !Number.isNaN(result.audit_points) ? result.audit_points : 0;
      }
    }
    return 0;
  };
  private buildSignatureArea = (): IArea => {
    const signatureOption = signatureOptions.find(x => x.value === this.state.checklist.signature_required);
    const area: IArea = {
      questions: [{
        has_audit_points: false,
        is_required: true,
        has_attachments_required: false,
        question_type: 'signature',
        title: this.props.t('Tester'),
        id: signaturePseudoQuestionIds.tester,
        display_as_checkbox: false,
        disable_na: false,
        unit: '',
        default_value: '',
        exclude_from_progress: false,
        signee_free_text: this.state.checklist.signee_free_text ?? false,
        position: 0,
        enable_speech_to_text: false,
        enable_cloud_attachments: false,
        test_question: false,
        correct_answers: [],
        timeonly: false,
        formula: '',
        action: []
      }],
      title: this.props.t(signatureOption.translationKey),
      position: -1,
      hide: undefined,
      description: '',
      team_ids: [],
      user_ids: [],
      linked_template_id: null
    };
    if (this.state.checklist.signature_required === 'double') {
      area.questions.push({
        has_audit_points: false,
        is_required: true,
        has_attachments_required: false,
        question_type: 'signature',
        title: this.props.t('Validator'),
        id: signaturePseudoQuestionIds.validator,
        display_as_checkbox: false,
        disable_na: false,
        unit: '',
        default_value: '',
        exclude_from_progress: false,
        signee_free_text: this.state.checklist.signee_free_text ?? false,
        position: 1,
        enable_speech_to_text: false,
        enable_cloud_attachments: false,
        test_question: false,
        correct_answers: [],
        timeonly: false,
        formula: '',
        action: []
      });
    }
    return area;
  };
  public closeResultSet = async () => {
    this.setState({
      isClosing: true
    });
    const resultSet: IResultSet = {
      ...this.state.resultSet,
      status: 'closed',
      closed_at: new Date(),
      _dirty: true,
      progress: ResultSetUtil.completionPercentage(this.state.resultSet, this.state.checklist)
    };
    const apicalls = this.state.checklist.areas.flatMap(x => x.questions).filter(x => x.question_type === 'apicall' && x.api_call_config?.execute_on_close);
    for (const question of apicalls) {
      await makeApiCall(question, resultSet, this.props.t, () => {});
    }
    const result = await api.resultSets.update(this.state.checklist.id, resultSet.id, resultSet, this.getAccessToken());
    const offlineChecklist = await this.offlineDb.resultSets.get(resultSet.id);
    if (offlineChecklist && !this.props.isOffline) {
      await this.offlineDb.resultSets.delete(resultSet.id);
    }
    this.setState({
      resultSet: result,
      isClosing: false
    });
    if (this.state.checklist.continously_create) {
      confirm(null, this.props.t('resultSet.createAnother'), () => {
        this.handleStartNextInspection();
      }, () => this.postClose());
    } else {
      this.postClose();
    }
  };
  private handleStartNextInspection = () => {
    const {
      resultSet
    } = this.state;
    const qs = querystring.stringify({
      due_date: resultSet.due_date.toString(),
      title: resultSet.title,
      project_id: resultSet.project_id,
      location: resultSet.location,
      contact_id: resultSet.contact_id,
      tester_id: resultSet.tester_ids.join(','),
      team_ids: resultSet.team_ids.join(','),
      resource_id: resultSet.ressource_id,
      schedule_id: resultSet.schedule_id
    });
    this.props.history.push(`/resultset/${this.state.checklist.id}/new?${qs}`);
  };
  private postClose() {
    if (this.props.match.path === '/tab/:tabIndex') {
      this.props.history.push('/');
      this.props.onClose(true);
    } else if (this.getAccessToken()) {
      // redirect to report after close
      this.props.history.push(`/checklists/${this.state.resultSet.checklist_id}/audit/${this.state.resultSet.id}/report` + `?access_token=${this.getAccessToken()}`);
    } else if (this.props.history) {
      // jump to "Finished Tests" tab
      const targetUrl = !this.props.match.params.resultSetId ? `/checklists/?tab=${closedInspectionsTabIndex}` : '/';
      this.props.history.push(targetUrl);
    } else {
      this.props.onClose(true);
    }
  }
  private checkCorrectAnswers = () => {
    const testQuestions = this.state.checklist.areas.flatMap(area => area.questions).filter(x => x.test_question);
    if (testQuestions.length === 0) {
      return true;
    }
    const wrongAnswers: IQuestion[] = [];
    for (const question of testQuestions) {
      const answers = this.state.resultSet.results.filter(x => x.question_id === question.id);
      if (answers.length === 0) {
        wrongAnswers.push(question);
      }
      if (question.question_type === 'dropdown') {
        const correctAnswers = question.question_dropdown_items.filter(x => x.is_correct).map(x => x.id);
        if (answers.filter(x => correctAnswers.includes(parseInt(x.value))).length === 0) {
          wrongAnswers.push(question);
        }
      } else if (question.question_type === 'checkbox') {
        if (answers.filter(x => question.correct_answers.filter(y => y === x.value)).length === 0) {
          wrongAnswers.push(question);
        }
      }
    }
    if (wrongAnswers.length > 0) {
      alert(this.props.t('resultSet.wrongAnswers', {
        wrongAnswers: wrongAnswers.map(x => x.title).join(', ')
      }));
    }
    const correctAnswers = testQuestions.length - wrongAnswers.length;
    const quota = correctAnswers === 0 ? 0 : correctAnswers / testQuestions.length * 100;
    return !(this.state.checklist.test_checklist && quota < this.state.checklist.required_quota);
  };
  public handleCloseInspection = () => {
    const {
      t
    } = this.props;

    // ADF-1455: prevent closing a checklist while still requests are running
    if (this.isRequestRunning()) {
      toast(t('resultSet.requestIsRunning'), {
        type: 'warning'
      });
      return;
    }

    // Somehow I managed to close the checklist
    // while page load even if not all required fields are filled
    // can't reproduce this now but this should fix it
    if (!this.state.checklist || !this.state.resultSet) {
      return;
    }

    // adf-592: check if all visible! required fields are filled
    const visibleAreas = this.state.checklist.areas.filter(x => !x.hide);
    const requiredQuestions = selectMany<IQuestion>(visibleAreas, x => x.questions).filter(question => (question.is_required || question.has_attachments_required) && !question.hide);
    const requiredNotFilledFields = [];
    for (const rq of requiredQuestions) {
      const result = this.state.resultSet.results.find(x => x.question_id === rq.id);
      if (!result) {
        requiredNotFilledFields.push(rq);
      } else {
        const attachments = result.attachments ?? [];
        if (rq.is_required && !isFilled(result, this.state.resultSet)) {
          requiredNotFilledFields.push(rq);
        } else if (rq.has_attachments_required && attachments.length === 0) {
          requiredNotFilledFields.push(rq);
        }
      }
    }
    const fieldsWithMissingAttachments = requiredQuestions.filter(x => x.has_attachments_required && !this.hasAttachments(x.id) && !x.hide);

    // if the question requires an attachment
    // then this is shown after the question title in
    // the modal which tells the not filled required questions.
    const validationErrors = requiredNotFilledFields.map(x => {
      const error: INotFilledQuestion = x;
      error.attachmentMissing = fieldsWithMissingAttachments.some(y => x.id === y.id);
      return error;
    });
    let isSignedValid = true;
    const signatureCount = this.state.resultSet.signatures.filter(s => s.signature && (s.signature.url || typeof s.signature === 'string')).length;
    if (this.state.checklist.signature_required === 'single') {
      isSignedValid = signatureCount >= 1;
    } else if (this.state.checklist.signature_required === 'double') {
      isSignedValid = signatureCount >= 2;
    }
    this.setState({
      selectedAreaId: null,
      selectedAreaPosition: null
    });
    if (!this.checkCorrectAnswers()) {
      return;
    }

    // if all required fields and the signature is filled (if required)
    if (!requiredNotFilledFields.length && isSignedValid) {
      if (this.state.checklist.measures.length > 0) {
        this.setState({
          showCatalogDialog: true
        });
      } else {
        this.closeResultSet();
      }
    } else {
      // ADF-335: show modal with a list of the not filled required questions
      this.setState({
        showCloseResultFailedModal: true,
        visibleAreas,
        validationErrors,
        isSignedValid
      });
    }
  };
  private isProcessingUpdateQueue = false;

  // ADF-997: use a queue for questions with rules
  // if there are multiple requests that execute rules are submitted parallel
  // Then it causes not all areas will be hidden or shown
  private processUpdateQueue = async () => {
    if (this.state.updateQueue.length === 0) {
      // nothing to do
      this.timer = setTimeout(this.processUpdateQueue, this.timerInterval);
      return;
    }
    log.debug("[processUpdateQueue()] Processing update queue");
    if (this.isProcessingUpdateQueue) {
      log.warn("[processUpdateQueue()] returning doing nothing, because queue is already being processed.");
      return;
    }
    this.setState({
      isRequestRunning: true
    }, async () => {
      this.isProcessingUpdateQueue = true;
      try {
        while (this.state.updateQueue.length > 0) {
          const [entry] = this.state.updateQueue;
          log.debug("[processUpdateQueue] Executing event", entry);
          await Promise.resolve(entry.func());

          // Remove the processed entry from the queue
          this.state.updateQueue.shift();
        }
      } catch (e) {
        log.error('[processUpdateQueue] Error in processUpdateQueue:', e);
      } finally {
        this.isProcessingUpdateQueue = false;
        this.timer = setTimeout(this.processUpdateQueue, this.timerInterval);
        if (this.state.updateQueue.length === 0) {
          this.setState({
            isRequestRunning: false
          });
        }
      }
    });
  };
  private handleRenameArea = async (areaId, areaPosition, name) => {
    const {
      resultSet
    } = this.state;
    let areaName = resultSet.area_names.find(x => x.area_id === areaId && x.area_position === areaPosition);
    if (!areaName) {
      areaName = {
        area_id: areaId,
        area_position: areaPosition,
        name
      };
      resultSet.area_names.push(areaName);
    } else {
      areaName.name = name;
    }
    const response = await api.resultSets.update(resultSet.checklist_id, resultSet.id, resultSet);
    this.setState({
      resultSet: response
    });
  };
  private handleUpdate = async (questionId: number, value: any, field = 'value', resultAreaPosition: number = 1, questionPosition: number = 1):
  // eslint-disable-next-line consistent-return
  Promise<IResult> => {
    this.setState({
      isRequestRunning: true
    });
    try {
      if (this.state.resultSet.status === 'closed') {
        // cant edit a closed checklist
        return new Promise<IResult>(() => null);
      }
      const question = selectMany<IQuestion>(this.state.checklist.areas, a => a.questions).find(q => q.id === questionId && q.area_position === resultAreaPosition); // Use resultAreaPosition to find the correct question

      /*
      if (question?.rule_sets?.length > 0) {
        this.setState({ isRequestRunning: true })
      }*/

      const {
        t
      } = this.props;
      const {
        resultSet
      } = this.state;
      if (!this.state.resultSet.results) {
        resultSet.results = [];
      }
      let result: any = resultSet.results.filter(item => item.question_id === questionId && item.area_position === resultAreaPosition && item.question_position === questionPosition);
      log.debug('[handleUpdate()] result', result);
      const oldValue = result.length ? result[0].value : '';
      if (field === 'start_date') {
        resultSet.start_date = value;
        api.resultSets.update(resultSet.checklist_id, resultSet.id, resultSet, this.getAccessToken());
        this.setState({
          resultSet: {
            ...resultSet
          }
        });
        return new Promise<IResult>(() => null);
      }
      if (field === 'external_email') {
        resultSet.external_email = value;
        api.resultSets.update(resultSet.checklist_id, resultSet.id, resultSet, this.getAccessToken());
        this.setState({
          resultSet: {
            ...resultSet
          }
        });
        return new Promise<IResult>(() => null);
      }
      if (field === 'full') {
        resultSet.results = resultSet.results.filter(item => item.question_id !== questionId);
        resultSet.results.push(value);
        this.setState({
          resultSet: {
            ...resultSet
          }
        });
      } else {
        if (result.length) {
          [result] = result;
        } else {
          result = {
            question_id: questionId,
            checklist_id: this.state.checklist.id,
            area_position: resultAreaPosition,
            question,
            question_position: questionPosition
          };
        }
        if (field === 'option_text') {
          result.option_text = value;
        } else if (field === 'audit_points') {
          result.audit_points = Math.min(value, AUDIT_POINT_MAX);
        } else if (field === 'cloud_attachments') {
          this.processCloudAttachments(value, result.id);
          return new Promise<IResult>(() => null);
        } else if (field === 'signature') {
          if (value) {
            result.signature = {
              url: value
            };
            result.value = 'signed';
          } else {
            result.signee = null;
            result.signature = {
              url: null
            };
            result.value = null;
          }
        } else if (field === 'contact') {
          result.contact_id = value;
          if (value) {
            result.user_id = null;
            result.signee_name = '';
          }
          const contact = this.state.contacts.find(x => x.id === value);
          if (contact) {
            result.signee = `${contact.firstname} ${contact.lastname}`;
          }
        } else if (field === 'user') {
          result.user_id = value;
          if (value) {
            result.contact_id = null;
            result.signee_name = '';
          }
          result.signee = this.state.contacts.find(x => x.id === value);
        } else if (field === 'signee_name') {
          result.user_id = null;
          result.contact_id = null;
          result.signee_name = value;
        } else if (field === '_working') {
          result._working = value;
          const results = resultSet.results.filter(item => item.id !== result.id);
          resultSet.results = [...results, {
            ...result
          }];
          this.setState({
            resultSet: {
              ...resultSet
            }
          });
          return;
        } else if (result.value === value && (question.question_type === 'checkbox' || question.question_type === 'rating' || question.question_type === 'real_checkbox')) {
          result.value = '';
        } else {
          result.value = value;
          if (!resultSet.start_date) {
            this.handleUpdate(null, new Date(), 'start_date', 1, 1);
          }
        }
        const user = this.state.allUsers.find(x => x.id === value);
        if (user) {
          result.signee = `${user.firstname} ${user.lastname}`;
        } else if (!result.signee?.length) {
          result.signee = t('users.roles.guest');
        }
        if (result.id) {
          const results = resultSet.results.filter(item => item.id !== result.id);
          resultSet.results = [...results, {
            ...result
          }];
          this.setState({
            resultSet: {
              ...resultSet
            }
          });
        }
        const requestFunc = async (): Promise<IResult> => {
          const action: Promise<IResult> = result.id ? api.results.update(this.state.checklist.id, resultSet.id, result, this.getAccessToken()) : api.results.create(this.state.checklist.id, resultSet.id, result, this.getAccessToken());
          try {
            const data = await action;
            if (data.action && data.action.length > 0) {
              const actions: ResultAction[] = this.filterActions(data.action);
              actions.forEach(result_action => {
                result_action.origin_question = question;
                this.executeAction(result_action);
              });

              // ADF-855: handle send email rule actions
              // shows a confirmation message to the user if change of the value
              // will cause an email delivery
              this.handleEmailActions(oldValue, data, actions);
            }
            if (!result.id) {
              this.setState(state => ({
                resultSet: {
                  ...state.resultSet,
                  results: [...state.resultSet.results, data]
                }
              }), () => {
                this.forceUpdate();
              });
            } else {
              this.setState(state => ({
                resultSet: {
                  ...state.resultSet,
                  results: [...state.resultSet.results.filter(x => x.id !== data.id), data]
                }
              }));
            }
            return data;
          } catch (error: any) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              log.error(error.response.data);
              log.error(error.response.status);
              log.error(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              log.error(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              log.error('Error', error.message);
            }
            log.error(error.config);
          }
        };
        if (this.props.isOffline) {
          const progress = ResultSetUtil.completionPercentage(resultSet, this.state.checklist);
          api.resultSets.update(this.state.checklist.id, resultSet.id, {
            ...resultSet,
            progress
          }, this.getAccessToken());
        }
        if (field === 'value' && question) {
          this.state.updateQueue.push({
            func: requestFunc,
            questionId: question.id
          });
          log.debug('[handleUpdate()] UpdateQueue post add', this.state.updateQueue);
          return new Promise<IResult>(() => null);
        }
        return await requestFunc();
      }
    } catch (e) {
      log.error('[handleUpdate] Error in handleUpdate:', e);
    } finally {
      this.setState({
        isRequestRunning: false
      });
    }
  };

  // ADF-935:
  // if the user changes a value of a question that has an send email rule action defined
  // then show a confirm dialog to the user
  // if the user accepts the value is kept and the email is sent
  // if the user rejects then the old value will be restored
  private handleEmailActions = (oldValue: string | null, newResult: IResult, frontendActions: any[]) => {
    const {
      t
    } = this.props;
    // if there are no send email actions return
    if (!frontendActions.some(x => x.action === 'send_email')) {
      return;
    }

    // only show confirm if the value was changed
    if (oldValue === newResult.value) {
      return;
    }

    // show confirm message
    confirm(null, t('resultSet.confirmSendEmailRule'), async () => {
      await api.results.sendRuleEmails(this.state.checklist.id, this.state.resultSet.id, newResult.id, this.getAccessToken());
    }, async () => {
      await this.handleUpdate(newResult.question_id, oldValue, 'value', newResult.area_position, newResult.question_position);
    });
  };

  // if we have multiple rule sets targeting the same areas as action
  // prevent other rule_sets from hiding the target areas if show area and hide area actions
  // are returned at the same time
  private filterActions = (actions: ResultAction[]): ResultAction[] => {
    const showAreaActions = actions.filter(x => x.action === 'show_area' || x.action === 'duplicate_area');
    const showAreaIds = showAreaActions.map(x => x.target_area_ids).flat();
    return actions.map(action => {
      if (action.action === 'hide_area') {
        action.target_area_ids = action.target_area_ids.filter(x => !showAreaIds.includes(x));
      }
      return action;
    });
  };
  private handleSignatureUpdated = (questionId: any, value: any, field?: string): Promise<ISignature> => {
    let signature: ISignature = questionId === signaturePseudoQuestionIds.tester ? this.state.resultSet.signatures.find((s: ISignature) => s.is_tester) : this.state.resultSet.signatures.find((s: ISignature) => !s.is_tester);
    if (!signature) {
      signature = {
        is_tester: questionId === signaturePseudoQuestionIds.tester,
        signature: null,
        user_id: null,
        display_user_name: null
      };
    }
    if (field === 'signature') {
      signature.signature = value;
    } else if (field === 'contact') {
      signature.contact_id = value;
      signature.user_id = null;
    } else if (field === 'user') {
      signature.user_id = value;
      signature.contact_id = null;
    } else if (field === 'signee_name') {
      signature.user_id = null;
      signature.contact_id = null;
      signature.user_name = value;
    }
    const request = signature.id ? api.resultSets.updateSignature(this.state.checklist.id, this.state.resultSet.id, signature.id, signature, this.getAccessToken()) : api.resultSets.createSignature(this.state.checklist.id, this.state.resultSet.id, signature, this.getAccessToken());
    request.then(result => {
      const resultSet: IResultSet = {
        ...this.state.resultSet,
        signatures: [...this.state.resultSet.signatures.filter(s => s.id !== result.id), result]
      };
      this.setState({
        resultSet
      });
    });
    return request;
  };
  public handleAttachmentUpdated = (questionId, attachmentId, updatedAttachment) => {
    const resultSet = {
      ...this.state.resultSet
    };
    const otherResults = resultSet.results.filter(x => x.question_id !== questionId);
    const result = resultSet.results.find(x => x.question_id === questionId);
    const otherAttachments = result.attachments.filter(x => x.id !== attachmentId);
    result.attachments = [...otherAttachments, {
      ...updatedAttachment
    }];
    resultSet.results = [{
      ...result
    }, ...otherResults];
    this.setState({
      resultSet
    });
  };
  public handleAttachmentDeleted = (questionId, attachmentId) => {
    const resultSet = {
      ...this.state.resultSet
    };
    const result = resultSet.results.find(x => x.question_id === questionId);
    result.attachments = result.attachments.filter(x => x.id !== attachmentId);
    this.setState({
      resultSet
    });
  };
  private onAddComment = async (questionId: number, areaPosition: number, text: string): Promise<void> => {
    if (this.state.resultSet.status === 'closed') {
      // cant edit a closed checklist
      return;
    }
    const resultSet = {
      ...this.state.resultSet
    };
    if (!this.state.resultSet.results) {
      resultSet.results = [];
    }
    const result = resultSet.results.find(theResult => theResult.question_id === questionId && theResult.area_position === areaPosition);
    const tempComment: IComment = {
      commentable_id: result.id,
      commentable_type: 'Result',
      text,
      user: this.props.user,
      user_id: this.props.user.id,
      commentable: result,
      created_at: new Date()
    };
    if (!result.comments) {
      result.comments = [];
    }
    result.comments.push(tempComment);
    this.setState({
      resultSet
    });
    api.comments.create(resultSet.id, result.id, text, this.props.user, 'Result').then(data => {
      const resultId = data.commentable_id;
      const userId = data.user?.id;
      const comment = {
        ...data,
        result_id: resultId,
        user_id: userId,
        created_at: new Date()
      };
      const {
        results
      } = this.state.resultSet;
      const updatedResult = results.find(x => x.id === resultId);
      if (!updatedResult) {
        return;
      }
      if (!updatedResult.comments) {
        updatedResult.comments = [];
      }
      updatedResult.comments = updatedResult.comments.filter(c => c.id);
      updatedResult.comments.push(comment);
      const otherResults = results.filter(x => x.id !== resultId);
      this.setState({
        resultSet: {
          ...this.state.resultSet,
          results: [...otherResults, updatedResult]
        }
      }, () => this.forceUpdate());
    });
  };

  // gets an array of cloud attachments
  // detects which attachments are new and if some must be deleted
  // then call create or destroy action of the backend
  private processCloudAttachments = (attachments: ICloudAttachment[], resultId: string | number) => {
    const newAttachments = attachments.filter(x => x._new);
    const deleteAttachments = attachments.filter(x => x._destroy);
    const result = this.state.resultSet.results.find(x => x.id === resultId);

    // array .forEach() doesn't support async functions
    newAttachments.forEach(attachment => this.addCloudAttachment(attachment, result));
    deleteAttachments.forEach(attachment => this.deleteCloudAttachment(attachment, result));
  };

  // post a new cloud attachment to backend and update state
  private addCloudAttachment = (attachment: ICloudAttachment, result: IResult) => {
    if (!result) return;
    if (!checkFileSelection(attachment, result.cloud_attachments, this.props.t)) {
      return;
    }

    // polymorphic association
    attachment.attachable_type = 'Result';
    attachment.attachable_id = result.id;
    api.cloudAttachments.create(attachment, this.getAccessToken()).then(response => {
      const otherResults = this.state.resultSet.results.filter(x => x.id !== result.id);
      result.cloud_attachments = [...result.cloud_attachments, response.data];
      const allResults = [...otherResults, {
        ...result
      }];
      this.setState({
        resultSet: {
          ...this.state.resultSet,
          results: allResults
        }
      });
    }).catch(error => {
      this.setState({
        error
      });
    });
  };

  // delete a cloud attachment to backend and update state
  private deleteCloudAttachment = (attachment: ICloudAttachment, result: IResult) => {
    api.cloudAttachments.deleteCloudAttachment(attachment.id, this.getAccessToken()).then(() => {
      const otherResults = this.state.resultSet.results.filter(x => x.id !== result.id);
      result.cloud_attachments = result.cloud_attachments.filter(x => x.id !== attachment.id);
      const allResults = [...otherResults, {
        ...result
      }];
      this.setState({
        resultSet: {
          ...this.state.resultSet,
          results: allResults
        }
      });
    }).catch(error => {
      this.setState({
        error
      });
    });
  };
  private onCloneArea = (areaId: number | string) => {
    api.resultSets.cloneArea(this.state.checklist.id, this.state.resultSet.id, areaId).then(response => {
      const otherItems = this.state.resultSet.result_set_area_duplicate_counts.filter(a => a.area_id !== areaId);
      const area = this.state.checklist.areas.find(a => a.id === areaId);
      const result_set_area_duplicate_counts = [...otherItems, response.data.area_duplicate_count];
      const results = [...this.state.resultSet.results, ...response.data.results];
      this.setState({
        resultSet: {
          ...this.state.resultSet,
          result_set_area_duplicate_counts,
          results
        }
      }, () => this.addOneToShowCount(area));
    });
  };
  private onDeleteArea = (areaId: number | string, areaPosition: number) => {
    api.resultSets.deleteArea(this.state.checklist.id, this.state.resultSet.id, areaId, areaPosition).then(() => {
      const area = this.state.checklist.areas.find(a => a.id === areaId);
      const allResultsWithoutDeleted = this.state.resultSet.results.filter(r => !(r.question && r.question.area_id === area.id && r.area_position === areaPosition));
      const resultsToRenumber = allResultsWithoutDeleted.filter(r => r.question && r.question.area_id === area.id);
      const otherResults = allResultsWithoutDeleted.filter(r => !(r.question && r.question.area_id === area.id));
      const duplicateAreaCounts = this.state.resultSet.result_set_area_duplicate_counts.map(areaCounts => {
        if (areaCounts.area_id === area.id) {
          areaCounts.area_duplicate_count -= 1;
          if (areaCounts.area_duplicate_count < 0) {
            areaCounts.area_duplicate_count = 0;
          }
        }
        return areaCounts;
      });
      let position = 1;
      const renumberedResults = resultsToRenumber.map(result => {
        result.area_position = position;
        position++;
        return result;
      });
      this.setState({
        resultSet: {
          ...this.state.resultSet,
          results: [...otherResults, ...renumberedResults],
          result_set_area_duplicate_counts: duplicateAreaCounts
        }
      }, () => this.subtractOneFromShowCount(area));
    });
  };
  private onDeleteComment = (commentId: number | string): void => {
    const {
      t
    } = this.props;
    if (this.state.resultSet.status === 'closed') {
      // cant edit a closed checklist
      return;
    }
    confirm(null, t('result.comment.delete'), () => {
      this.state.resultSet.results.forEach(result => {
        const comment = result.comments ? result.comments.find(x => x.id === commentId) : null;
        if (comment) {
          api.comments.deleteComment(this.state.resultSet.checklist_id, this.state.resultSet.id, comment.commentable_id, comment.id);
          const filteredComments = result.comments.filter(x => x.id !== comment.id);
          const resultWithComment = this.state.resultSet.results.find(x => x.comments.some(y => y.id === commentId));
          resultWithComment.comments = filteredComments;
          const otherResults = this.state.resultSet.results.filter(x => x.id !== resultWithComment.id);
          this.setState({
            resultSet: {
              ...this.state.resultSet,
              results: [...otherResults, resultWithComment]
            }
          });
        }
      });
    });
  };
  private getUrlSuffix = () => {
    const getParams: any = {};
    if (this.props.isFullscreen) {
      getParams.fullscreen = 1;
    }
    if (this.props.isExternalAccess) {
      getParams.access_token = this.getAccessToken();
    }
    const {
      location
    } = this.props;
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get('ref')) {
      getParams.ref = urlParams.get('ref');
    }
    const paramString = querystring.stringify(getParams);
    return paramString.length ? `?${paramString}` : '';
  };
  private onClickAreaCard = (areaId: number | string, areaPosition: number) => {
    // append the area id and position to the url without showing a loadspinner
    const {
      tabIndex,
      folderId,
      context
    } = this.props;
    const {
      resultSet,
      checklist
    } = this.state;
    let url = `/checklists/tab/${tabIndex}/folders/${folderId ?? resultSet.folder_id}/${checklist.id}/audit/` + `${resultSet.id}/areas/${areaId}/position/${areaPosition}`;
    if (context === 'overview') {
      url = `/overview${url}`;
    } else if (this.props.context === 'embedded') {
      url = `/embedded/process/${this.state.resultSet.id}/areas/${areaId}/position/${areaPosition}`;
    }
    if (document.getElementById('result-column')) {
      document.getElementById('result-column').scrollTop = 0;
    }
    url += this.getUrlSuffix();
    this.props.history.push(url);
    this.setState({
      selectedAreaId: areaId,
      selectedAreaPosition: areaPosition
    });
    window.scrollTo(0, 1);
  };

  // ADF-1455: Check if any request is running
  private isRequestRunning = (): boolean => this.state.updateQueue.length > 0;

  // click the "Zurück" button when an area is open
  private onCloseArea = (event?: React.MouseEvent<HTMLAnchorElement> | null) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const url = this.getChecklistBaseUrl();
    this.props.history.push(url);
    this.setState({
      selectedAreaId: null,
      selectedAreaPosition: null
    });
  };
  private getChecklistBaseUrl = () => {
    const {
      tabIndex,
      folderId
    } = this.props;
    const {
      checklist,
      resultSet
    } = this.state;
    let url = `/checklists/tab/${tabIndex}/folders/${folderId ?? resultSet.folder_id}/${checklist.id}/audit/` + `${resultSet.id}`;
    if (this.props.context === 'overview') {
      url = `/overview${url}`;
    } else if (this.props.context === 'embedded') {
      url = `/embedded/process/${this.state.resultSet.id}`;
    }
    url += this.getUrlSuffix();
    return url;
  };
  private onFullscreen = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.toggleDropdown(false);
    this.props.onFullscreen(this.state.resultSet);
  };
  private onCloseErrorModal = () => {
    this.setState({
      showCloseResultFailedModal: false
    });
  };
  private handleSubmit = (resultSet: IResultSet) => {
    this.setState({
      resultSet: {
        ...resultSet
      },
      showEditModal: false
    });
  };
  private toggleEditModal = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState(prevState => ({
      showEditModal: !prevState.showEditModal
    }));
    this.hideDropdown();
  };
  public toggleHistory = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({
      showHistory: !this.state.showHistory
    });
    this.hideDropdown();
  };
  private getExcelExportUrl = () => {
    const {
      checklist,
      resultSet
    } = this.state;
    const checklist_id = checklist.id;
    const result_set_id = resultSet.id;
    return `${process.env.REACT_APP_API_BASE_URL}/checklists/` + `${checklist_id}/result_sets/${result_set_id}/excel_export`;
  };
  private handleCloseCatalogDialog = async (reload: boolean) => {
    if (reload) {
      await this.closeResultSet();
    } else {
      this.setState({
        showCatalogDialog: false
      });
    }
  };
  private renderSelectedArea = () => {
    const {
      selectedAreaId,
      selectedAreaPosition
    } = this.state;
    const {
      t
    } = this.props;
    const area = this.state.checklist.areas.find(a => {
      // undefined should never happen, but -1 will => that is a special case for signatures
      if (selectedAreaPosition === undefined || selectedAreaPosition === -1) {
        return a.id === selectedAreaId;
      } else {
        return a.id === selectedAreaId && a.area_position === selectedAreaPosition;
      }
    });
    if (area && !this.currentUserCanAccessArea(area)) {
      this.onCloseArea();
      return null;
    }
    if (!area && selectedAreaId !== -1) {
      return 'Area not found. This should never happen!';
    }
    if (Number.isNaN(selectedAreaPosition)) {
      return <Alert severity="error" sx={{
        mx: 2,
        my: 3
      }}>
          <Trans>resultSet.errors.noAreaPosition</Trans>
        </Alert>;
    }
    const visibleAreas = this.state.checklist.areas.filter(a => !a.hide);
    const index = visibleAreas.findIndex(area => area.id === selectedAreaId);
    let nextArea = null;
    let prevArea = null;
    if (area && this.state.selectedAreaPosition < area.showCount) {
      nextArea = {
        id: area.id,
        position: this.state.selectedAreaPosition + 1
      };
    } else {
      // Find the next area that is not a duplicate of the current area
      const nextUniqueAreaIndex = visibleAreas.findIndex((a, i) => i > index && area && a.id !== area.id);
      if (nextUniqueAreaIndex !== -1) {
        nextArea = {
          id: visibleAreas[nextUniqueAreaIndex].id,
          position: 1
        };
      } else if (this.state.checklist.signature_required !== 'no') {
        nextArea = {
          id: -1,
          position: 1
        };
      }
    }
    if (selectedAreaId === -1) {
      // Find the last area that is not a duplicate of the special area
      const lastUniqueAreaIndex = visibleAreas.reverse().findIndex(a => a.id !== -1);
      if (lastUniqueAreaIndex !== -1) {
        const lastUniqueArea = visibleAreas[lastUniqueAreaIndex];
        const lastUniqueAreaShowCount = lastUniqueArea.showCount || 1;
        prevArea = {
          id: lastUniqueArea.id,
          position: lastUniqueAreaShowCount
        };
      }
    } else if (area && this.state.selectedAreaPosition > 1) {
      prevArea = {
        id: area.id,
        position: this.state.selectedAreaPosition - 1
      };
    } else {
      // Find the previous area that is not a duplicate of the current area
      const prevUniqueAreaIndex = visibleAreas.slice(0, index).reverse().findIndex(a => area && a.id !== area.id);
      if (prevUniqueAreaIndex !== -1) {
        const prevUniqueArea = visibleAreas[index - prevUniqueAreaIndex - 1];
        const prevUniqueAreaShowCount = prevUniqueArea.showCount || 1;
        prevArea = {
          id: prevUniqueArea.id,
          position: prevUniqueAreaShowCount
        };
      } else if (this.state.checklist.signature_required !== 'no') {
        prevArea = {
          id: -1,
          position: 1
        };
      }
    }
    const folderId = this.props.folderId ?? this.state.resultSet.folder_id;
    const urlPrefix = `/checklists/tab/${this.props.tabIndex}/folders/${folderId}`;
    const urlSuffix = this.getUrlSuffix();
    const moveToNextArea = nextArea ? () => {
      const url = `${urlPrefix}/${this.state.resultSet.checklist_id}/audit/${this.state.resultSet.id}/areas/${nextArea.id}/position/${nextArea.position}${urlSuffix}`;
      this.props.history.push(url);
      this.setState({
        selectedAreaId: nextArea.id,
        selectedAreaPosition: nextArea.position
      }, () => {
        document.getElementById('result-column').scrollTop = 0;
      });
    } : null;
    const moveToPrevArea = prevArea ? () => {
      const url = `${urlPrefix}/${this.state.resultSet.checklist_id}/audit/${this.state.resultSet.id}/areas/${prevArea.id}/position/${prevArea.position}${urlSuffix}`;
      this.props.history.push(url);
      this.setState({
        selectedAreaId: prevArea.id,
        selectedAreaPosition: prevArea.position
      }, () => {
        document.getElementById('result-column').scrollTop = 0;
      });
    } : () => {
      // Navigate to the checklist audit view
      const url = `${urlPrefix}/${this.state.resultSet.checklist_id}/audit/${this.state.resultSet.id}`;
      this.props.history.push(url);
    };

    // -1 is a special case for the signature feature
    // since the signature area isn't an actual area in the checklist template
    const renderArea = selectedAreaId !== -1 ? <ResultArea jumpToQuestionId={this.state.jumpToQuestionId} isResponsible={this.currentUserIsResponsible()} accessToken={this.getAccessToken()} isExternalAccess={this.props.isExternalAccess} user={this.props.user} boards={this.state.boards} freeSigneeSelection users={this.state.users} allUsers={this.state.allUsers} contacts={this.state.contacts} key={`${area.id}-${this.state.selectedAreaPosition}`} area={area} onUpdate={this.handleUpdate} onAddComment={this.onAddComment} onDeleteComment={this.onDeleteComment} filledQuestionCount={this.getFilledQuestionCount(area.id, this.state.selectedAreaPosition)} disabled={this.state.disabled} resultSetId={this.state.resultSet.id} onAttachmentDeleted={this.handleAttachmentDeleted} onAttachmentUpdated={this.handleAttachmentUpdated} getAuditPoints={this.getAuditPoints} showAuditPoints={this.state.checklist.is_audit} allAreas={this.state.checklist.areas} resultSet={this.state.resultSet} resultAreaPosition={this.state.selectedAreaPosition} markNotFilledRequiredFields={this.state.validationErrors.length > 0} onClose={this.onCloseArea} onPrevArea={moveToPrevArea} onNextArea={moveToNextArea} onCloseChecklist={this.handleCloseInspection} checklistId={this.state.checklist.id} title={this.getAreaName(area, this.state.selectedAreaPosition)} isLoading={this.state.isRequestRunning} onRenameArea={this.handleRenameArea} onOpenPermissionsModal={this.openPermissionsModal} /> : <ResultArea isResponsible={this.currentUserIsResponsible()} accessToken={this.getAccessToken()} isExternalAccess={this.props.isExternalAccess} user={this.props.user} boards={this.state.boards} freeSigneeSelection={this.state.checklist.signee_free_text} users={this.state.users} allUsers={this.state.allUsers} contacts={this.state.contacts} area={this.buildSignatureArea()} disabled={this.state.disabled} getAuditPoints={this.getAuditPoints} filledQuestionCount={this.getFilledQuestionCount(-1, 1)}
    /*this.state.resultSet.signatures.filter(
      (s) => (s.signature && s.signature.url) || typeof s.signature === 'string'
    ).length
    }*/ onUpdate={this.handleSignatureUpdated} resultSet={this.state.resultSet} resultSetId={this.state.resultSet.id} onAttachmentDeleted={this.handleAttachmentDeleted} onAttachmentUpdated={this.handleAttachmentUpdated} onAddComment={this.onAddComment} onDeleteComment={this.onDeleteComment} showAuditPoints={false} hideCommentAndAttachments resultAreaPosition={1} markNotFilledRequiredFields={this.state.validationErrors.length > 0} onClose={this.onCloseArea} onPrevArea={moveToPrevArea} onNextArea={null} onCloseChecklist={this.handleCloseInspection} checklistId={this.state.checklist.id} onRenameArea={this.handleRenameArea} isLoading={this.state.isRequestRunning} onOpenPermissionsModal={this.openPermissionsModal} />;
    const shareModal = this.state.showShareModal ? <CreateResultSetFollowerSettings onCancel={this.closeShareModal} externalCanEdit={this.state.checklist.external_can_edit} users={this.state.users} onSubmit={this.handleShareSubmit} isLoading={this.state.isClosing} resultSet={this.state.resultSet} /> : null;
    return <EventBusContext.Provider value={{
      events: this.state.events,
      onEventProcessed: this.handleEventProcessed
    }}>
        <div className="audit-form">
          {shareModal}
          <div className={clsx('has-margin-bottom-16 back-button-container', isMobileOnly && 'mobile', this.props.context === 'embedded' && 'is-hidden')}>
            <a href="#" onClick={this.onCloseArea} id="back-button">
              <Icon className="fas fa-arrow-left" />
              {t('common.back')}
            </a>
          </div>
          {renderArea}
          {this.renderModals()}
        </div>
      </EventBusContext.Provider>;
  };
  private renderModals = () => {
    const {
      t
    } = this.props;

    // const resultHistories = this.state.resultSet.results.map((x) => x.versions)
    //
    // let concatedResultHistories = []
    // resultHistories.forEach((element) => {
    //   concatedResultHistories = concatedResultHistories.concat(element)
    // })
    const questions = selectMany<IQuestion>(this.state.checklist.areas, x => x.questions);
    return <>
        <div id="history-quickview" className={`quickview ${this.state.showHistory ? 'is-active' : ''}`}>
          <header className="quickview-header">
            <div className="second-caption">
              {t('History')}
            </div>
            <span className="delete" data-dismiss="quickview" onClick={this.toggleHistory} />
          </header>
          <div className="quickview-body">
            <div className="quickview-block">
              {this.state.showHistory && <ResultSetHistory resultSetId={this.state.resultSet.id} questions={questions} />}
            </div>
          </div>
        </div>
        {this.state.showEditModal && <EditResultSetModal projects={this.state.projects} resultSet={this.state.resultSet} isActive={this.state.showEditModal} onClose={this.toggleEditModal} onSubmit={this.handleSubmit} users={this.state.users} contacts={this.state.contacts} resources={this.state.resources} companies={this.state.companies} />}
        {this.state.showAreaPermissionsModal !== null && <AreaPermissionsModal checklist={this.state.checklist} area={this.state.showAreaPermissionsModal} permissions={this.getAreaPermissions(this.state.showAreaPermissionsModal)} users={this.state.allUsers} teams={this.state.teams} onCancel={this.closePermissionsModal} onSubmit={this.changeAreaPermissions} />}
        {this.state.showCatalogDialog && <CreateCatalogDialog template={this.state.checklist} checklist={this.state.resultSet} onClose={this.handleCloseCatalogDialog} />}
      </>;
  };
  private canClose = () => {
    const {
      user,
      isExternalAccess
    } = this.props;
    const {
      only_closable_by_tester
    } = this.state.checklist;
    const {
      tester_ids
    } = this.state.resultSet;
    if (isExternalAccess) return false;
    if (user.current_role !== 'employee') return true;
    if (!only_closable_by_tester) return true;
    if (tester_ids.includes(user.id)) return true;
    return false;
  };

  // opens an area (if required) and jumps to a question
  // if the question argument is null it jumps to the signatures
  private onJumpToQuestion = (question?: IQuestion, areaPosition?: number) => {
    this.onCloseErrorModal();
    const areaId = question ? question.area_id : -1;
    this.onClickAreaCard(areaId, areaPosition ?? 1);
    const cssClassIdSuffix = question ? question.id : 'signature';

    // the setTimeout is required because without it the scrollIntoView() doesn't works always
    this.setState({
      jumpToAreaId: question ? question.area_id : 'signature'
    }, () => setTimeout(() => {
      const items = document.querySelectorAll(`.question-${cssClassIdSuffix.toString()}`);
      const resultColumn = document.querySelector('#result-column');
      const resultHead = document.querySelector('.result-head');
      if (items.length) {
        // scroll to the target question
        items[0].scrollIntoView(true);
        if (resultColumn && resultHead) {
          // height of the sticky header + padding
          resultColumn.scrollTop = resultColumn.scrollTop - resultHead.clientHeight - 5;
        }
        // offset height of the header
        window.scrollBy(0, -70);
      }
    }, 100));
  };
  private getAreaPermissions = (area: IArea): IAreaPermissions => {
    const areaPermissions: IAreaPermissions[] = typeof this.state.resultSet.area_permissions === 'string' ? JSON.parse(this.state.resultSet.area_permissions) : this.state.resultSet.area_permissions;
    const resultSetAreaPermissions = areaPermissions?.find(x => x.id === area.id);
    if (resultSetAreaPermissions) {
      return resultSetAreaPermissions;
    }
    return {
      id: parseInt(area.id.toString()),
      user_ids: area.user_ids,
      team_ids: area.team_ids
    };
  };

  // the area permissions are stored as a jsonb field in the result set entity
  public changeAreaPermissions = (permissions: IAreaPermissions) => {
    const {
      resultSet
    } = this.state;
    // this is converted to string to post this to backend
    // convert this to array if this is a json string
    const areaPermissions: IAreaPermissions[] = typeof this.state.resultSet.area_permissions === 'string' ? JSON.parse(this.state.resultSet.area_permissions) : this.state.resultSet.area_permissions;
    const otherPermissions = areaPermissions.filter(x => x.id !== permissions.id);
    resultSet.area_permissions = [...otherPermissions, permissions];
    return api.resultSets.update(resultSet.checklist_id, resultSet.id, resultSet).then(rs => {
      this.setState({
        showAreaPermissionsModal: null,
        resultSet: {
          ...rs
        }
      });
    }).catch(error => {
      this.setState({
        error,
        showAreaPermissionsModal: null
      });
    });
  };
  private currentUserIsResponsible = () => {
    const {
      resultSet
    } = this.state;
    const currentUser = this.props.user;
    return resultSet.tester_ids.includes(currentUser?.id) || resultSet.team_ids.some(x => currentUser?.teams.map(team => team.id).includes(x)) || currentUser?.current_role === 'power_user' || currentUser?.current_role === 'admin';
  };
  private currentUserCanAccessArea = (area: IArea): boolean => {
    const currentUser = this.props.user;

    // no limitations for managers
    if (currentUser?.current_role === 'power_user' || currentUser?.current_role === 'admin') {
      return true;
    }

    // if the current user is assigned as tester or team
    // then he can access the areas that have no permissions
    // also external users can fill areas that have no permissions assigned
    const isResponsible = this.currentUserIsResponsible() || !!this.getAccessToken();
    const areaPermissions = this.getAreaPermissions(area);

    // user can always access if he is tester or in a team of the result set
    //  and there are no area no permission assigned
    if (!areaPermissions.user_ids.length && !areaPermissions.team_ids.length) {
      return isResponsible;
    }
    if (areaPermissions.user_ids.some(x => x === currentUser?.id)) {
      return true;
    }
    return areaPermissions.team_ids.some(x => currentUser?.teams.map(team => team.id).includes(x));
  };

  // ADF-1168: If the user is in another corporation and clicks a link in an email
  // An info message and a button to change the corporation will be displayed
  private handleChangeCorporation = () => {
    this.setState({
      isLoading: true
    });
    const errorData = (this.state.error?.response.data as any);
    const corporation_id = errorData?.corporation_id;
    if (corporation_id) {
      api.users.update(this.props.user.id, {
        ...this.props.user,
        corporation_id
      }).then(response => {
        // if corporation change was successful update stored user
        // and do a full page reload
        this.props.updateUser(response.data);
        window.location.reload();
      }).catch(error => this.setState({
        error
      })).finally(() => this.setState({
        isLoading: false
      }));
    }
  };
  private getAreaName = (area: IArea, position: number): string => {
    const {
      resultSet
    } = this.state;
    return ResultSetUtil.getAreaName(area, position, resultSet.area_names);
  };
  public render() {
    const {
      t
    } = this.props;
    const {
      error
    } = this.state;
    if (this.state.isLoading) {
      return isMobile ? <LoadSpinner className="centered" /> : <div className="audit-form">
            <Panel className="audit-panel is-centered">
              <div className="is-fullwidth has-audit-form-padding">
                <LoadSpinner className="has-wave-with-margin-auto" />
              </div>
            </Panel>
          </div>;
    }
    if (error) {
      return !(error?.response?.data as any)?.translation_key?.endsWith('invalidCorporation') ? <>
          <ErrorView error={this.state.error} customErrorMessages={[{
          status: 404,
          message: t('resultSet.errors.notFound')
        }]} />
          {!!this.getAccessToken() && !!this.state.resultSet && this.props.user && <Button className="has-margin-left-25" onClick={this.closeWithoutReload}>
              {t('common.back')}
            </Button>}
        </> : <div className="audit-form">
          <Panel className="audit-panel is-centered">
            <div className="is-fullwidth has-audit-form-padding">
              <Notification isColor="warning" isMarginless>
                {t('checklist.errors.invalidCorporation', (error?.response?.data as any)?.placeholders ?? [])}
              </Notification>
              <Button className="has-pointer has-margin-top-5" isColor="link" isPulled="right" onClick={this.handleChangeCorporation}>
                {t('checklist.switchCorporation')}
              </Button>
            </div>
          </Panel>
        </div>;
    }
    const {
      resultSet
    } = this.state;
    if (this.state.selectedAreaId) {
      return this.renderSelectedArea();
    }
    const filteredAreas = this.state.checklist.areas.filter(x => x.questions.length && !x.hide);
    const areas = filteredAreas.map(area => <Column key={`${area.id}-${area.area_position}`} isFullWidth className="area-card-wrapper">
        <AreaCard area={area} filledQuestionCount={this.getFilledQuestionCount(area.id, area.area_position ?? 1)} areaPosition={area.area_position ?? 1} onClick={this.onClickAreaCard} onCloneArea={this.onCloneArea} onDeleteArea={this.onDeleteArea} onOpenPermissionsModal={this.openPermissionsModal} canOpen={this.currentUserCanAccessArea(area)} onRenameArea={this.handleRenameArea} title={this.getAreaName(area, area.area_position ?? 1)} />
      </Column>);
    const questionCount = this.state.checklist.signature_required === 'double' ? 2 : 1;
    const signatureSection = this.state.checklist.signature_required !== 'no' ? <Column className="is-full">
          <AreaCard isSignatureArea filledQuestionCount={this.getFilledQuestionCount(-1, 1)}
      /*this.state.resultSet.signatures.filter((s) => (s.signature && s.signature.url)
        || typeof s.signature === 'string').length
      }*/ key="area-signatures" canOpen areaPosition={1} onClick={this.onClickAreaCard} onCloneArea={this.onCloneArea} onDeleteArea={this.onDeleteArea} onOpenPermissionsModal={null} totalQuestionCount={questionCount} onRenameArea={this.handleRenameArea} title={this.state.checklist.signature_required === 'double' ? t('checklist.signatureOptions.double') : t('checklist.signatureOptions.single')} />
        </Column> : null;
    const closingButton = this.state.resultSet.status === 'closed' ? null : <Button id="close-result-button" className="button btnOpen" onClick={this.handleCloseInspection} isLoading={this.state.isClosing} isPulled="right" isColor="link" data-tooltip={t('resultSet.canNotCloseOffline')} disabled={!this.canClose() || this.state.disabled}>
        {t('resultSet.close')}
      </Button>;
    const startNextButton = this.state.checklist.continously_create ? <Button id="start-next-result-button" className="button btnOpen" onClick={this.handleStartNextInspection} isLoading={this.state.isClosing} isPulled="right" isColor="link">
        {t('resultSet.startNext')}
      </Button> : null;
    const shareModal = this.state.showShareModal ? <CreateResultSetFollowerSettings externalCanEdit={this.state.checklist.external_can_edit} onCancel={this.closeShareModal} users={this.state.users} onSubmit={this.handleShareSubmit} isLoading={this.state.isClosing} resultSet={this.state.resultSet} /> : null;

    // ADF-335: show modal with a list of the not filled required questions
    const errorModal = this.state.showCloseResultFailedModal ? <NotFilledRequiredFieldsModal areas={this.state.visibleAreas} users={this.state.allUsers} teams={this.state.teams} errors={this.state.validationErrors} isSignedValid={this.state.isSignedValid} onClose={this.onCloseErrorModal} onJumpToQuestion={this.onJumpToQuestion} currentUserCanAccessArea={this.currentUserCanAccessArea} /> : null;
    const description = this.state.checklist && this.state.checklist.show_description && this.state.checklist.description && this.state.checklist.description.length > 0 ? <Content className="description"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: this.state.checklist.description
    }} /> : null;
    const templateAttachments = this.state.checklist?.attach_files && this.state.checklist?.cloud_attachments?.length ? <ResultCloudAttachments attachments={this.state.checklist.cloud_attachments} onAdd={null} onDelete={null} cloudAttachmentCount={this.state.checklist.cloud_attachments.length} onExpansion={this.setTemplateAttachmentsExpanded} isExpanded={this.state.templateAttachmentsExpanded} title={t('resultSet.attachedFiles.template')} /> : null;
    const resourceAttachments = this.state.checklist?.attach_files && this.state.resultSet?.resource_cloud_attachments?.length ? <ResultCloudAttachments attachments={this.state.resultSet.resource_cloud_attachments} onAdd={null} onDelete={null} cloudAttachmentCount={this.state.resultSet.resource_cloud_attachments.length} onExpansion={this.setResourceAttachmentsExpanded} isExpanded={this.state.resourceAttachmentsExpanded} title={t('resultSet.attachedFiles.resource')} /> : null;
    const contactAttachments = this.state.checklist?.attach_files && this.state.resultSet?.contact_cloud_attachments?.length ? <ResultCloudAttachments attachments={this.state.resultSet.contact_cloud_attachments} onAdd={null} onDelete={null} cloudAttachmentCount={this.state.resultSet.contact_cloud_attachments.length} onExpansion={this.setContactAttachmentsExpanded} isExpanded={this.state.contactAttachmentsExpanded} title={t('resultSet.attachedFiles.contact')} /> : null;
    const dueDateText = DateUtil.isToday(resultSet.due_date) ? t('resultSet.dueToday') : <div>
          {t('resultSet.due')}
          {' '}
          {t(' ')}
          {DateUtil.format(resultSet.due_date, DateUtil.LongFormat)}
        </div>;
    const projectTag = resultSet.project ? <Link to={`/checklists?project_id=${resultSet.project_id}&project_name=` + `${encodeURIComponent(resultSet.project)}`} className="tag is-project-tag has-margin-right-0 has-margin-top-0">
          <span className="is-ellipsed is-ellipsed-tag has-font-size-1em">
            {resultSet.project}
          </span>
        </Link> : null;
    const isOverdue = DateUtil.isAfter(new Date(), resultSet.due_date);
    return <EventBusContext.Provider value={{
      events: this.state.events,
      onEventProcessed: this.handleEventProcessed
    }}>
        <div>
          {shareModal}
          {errorModal}
          <div className="audit-form">
            <div className="safe-area-block" />
            {this.props.user && <div className={clsx('has-margin-bottom-16 is-hidden-desktop is-hidden-table back-button-container', isMobileOnly && 'mobile', this.props.context === 'embedded' && 'is-hidden')}>
                <Link to={this.getBackLink()}>
                  <Icon className="fas fa-arrow-left" />
                  {t('common.back')}
                </Link>
              </div>}
            <Panel className="audit-panel">
              <div className="is-full-width has-audit-form-padding">
                <div className="result-set-head">
                  <div className="caption is-uppercase">
                    {t('resultSet.partialTasks')}
                  </div>
                  <div className="second-caption has-margin-bottom-10 has-text-weight-bold">
                    <div className="is-ellipsed is-inline-block">
                      {resultSet.title}
                    </div>
                    {!this.props.isExternalAccess && <img className="is-pulled-right close-result is-hidden-mobile" src={closeIcon} alt="close" onClick={this.closeWithoutReload} />}

                    {!this.props.isExternalAccess && this.currentUserIsResponsible() && <ResultSettingsMenu isActive={this.state.isDropdownOpen} resultSet={this.state.resultSet} resources={this.state.resources} contacts={this.state.contacts} users={this.state.users} teams={this.state.teams} boards={this.state.boards} onToggleHistory={this.toggleHistory} onExcelExport={this.onExcelExport} onToggleEditModal={this.toggleEditModal} onToggleDropDown={this.toggleDropdown} onCloseDropdown={this.closeDropdown} onEditTemplate={this.onEditTemplate} onShare={this.onShare} onFullscreen={this.onFullscreen} excelExportUrl={this.getExcelExportUrl()} />}
                    <div className="tags-container is-flex-desktop">
                      <Tag className="is-status-tag has-margin-right-0 has-margin-top-0">
                        <StatusText status={resultSet.status} />
                      </Tag>
                      <Tag className="created-at-tag has-margin-right-0 has-margin-top-0">
                        {DateUtil.format(resultSet.created_at, DateUtil.LongFormat)}
                      </Tag>
                      <Tag isColor={isOverdue ? 'danger' : undefined} className="is-due-tag has-margin-right-0 has-margin-top-0">
                        {dueDateText}
                      </Tag>

                      {projectTag}

                      {this.props.isOffline && <Tag className="is-due-tag has-margin-right-0 has-margin-top-0">
                            {t('offlineMode.offline')}
                          </Tag>}

                      {resultSet.ressource && <Tag isColor="link" className="is-resource-tag has-margin-right-0 has-margin-top-0">
                          {resultSet.ressource.title}
                        </Tag>}
                      {resultSet.contact && <Tag isColor="link" className="is-contact-tag has-margin-right-0 has-margin-top-0">
                          {resultSet.contact}
                        </Tag>}
                      {resultSet.company && <Link to={`/companies/${resultSet.company.id}`}>
                          <Tag isColor="link" className="is-company-tag has-margin-right-0 has-margin-top-0">
                            {resultSet.company.name}
                          </Tag>
                        </Link>}
                    </div>
                  </div>
                  {description}
                  {templateAttachments}
                  {resourceAttachments}
                  {contactAttachments}
                  {this.state.checklist.attachments && <ResultAttachments questionId={0} isExpanded onExpansion={() => {}} onUpdate={() => {}} readonly disableEdit accordion={false} attachments={this.state.checklist.attachments} className="has-margin-bottom-8" />}
                  <Columns isFullWidth className="area-card-columns">
                    <Column isSize="full" className="has-padding-top-1rem has-padding-bottom-5">
                      <Columns isMultiline isMobile>
                        {this.state.isDueDateExceeded && <Notification isColor="warning">
                            {t('resultSet.notifications.dueDateExceededMessage')}
                          </Notification>}
                        {areas.length !== 1 && areas}
                        {areas.length === 1 && <div className="audit-form">
                            {shareModal}
                            <div className={clsx('has-margin-bottom-16 back-button-container', isMobileOnly && 'mobile', this.props.context === 'embedded' && 'is-hidden')}>
                              <ResultArea jumpToQuestionId={this.state.jumpToQuestionId} isResponsible={this.currentUserIsResponsible()} accessToken={this.getAccessToken()} isExternalAccess={this.props.isExternalAccess} user={this.props.user} boards={this.state.boards} freeSigneeSelection users={this.state.users} allUsers={this.state.allUsers} contacts={this.state.contacts} key={`${this.state.checklist.areas[0].id}-1}`} area={this.state.checklist.areas[0]} onUpdate={this.handleUpdate} onAddComment={this.onAddComment} onDeleteComment={this.onDeleteComment} filledQuestionCount={this.getFilledQuestionCount(this.state.checklist.areas[0].id, 1)} disabled={this.state.disabled} resultSetId={this.state.resultSet.id} onAttachmentDeleted={this.handleAttachmentDeleted} onAttachmentUpdated={this.handleAttachmentUpdated} getAuditPoints={this.getAuditPoints} showAuditPoints={this.state.checklist.is_audit} allAreas={this.state.checklist.areas} resultSet={this.state.resultSet} resultAreaPosition={1} markNotFilledRequiredFields={this.state.validationErrors.length > 0} onClose={this.onCloseArea} onPrevArea={() => {}} onNextArea={() => {}} onCloseChecklist={this.handleCloseInspection} checklistId={this.state.checklist.id} title={this.getAreaName(this.state.checklist.areas[0], 1)} isLoading={this.state.isRequestRunning} onRenameArea={this.handleRenameArea} onOpenPermissionsModal={this.openPermissionsModal} hideButtons />
                            </div>
                          </div>}
                        {signatureSection}
                      </Columns>
                      <Field className="sticky-button">
                        <Control>{closingButton}</Control>
                        <Control>{startNextButton}</Control>
                      </Field>
                    </Column>
                  </Columns>
                </div>
              </div>
            </Panel>
            {this.renderModals()}
          </div>
        </div>
      </EventBusContext.Provider>;
  }
  private createTaskFromAction = (task_data: TaskData) => {
    confirm(null, this.props.t('resultSet.confirmCreateTask'), () => {
      api.tasks.create(task_data.board_id, {
        project_ids: [],
        ressource_ids: [],
        id: undefined,
        position: 0,
        priority: undefined,
        team_ids: [],
        board_id: task_data.board_id,
        lane_id: undefined,
        title: task_data.title,
        result_set_ids: [this.state.resultSet.id],
        user_ids: task_data.user_ids
      }).then(() => {
        toast(this.props.t('resultSet.notifications.taskCreated'));
      });
    });
  };
}
const mapDispatchToProps = (dispatch: (x: any) => void): IDispatchProps => ({
  updateUser: (user: IUser) => dispatch(updateUser(user))
});
const mapStateToProps = ({
  core
}: IAlphaprocessState): IStateProps => ({
  user: core.user,
  isOffline: core.isOffline,
  isLoggedIn: !!core.jwt
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResultView)));