import { useQuery } from '@apollo/client';
import { Box, Dialog, DialogContent, DialogTitle, Grid, InputAdornment, Slide, Stack, TextField, DialogActions, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, FunctionComponent, ReactElement, Ref, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import searchFill from '@iconify/icons-eva/search-fill';
import FetchTemplateStoreEntries, { FetchTemplateStoreEntriesResponse, FetchTemplateStoreEntriesVariables, TemplateStoreEntriesEntity } from '../../graphql/queries/FetchTemplateStoreEntries';
import LoadSpinner from '../loadSpinner/LoadSpinner';
import TemplateStoreEntryDetailsCard from './TemplateStoreEntryDetailsCard';
interface Props {
  open: boolean;
  onClose: () => void;
  onCardClick: (entry: TemplateStoreEntriesEntity) => void;
}
export const Transition = forwardRef((props: TransitionProps & {
  children: ReactElement<any, any>;
}, ref: Ref<unknown>) => <Slide direction="up" ref={ref} {...props} />);
const TemplateStoreDialog: FunctionComponent<Props> = ({
  open,
  onClose,
  onCardClick
}) => {
  const [term, setTerm] = useState('');
  const {
    t
  } = useTranslation();
  const {
    loading,
    data
  } = useQuery<FetchTemplateStoreEntriesResponse, FetchTemplateStoreEntriesVariables>(FetchTemplateStoreEntries, {
    variables: {
      limit: null,
      favored: null
    },
    fetchPolicy: 'cache-and-network'
  });
  const filterFunction = useCallback((entry: TemplateStoreEntriesEntity) => {
    if (term.length === 0) return true;
    const searchTerm = term.toLowerCase();
    if (entry.name.toLowerCase().includes(searchTerm)) return true;
    if (entry.templateStoreCategory.name.toLowerCase().includes(searchTerm)) return true;
    return entry.tags.some(x => x.title.toLowerCase().includes(searchTerm));
  }, [term]);
  if (loading) return <LoadSpinner className="centered" />;
  return <Dialog open={open} onClose={onClose} TransitionComponent={Transition} keepMounted maxWidth="xl" fullWidth>
      <DialogTitle sx={{
      px: '16px !important',
      py: '8px !important'
    }}>
        <Trans>templateStore.dialog.title</Trans>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column">
          <TextField placeholder={t('common.search')} sx={{
          mb: 2
        }} onChange={event => setTerm(event.target.value)} InputProps={{
          startAdornment: <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{
              ml: 1,
              width: 20,
              height: 20,
              color: 'text.disabled'
            }} />
                </InputAdornment>
        }} />
          <Grid container spacing={2}>
            {data.templateStoreEntries.filter(filterFunction).map(entry => <TemplateStoreEntryDetailsCard key={entry.id} entry={entry} onClick={onCardClick} />)}
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          <Trans>common.close</Trans>
        </Button>
      </DialogActions>
    </Dialog>;
};
export default TemplateStoreDialog;