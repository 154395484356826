import { Button, Control, Delete, Field, Icon, Label } from 'bloomer';
import { ErrorMessage, FastField, Form, useFormikContext } from 'formik';
import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import ITeam, { ITeamMembership, TeamRole } from '../../api/types/team';
import IUser from '../../api/types/user';
import { IAlphaprocessState } from '../../store';
import UserSelect from '../widgets/selects/UserSelect';
const TeamForm: FunctionComponent = () => {
  const {
    t
  } = useTranslation();
  const users = useSelector((state: IAlphaprocessState) => state.data.users);
  const {
    values,
    setFieldValue,
    isSubmitting,
    submitForm,
    dirty,
    setFieldTouched
  } = useFormikContext<ITeam>();
  const [selectedNewMember, setSelectedNewMember] = useState<number | null>(null);
  const roleOptions = [{
    label: t('team.roles.member'),
    value: 'member'
  }, {
    label: t('team.roles.deputy'),
    value: 'deputy'
  }, {
    label: t('team.roles.leader'),
    value: 'leader'
  }];
  const handleNewMemberSelect = useCallback((value: number | number[]) => {
    setSelectedNewMember((value as number));
  }, []);
  const handleRemoveMember = useCallback((id: number) => {
    let memberships = [...values.team_memberships];
    const membership = {
      ...memberships.find(m => m.user.id === id)
    };
    memberships = memberships.filter(m => m.user.id !== id);
    if (membership.id) {
      membership._destroy = true;
      memberships.push(membership);
    }
    setFieldValue('team_memberships', memberships);
    setFieldTouched('team_memberships', true);
  }, [setFieldTouched, setFieldValue, values.team_memberships]);
  const handleAddNewMember = useCallback(() => {
    if (selectedNewMember) {
      const user: IUser = {
        ...users.find(u => u.id === selectedNewMember)
      };
      if (values.team_memberships.some(x => x.user.id === user.id)) {
        setSelectedNewMember(null);
        return;
      }
      const membership: ITeamMembership = {
        role: 'member',
        user,
        user_id: user.id
      };
      const newMemberships = [...values.team_memberships, membership];
      setFieldValue('team_memberships', newMemberships);
      setSelectedNewMember(null);
    }
  }, [selectedNewMember, setFieldValue, users, values.team_memberships]);
  const handleSubmitClicked = useCallback(async () => {
    handleAddNewMember();
    await submitForm();
    setSelectedNewMember(null);
  }, [handleAddNewMember, submitForm]);
  const handleRoleChanged = useCallback((id: number, value: TeamRole) => {
    const tm = values.team_memberships.find(x => x.id === id);
    if (tm) {
      const updatedTm: ITeamMembership = {
        ...tm,
        role: value
      };
      const memberships = values.team_memberships.filter(x => x.id !== id);
      setFieldValue('team_memberships', [...memberships, updatedTm].sort((a, b) => a.id - b.id));
    }
  }, [setFieldValue, values.team_memberships]);
  return <Form noValidate>
      <Field isGrouped>
        <Control isExpanded>
          <Label>
            {t('Name')}
          </Label>
          <FastField name="name" className="input" placeholder={t('Name')} />
          <ErrorMessage component="span" name="name" className="has-text-danger" />
        </Control>
        <Control>
          <Label>
            {t('Code')}
          </Label>
          <FastField name="code" className="input" placeholder={t('Code')} />
          <ErrorMessage component="span" name="code" className="has-text-danger" />
        </Control>
      </Field>
      <Field>
        <Label>
          {t('Members')}
        </Label>
        <div className="list">
          {values.team_memberships.filter(m => !m._destroy).map(m => <div key={m.id || m.user_id || m.user?.id} className="list-item">
              <span className="member-list-user-name">
                {' '}
                {`${m.user.firstname} ${m.user.lastname}`}
              </span>
              <ReactSelect className="team-role-select" options={roleOptions} value={roleOptions.find(o => o.value === m.role)} onChange={(value: any) => handleRoleChanged(m.id, value.value)} noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
            menuPortal: base => ({
              ...base,
              zIndex: 9999
            })
          }} />
              <Delete className="has-margin-top-5" isPulled="right" onClick={() => handleRemoveMember(m.user.id)} />
            </div>)}
        </div>
        <ErrorMessage component="span" name="team_memberships" className="has-text-danger has-margin-top-8" />
      </Field>
      <Field hasAddons="right">
        <Control isExpanded>
          <UserSelect placeholder={t('team.placeholders.newMember')} selected={selectedNewMember} onChange={handleNewMemberSelect} name="new_member" id="new_member" setSelectedAsValue userOverrides={users.filter(x => !values.team_memberships.some(y => y.user.id === x.id))} />
        </Control>
        <Control>
          <Button id="add-member" onClick={handleAddNewMember}>
            <Icon className="fas fa-plus" />
          </Button>
        </Control>
      </Field>
      <Field>
        <Control>
          <div className="has-text-right">
            <Link to="/people?currentTab=1">
              <Button isColor="secondary" className="has-margin-right-5">
                {t('common.close')}
              </Button>
            </Link>
            <Button id="save-team" onClick={handleSubmitClicked} isLoading={isSubmitting} disabled={isSubmitting || !dirty} isLink>
              {t('common.save')}
            </Button>
          </div>
        </Control>
      </Field>
    </Form>;
};
export default TeamForm;