import querystring from 'query-string'
import { FilterSettings } from '../reducers/filterReducer'
import {
  Assignee, FolderBoard, FolderChecklist, FolderTemplate
} from '../types/FolderChecklist'
import DateUtil from '../util/DateUtil'
import { ApiBase, IPaginatedData } from './ApiBase'
import { ResultSetStatus } from './types/resultSet'

export interface IFolder {
  template_count: number
  closed_checklist_count: number
  open_checklist_count: number
  id: string
  name: string
  child_count: number
  children: IFolder[]
  parent: IFolder
  folder_type: 'checklists' | 'boards'
}

interface UpdateFolderDto {
  name?: string
  parent_id?: string
}

export interface FolderParams {
  ressource_id?: number | number[]
  team_ids?: number[]
  tester_ids?: number[]
  contact_id?: number[]
  project_id?: number[]
  title?: string
  start_date?: string | Date
  end_date?: string | Date
  created_start_date?: string | Date
  created_end_date?: string | Date
  parent_id?: string
  filter_mode: 'checklists' | 'checklists_home' | 'templates'
  page: number
  sort: string
  direction: 'asc' | 'desc'
  status?: 'open' | 'closed' | 'due' | 'invited'
  creator_ids?: number[]
  closer_ids?: number[]
}

const PER_PAGE = 15

function statusToNumber(status: ResultSetStatus) {
  switch (status) {
    case 'open':
      return 0
    case 'closed':
      return 1
    case 'reopened':
      return 2
    default:
      return 0
  }
}

export class FolderApi extends ApiBase {
  public async root(): Promise<IFolder> {
    if (this.isOffline()) {
      return {
        id: 'root',
        name: '/',
        child_count: 0,
        children: [],
        parent: null,
        closed_checklist_count: 0,
        open_checklist_count: 0,
        template_count: 0,
        folder_type: 'checklists'
      }
    }
    const response = await this.get<IFolder>('/folders/root')
    return response.data
  }

  public async all(params: FolderParams) {
    if (params.title === null) {
      delete params.title
    }
    const query = querystring.stringify(params, { arrayFormat: 'bracket' })
    const response = await this.get<IFolder[]>(`/folders?${query}`)
    return this.paginateResponse(response)
  }

  public async folderChecklists(
    params: FolderParams,
    corporationId: number
  ): Promise<IPaginatedData<FolderChecklist>> {
    let query = this.offlineDb.resultSets.orderBy(params.sort)
      .filter((x) => x.corporation_id === corporationId || !x.corporation_id)

    if (params.parent_id && params.parent_id !== 'root') {
      query = query.filter((x) => x.folder_id === params.parent_id || !x.folder_id)
    }

    if (params.status === 'closed') {
      query = query.filter((x) => x.status === 'closed')
    } else {
      query = query.filter((x) => x.status !== 'closed')
    }

    if (params.title && params.title.length) {
      query = query.filter((x) => x.title.toLowerCase().includes(params.title.toLowerCase()))
    }

    if (params.start_date && params.end_date) {
      const startDate = DateUtil.parse(params.start_date)
      const endDate = DateUtil.parse(params.end_date)
      query = query.filter((x) => DateUtil.isAfter(x.due_date, startDate)
        && DateUtil.isBefore(x.due_date, endDate))
    } else if (params.start_date) {
      const startDate = DateUtil.parse(params.start_date)
      query = query.filter((x) => DateUtil.isAfter(x.due_date, startDate))
    } else if (params.end_date) {
      const endDate = DateUtil.parse(params.end_date)
      query = query.filter((x) => DateUtil.isBefore(x.due_date, endDate))
    }

    if (params.tester_ids && params.tester_ids.length) {
      query = query.filter((x) => params.tester_ids.some((y) => x.tester_ids.includes(y)))
    }

    if (params.project_id && params.project_id.length) {
      query = query.filter((x) => params.project_id.includes(x.project_id))
    }

    if (params.ressource_id) {
      if (typeof params.ressource_id === 'number') {
        query = query.filter((x) => params.ressource_id === x.ressource_id)
      } else if (Array.isArray(params.ressource_id) && params.ressource_id.length) {
        const ids = params.ressource_id
        query = query.filter((x) => ids.includes(x.ressource_id))
      }
    }

    if (params.contact_id && params.contact_id.length) {
      query = query.filter((x) => params.contact_id.includes(x.contact_id))
    }

    if (params.direction === 'desc') {
      query = query.reverse()
    }

    const totalCount = await query.count()
    const totalPages = Math.ceil(totalCount / PER_PAGE)
    const resultSets = await query.offset((params.page - 1) * PER_PAGE)
      .limit(PER_PAGE).toArray()

    const projects = await this.offlineDb.projects.toArray()
    const resources = await this.offlineDb.resources.toArray()
    const offlineData = resultSets.map<FolderChecklist>((rs) => ({
      creator: {
        name: rs.creator?.full_name ?? '',
        url: rs.creator?.avatar?.url ?? '',
        type: 'user'
      },
      id: rs.id.toString(),
      entity_type: 'result_set',
      area_count: 1,
      assignees: rs.testers?.map<Assignee>((tester) => ({ name: tester.full_name, type: 'user', url: '' }))
        .concat(rs.teams.map<Assignee>((team) => ({ name: team.name, type: 'team', url: '' }))),
      checklist_id: rs.checklist_id,
      closed_checklist_count: 0,
      contact_id: rs.contact_id,
      folder_id: '',
      audit_points: 0,
      corporation_id: rs.corporation_id,
      due_date: rs.due_date as string,
      title: rs.title,
      employees_can_restart: true,
      open_checklist_count: 0,
      is_audit: false,
      is_over_due: DateUtil.isAfter(rs.due_date, new Date()),
      project_id: rs.project_id,
      progress: rs.progress,
      project: projects.find((x) => x.id === rs.project_id)?.name ?? '',
      question_count: 0,
      resource: resources.find((x) => x.id === rs.ressource_id)?.title ?? '',
      status: statusToNumber(rs.status),
      resource_id: rs.ressource_id,
      result_count: rs.results.length,
      template_count: 0,
      tasks_remaining: 0,
      updated_at: rs.updated_at as string,
      closed_at: rs.closed_at as string
    }))
    if (this.isOffline()) {
      return {
        totalPages,
        currentPage: params.page,
        totalCount,
        data: offlineData
      }
    }
    const response = await this.get<FolderChecklist[]>('/folder_checklists', params)

    const onlineIds = response.data.map((x) => x.id)
    response.data = response.data.concat(offlineData.filter((x) => !onlineIds.includes(x.id)))

    return this.paginateResponse(response)
  }

  public async find(id: string) {
    const response = await this.get<IFolder>(`/folders/${id}`)
    return response.data
  }

  public async create(param: { parent_id: string; name: string, folder_type?: string }) {
    const response = await this.post<IFolder>('/folders', param)
    return response.data
  }

  public async updateFolder(id: any, data: UpdateFolderDto) {
    const response = await this.put<IFolder>(`/folders/${id}`, data)
    return response.data
  }

  public async deleteFolder(id: string) {
    if (this.isOffline()) {
      await this.offlineDb.checklists.delete(parseInt(id))
    } else {
      await this.delete(`/folders/${id}`)
    }
  }

  async folderBoards(parentId: string, filters: FilterSettings) {
    const params = {
      parent_id: parentId,
      team_ids: filters.selectedTeams?.map((x) => x.value),
      project_ids: filters.selectedProjects?.map((x) => x.value),
      title: filters.term
    }
    const response = await this.get<FolderBoard[]>('/folder_boards', params)
    return response.data
  }

  async folderTemplates(
    params: FolderParams,
    corporationId: number
  ): Promise<IPaginatedData<FolderTemplate>> {
    if (this.isOffline()) {
      let query = this.offlineDb.checklists.orderBy(params.sort)
        .filter((x) => x.corporation_id === corporationId)

      if (params.parent_id && params.parent_id !== 'root') {
        query = query.filter((x) => x.folder_id === params.parent_id || !x.folder_id)
      }

      if (params.title && params.title.length) {
        query = query.filter((x) => x.title.includes(params.title))
      }

      if (params.start_date && params.end_date) {
        const startDate = DateUtil.parse(params.start_date)
        const endDate = DateUtil.parse(params.end_date)
        query = query.filter((x) => DateUtil.isAfter(x.created_at, startDate)
          && DateUtil.isBefore(x.created_at, endDate))
      } else if (params.start_date) {
        const startDate = DateUtil.parse(params.start_date)
        query = query.filter((x) => DateUtil.isAfter(x.created_at, startDate))
      } else if (params.end_date) {
        const endDate = DateUtil.parse(params.end_date)
        query = query.filter((x) => DateUtil.isBefore(x.created_at, endDate))
      }

      if (params.tester_ids && params.tester_ids.length) {
        query = query.filter((x) => params.tester_ids.includes(x.creator_id))
      }

      if (params.direction === 'desc') {
        query = query.reverse()
      }

      const totalPages = Math.ceil((await query.count()) / PER_PAGE)
      const totalCount = await query.count()
      const templates = await query.offset((params.page - 1) * PER_PAGE)
        .limit(PER_PAGE).toArray()

      return {
        totalPages,
        currentPage: params.page,
        totalCount,
        data: templates.map((x) => ({
          id: x.id.toString(),
          draft: x.draft,
          template_count: 0,
          title: x.title,
          open_checklist_count: 0,
          corporation_id: x.corporation_id,
          closed_checklist_count: 0,
          assignees: x.creator ? [{ name: x.creator.full_name, type: 'user', url: x.creator.avatar?.url }] : [],
          entity_type: 'template',
          created_at: x.created_at as string,
          is_audit: x.is_audit,
          parent_id: ''
        }))
      }
    }

    const response = await this.get<FolderTemplate[]>('/folder_templates', params)

    return this.paginateResponse(response)
  }
}
