import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, Control, Field, FieldBody, FieldLabel } from 'bloomer';
import clsx from 'clsx';
import { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactSelect, { MenuPlacement } from 'react-select';
import 'animate.css';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { FilterLocation, overrideFilters, resetFilters } from '../../../actions/filterActions';
import { ICompany } from '../../../api/CompanyApi';
import IContact from '../../../api/types/contact';
import IResource, { ITag } from '../../../api/types/ressource';
import ITeam from '../../../api/types/team';
import IUser, { IUserToReactSelectValues } from '../../../api/types/user';
import { reactSelectMaxMenuHeight } from '../../../constants';
import filtersClose from '../../../images/filters_close.png';
import { emptyFilterSettings, FilterSettings, getFilterSettings, IFilterState, initialFilterState, updateFilterState } from '../../../reducers/filterReducer';
import { IAlphaprocessState } from '../../../store';
import ISelectListValueModel from '../../../types/selectListModel';
import ITranslationProps from '../../../types/translationProps';
import ResourceSelect from '../selects/ResourceSelect';
import { CompanySelect, ContactSelect, TeamSelect, UserSelect } from '../selects';
import TagSelect from '../selects/TagSelect';
interface IProps extends ITranslationProps, IStateProps, IDispatchProps {
  isOpen: boolean;
  projects: Array<ISelectListValueModel<any>>;
  tags: ITag[];
  resources: IResource[];
  contacts: IContact[];
  companies: ICompany[];
  onApply: () => void;
  showTags: boolean;
  showTeams: boolean;
  showUsers: boolean;
  showProjects: boolean;
  showResources: boolean;
  showContacts: boolean;
  showDatepicker: boolean;
  showCompanies: boolean;
  showStartDate: boolean;
  isClosedTab: boolean;
  showCreator: boolean;
  showCloser: boolean;
  menuPlacement?: MenuPlacement | null;
  filterLocation: FilterLocation;
}
interface IStateProps {
  filters: IFilterState;
  users: IUser[];
  teams: ITeam[];
  renameResources: boolean;
  locale: string;
}
interface IDispatchProps {
  overrideFilters: (filters: IFilterState, location: FilterLocation) => void;
  resetFilters: (location: FilterLocation) => void;
}
interface IState {
  filters: IFilterState;
}
const FilterHeader = styled('div')(({
  theme
}) => ({
  boxSizing: 'border-box',
  height: '46px',
  borderBottom: '1px solid rgba(212, 216, 224, 1)',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  paddingLeft: '24px',
  paddingTop: '7px',
  paddingBottom: '7px',
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.modal,
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));
const FilterBody = styled('div')(({
  theme
}) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '80vh',
  [theme.breakpoints.down('sm')]: {
    bottom: 20,
    paddingBottom: 20
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: 115
  }
}));
class Filters extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      filters: props.filters
    };
  }
  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.filters !== this.props.filters) {
      this.setState({
        filters: this.props.filters
      });
    }
  }
  private onDatesChanged = (value: Date[] | null) => {
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation) || emptyFilterSettings;
    if (!value) {
      filters = {
        ...filters,
        startDate: null,
        endDate: null
      };
    } else {
      const [startDate, endDate] = value;
      filters = {
        ...filters,
        startDate,
        endDate
      };
    }
    this.updateFilterState(filters);
  };
  private onStartDatesChanged = (value: Date[] | null) => {
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation) || emptyFilterSettings;
    if (!value) {
      filters = {
        ...filters,
        createdStartDate: null,
        createdEndDate: null
      };
    } else {
      const [createdStartDate, createdEndDate] = value;
      filters = {
        ...filters,
        createdStartDate,
        createdEndDate
      };
    }
    this.updateFilterState(filters);
  };
  private handleChangeResources = (selected: number[]) => {
    const selectedResourceItems = this.props.resources.filter(x => selected.includes(x.id)).map(resource => ({
      label: resource.title,
      value: resource.id
    }));
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation) || emptyFilterSettings;
    filters = {
      ...filters,
      selectedRessources: selectedResourceItems
    };
    this.updateFilterState(filters);
  };
  private handleSelectTeams = (selected: number[]) => {
    const selectedTeams = this.props.teams.filter(x => selected.includes(x.id)).map(team => ({
      label: team.name,
      value: team.id
    }));
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation);
    filters = {
      ...filters,
      selectedTeams
    };
    this.updateFilterState(filters);
  };
  private handleSelectTags = (selected: number[]) => {
    const selectedTags = this.props.tags.filter(x => selected.includes(x.id)).map(tag => ({
      label: tag.title,
      value: tag.id
    }));
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation) || emptyFilterSettings;
    filters = {
      ...filters,
      selectedTags
    };
    this.updateFilterState(filters);
  };
  private handleSelectTesters = (selected: number[]) => {
    const selectedOptions = IUserToReactSelectValues(this.props.users.filter(x => selected.includes(x.id)));
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation);
    filters = {
      ...filters,
      selectedTesters: selectedOptions
    };
    this.updateFilterState(filters);
  };
  private handleSelectClosers = (selected: number[]) => {
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation);
    filters = {
      ...filters,
      selectedClosers: selected
    };
    this.updateFilterState(filters);
  };
  private handleSelectCreators = (selected: number[]) => {
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation);
    filters = {
      ...filters,
      selectedCreators: selected
    };
    this.updateFilterState(filters);
  };
  private updateFilterState = (filters: FilterSettings) => {
    this.setState({
      filters: updateFilterState(this.state.filters, filters, this.props.filterLocation)
    });
  };
  private handleChangeProjects = (selected: any[]) => {
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation);
    filters = {
      ...filters,
      selectedProjects: selected
    };
    this.updateFilterState(filters);
  };
  private applyFilters = () => {
    this.props.overrideFilters(this.state.filters, this.props.filterLocation);
    this.props.onApply();
    this.scrollToTop();
  };
  private onClose = () => {
    this.props.onApply();
    this.scrollToTop();
  };
  private handleSelectCompanies = (selected: string[]) => {
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation);
    filters = {
      ...filters,
      selectedCompanies: selected
    };
    this.updateFilterState(filters);
  };
  private handleChangeContacts = (selected: number[]) => {
    const selectedContactItems = this.props.contacts.filter(x => selected.includes(x.id)).map(contact => ({
      label: `${contact.firstname} ${contact.lastname}`,
      value: contact.id
    }));
    let filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation) || emptyFilterSettings;
    filters = {
      ...filters,
      selectedContacts: selectedContactItems
    };
    this.updateFilterState(filters);
  };
  private scrollToTop = () => {
    window.scrollTo(0, 1);
  };
  public render() {
    const {
      isOpen,
      projects,
      resources,
      contacts,
      t
    } = this.props;
    const filters: FilterSettings = getFilterSettings(this.state.filters, this.props.filterLocation) || emptyFilterSettings;
    const start = filters.startDate;
    const end = filters.endDate;
    const selectedProjectIds = filters.selectedProjects.map(x => typeof x.value === 'number' ? x.value : x[0]);
    const selectedProjects = projects.filter(x => selectedProjectIds.includes(x.value));
    const selectedResourceIds = filters.selectedRessources.map(x => typeof x.value === 'number' ? x.value : x[0]);
    const selectedContactIds = filters.selectedContacts.map(x => typeof x.value === 'number' ? x.value : x[0]);
    const menuPlacement = this.props.menuPlacement ? this.props.menuPlacement : 'bottom';
    if (!isOpen) {
      return null;
    }
    return <div className={clsx('filters', 'animate__animated', isMobile && 'animate__slideInUp', !isMobile && 'fadeIn', 'animate__faster')}>
        <FilterHeader>
          <div className="filter-title">
            {t('filters.header')}
            <div className="close-filters has-pointer" onClick={this.onClose}>
              <img src={filtersClose} alt="close" />
            </div>
          </div>
        </FilterHeader>
        <FilterBody className={clsx('filter-body', !isOpen && 'is-hidden')}>
          <Field className="conditional-margin" isHidden={!this.props.showDatepicker}>
            <FieldLabel style={{
            textAlign: 'left',
            marginLeft: '8px'
          }}>
              <Trans>{this.props.isClosedTab ? 'resultSet.closedAt' : 'resultSet.dueDate'}</Trans>
            </FieldLabel>
            <FieldBody>
              <StaticDateRangePicker displayStaticWrapperAs="desktop" value={[start, end]} onChange={this.onDatesChanged} renderInput={(startProps, endProps) => <>
                    <TextField {...startProps} />
                    <Box sx={{
                mx: 2
              }}> to </Box>
                    <TextField {...endProps} />
                  </>} />
            </FieldBody>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showStartDate}>
            <FieldLabel style={{
            textAlign: 'left',
            marginLeft: '8px'
          }}>
              <Trans>resultSet.startDate</Trans>
            </FieldLabel>
            <FieldBody>
              <StaticDateRangePicker displayStaticWrapperAs="desktop" value={[filters.createdStartDate, filters.createdEndDate]} onChange={this.onStartDatesChanged} renderInput={(startProps, endProps) => <>
                    <TextField {...startProps} />
                    <Box sx={{
                mx: 2
              }}> to </Box>
                    <TextField {...endProps} />
                  </>} />
            </FieldBody>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showProjects}>
            <Control>
              <ReactSelect value={selectedProjects} isMulti options={projects} placeholder={t('board.projects')} onChange={(this.handleChangeProjects as any)} className="dropdown-select-container" classNamePrefix="dropdown-select" onMenuOpen={this.scrollToTop} maxMenuHeight={reactSelectMaxMenuHeight} noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
              menuPortal: base => ({
                ...base,
                zIndex: 9999
              })
            }} />
            </Control>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showResources}>
            <Control>
              <ResourceSelect ressources={resources} isMulti onChange={(this.handleChangeResources as any)} selected={selectedResourceIds} placeholder={this.props.renameResources ? t('board.projects') : t('ressource.singular')} onMenuOpen={this.scrollToTop} menuPlacement={menuPlacement} />
            </Control>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showContacts}>
            <Control>
              <ContactSelect contacts={contacts} isMulti onChange={(this.handleChangeContacts as any)} selected={selectedContactIds} placeholder={t('resultSet.contact')} onMenuOpen={this.scrollToTop} menuPlacement={menuPlacement} />
            </Control>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showTeams}>
            <Control>
              <TeamSelect onChange={(this.handleSelectTeams as any)} selected={filters.selectedTeams.map(x => x.value)} placeholder={t('Teams')} isMulti onMenuOpen={this.scrollToTop} />
            </Control>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showUsers}>
            <Control>
              <UserSelect onChange={(this.handleSelectTesters as any)} selected={filters.selectedTesters.map(x => x.value)} placeholder={t('resultSet.tester')} isMulti setSelectedAsValue onMenuOpen={this.scrollToTop} menuPlacement={menuPlacement} />
            </Control>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showCloser}>
            <Control>
              <UserSelect onChange={(this.handleSelectClosers as any)} selected={filters.selectedClosers ?? []} placeholder={t('resultSet.closedBy')} isMulti setSelectedAsValue onMenuOpen={this.scrollToTop} menuPlacement={menuPlacement} />
            </Control>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showCreator}>
            <Control>
              <UserSelect onChange={(this.handleSelectCreators as any)} selected={filters.selectedCreators ?? []} placeholder={t('Creator')} isMulti setSelectedAsValue onMenuOpen={this.scrollToTop} menuPlacement={menuPlacement} />
            </Control>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showCompanies}>
            <Control>
              <CompanySelect companies={this.props.companies} onChange={(this.handleSelectCompanies as any)} selected={filters.selectedCompanies || []} placeholder={t('menu.companies')} isMulti />
            </Control>
          </Field>
          <Field className="conditional-margin" isHidden={!this.props.showTags}>
            <Control>
              <TagSelect tags={this.props.tags} onChange={(this.handleSelectTags as any)} selected={filters.selectedTags.map(x => x.value)} placeholder={t('ressource.fields.type')} isMulti setSelectedAsValue />
            </Control>
          </Field>
        </FilterBody>
        <Field isGrouped className="has-margin-top-5 top-menu-footer">
          <Control isExpanded>
            <Button className="reset-filters" isFullWidth onClick={() => {
            this.setState({
              filters: initialFilterState
            });
            this.props.resetFilters(this.props.filterLocation);
          }}>
              {t('filters.reset')}
            </Button>
          </Control>
          <Control isExpanded>
            <Button isColor="link" isFullWidth onClick={this.applyFilters}>
              {t('filters.apply')}
            </Button>
          </Control>
        </Field>
      </div>;
  }
}
const mapState = (state: IAlphaprocessState): IStateProps => ({
  filters: state.filter,
  users: state.data.users,
  teams: state.data.teams,
  renameResources: state.core.user?.corporation.corporation_setting.rename_resources ?? false,
  locale: state.core.user.language
});
const mapDispatch = (dispatch: (x: any) => void): IDispatchProps => ({
  overrideFilters: (filters, location) => dispatch(overrideFilters(filters, location)),
  resetFilters: location => dispatch(resetFilters(location))
});
export default connect(mapState, mapDispatch)(withTranslation()(Filters));