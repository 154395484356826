// tslint:disable:no-console

import { Component } from 'react';
// @ts-ignore
import DropboxChooser from 'react-dropbox-chooser';
import Btn from '../button';
interface DropboxProps {
  onSelect: (data: string, accessToken: string) => void;
  onClick: () => void;
}
class Dropbox extends Component<DropboxProps, {}> {
  constructor(props: DropboxProps) {
    super(props);
  }
  private onSuccess = (files: any) => {
    const selectedFiles = files.map((file: any) => ({
      id: file.id,
      name: file.name,
      url: file.link,
      provider: 'dropbox'
    }));
    this.props.onSelect(selectedFiles, '');
  };
  private onCancel = () => {
    console.log('Log :=> Dropbox Select Cancelled');
  };
  public render() {
    return <DropboxChooser appKey={process.env.REACT_APP_DROPBOX_APP_KEY} success={this.onSuccess} cancel={this.onCancel} multiselect linkType="direct" extensions={['.png', '.jpeg', '.pdf', '.*']}>
        <Btn text="Dropbox" onClick={this.props.onClick} />
      </DropboxChooser>;
  }
}
export default Dropbox;