import { ApiBase } from './ApiBase'
import { IArea } from './types/checklist'

export default class AreasApi extends ApiBase {
  public async create(title: string, templateId: number) {
    const area: IArea = {
      title,
      questions: [],
      team_ids: [],
      user_ids: [],
      allow_clone: false,
      description: '',
      hide: false,
      has_page_break: false,
      id: null,
      position: null,
      showCount: null,
      checklist_id: templateId,
      linked_template_id: null
    }

    const response = await this.post<IArea>('/areas', area)

    return response.data
  }
}
