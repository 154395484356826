import { Notification } from 'bloomer';
import { useTranslation } from 'react-i18next';
import { IArea } from '../../../api/types/checklist';
import ITeam from '../../../api/types/team';
import IUser from '../../../api/types/user';
interface IProps {
  area: IArea;
  users: IUser[];
  teams: ITeam[];
}

// ADF-335: this component shows a modal dialog with a list of all not filled required fields when closing a checklist
// the user can jump to the fields by clickinga link
function RequiredFieldAreaNoPermissionMessage({
  area,
  users,
  teams
}: IProps) {
  const {
    t
  } = useTranslation();
  let sentence = '';
  if (area.user_ids.length && area.team_ids.length) {
    sentence = t('checklist.usersAndTeamsPermittedToAccessArea');
  } else if (area.user_ids.length) {
    sentence = t('checklist.usersPermittedToAccessArea');
  } else if (area.team_ids.length) {
    sentence = t('checklist.usersPermittedToAccessArea');
  }
  const responsibleUsers = users.filter(user => area.user_ids.includes(user.id));
  const responsibleTeams = teams.filter(team => area.team_ids.includes(team.id));
  let responsibleNames = [];
  responsibleNames = responsibleNames.concat(responsibleUsers.map(user => user.full_name));
  responsibleNames = responsibleNames.concat(responsibleTeams.map(team => team.name));
  responsibleNames.sort();
  const responsibleSomethings = responsibleNames.map((name, index) => <li key={`responsible-${index}`}>{name}</li>);
  return <Notification isColor="warning" isMarginless className="area-permission-notification">
      <p>{t('checklist.notPermittedToAccessArea')}</p>
      <p>
        {sentence}
        <ul>
          {responsibleSomethings}
        </ul>
      </p>
    </Notification>;
}
export default RequiredFieldAreaNoPermissionMessage;