import { AxiosError } from 'axios';
import { Button, Control, Field, Label, Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, ModalCardTitle } from 'bloomer';
import { ErrorMessage, Field as FormikField, Form, Formik, FormikHelpers as FormikActions } from 'formik';
import { useState } from 'react';
import * as React from 'react';
import { BrowserView, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import api from '../../../api';
import { ICompany } from '../../../api/CompanyApi';
import IContact from '../../../api/types/contact';
import IProject from '../../../api/types/project';
import IResource from '../../../api/types/ressource';
import IResultSet from '../../../api/types/resultSet';
import IUser from '../../../api/types/user';
import { fallbackCorporationSetting } from '../../../constants';
import { IAlphaprocessState } from '../../../store';
import ErrorView from '../../ErrorView';
import { CompanySelect } from '../../widgets/selects';
import ResourceSelect from '../../widgets/selects/ResourceSelect';
import ContactSelect from '../../widgets/selects/ContactSelect';
import ProjectSelect from '../../widgets/selects/ProjectSelect';
import TeamSelect from '../../widgets/selects/TeamSelect';
import UserSelect from '../../widgets/selects/UserSelect';
import VelianDateInput from '../../widgets/VelianDateInput';
interface IProps {
  isActive: boolean;
  resultSet: IResultSet;
  onClose: (event?: React.MouseEvent) => void;
  onSubmit: (resultSet: IResultSet) => void;
  users: IUser[];
  projects: IProject[];
  contacts: IContact[];
  resources: IResource[];
  companies: ICompany[];
}
export default function EditResultSetModal({
  isActive,
  resultSet,
  onClose,
  onSubmit,
  users,
  projects,
  contacts,
  resources,
  companies
}: IProps) {
  const {
    t
  } = useTranslation();
  const [error, setError] = useState<AxiosError | null>(null);
  const corporationSettings = useSelector((state: IAlphaprocessState) => state.core.user?.corporation.corporation_setting ?? fallbackCorporationSetting);
  const schema = yup.object({
    project_id: yup.number().nullable(),
    tester_ids: yup.array(),
    team_ids: yup.array(),
    testers_and_teams: yup.bool().when(['tester_ids', 'team_ids'], {
      is: (tester_ids, team_ids) => !(tester_ids && tester_ids.length || team_ids && team_ids.length),
      then: yup.bool().required('some error msg'),
      otherwise: yup.bool()
    }),
    title: yup.string().required(t('TitleRequired')),
    contact_id: yup.number().nullable(),
    ressource_id: yup.number().nullable(),
    location: yup.string().nullable(),
    due_date: yup.date().required(t('resultSet.dueDateRequired'))
  });
  const saveResultSet = async (values: IResultSet, actions: FormikActions<IResultSet>) => {
    try {
      const data = await api.resultSets.update(values.checklist_id, values.id, values);
      onSubmit(data);
    } catch (error) {
      setError((error as AxiosError));
    } finally {
      actions.setSubmitting(false);
    }
  };
  const realUsers = [...users];
  for (const user of resultSet.testers) {
    if (!realUsers.some(x => x.id === user.id)) {
      realUsers.push(user);
    }
  }
  return <Formik onSubmit={saveResultSet} initialValues={resultSet} validationSchema={schema} render={({
    values,
    setFieldValue,
    isSubmitting,
    errors
  }) => {
    const testerOrTeamValidationMessage = errors && (errors as any).testers_and_teams ? <span className="help has-text-danger">
              {t('schedule.validation.selectTeamsOrUsers')}
            </span> : null;
    return <Form className="edit-result-set-form">
            <Modal isActive={isActive} className={isMobile ? 'is-huge modal-full-screen modal-fx-fadeInScale' : 'is-huge modal-fx-fadeInScale'}>
              <BrowserView>
                <ModalBackground />
              </BrowserView>

              <ModalCard className="modal-content" style={{
          margin: 0
        }}>
                <ModalCardHeader>
                  <ModalCardTitle className="is-ellipsed">{resultSet.title}</ModalCardTitle>
                </ModalCardHeader>
                <ModalCardBody>
                  {error && <ErrorView error={error} />}
                  <Field>
                    <Control>
                      <Label>
                        {t('resultSet.title')}
                      </Label>
                      <FormikField name="title" className="input" placeholder={t('resultSet.title')} />
                      <ErrorMessage component="span" name="title" className="help has-text-danger" />
                    </Control>
                  </Field>
                  <Field>
                    <Control>
                      <Label>
                        {t('resultSet.dueDate')}
                      </Label>
                      <VelianDateInput className="input" onChange={value => setFieldValue('due_date', value)} selected={new Date(values.due_date)} mode="datetime" />
                      <ErrorMessage component="span" name="due_date" className="help has-text-danger" />
                    </Control>
                  </Field>
                  <Field isHidden={corporationSettings.rename_resources}>
                    <Control>
                      <Label>{t('resultSet.project')}</Label>
                      <ProjectSelect projects={projects} onChange={value => {
                  setFieldValue('project_id', value);
                }} placeholder={t('resultSet.project')} selected={[resultSet.project_id]} />
                    </Control>
                  </Field>
                  <Field>
                    <Control>
                      <Label>
                        {t('resultSet.tester')}
                      </Label>
                      <UserSelect selected={values.tester_ids} onChange={value => setFieldValue('tester_ids', value)} placeholder={t('resultSet.tester')} isMulti setSelectedAsValue />
                      {testerOrTeamValidationMessage}
                    </Control>
                  </Field>
                  <Field>
                    <Control>
                      <Label>
                        {t('resultSet.teams')}
                      </Label>
                      <TeamSelect selected={values.team_ids} onChange={value => setFieldValue('team_ids', value)} placeholder={t('resultSet.teams')} isMulti />
                      {}
                    </Control>
                  </Field>
                  {testerOrTeamValidationMessage}

                  {corporationSettings.use_contacts && <Field>
                      <Control>
                        <Label>
                          {' '}
                          {t('resultSet.contact')}
                        </Label>
                        <ContactSelect contacts={contacts} selected={values.contact_id} onChange={value => setFieldValue('contact_id', value)} placeholder={t('resultSet.contact')} />
                        <ErrorMessage component="span" name="contact_id" className="help has-text-danger" />
                      </Control>
                    </Field>}
                  {corporationSettings.use_resources && <Field>
                      <Control>
                        <Label>
                          {corporationSettings.rename_resources ? t(t('resultSet.project')) : t('resultSet.resource')}
                        </Label>
                        <ResourceSelect ressources={resources} selected={values.ressource_id} onChange={selected => {
                  setFieldValue('ressource_id', selected);
                }} placeholder={corporationSettings.rename_resources ? t(t('resultSet.project')) : t('resultSet.resource')} />
                        <ErrorMessage component="span" name="ressource_id" className="help has-text-danger" />
                      </Control>
                    </Field>}
                  <Field isHidden={!corporationSettings.use_companies}>
                    <Control>
                      <Label>
                        {t('resultSet.company')}
                      </Label>
                      <CompanySelect companies={companies} selected={values.company_id} onChange={value => setFieldValue('company_id', value)} placeholder={t('resultSet.company')} />
                      <ErrorMessage component="span" name="contact_id" className="help has-text-danger" />
                    </Control>
                  </Field>
                  <Field>
                    <Control>
                      <Label>
                        {' '}
                        {t('resultSet.location')}
                      </Label>
                      <FormikField name="location" className="input" placeholder={t('resultSet.location')} />
                      <ErrorMessage component="span" name="location" className="help has-text-danger" />
                    </Control>
                  </Field>
                </ModalCardBody>
                <ModalCardFooter>
                  <Button type="button" onClick={onClose}>{t('common.cancel')}</Button>
                  <Button isLoading={isSubmitting} type="submit" isColor="link">{t('common.save')}</Button>
                </ModalCardFooter>
              </ModalCard>
            </Modal>
          </Form>;
  }} />;
}