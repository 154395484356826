import { gql } from '@apollo/client'

export interface CreateTemplatePayload {
  externalCanCreate: boolean;
  lockChecklistAfterDueDate: boolean;
  externalCanReopen: boolean;
  corporationId: number;
  employeesCanRestart: boolean;
  incompleteMailTeamIds?: (number)[] | null;
  addTimestampToImages: boolean;
  hideProgressInReport: boolean;
  sendEmailReport: boolean;
  useStartDate: boolean;
  externalCanRestart: boolean;
  userIds?: (number)[] | null;
  externalCanEdit: boolean;
  isAudit: boolean;
  attachFiles: boolean;
  signeeFreeText: boolean;
  incompleteMailUserIds?: (number)[] | null;
  title: string;
  teamIds?: (number)[] | null;
  description: string;
  reportEmptyResults: boolean;
  additionalEmails?: (string)[] | null;
  showDescription: boolean;
  continouslyCreate: boolean;
  folderId: string | null
}

export interface CreateTemplateMutation {
  createTemplate: {
    errors: string[]
    template: {
      id: number
      title: string
    }
  }
}

export default gql`
mutation createTemplate(
  $title: String!
  $description: String
  $isAudit: Boolean
  $showDescription: Boolean
  $sendEmailReport: Boolean
  $addTimestampToImages: Boolean
  $useStartDate: Boolean
  $externalCanEdit: Boolean
  $additionalEmails: [String!]
  $lockChecklistAfterDueDate: Boolean
  $attachFiles: Boolean
  $hideProgressInReport: Boolean
  $externalCanCreate: Boolean
  $externalCanRestart: Boolean
  $externalCanReopen: Boolean
  $signeeFreeText: Boolean
  $reportEmptyResults: Boolean
  $employeesCanRestart: Boolean
  $continouslyCreate: Boolean
  $userIds: [ID!]
  $incompleteMailUserIds: [ID!]
  $teamIds: [ID!]
  $incompleteMailTeamIds: [ID!]
  $corporationId: ID!,
  $folderId: ID,
) {
  createTemplate(
    title: $title,
    description: $description,
    isAudit: $isAudit,
    sendEmailReport: $sendEmailReport,
    showDescription:$showDescription,
    addTimestampToImages: $addTimestampToImages,
    useStartDate: $useStartDate,
    externalCanEdit: $externalCanEdit,
    additionalEmails: $additionalEmails,
    lockChecklistAfterDueDate: $lockChecklistAfterDueDate,
    attachFiles: $attachFiles,
    hideProgressInReport: $hideProgressInReport,
    externalCanCreate: $externalCanCreate,
    externalCanRestart: $externalCanRestart,
    externalCanReopen: $externalCanReopen,
    signeeFreeText: $signeeFreeText,
    reportEmptyResults: $reportEmptyResults,
    employeesCanRestart: $employeesCanRestart,
    continouslyCreate: $continouslyCreate,
    userIds: $userIds,
    incompleteMailUserIds: $incompleteMailUserIds,
    teamIds: $teamIds,
    incompleteMailTeamIds: $incompleteMailTeamIds,
    corporationId: $corporationId,
    folderId: $folderId
  ) {
    errors
    template {id title}
  }
}
`
