import { gql, useQuery } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import ReactSelect, { MenuPlacement } from 'react-select';
import { useTranslation } from 'react-i18next';
import IContact from '../../../api/types/contact';
import { reactSelectMaxMenuHeight } from '../../../constants';
interface IProps {
  contacts: IContact[];
  onChange: (value: number | number[]) => void;
  selected: number | number[];
  isMulti?: boolean;
  placeholder: string;
  onMenuOpen?: any;
  menuPlacement?: MenuPlacement | null;
  name?: string;
  id?: string;
}
interface NewContactSelectProps {
  selected: number[];
  label: string;
  onChange: (selected: number[]) => void;
  disabled?: boolean;
}
const FETCH_CONTACTS_QUERY = gql`
    query ContactSelect {
        contacts {
            id
            firstname
            lastname
        }
    }
`;
interface FetchContactsResponse {
  contacts: Array<{
    id: string;
    firstname: string;
    lastname: string;
  }>;
}
export function NewContactSelect({
  selected,
  label,
  onChange,
  disabled
}: NewContactSelectProps) {
  const {
    data
  } = useQuery<FetchContactsResponse>(FETCH_CONTACTS_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const users = data?.contacts ?? [];
  return <Autocomplete fullWidth value={users.filter(x => selected.includes(parseInt(x.id)))} options={users} multiple disabled={disabled} getOptionLabel={user => typeof user === 'string' ? user : `${user.firstname} ${user.lastname}`} renderInput={params => <TextField {...params} label={label} />} onChange={(event, newValue) => {
    onChange(newValue.map(x => typeof x === 'string' ? parseInt(x) : parseInt(x.id)));
  }} />;
}
export default function ContactSelect(props: IProps) {
  const {
    t
  } = useTranslation();
  const options = props.contacts.map(c => ({
    value: c.id,
    label: `${c.firstname} ${c.lastname}`
  }));
  let selected = Array.isArray(props.selected) ? options.filter(o => (props.selected as number[]).includes(o.value)) : options.find(o => o.value === props.selected);
  if (props.selected === null) {
    selected = null;
  }
  const handleChange = (values: any) => {
    if (Array.isArray(values)) {
      props.onChange(values.map(v => v.value));
    } else {
      props.onChange(values.value);
    }
  };
  return <ReactSelect id={props.id} name={props.name} options={options} isMulti={props.isMulti} onChange={handleChange} value={selected} placeholder={props.placeholder} isSearchable maxMenuHeight={reactSelectMaxMenuHeight} className="dropdown-select-container" classNamePrefix="dropdown-select" onMenuOpen={props.onMenuOpen} noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
    menuPortal: base => ({
      ...base,
      zIndex: 9999
    })
  }} />;
}