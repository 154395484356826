import { ApiBase } from './ApiBase'

import ICorporation from './types/corporation'

export default class CorporationApi extends ApiBase {
  public create(data: ICorporation) {
    return this.post<ICorporation>('/corporations', data)
  }

  public update(id: number, data: ICorporation | FormData) {
    return this.put<ICorporation>(`/corporations/${id}`, data)
  }

  public find(id: number) {
    return this.get<ICorporation>(`/corporations/${id}`)
  }

  public removeCalenderFile(id: number, fileId: number) {
    return this.delete<ICorporation>(`/corporations/${id}/remove_non_working_days?file_id=${fileId}`)
  }
}
