import { gql } from '@apollo/client'

export interface CreateRuleSetPayload {
  questionId: number;
  position: number;
}

export interface RuleSet {
  id: string;
}

export interface CreateRuleSet {
  errors: string[]
  ruleSet: RuleSet;
}

export interface CreateRuleSetResponse {
  createRuleSet: CreateRuleSet;
}

const CREATE_RULESET = gql`
mutation createRuleSet($questionId: ID!, $position: Int!) {
  createRuleSet(questionId: $questionId, position: $position) {
    errors,
    ruleSet {
      id
    }
  }
}
`
export default CREATE_RULESET
