import { useTranslation } from 'react-i18next';
import { MenuPlacement } from 'react-select';
import Creatable from 'react-select/creatable';
import api from '../../../api';
import { ICompany } from '../../../api/CompanyApi';
interface Props {
  companies: ICompany[];
  isMulti: boolean;
  selected: string | string[];
  onCreateCompany: (company: ICompany) => void;
  placeholder: string;
  onChange: (value: string | string[]) => void;
  menuPlacement?: MenuPlacement;
}
function CompanyCreatable(props: Props) {
  const {
    t
  } = useTranslation();
  const handleCreateCompany = (name: string) => {
    api.companies.create({
      name,
      description: '',
      address: '',
      address_supplement: '',
      city: '',
      zip_code: ''
    }, null).then(props.onCreateCompany);
  };
  const handleChange = (values: any) => {
    if (Array.isArray(values)) {
      props.onChange(values.map(v => v.value));
    } else {
      props.onChange(values.value);
    }
  };
  const options = props.companies.map(x => ({
    label: x.name,
    value: x.id
  }));
  const selected = Array.isArray(props.selected) ? options.filter(o => (props.selected as string[]).includes(o.value)) : options.find(o => o.value === props.selected);
  return <Creatable options={options} value={selected} isMulti={props.isMulti} onCreateOption={handleCreateCompany} placeholder={props.placeholder} onChange={handleChange} menuPlacement={props.menuPlacement} noOptionsMessage={() => t('contact.validation.company')} formatCreateLabel={option => t('common.createOption', {
    option
  })} />;
}
export default CompanyCreatable;