import { gql } from '@apollo/client'

export interface CreateSchedulePayload {
  checklistId: number;
  dueDate: string;
  startDate: string;
  autostart: boolean;
  projectId: number;
  location: string;
  ressourceId: number | null;
  title: string;
  testerId: number | null;
  contactId: number | null;
  repeat: string;
}

export interface Schedule {
  id: string;
}

export interface CreateSchedule {
  errors: string[]
  schedule: Schedule;
}

export interface CreateScheduleResponse {
  createSchedule: CreateSchedule;
}

const CREATE_SCHEDULE = gql`
mutation createSchedule(
  $checklistId: ID!
  $startDate: ISO8601DateTime!
  $dueDate: ISO8601DateTime!
  $repeat: ScheduleRepeat!
  $title: String!
  $projectId: Int
  $autostart: Boolean
  $testerId: Int
  $location: String
  $ressourceId: Int
  $contactId: Int
) {
  createSchedule(
    checklistId: $checklistId
    startDate: $startDate
    dueDate: $dueDate
    repeat: $repeat
    title:$title
    projectId: $projectId
    autostart: $autostart
    testerId: $testerId
    location: $location
    ressourceId: $ressourceId
    contactId: $contactId
  ) {
    errors
    schedule {
      id
    }
  }
}
`

export default CREATE_SCHEDULE
