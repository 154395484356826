import { IFilterState } from '../reducers/filterReducer'
import ISelectListValueModel from '../types/selectListModel'

export enum FilterLocation {
  checklists,
  contacts,
  resources,
  calendar,
  boards,
  people,
  companies,
  tasks,
  charts
}

export interface IFilterTagsIndexAction {
  type: 'FILTER_TAGS_INDEX'
  selected: Array<ISelectListValueModel<string>>
  location: FilterLocation
}

export interface IFilterTagsDetailsAction {
  type: 'FILTER_TAGS_DETAILS'
  selected: Array<ISelectListValueModel<string>>
  location: FilterLocation
}

export interface IFilterTagsAddAction {
  type: 'FILTER_TAGS_ADD'
  selected: Array<ISelectListValueModel<string>>
  location: FilterLocation
}

export interface IResetFiltersAction {
  type: 'FILTER_RESET'
  location: FilterLocation
}

export const resetFilters = (location: FilterLocation): IResetFiltersAction => ({ type: 'FILTER_RESET', location })

export interface IOverrideFiltersAction {
  type: 'OVERRIDE_FILTERS'
  filters: IFilterState
  location: FilterLocation
}

export const overrideFilters = (
  filters: IFilterState,
  location: FilterLocation
): IOverrideFiltersAction => (
  { type: 'OVERRIDE_FILTERS', filters, location }
)

export const filterTagsDetails = (
  selected: Array<ISelectListValueModel<string>>,
  location: FilterLocation
): IFilterTagsDetailsAction => ({
  type: 'FILTER_TAGS_DETAILS',
  selected,
  location
})

export const filterTagsAdd = (
  selected: Array<ISelectListValueModel<string>>,
  location: FilterLocation
): IFilterTagsAddAction => ({
  type: 'FILTER_TAGS_ADD',
  selected,
  location
})

export interface ISetFilterDatesAction {
  type: 'FILTER_SET_FILTER_DATES'
  startDate: any
  endDate: any
  location: FilterLocation
}

export function setFilterDates(
  startDate: Date,
  endDate: Date,
  location: FilterLocation
): ISetFilterDatesAction {
  return {
    endDate,
    startDate,
    type: 'FILTER_SET_FILTER_DATES',
    location
  }
}

export interface ISetTestersAction {
  type: 'FILTER_SET_TESTERS'
  testers: Array<ISelectListValueModel<any>>
  location: FilterLocation
}

export function setTesters(
  testers: Array<ISelectListValueModel<any>>,
  location: FilterLocation
): ISetTestersAction {
  return {
    testers,
    type: 'FILTER_SET_TESTERS',
    location
  }
}

export interface ISetProjectsAction {
  type: 'FILTER_SET_PROJECTS'
  projects: Array<ISelectListValueModel<any>>
  location: FilterLocation
}

export function setProjects(
  projects: Array<ISelectListValueModel<any>>,
  location: FilterLocation
): ISetProjectsAction {
  return {
    projects,
    type: 'FILTER_SET_PROJECTS',
    location
  }
}

export interface ISetTeamsAction {
  type: 'FILTER_SET_TEAMS'
  teams: Array<ISelectListValueModel<any>>
  location: FilterLocation
}

export function setTeams(
  teams: Array<ISelectListValueModel<any>>,
  location: FilterLocation
): ISetTeamsAction {
  return {
    teams,
    type: 'FILTER_SET_TEAMS',
    location
  }
}

export interface ISetTermAction {
  type: 'FILTER_SET_TERM'
  term: string
  location: FilterLocation
}

export function setTerm(term: string, location: FilterLocation): ISetTermAction {
  return {
    type: 'FILTER_SET_TERM',
    term,
    location
  }
}

export interface ISetContactsAction {
  type: 'FILTER_SET_CONTACTS'
  contacts: Array<ISelectListValueModel<any>>
  location: FilterLocation
}

export function setContacts(
  contacts: Array<ISelectListValueModel<any>>,
  location: FilterLocation
): ISetContactsAction {
  return {
    contacts,
    type: 'FILTER_SET_CONTACTS',
    location
  }
}

export interface ISetRessourcesAction {
  type: 'FILTER_SET_RESOURCES'
  resources: Array<ISelectListValueModel<any>>
  location: FilterLocation
}

export type FilterActions = IFilterTagsAddAction
| IFilterTagsDetailsAction
| IFilterTagsIndexAction
| ISetFilterDatesAction
| ISetTestersAction
| ISetProjectsAction
| ISetTeamsAction
| ISetTermAction
| ISetContactsAction
| ISetRessourcesAction
| IResetFiltersAction
| IOverrideFiltersAction
