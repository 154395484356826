import { AxiosError } from 'axios';
import { Box, Button, Column, Columns, Control, Field, Input, Notification, Title } from 'bloomer';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { FilterLocation } from '../../actions/filterActions';
import api from '../../api';
import { IChart, IChartTag } from '../../api/chartsApi';
import { IChecklistSelectItem } from '../../api/checklistsApi';
import IProject from '../../api/types/project';
import ChartBox from '../../components/charts/ChartBox';
import ErrorView from '../../components/ErrorView';
import LoadSpinner from '../../components/loadSpinner/LoadSpinner';
import ActiveFilterMessage from '../../components/widgets/ActiveFilterMessage';
import { IsAdminProps } from '../../higherOrderComponents/isAdminProps';
import { emptyFilterSettings, FilterSettings } from '../../reducers/filterReducer';
import { IAlphaprocessState } from '../../store';
import ITranslationProps from '../../types/translationProps';
import { confirm } from '../../util';
import ChartDetailsEdit from './ChartDetailsEdit';
interface StateProps extends IsAdminProps {
  filters: FilterSettings;
}
const COLUMN_SIZE = {
  mobile: 'full',
  tablet: '1/2',
  desktop: '1/4',
  widescreen: 2
};
type Props = ITranslationProps & StateProps;
interface IState {
  charts: IChart[];
  initialLoaded: boolean;
  error: AxiosError | null;
  isLoading: boolean;
  title: string;
  projects: IProject[];
  templates: IChecklistSelectItem[];
  checklists: IChecklistSelectItem[];
  tags: IChartTag[];
  selectedChart: IChart | null;
  isShown: boolean;
}
class ChartIndex extends React.Component<Props, IState> {
  public state = {
    charts: [],
    error: null,
    isLoading: true,
    initialLoaded: false,
    title: '',
    projects: [],
    templates: [],
    checklists: [],
    tags: [],
    selectedChart: null,
    isShown: true
  };
  public async componentDidMount() {
    await this.fetchData();
  }
  private async fetchData() {
    this.setState({
      isLoading: true
    });
    try {
      const charts = await api.charts.all();
      const projects = await api.projects.select(0, 0);
      const templates = await api.checklists.select();
      const tags = await api.chartsTags.all();
      const checklists = await api.resultSets.select();
      this.setState({
        charts,
        projects,
        templates,
        checklists,
        tags
      });
    } catch (error) {
      this.setState({
        error: (error as AxiosError)
      });
    } finally {
      this.setState({
        isLoading: false,
        initialLoaded: true
      });
    }
  }
  private handleUpdate = (chart: IChart) => {
    const charts = [...this.state.charts];
    const itemIndex = charts.findIndex(x => x.id === chart.id);
    if (itemIndex >= 0) {
      charts[itemIndex] = chart;
      this.setState({
        charts
      });
    }
    this.setState({
      selectedChart: null,
      isShown: false
    });
  };
  private filterTasks = (chart: IChart) => {
    const {
      term
    } = this.props.filters;
    return !(term && !chart.title.toLowerCase().includes(term.toLowerCase()));
  };
  private handleTitleChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      title: ev.target.value
    });
  };
  private onSelect = (selectedChart: IChart) => {
    this.setState({
      selectedChart,
      isShown: true
    });
  };
  private handleCancelEdit = () => {
    this.setState({
      selectedChart: null,
      isShown: false
    });
  };
  private handleDelete = () => {
    this.setState({
      isShown: false
    });
    confirm(null, this.props.t('chart.confirmDelete'), async () => {
      try {
        await api.charts.deleteChart(this.state.selectedChart.id);
        const charts = this.state.charts.filter(b => b.id !== this.state.selectedChart.id);
        this.setState({
          charts,
          selectedChart: null,
          isShown: false
        });
      } catch (error) {
        this.setState({
          error: (error as AxiosError),
          isShown: false
        });
      }
    });
  };
  private handleCreateTask = () => {
    const {
      title
    } = this.state;
    if (!title.length) {
      return;
    }
    this.setState({
      isLoading: true
    });
    api.charts.create(title).then(() => this.fetchData()).catch(error => this.setState({
      error
    })).finally(() => this.setState({
      isLoading: false,
      title: ''
    }));
  };
  private handleAddTag = (tag: IChartTag) => {
    this.setState({
      tags: [...this.state.tags, tag]
    });
  };
  public render() {
    const {
      error,
      charts,
      isLoading,
      initialLoaded,
      selectedChart,
      isShown,
      projects,
      title,
      templates,
      tags,
      checklists
    } = this.state;
    const {
      t
    } = this.props;
    const boxes = charts.filter(this.filterTasks).map(c => <Column isSize={(COLUMN_SIZE as any)} key={c.id}>
        <ChartBox chart={c} onSelect={this.onSelect} />
      </Column>);
    const editModal = selectedChart && isShown ? <ChartDetailsEdit onDelete={this.handleDelete} chartId={selectedChart.id} onCancel={this.handleCancelEdit} onUpdate={this.handleUpdate} templates={templates} checklists={checklists} tags={tags} onAddTag={this.handleAddTag} projects={projects} /> : null;
    const customUI = ({
      onClose
    }) => <Box className="change-team-title-box centered" style={{
      maxWidth: '400px'
    }}>
        <Title isSize={6} className="has-text-centered">
          {t('chart.new')}
        </Title>
        <Field>
          <Control>
            <Input name="title" onChange={this.handleTitleChanged} defaultValue={title} placeholder={t('chart.fields.title')} />
          </Control>
        </Field>
        <Field isGrouped>
          <Control>
            <Button className="has-margin-bottom-10" isPulled="right" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Control>
          <Control isExpanded>
            <Button id="save-team" className="has-margin-bottom-10 has-pointer" isPulled="right" onClick={() => {
            if (this.state.title) {
              this.handleCreateTask();
            }
            onClose();
          }}>
              {t('common.save')}
            </Button>
          </Control>
        </Field>
      </Box>;
    if (isLoading && !initialLoaded) {
      return <LoadSpinner className="centered" />;
    }
    return <div className="chart-index">
        <h3 className="chart-title">{t('chart.title')}</h3>
        {error !== null && <ErrorView error={error} />}
        <ActiveFilterMessage filterLocation={FilterLocation.charts} />
        {!this.props.isPowerUser && charts.length === 0 && <Notification isColor="info">
            {t('chart.noChartsAvailable')}
          </Notification>}
        <Columns isMobile isMultiline>
          <Column isHidden={!this.props.isPowerUser} isSize={(COLUMN_SIZE as any)}>
            <Box isFullWidth className="chart-box" style={{
            backgroundColor: 'white'
          }} onClick={() => confirmAlert({
            customUI
          })}>
              <Title isSize={5} hasTextAlign="centered" style={{
              marginTop: '15%'
            }}>
                {t('chart.new')}
              </Title>
            </Box>
          </Column>
          {boxes}
        </Columns>
        {editModal}
      </div>;
  }
}
function mapState(state: IAlphaprocessState): StateProps {
  return {
    filters: state.filter.chartFilterSettings || emptyFilterSettings,
    isPowerUser: state.core.user?.current_role === 'admin'
  };
}
export default connect(mapState)(withTranslation()(ChartIndex));