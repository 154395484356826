import { Box, Button, Control, Field, Input, Title } from 'bloomer';
import { FunctionComponent, useCallback, useState } from 'react';
import * as React from 'react';
import { Trans } from 'react-i18next';
interface Props {
  currentName: string;
  onClose: () => void;
  onRename: (newName: string) => void;
}
const RenameAreaDialog: FunctionComponent<Props> = ({
  currentName,
  onRename,
  onClose
}) => {
  const [name, setName] = useState(currentName);
  const handleRename = useCallback(() => {
    if (name !== currentName) {
      onRename(name);
    }
    onClose();
  }, [name, currentName, onClose, onRename]);
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);
  return <Box className="move-folder-dialog">
      <Title isSize={6} className="has-text-centered">
        <Trans>resultSet.renameArea</Trans>
      </Title>
      <Field>
        <Control>
          <Input name="name" value={name} onChange={handleChange} />
        </Control>
      </Field>
      <div className="buttons has-margin-top-8 is-right">
        <Button onClick={onClose}><Trans>common.cancel</Trans></Button>
        <Button isColor="link" onClick={handleRename}>
          <Trans>common.rename</Trans>
        </Button>
      </div>
    </Box>;
};
export default RenameAreaDialog;