import { ITag } from '../api/types/ressource'
import ITeam from '../api/types/team'
import IUser from '../api/types/user'

export interface UpdateTagsAction {
  type: 'DATA_UPDATE_TAG'
  tags: ITag[]
}

export const updateTags = (tags: ITag[]): UpdateTagsAction => (
  {
    tags: tags?.sort((a, b) => a.title.localeCompare(b.title)) ?? [],
    type: 'DATA_UPDATE_TAG'
  }
)

export interface UpdateUserAction {
  type: 'DATA_UPDATE_USERS'
  users: IUser[]
}

export const updateUsersAction = (users: IUser[]): UpdateUserAction => ({
  type: 'DATA_UPDATE_USERS',
  users
})

export interface UpdateTeamsAction {
  type: 'DATA_UPDATE_TEAMS'
  teams: ITeam[]
}

export const updateTeamsAction = (teams: ITeam[]): UpdateTeamsAction => ({
  type: 'DATA_UPDATE_TEAMS',
  teams
})

export interface AddTeamAction {
  type: 'DATA_ADD_TEAM'
  team: ITeam
}

export const addTeamAction = (team: ITeam): AddTeamAction => ({
  team,
  type: 'DATA_ADD_TEAM'
})

export type DataStoreActions = UpdateTagsAction | UpdateUserAction | UpdateTeamsAction | AddTeamAction
