import { Button, Control, Field, Label, ModalCard, ModalCardBody, ModalCardFooter, ModalCardHeader, ModalCardTitle } from 'bloomer';
import { ErrorMessage, Field as FormikField, Form, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { GithubPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import api from '../../api';
import { IChart, IChartTag } from '../../api/chartsApi';
import { IChecklistSelectItem } from '../../api/checklistsApi';
import IProject from '../../api/types/project';
import { TeamSelect, UserSelect } from '../../components/widgets/selects';
import ProjectSelect from '../../components/widgets/selects/ProjectSelect';
import { reactSelectMaxMenuHeight } from '../../constants';
interface IOwnProps {
  projects: IProject[];
  onCancel: () => void;
  onDelete: () => void;
  tags: IChartTag[];
  onAddTag: (tag: IChartTag) => void;
  templates: IChecklistSelectItem[];
  checklists: IChecklistSelectItem[];
}
type Props = IOwnProps;
const CHART_COLORS = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB'];
function ChartForm(props: Props) {
  const {
    templates,
    checklists,
    tags,
    onCancel,
    onDelete,
    onAddTag
  } = props;
  const {
    t
  } = useTranslation();
  const {
    values,
    setFieldValue,
    initialValues,
    isSubmitting
  } = useFormikContext<IChart>();
  const templateOptions = templates.map(template => ({
    value: template.id,
    label: template.title
  }));
  const selectedTemplates = templateOptions.filter(x => values.checklist_ids.includes(x.value));
  const checklistOptions = checklists.map(template => ({
    value: template.id,
    label: template.title
  }));
  const selectedChecklists = checklistOptions.filter(x => values.result_set_ids.includes(x.value));
  const tagOptions = tags.map(template => ({
    value: template.id,
    label: template.title
  }));
  const selectedTags = tagOptions.filter(x => values.charts_tags_ids.includes(x.value));
  const handleTagsChanged = useCallback((selectedValues: any) => {
    const oldIds = values.charts_tags_ids;

    // if the user unselected everything, then do clear arrays
    if (!selectedValues.length) {
      setFieldValue('charts_tags_ids', []);
    }
    selectedValues.map(option => {
      // if tag is not saved yet, then post to backend to create tag,
      // then set this.state.ressource.tag_ids, too enable us to post the
      // tag associations to the backend
      if (option.__isNew__) {
        const tag = option.label;
        // create the new tag, post it to backend
        api.chartsTags.create(tag).then(result => {
          // after wie got a response from backend we have to update the value of the item
          const index = selectedValues.findIndex(x => x.label === result.data.title);
          // the value on new created items is the entered string
          // now we have a id
          // so value must be the id
          if (index >= 0) {
            selectedValues[index].value = result.data.id;
          }
          const tag_ids = [...oldIds, result.data.id];
          setFieldValue('charts_tags_ids', tag_ids);
          onAddTag(result.data);
        }).catch(() => {
          // if the creation of the tag is failed then check if we have already this tag
          api.chartsTags.existing_tag(tag).then(result => {
            const index = selectedValues.findIndex(x => x.label === result.data.title);
            // the value on new created items is the entered string
            // now we have a id
            // so value must be the id
            if (index >= 0) {
              selectedValues[index].value = result.data.id;
            }
            const tag_ids = [...oldIds, result.data.id];
            setFieldValue('charts_tags_ids', tag_ids);
          });
        });
      } else {
        const tag_ids = selectedValues.map(x => x.value);
        setFieldValue('charts_tags_ids', tag_ids);
      }
      option.__isNew__ = false;
      return option;
    });
  }, [onAddTag, setFieldValue, values.charts_tags_ids]);
  return <Form>
      <ModalCard style={{
      margin: 0
    }}>
        <ModalCardHeader>
          <ModalCardTitle>
            {initialValues.title}
          </ModalCardTitle>
        </ModalCardHeader>
        <ModalCardBody>
          <Field>
            <Control>
              <Label>
                {' '}
                {t('chart.fields.title')}
                {' '}
              </Label>
              <FormikField type="text" name="title" className="input" placeholder={t('chart.fields.title')} />
              <ErrorMessage component="span" name="title" className="helper has-text-danger" />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>
                {' '}
                {t('chart.fields.users')}
                {' '}
              </Label>
              <UserSelect onChange={(vals: number | number[]) => {
              setFieldValue('user_ids', vals);
            }} placeholder={t('chart.fields.users')} selected={values.user_ids} setSelectedAsValue isMulti />
              <ErrorMessage component="span" name="user_ids" className="helper has-text-danger" />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>
                {' '}
                {t('chart.fields.teams')}
                {' '}
              </Label>
              <TeamSelect onChange={(vals: number | number[]) => {
              setFieldValue('team_ids', vals);
            }} placeholder={t('chart.fields.teams')} selected={values.team_ids} isMulti />
              <ErrorMessage component="span" name="team_ids" className="helper has-text-danger" />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>{t('chart.fields.projects')}</Label>
              <ProjectSelect projects={props.projects} selected={values.project_ids} isMulti onChange={vals => setFieldValue('project_ids', vals)} placeholder={t('chart.fields.projects')} />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>{t('chart.fields.templates')}</Label>
              <ReactSelect value={selectedTemplates} name="checklist_ids" className="react-select" options={templateOptions} isMulti placeholder={t('chart.fields.templates')} onChange={(value: any) => {
              setFieldValue('checklist_ids', value.map(o => o.value));
            }} maxMenuHeight={reactSelectMaxMenuHeight} noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
              menuPortal: base => ({
                ...base,
                zIndex: 9999
              })
            }} />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>{t('chart.fields.checklists')}</Label>
              <ReactSelect value={selectedChecklists} className="react-select" name="result_set_ids" options={checklistOptions} isMulti placeholder={t('chart.fields.checklists')} onChange={(value: any) => {
              setFieldValue('result_set_ids', value.map(o => o.value));
            }} maxMenuHeight={reactSelectMaxMenuHeight} noOptionsMessage={() => t('common.noOptions')} menuPortalTarget={document.body} menuPlacement="auto" styles={{
              menuPortal: base => ({
                ...base,
                zIndex: 9999
              })
            }} />
            </Control>
          </Field>
          <Field hidden>
            <Control>
              <Label htmlFor="type">{t('chart.fields.types')}</Label>
              <Creatable className="react-select" value={selectedTags} options={tagOptions} onChange={handleTagsChanged} placeholder={t('chart.fields.types')} name="type" isSearchable isMulti menuPlacement="top" formatCreateLabel={option => t('common.createOption', {
              option
            })} noOptionsMessage={() => t('chart.validation.typeRequired')} />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>{t('chart.fields.color')}</Label>
              <GithubPicker color={values.color} onChange={color => setFieldValue('color', color.hex)} colors={CHART_COLORS} />
              <ErrorMessage component="span" name="color" className="helper has-text-danger" />
            </Control>
          </Field>
        </ModalCardBody>
        <ModalCardFooter>
          <Button onClick={onCancel} type="button">{t('common.cancel')}</Button>
          <Button onClick={onDelete} type="button">{t('common.delete')}</Button>
          <Button isColor="link" isLoading={isSubmitting} type="submit">
            {t('common.save')}
          </Button>
        </ModalCardFooter>
      </ModalCard>
    </Form>;
}
export default ChartForm;