import { compiledBlock as _compiledBlock } from "million/react";
const JSX_cols_1 = _compiledBlock(_props => <td key="multiselect">{_props.v0}</td>, {
  name: "JSX_cols_1",
  portals: ["v0"]
});
const JSX_actionTd_1 = _compiledBlock(_props2 => <td className="data-table-action-row">
      {_props2.v0}
    </td>, {
  name: "JSX_actionTd_1",
  portals: ["v0"]
});
const DataTableRow_1 = _compiledBlock(_props3 => <tr className={_props3.v0}>
      {_props3.v1}
      {_props3.v2}
    </tr>, {
  name: "DataTableRow_1",
  portals: ["v1", "v2"]
});
const DataTableRow = function DataTableRow({
  headers,
  data,
  actions,
  isSelected = false,
  dataId = null,
  selectable,
  selected,
  onSelect
}: IProps<T>) {
  const {
    t
  } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const handleClicked = useCallback((action: IDataTableAction<T>, data: T) => {
    const disabled = action.isDisabledForItem ? action.isDisabledForItem(data) : false;
    if (disabled) return;
    setIsMenuOpen(false);
    action.onClick(data);
  }, []);
  let cols = selectable ? [/*@million jsx-skip*/<JSX_cols_1 v0={<Checkbox checked={selected} onChange={onSelect} />} />] : [];
  cols = cols.concat(headers.map(c => {
    const repeatOption = c.formatAs === 'repeat' ? scheduleRepeatOptions.find(x => x.value === data[c.field]) : {
      translationKey: '-'
    };
    switch (c.formatAs) {
      case 'repeat':
        return <td colSpan={actions.length === 0 ? c.colSpan : null} key={c.field}>
            {repeatOption ? t(repeatOption.translationKey) : 'deleted'}
          </td>;
      case 'dueDate':
        return <td colSpan={actions.length === 0 ? c.colSpan : null} key={c.field}>
            <TableDueDate date={data[c.field]} />
          </td>;
      // Garstige Datumse
      case 'date':
        return <td colSpan={actions.length === 0 ? c.colSpan : null} key={c.field}>
            <span>
              {data[c.field] && DateUtil.format(data[c.field], DateUtil.ShortFormat)}
            </span>
          </td>;
      case 'datetime':
        return <td key={c.field} colSpan={actions.length === 0 ? c.colSpan : null}>
            <span>
              {data[c.field] && DateUtil.format(data[c.field], DateUtil.LongFormat)}
            </span>
          </td>;
      case 'assignees':
        return <td key={c.field} colSpan={actions.length === 0 ? c.colSpan : null}>
            <TableAssignees assignees={data[c.field] ?? []} />
          </td>;
      case 'percentage':
        return <td key={c.field} colSpan={actions.length === 0 ? c.colSpan : null}>
            {fPercent(data[c.field])}
          </td>;
      case 'progress':
        return <td key={c.field} colSpan={actions.length === 0 ? c.colSpan : null}>
            <ChecklistProgressbar progress={data[c.field] ?? 0} />
          </td>;

      // All other values
      default:
        return <td key={c.field} colSpan={actions.length === 0 ? c.colSpan : null} className={clsx('text-row', c.bold && 'bold-text-row')}>
            {data[c.field]}
            {' '}
          </td>;
    }
  }));
  const filteredActions = actions.filter(action => {
    if (typeof action.isValidForItem === 'function') {
      return action.isValidForItem(data);
    }
    return true;
  });
  const actionTd = filteredActions.length > 0 ? /*@million jsx-skip*/<JSX_actionTd_1 v0={<GearMenu isUp={false} isOpen={isMenuOpen} onToggle={setIsMenuOpen} dataId={dataId}>
        {filteredActions.map(action => <DropdownItem className={clsx(action.className, action.isDisabledForItem && action.isDisabledForItem(data) && 'is-disabled')} isHidden={(action.isHidden as any)} key={action.icon} disabled={action.isDisabledForItem ? action.isDisabledForItem(data) : false} onClick={() => handleClicked(action, data)}>
            <Icon className={`${action.icon} has-rem-1`} />
            {action.tooltip}
          </DropdownItem>)}
      </GearMenu>} /> : null;
  return /*@million jsx-skip*/<DataTableRow_1 v0={isSelected ? 'is-selected' : null} v1={cols} v2={actionTd} />;
};
import { block as _block } from "million/react";
import { Checkbox, DropdownItem, Icon } from 'bloomer';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { scheduleRepeatOptions } from '../../constants';
import DateUtil from '../../util/DateUtil';
import { fPercent } from '../../util/formatNumber';
import ChecklistProgressbar from '../checklists/result/ChecklistProgressbar';
import { TableAssignees } from '../checklists/result/TableAvatarDisplay';
import { TableDueDate } from '../checklists/result/TableDueDate';
import { IDataTableAction, IDataTableHeader } from './DataTableHeader';
import GearMenu from './GearMenu';
interface IProps<T> {
  isSelected?: boolean;
  data: T;
  actions: Array<IDataTableAction<T>>;
  headers: IDataTableHeader[];
  dataId?: string | null;
  selectable: boolean;
  selected: boolean;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * A singular row for the DataTableComponent
 */
export { DataTableRow };