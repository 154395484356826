import { ApiBase } from './ApiBase'
import { IComment } from './types/result'
import IUser from './types/user'

export default class CommentsApi extends ApiBase {
  public async create(
    resultSetId: number,
    result_id: string,
    text: string,
    user: IUser,
    commentableType: string
  ): Promise<IComment> {
    if (this.isOffline()) {
      return this.offlineDb.addComment(resultSetId, result_id, text, user)
    }

    const response = await this.post<IComment>(
      '/comments',
      {
        text,
        commentable_id: result_id,
        commentable_type: commentableType
      }
    )

    return response.data
  }

  public async deleteComment(
    checklistId: number,
    resultSetId: number,
    result_id: string,
    commentId: number | string
  ) {
    if (this.isOffline()) {
      return this.offlineDb.deleteComment(resultSetId, result_id, commentId)
    }

    await this.delete(
      `/comments/${commentId}`
    )
  }
}
