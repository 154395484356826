import axios from 'axios';
import { Button, Modal, ModalBackground, ModalContent, ModalClose } from 'bloomer';
import { FilePondErrorDescription, FilePondFile } from 'filepond';
import { useCallback, useRef, useState } from 'react';
import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { FilePond } from 'react-filepond';
import { toast } from 'react-toastify';
import { PreSign } from '../../../../../types/PreSign';
import { CordovaWindow } from '../../../../../typings/global';
import { blob2file, isCordova } from '../../../../../util';
import LoadSpinner from '../../../../loadSpinner/LoadSpinner';
import MiniAccordion from '../../../../widgets/MiniAccordion';
const PRESIGN_URL = 'https://e6o8zd0ln4.execute-api.eu-central-1.amazonaws.com/api/';
const FILE_EXT_REGEX = /^(.*)\.(mp3|MP3|wav|WAV|ogg|OGG|m4a|M4A|AMR|amr|mp4|MP4|flac|FLAC|WEBA|weba)$/;
const ACCEPTED_FILE_TYPES = ['audio/mpeg', 'audio/wav', 'audio/m4a', 'audio/AMR', 'audio/x-flac', 'audio/webm', 'audio/x-m4a'];
declare let window: CordovaWindow;
interface Props {
  accessToken: string;
  checklistId: number;
  resultSetId: number;
  resultId: string;
  onUploadFinished: () => Promise<void>;
}
export const SpeechToText: React.FunctionComponent<Props> = ({
  accessToken,
  checklistId,
  resultId,
  resultSetId,
  onUploadFinished
}) => {
  const {
    t
  } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const pond = useRef();
  const closeDialog = useCallback(() => {
    if (!isUploading) setShowDialog(false);
  }, [isUploading]);
  const processFile = useCallback(async (file: File) => {
    setIsUploading(true);
    const matches = file.name.match(FILE_EXT_REGEX);
    const extension = matches[2] === 'm4a' ? 'mp4' : matches[2];
    const name = matches[1].replace(' ', '');
    const fileName = `${process.env.REACT_APP_SYSTEM}_${accessToken}_${checklistId}_${resultSetId}_${resultId}_${name}_${+new Date()}.${extension}`;
    const params = {
      key: fileName,
      type: file.type
    };
    try {
      const {
        data: presign
      } = await axios.get<PreSign>(PRESIGN_URL, {
        params
      });
      const formData = new FormData();
      Object.keys(presign.fields).forEach(fieldName => {
        formData.set(fieldName, presign.fields[fieldName]);
      });
      formData.append('file', file);
      await axios.post(presign.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast(t('result.sttUploaded'), {
        type: 'success'
      });
      if (pond && pond.current) {
        // @ts-ignore -- already checking if it is undefined. fuck you TS
        pond.current.removeFiles();
      }
      setIsUploading(false);
      closeDialog();
      await onUploadFinished();
    } catch (e) {
      toast((e as Error).message, {
        type: 'error'
      });
    }
  }, [accessToken, checklistId, closeDialog, onUploadFinished, resultId, resultSetId, t]);
  const handleClick = useCallback(async () => {
    if (isCordova()) {
      const file = await window.chooser.getFile(ACCEPTED_FILE_TYPES.join(','));
      if (file) {
        const blob = new Blob([file.data.buffer]);
        await processFile(blob2file(blob, file.name));
      }
    } else {
      setShowDialog(true);
    }
  }, [processFile]);
  const handleAddFile = useCallback((error: FilePondErrorDescription, {
    file
  }: FilePondFile) => {
    processFile((file as File));
  }, [processFile]);
  return <MiniAccordion className="result-speech-to-text" title={t('result.speechToText')} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Button onClick={handleClick}>
        <Trans>common.upload</Trans>
      </Button>
      {showDialog && <Modal className="tts-modal" isActive>
          <ModalBackground onClick={closeDialog} />
          <ModalContent>
            {isUploading && <LoadSpinner className="centered" />}
            <FilePond ref={pond} acceptedFileTypes={ACCEPTED_FILE_TYPES} onaddfile={handleAddFile} labelInvalidField={t('FilePond-InvalidField')} labelFileWaitingForSize={t('FilePond-FileWaitingForSize')} labelFileSizeNotAvailable={t('FilePond-FileSizeNotAvailable')} labelFileCountSingular={t('FilePond-FileCountSingular')} labelFileCountPlural={t('FilePond-FileCountPlural')} labelFileLoading={t('FilePond-FileLoading')} labelFileAdded={t('FilePond-FileAdded')} labelFileLoadError={t('FilePond-FileLoadError')} labelFileRemoved={t('FilePond-FileRemoved')} labelFileRemoveError={t('FilePond-FileRemoveError')} labelFileProcessing={t('FilePond-FileProcessing')} labelFileProcessingComplete={t('FilePond-FileProcessingComplete')} labelFileProcessingAborted={t('FilePond-FileProcessingAborted')} labelFileProcessingRevertError={t('FilePond-FileProcessingRevertError')} labelTapToCancel={t('FilePond-TapToCancel')} labelTapToRetry={t('FilePond-TapToRetry')} labelTapToUndo={t('FilePond-TapToUndo')} labelButtonRemoveItem={t('FilePond-ButtonRemoveItem')} labelButtonAbortItemLoad={t('FilePond-ButtonAbortItemLoad')} labelButtonRetryItemLoad={t('FilePond-ButtonRetryItemLoad')} labelButtonAbortItemProcessing={t('FilePond-ButtonAbortItemProcessing')} labelButtonUndoItemProcessing={t('FilePond-ButtonUndoItemProcessing')} labelButtonRetryItemProcessing={t('FilePond-ButtonRetryItemProcessing')} labelButtonProcessItem={t('FilePond-ButtonProcessItem')} />
          </ModalContent>
          <ModalClose onClick={closeDialog} />
        </Modal>}
    </MiniAccordion>;
};