import { gql } from '@apollo/client'

export interface FetchTemplateStoreEntriesVariables {
  limit: number | null
  favored: boolean | null
}

export interface FetchTemplateStoreEntriesResponse {
  templateStoreEntries: (TemplateStoreEntriesEntity)[]
}
export interface TemplateStoreEntriesEntity {
  description: string;
  favored: boolean;
  id: string;
  image: string | null;
  name: string;
  templateFile: string;
  published: boolean;
  templateStoreCategory: TemplateStoreCategory;
  templateStoreCategoryId: string;
  tags: Array<{id: number, title: string}>
}
export interface TemplateStoreCategory {
  id: string;
  name: string;
}

const FetchTemplateStoreEntries = gql(`
query FetchTemplateStoreEntries($limit: Int, $favored: Boolean) {
  templateStoreEntries(limit: $limit, favored: $favored) {
    description
    favored
    id
    image
    name
    templateFile
    published
    position
    templateFile
    templateStoreCategory {
      id
      name
    }
    templateStoreCategoryId
    tags {
      id
      title
    }
  }
}

`)

export default FetchTemplateStoreEntries
