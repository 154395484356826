import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultSetStatus } from '../../../api/types/resultSet';
interface IProps {
  status: ResultSetStatus;
}
export default function StatusText({
  status
}: IProps) {
  const {
    t
  } = useTranslation();
  const message = useMemo(() => {
    switch (status) {
      case 'closed':
        return t('Complete');
      case 'reopened':
        return t('Reopened');
      case 'open':
        return t('In Progress');
      case 'planned':
        return t('Planned');
      case 'closed_and_overdue':
        return t('ClosedAndOverdue');
      default:
        return status ?? '';
    }
  }, [status, t]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{message}</>;
}