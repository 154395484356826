import { Component } from 'react';
import TaskCard from './TaskCard';
export interface ICard {
  id: number;
  title: string;
  taskProgress: string;
  progress: number;
  information: string;
  dueDate: string;
  avgTimeNeeded: string;
  location: string;
  checklist_id: number;
  question_count: number;
  result_count: number;
  isOffline: boolean;
}
export interface ITaskCardsStackProps {
  cards: ICard[];
  cardAccepted: () => void;
  cardRejected: () => void;
  cardSkipped: () => void;
  hideCard: () => void;
}
export default class TaskCardsStack extends Component<ITaskCardsStackProps> {
  public render() {
    const {
      cards,
      cardAccepted,
      cardRejected,
      cardSkipped,
      hideCard
    } = this.props;
    return <div className="taskCardWrapper">
        <div className="cards">
          {cards.map((card, index) => <TaskCard key={card.id} card={card} cards={this.props.cards} isCurrent={index === 0} cardAccepted={cardAccepted} cardRejected={cardRejected} cardSkipped={cardSkipped} hideCard={hideCard} />)}
        </div>
      </div>;
  }
}