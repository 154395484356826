import { MenuItem, TextField } from '@mui/material';
import { Icon } from 'bloomer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskPriority } from '../../api/tasksApi';
interface IPriorityOptions {
  value: TaskPriority;
  label: string;
  icon: React.ReactNode;
}
interface IProps {
  value: TaskPriority;
  onChange: (value: TaskPriority) => void;
  readonly?: boolean;
  disabled?: boolean;
}
export default function PrioritySelect({
  value,
  onChange,
  readonly = false,
  disabled = false
}: IProps) {
  const {
    t
  } = useTranslation();
  const options: IPriorityOptions[] = [{
    icon: <Icon className="fas fa-arrow-down" hasTextColor="grey" />,
    label: t('task.priorities.trivial'),
    value: 'trivial'
  }, {
    value: 'minor',
    label: t('task.priorities.minor'),
    icon: <Icon className="fas fa-angle-double-down" hasTextColor="success" />
  }, {
    value: 'medium',
    label: t('task.priorities.medium'),
    icon: <Icon className="fas fa-arrow-up" hasTextColor="danger" />
  }, {
    value: 'critical',
    label: t('task.priorities.critical'),
    icon: <Icon className="fas fa-angle-double-up" hasTextColor="danger" />
  }, {
    value: 'blocker',
    label: t('task.priorities.blocker'),
    icon: <Icon className="fas fa-ban" hasTextColor="danger" />
  }];
  return <TextField value={value} onChange={e => onChange((e.target.value as TaskPriority))} label={t('task.priority')} select inputProps={{
    readOnly: readonly
  }} disabled={disabled}>
      {options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
    </TextField>;
}