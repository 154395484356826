import { ApiBase } from './ApiBase'
import ITeam, { ITeamDto } from './types/team'

export default class TeamApi extends ApiBase {
  public find(teamId: number) {
    return this.get<ITeam>(`/corporations/1/teams/${teamId}`)
  }

  public async all(corporationId: number, full = false, accessToken?: string | null) {
    if (this.isOffline()) {
      return this.offlineDb.teams.toArray()
    }

    let url = `/corporations/${corporationId}/teams?full=${full}`

    if (accessToken) {
      url += `&access_token=${accessToken}`
    }

    const response = await this.get<ITeam[]>(url)
    return response.data
  }

  public update(corporationId: number, teamId: number, team: ITeamDto) {
    return this.put<ITeam>(`/corporations/${corporationId}/teams/${teamId}`, team)
  }

  public deleteTeam(corporationId: number, teamId: number) {
    return this.delete<ITeam>(`/corporations/${corporationId}/teams/${teamId}`)
  }

  public create(corporationId: number, team: ITeamDto) {
    return this.post<ITeam>(`/corporations/${corporationId}/teams`, team)
  }
}
