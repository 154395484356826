import { gql } from '@apollo/client'

export interface Measure {
  id: string;
}

export interface CreateMeasure {
  errors: string[]
  measure: Measure;
}

export interface CreateMeasureResponse {
  createMeasure: CreateMeasure;
}

export interface CreateMeasurePayload {
  position: number;
  title: string;
  severity: string;
  checklistId: number;
  detailedAnswer: string;
  measure: string;
  code: string
}

const CREATE_MEASURE = gql`
mutation createMeasure(
  $title: String!
  $severity: String!
  $checklistId: ID!
  $detailedAnswer: String!
  $measure: String!
  $position: Int!
  $code: String!
) {
  createMeasure(
    title: $title
    checklistId: $checklistId
    severity: $severity
    detailedAnswer: $detailedAnswer
    position: $position
    measure: $measure
    code: $code
  ) {
    errors
    measure {
      id
    }
  }
}
`

export default CREATE_MEASURE
