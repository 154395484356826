import { AxiosError } from 'axios';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import IResultSet from '../../../api/types/resultSet';
import { IAlphaprocessState } from '../../../store';
import { alert } from '../../../util';
import DOMPurify from 'dompurify';
interface IProps {
  children: any;
  resultSet: IResultSet;
  onFailure?: (error: Error) => void;
  onClick?: () => void;
  accessToken?: string | null;
  isButton: boolean;
}
const RETRIES = 40;
export default function ReportDownloader({
  children,
  resultSet,
  onFailure,
  onClick,
  accessToken,
  isButton
}: IProps) {
  const token = useSelector((state: IAlphaprocessState) => state.core.jwt);
  const {
    t
  } = useTranslation();
  async function downloadReport(run = 1) {
    if (run > RETRIES) {
      toast(t('resultSet.couldNotGenerateReport'), {
        type: 'error'
      });
      return;
    }
    let fetchUrl = `${process.env.REACT_APP_API_BASE_URL}/checklists/${resultSet.checklist_id}/result_sets/${resultSet.id}/report.pdf`;
    if (accessToken) {
      fetchUrl += `?access_token=${accessToken}`;
    }
    try {
      const response = await fetch(fetchUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        const parsedBody = await response.json();
        if ('cordova' in window) {
          (window as any).cordova.InAppBrowser.open(parsedBody.url, '_system', 'location=yes');
        } else {
          const pdfRequest = await fetch(parsedBody.url);
          const blob = await pdfRequest.blob();
          const url = (window as Window & typeof globalThis).URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;

          // Sanitize the title to prevent XSS attacks
          /* ✗ [Medium] Cross-site Scripting (XSS) Path: src/components/checklists/result/ReportDownloader.tsx, line 58 Info: Unsanitized input from data from a remote resource flows into appendChild, where it is used to dynamically construct the HTML page on client side. This may result in a DOM Based Cross-Site Scripting attack (DOMXSS). */
          const sanitizedTitle = DOMPurify.sanitize(resultSet.title);
          link.setAttribute('download', `${encodeURI(sanitizedTitle)}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      } else if (response.status === 420) {
        setTimeout(() => downloadReport(run + 1), 3000);
        if (run === 1) {
          toast(t('resultSet.generatingReport'), {
            type: 'info'
          });
        }
      } else if (response.status === 204) {
        alert(t('resultSet.noDataForReport'));
      } else {
        toast(t('resultSet.couldNotGenerateReport'), {
          type: 'error'
        });
      }
    } catch (error) {
      if (typeof onFailure === 'function') {
        onFailure((error as AxiosError));
      } else {
        toast(t('resultSet.couldNotGenerateReport'), {
          type: 'error'
        });
      }
    }
  }
  const onClickDownloadButton = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (onClick) {
      onClick();
    }
    return downloadReport();
  };
  return <Link onClick={onClickDownloadButton} className={isButton ? 'report-downloader is-link button' : 'report-downloader is-link'} to="#">
      {children}
    </Link>;
}