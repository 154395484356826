import { useQuery } from '@apollo/client';
import { Button } from 'bloomer';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { styled } from '@mui/material/styles';
import { Alert, Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { FunctionComponent, useCallback, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { toggleStartWithTemplate } from '../../actions/menuActions';
import FetchTemplateStoreEntries, { FetchTemplateStoreEntriesResponse, FetchTemplateStoreEntriesVariables, TemplateStoreEntriesEntity } from '../../graphql/queries/FetchTemplateStoreEntries';
import { IAlphaprocessState } from '../../store';
import LoadSpinner from '../loadSpinner/LoadSpinner';
import TemplateStoreDetailsDialog from './TemplateStoreDetailsDialog';
import TemplateStoreDialog from './TemplateStoreDialog';
import TemplateStoreEntryCard from './TemplateStoreEntryCard';
import CarouselControlsArrowsBasic1 from '../CarouselControlsArrowsBasic1';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const StyledSlider = styled(Slider)(({
  theme
}) => ({
  '.slick-track': {
    paddingBottom: theme.spacing(0.5)
  }
}));
interface Props {
  compact?: boolean;
}
const StartWithTemplate: FunctionComponent<Props> = ({
  compact = false
}) => {
  const theme = useTheme();
  const open = useSelector((state: IAlphaprocessState) => state.core.startWithTemplateOpen);
  const dispatch = useDispatch();
  const carouselRef = useRef<Slider | null>(null);
  const [selectedEntry, setSelectedEntry] = useState<string | null>(null);
  const [importRunning] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [allEntriesDialogWasOpen, setAllEntriesDialogWasOpen] = useState(false);
  const toggle = useCallback(() => {
    dispatch(toggleStartWithTemplate());
  }, [dispatch]);
  const {
    loading,
    error,
    data
  } = useQuery<FetchTemplateStoreEntriesResponse, FetchTemplateStoreEntriesVariables>(FetchTemplateStoreEntries, {
    variables: {
      limit: 10,
      favored: true
    },
    fetchPolicy: 'cache-and-network'
  });
  const toggleDialog = useCallback(() => {
    setDialogOpen(!dialogOpen);
    setAllEntriesDialogWasOpen(false);
  }, [dialogOpen]);
  const onCardClicked = useCallback(({
    id
  }: TemplateStoreEntriesEntity) => {
    setAllEntriesDialogWasOpen(dialogOpen);
    setDialogOpen(false);
    setSelectedEntry(id);
  }, [dialogOpen]);
  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: Math.min(compact ? 6 : 10, data?.templateStoreEntries?.length ?? 0),
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [{
      breakpoint: theme.breakpoints.values.xl,
      settings: {
        slidesToShow: Math.min(compact ? 4 : 8, data?.templateStoreEntries?.length ?? 0)
      }
    }, {
      breakpoint: theme.breakpoints.values.lg,
      settings: {
        slidesToShow: Math.min(compact ? 3 : 6, data?.templateStoreEntries?.length ?? 0)
      }
    }, {
      breakpoint: theme.breakpoints.values.md,
      settings: {
        slidesToShow: Math.min(compact ? 2 : 4, data?.templateStoreEntries?.length ?? 0)
      }
    }, {
      breakpoint: theme.breakpoints.values.sm,
      settings: {
        slidesToShow: Math.min(compact ? 1 : 2, data?.templateStoreEntries?.length ?? 0)
      }
    }, {
      breakpoint: theme.breakpoints.values.xs,
      settings: {
        slidesToShow: Math.min(1, data?.templateStoreEntries?.length ?? 0)
      }
    }]
  };
  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };
  const handleNext = () => {
    carouselRef.current?.slickNext();
  };
  if (loading) return <LoadSpinner className="centered" />;
  if (error) return <Alert severity="error">{error.message}</Alert>;
  if (!data || data.templateStoreEntries.length === 0) return null;
  return <Box sx={{
    px: 1,
    mb: 1
  }}>
      {importRunning && <LoadSpinner className="centered" />}
      <TemplateStoreDialog open={dialogOpen} onClose={toggleDialog} onCardClick={onCardClicked} />
      {selectedEntry && <TemplateStoreDetailsDialog id={selectedEntry} onClose={() => {
      setSelectedEntry(null);
      if (allEntriesDialogWasOpen) {
        setDialogOpen(true);
      }
    }} />}
      <Stack direction="row">
        <Typography sx={{
        flexGrow: 1
      }} variant="h6">
          <Trans>templateStore.startWithTemplate</Trans>
        </Typography>
        <Button onClick={toggleDialog} style={{
        marginRight: '12px',
        backgroundColor: theme.palette.primary.light,
        minWidth: '135px'
      }}>
          <Trans>templateStore.allTemplates</Trans>
        </Button>
        <IconButton onClick={toggle}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Stack>
      {open && <>
        <CarouselControlsArrowsBasic1 arrowLine onNext={handleNext} onPrevious={handlePrevious} sx={{
        position: 'static',
        '& button': {
          color: 'text.primary'
        }
      }} />
        <StyledSlider ref={carouselRef} {...settings}>
          {data.templateStoreEntries.map(entry => <TemplateStoreEntryCard key={entry.id} entry={entry} onClick={onCardClicked} />)}
        </StyledSlider>
      </>}
    </Box>;
};
export default StartWithTemplate;