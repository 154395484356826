import clsx from 'clsx';
import Lottie from 'react-lottie-player';
import * as animationData from './animationData.json';
interface IProps {
  className?: string;
}
export default function LoadSpinner({
  className
}: IProps) {
  return <span className={clsx(className, 'load-spinner')}>
      <Lottie loop play animationData={(animationData as any).default} style={{
      height: 40
    }} rendererSettings={{
      preserveAspectRatio: 'xMidYMid slice'
    }} />
    </span>;
}