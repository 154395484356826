import clsx from 'clsx';
import { useState } from 'react';
import * as React from 'react';
import { IDropdownItem } from '../../../../../api/types/checklist';
import { highlightTimeout } from '../../../../../constants';
import { useEventBus } from '../../../../../pages/checklists/ResultView/EventBus';
import QuestionDropdownOption from './QuestionDropdownOption';
interface Props {
  isMulti: boolean;
  values: number[];
  items: IDropdownItem[];
  onOptionTextChanged: (id: number, e: React.FocusEvent<HTMLInputElement>) => void;
  getOptionText: (id: number) => string;
  disabled: boolean;
  isHorizontal: boolean;
  onChange: (id: number) => void;
  resultId: string;
}
function QuestionDropdownInput({
  onChange,
  items,
  isMulti,
  values,
  getOptionText,
  onOptionTextChanged,
  disabled,
  isHorizontal,
  resultId
}: Props) {
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  useEventBus('result', resultId, () => {
    setIsHighlighted(true);
    setTimeout(() => setIsHighlighted(false), highlightTimeout);
  });
  const options = items.map(item => <QuestionDropdownOption isMulti={isMulti} selected={values.includes(item.id)} item={item} key={item.id} optionText={getOptionText(item.id)} onOptionTextChanged={e => onOptionTextChanged(item.id, e)} isHorizontal={isHorizontal} disabled={disabled} onChange={onChange} />);
  return <ul className={clsx('dropdown-question-items', isHighlighted && 'is-highlighted')}>
      {options}
    </ul>;
}
export default QuestionDropdownInput;