import { lazy, Suspense } from 'react';
import { IChecklist } from '../../../../api/types/checklist';
import IResultSet from '../../../../api/types/resultSet';
import LoadSpinner from '../../../loadSpinner/LoadSpinner';
const LazyDialog = lazy(() => import( /* webpackChunkName: "create_catalog_dialog" */'./CreateCatalogDialog'));
interface Props {
  template: IChecklist;
  checklist: IResultSet;
  onClose: (reload?: boolean) => void;
}
export default function (props: Props) {
  return <Suspense fallback={<LoadSpinner className="centered" />}>
      <LazyDialog {...props} />
    </Suspense>;
}