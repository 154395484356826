import { DataStoreActions } from '../actions/dataStoreActions'
import { ITag } from '../api/types/ressource'
import ITeam from '../api/types/team'
import IUser from '../api/types/user'

export interface DataStore {
  tags: ITag[]
  users: IUser[]
  teams: ITeam[]
}

export const initialState: DataStore = {
  tags: [],
  users: [],
  teams: []
}

export function dataReducer(state: DataStore = initialState, action: DataStoreActions): DataStore {
  switch (action.type) {
    case 'DATA_UPDATE_TAG':
      return {
        ...state,
        tags: action.tags
      }
    case 'DATA_UPDATE_USERS':
      return {
        ...state,
        users: action.users
      }
    case 'DATA_UPDATE_TEAMS':
      return {
        ...state,
        teams: action.teams
      }
    case 'DATA_ADD_TEAM':
      return {
        ...state,
        teams: [...state.teams, action.team]
          .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
      }
    default:
      return state
  }
}
